import Providers from "@figmentjs/web-core/src/components/providers/";
import {
  StakeEthereumMutationVariables,
  useStakeEthereumMutation,
} from "../../../graphql/partners/generated/gql";
import { StakingFlowData } from "../../staking/components/types";

type Props = {
  input: StakeEthereumMutationVariables;
  onError: (values: Partial<StakingFlowData>) => void;
  onCreation: (values: Partial<StakingFlowData>) => void;
};

export const useStakeEthereum = ({ input, onError, onCreation }: Props) => {
  const { mutateAsync: stakeEthereum, isLoading } = useStakeEthereumMutation();
  const { network } = Providers.useEthereumWallet();

  const stake = async () => {
    if (!network) {
      onError({
        errors: [{ message: "Could not resolve the ETH network.", code: "" }],
      });
      throw new Error("No value for 'network'");
    }

    const {
      ethereumStakingRequestCreate: { data, userErrors },
    } = await stakeEthereum(input);

    if (userErrors?.length || !data) {
      onError({
        errors: userErrors,
      });
      return;
    }

    onCreation({
      flowId: data.id,
      unsignedRawTransaction: data.unsignedTransactionSerialized,
    });
  };

  return { stake, isLoading };
};
