import Providers from "@figmentjs/web-core/src/components/providers";
import { UnstakingFlowData } from "../..";
import { useQueryClient } from "@tanstack/react-query";
import {
  useStakingActivityQuery,
  useStakingActivityV2Query,
  useUnstakeEthereumMutation,
} from "../../../graphql/partners/generated/gql";
import { networkMap } from "../../staking/components/activity-table/activity-table";
import { getCoreAPINetwork } from "../../staking/components/utils/get-core-api-network";

type Props = {
  onError: (values: Partial<UnstakingFlowData>) => void;
  onUnstakeSuccess: (values: Partial<UnstakingFlowData>) => void;
};

export const useUnstakeEthereum = ({ onError, onUnstakeSuccess }: Props) => {
  const { network, account } = Providers.useEthereumWallet();

  const { mutateAsync: unstakeEthereum, isLoading: isProcessingUnstake } =
    useUnstakeEthereumMutation();

  const queryClient = useQueryClient();

  const unstakeValidators = async ({ amount }: { amount: number }) => {
    if (!network) {
      onError({
        errors: [
          {
            message: "Could not resolve the ETH network.",
            code: "NETWORK_NOT_RESOLVED",
          },
        ],
      });
      throw new Error("No value for 'network'");
    }

    const { ethereumExitRequestCreate: result } = await unstakeEthereum({
      amountEth: amount,
      withdrawalAddress: Providers.getEthereumAddress(account as string),
      network: getCoreAPINetwork(network),
    });

    if (result.userErrors || !result.data) {
      onError({
        errors: result.userErrors ?? [],
      });
      return;
    }

    queryClient.invalidateQueries(
      useStakingActivityV2Query.getKey({
        network: networkMap[network!]!,
      })
    );

    onUnstakeSuccess({ amount });

    queryClient.invalidateQueries(
      useStakingActivityQuery.getKey({
        network: networkMap[network!]!,
      })
    );

    onUnstakeSuccess({ amount });
  };

  return {
    unstakeValidators,
    isProcessingUnstake: isProcessingUnstake,
  };
};
