import React, { Fragment, useMemo, useRef, useState } from "react";
import { twMerge as tw } from "tailwind-merge";
import { HeaderProps, TableProps } from "./Table.types";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  createColumnHelper,
  getExpandedRowModel,
  ColumnDef,
} from "@tanstack/react-table";
import { TableHeaderCell } from "../TableHeaders";
import { Spinner } from "../../graphics/Spinner";

const FigmentTable = <TData extends object>({
  rows,
  cols,
  className,
  containerClassName,
  getRowCanExpand,
  renderSubComponent,
  cellSpacing = false,
  isLoading = false,
  darkenOddRows = true,
  showHeaderBorder = true,
  renderHeader = true,
  hasSorting = true,
  sorting = [],
  onSorting,
  filters,
  onFiltering,
}: TableProps<TData>) => {
  const [autoSorting, setAutoSorting] = useState<SortingState>([]);
  // 🔀 For manual sorting...
  const manualSorting = Boolean(sorting.length && onSorting && hasSorting);
  // 1️⃣ Determine which columns should have sorting enabled
  const columns = useMemo<ColumnDef<TData>[]>(
    () =>
      !manualSorting
        ? cols
        : cols.map((col) => ({
            ...col,
            enableSorting: Boolean(
              col.id && sorting.find(({ field }) => field === col.id)
            ),
          })),
    [cols, manualSorting, sorting]
  );
  // 2️⃣ Get table sorting state from list of sorted columns
  const sortingState: SortingState | undefined = useMemo(() => {
    if (!hasSorting) return undefined;
    if (!manualSorting) return autoSorting;
    return sorting.reduce(
      (prevSort, { field, sortDirection }) =>
        sortDirection
          ? [...prevSort, { id: field, desc: sortDirection === "desc" }]
          : prevSort,
      [] as SortingState
    );
  }, [autoSorting, manualSorting, sorting, hasSorting]);

  /** Storing previous data to display it in loading states, like sorting, pagination, etc. */
  const prevData = useRef<TData[]>(rows);
  const data = useMemo<TData[]>(() => {
    if (isLoading) {
      return prevData.current;
    } else {
      prevData.current = rows;
      return rows;
    }
  }, [isLoading, rows]);

  const table = useReactTable<TData>({
    data,
    columns,
    state: { sorting: sortingState },
    manualSorting,
    enableMultiSort: false,
    onSortingChange: setAutoSorting,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand,
  });

  return (
    <div className={tw(containerClassName, `w-full`)}>
      <table
        className={tw(className, `w-full h-0`)}
        cellPadding={0}
        cellSpacing={0}
      >
        {renderHeader && (
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className={tw(
                  "w-full h-full",
                  showHeaderBorder && "border-t border-b border-basic-200",
                  cellSpacing &&
                    "[&>th]:px-1 [&>th:first-child]:pl-2 [&>th:last-child]:pr-2",
                  cellSpacing &&
                    !darkenOddRows &&
                    "[&>th:first-child]:pl-0 [&>th:last-child]:pr-0"
                )}
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHeader<TData>
                      key={header.id}
                      header={header}
                      enableSorting={hasSorting}
                      onSorting={manualSorting ? onSorting : undefined}
                      enableFiltering={
                        filters && filters.find((f) => f.field === header.id)
                          ? true
                          : false
                      }
                      filterOptions={
                        filters?.find((f) => f.field === header.id)?.options ??
                        []
                      }
                      onFiltering={(updatedOptions) => {
                        const currentFilter = filters?.find(
                          (f) => f.field === header.id
                        );
                        if (filters && currentFilter) {
                          currentFilter.options = [...updatedOptions];
                          onFiltering?.(filters);
                        }
                      }}
                    />
                  );
                })}
              </tr>
            ))}
          </thead>
        )}
        <tbody
          className={tw(
            isLoading && "opacity-30",
            cellSpacing &&
              "[&_td]:px-1 [&_td:first-child]:pl-2 [&_td:last-child]:pr-2",
            cellSpacing &&
              !darkenOddRows &&
              "[&_td:first-child]:pl-0 [&_td:last-child]:pr-0"
          )}
        >
          {table.getRowModel().rows.map((row, idx) => (
            <Fragment key={row.id}>
              <tr
                key={row.id}
                className={tw(
                  "w-full h-full border-b border-basic-200 p-2",
                  "hover:bg-basic-50 focus-within:bg-basic-50",
                  darkenOddRows && idx % 2 === 0 ? "bg-basic-100" : "bg-white",
                  row.getCanExpand() && "cursor-pointer"
                )}
                onClick={() => {
                  if (!row.getCanExpand()) return;
                  const { getIsSomeRowsExpanded, resetExpanded } = table;
                  if (getIsSomeRowsExpanded()) resetExpanded();
                  const { getIsExpanded, getToggleExpandedHandler } = row;
                  if (!getIsExpanded()) getToggleExpandedHandler()();
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.column.id}
                    className="h-full"
                    style={{ width: cell.column.getSize() }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              {row.getIsExpanded() && renderSubComponent && (
                <tr key={row.id + "expanded"} className="h-full">
                  <td
                    key={row.id}
                    className="h-full"
                    colSpan={row.getVisibleCells().length}
                  >
                    {renderSubComponent({ row })}
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>

      {isLoading && !data.length && (
        <div className="my-5 flex items-center justify-center">
          <Spinner size={50} />
        </div>
      )}
    </div>
  );
};

const TableHeader = <TData,>({
  header,
  enableSorting,
  onSorting,
  enableFiltering,
  filterOptions,
  onFiltering,
}: HeaderProps<TData>) => {
  const renderControls =
    (header.column.getCanSort() && enableSorting) || enableFiltering;
  return (
    <th key={header.id} colSpan={header.colSpan} className="h-full">
      {header.isPlaceholder ? null : renderControls ? (
        <div className="text-left">
          <TableHeaderCell
            hasSorting={header.column.getCanSort() && enableSorting}
            sortDirection={
              enableSorting
                ? header.column.getIsSorted() || undefined
                : undefined
            }
            onSorting={() => {
              if (onSorting) {
                const { id, getNextSortingOrder } = header.column;
                const sortDirection = getNextSortingOrder();
                onSorting({ field: id, sortDirection });
              } else {
                header.column.toggleSorting();
              }
            }}
            hasFiltering={enableFiltering}
            filterOptions={filterOptions}
            onFiltering={onFiltering}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
          </TableHeaderCell>
        </div>
      ) : typeof header.column.columnDef.header === "function" ? (
        // Renders a custom component instead of <TableHeaderCell>
        flexRender(header.column.columnDef.header, header.getContext())
      ) : (
        <TableHeaderCell>
          {flexRender(header.column.columnDef.header, header.getContext())}
        </TableHeaderCell>
      )}
    </th>
  );
};

export { createColumnHelper };
export default FigmentTable;
