import { useQuery, hashQueryKey } from "@tanstack/react-query";
import { useCurrentETHAddress } from "@figmentjs/web-core/src/components/providers/EthereumWalletProvider/hooks";
import Providers from "@figmentjs/web-core/src/components/providers";
import { useSignatureAccessToken } from "../use-signature-access-token";

export const useValidateAccessToken = () => {
  const { account } = Providers.useEthereumWallet();
  const { currentETHAddress } = useCurrentETHAddress();
  const { signatureAccessToken } = useSignatureAccessToken({
    account,
  });

  const accessTokenHeaderKey = `X-Figment-Signature-Access-Token-${currentETHAddress!}`;

  const { isLoading, data } = useQuery({
    enabled: !!currentETHAddress,
    queryKey: ["validate-access-token"],
    cacheTime: 0,
    queryKeyHashFn: () =>
      hashQueryKey([
        "validate-access-token",
        currentETHAddress,
        signatureAccessToken,
      ]),
    queryFn: () =>
      fetch("/api/validate-access-token", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          ...(currentETHAddress
            ? { "X-Figment-Wallet-Address": currentETHAddress }
            : {}),
          ...(signatureAccessToken
            ? { [accessTokenHeaderKey]: signatureAccessToken }
            : {}),
        },
      }).then(async (res) => res.json()),
  });

  return { isLoading, data };
};
