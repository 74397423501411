import React, { memo } from "react";
import { twMerge as tw } from "tailwind-merge";
import * as SwitchPrimitive from "@radix-ui/react-switch";
import { SwitchProps, SwitchSize } from "./Switch.types";

const switchRootSizeMap: { [key in SwitchSize]: string } = {
  large: "w-[76px] h-9",
  medium: "w-14 h-7",
  small: "w-[42px] h-6",
};

const switchThumbSizeMap: { [key in SwitchSize]: string } = {
  large: "w-[30px] h-[30px] data-[state=checked]:translate-x-[44px]",
  medium: "w-6 h-6 data-[state=checked]:translate-x-[30px]",
  small: "w-[18px] h-[18px] data-[state=checked]:translate-x-[22px]",
};

const defaultRootStyles =
  "relative rounded-full bg-basic-300 data-[state=checked]:bg-green-800";
const defaultThumbStyles =
  "rounded-full block bg-white drop-shadow-lg transition-transform duration-100 translate-x-0.5 will-change-transform";

const Switch: React.FC<SwitchProps> = ({
  testId,
  size = "medium",
  onCheckedChange,
  checked,
  disabled = false,
  label,
}) => {
  return (
    <div data-testid={testId} className="flex flex-row relative align-middle">
      <SwitchPrimitive.Root
        className={tw(
          switchRootSizeMap[size],
          defaultRootStyles,
          disabled && "opacity-60 cursor-not-allowed"
        )}
        onCheckedChange={onCheckedChange}
        checked={checked}
        disabled={disabled}
      >
        <SwitchPrimitive.Thumb
          className={tw(switchThumbSizeMap[size], defaultThumbStyles)}
        />
      </SwitchPrimitive.Root>
      {label && <span className="text-base leading-6 px-3">{label}</span>}
    </div>
  );
};

export default memo(Switch);
