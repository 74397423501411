import React, { memo } from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";
import { twMerge as tw } from "tailwind-merge";
import { ErrorText, HelperText, InputAdornment } from "../shared/components";
import { InputNumberProps } from "./InputNumber.types";

/**
 * Renders a numeric input field.
 *
 * @param InputNumberProps
 * @returns The InputNumber component
 */
const InputNumber: React.FC<InputNumberProps> = ({
  allowNegative,
  autoFocus,
  decimalScale,
  defaultValue,
  disabled,
  endAdornment,
  error,
  helperText,
  id,
  isAllowed,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  onKeyUp,
  placeholder,
  prefix,
  readOnly,
  required,
  size = "base",
  startAdornment,
  suffix,
  testId,
  thousandSeparator,
  value,
}) => {
  const props: NumberFormatProps = {
    autoFocus,
    disabled,
    id,
    isAllowed,
    name,
    onBlur,
    onValueChange: (value) => onChange && onChange(value),
    onFocus,
    onKeyDown,
    onKeyUp,
    placeholder,
    prefix,
    readOnly,
    required,
    suffix,
  };
  const inputHeight = size === "small" ? "h-6" : "h-10";
  const inputClassName = tw(
    `
      w-full appearance-none bg-white border border-black rounded text-black font-normal text-base leading-5 font-sans placeholder-basic-800 placeholder-opacity-60
      focus:shadow-md focus:outline-none focus:border-green focus:ring-1 focus:ring-green
      disabled:border-basic-300 disabled:shadow-none
      invalid:border-error
      focus:invalid:border-error focus:invalid:ring-error
    `,
    error && "border-error focus:border-error focus:ring-error",
    size === "small" ? "py-2 px-4" : "p-4",
    disabled && "cursor-not-allowed",
    startAdornment && "pl-10",
    endAdornment && "pr-10",
    inputHeight
  );

  return (
    <div className="w-full">
      <div className="flex flex-row relative">
        {startAdornment && (
          <div className={`absolute left-4 flex items-center ${inputHeight}`}>
            <InputAdornment placement="start" adornment={startAdornment} />
          </div>
        )}
        <NumberFormat
          {...props}
          allowNegative={allowNegative}
          value={value?.toString()}
          defaultValue={defaultValue}
          className={inputClassName}
          decimalScale={decimalScale}
          displayType="input"
          thousandSeparator={thousandSeparator}
          prefix={prefix}
          suffix={suffix}
          inputMode="numeric"
          data-testid={testId}
        />
        {endAdornment && (
          <div className="absolute right-4 flex items-center h-full">
            <InputAdornment placement="end" adornment={endAdornment} />
          </div>
        )}
      </div>
      {helperText && (
        <div className="mx-2 my-0">
          <HelperText>{helperText}</HelperText>
        </div>
      )}
      {error && (
        <div className="mx-2 my-0">
          <ErrorText>{error}</ErrorText>
        </div>
      )}
    </div>
  );
};

export default memo(InputNumber);
