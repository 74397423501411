import React from "react";
import { components, GroupBase, SingleValueProps } from "react-select";
import { OptionType } from "../InputSelect.types";

const SingleValue = <TOptionType extends OptionType>(
  props: SingleValueProps<TOptionType, false, GroupBase<TOptionType>>
) => {
  const { selectProps } = props;
  const { renderSelectedOption } = selectProps;

  return (
    <components.SingleValue {...props}>
      {renderSelectedOption
        ? renderSelectedOption({ children: props.children })
        : props.children}
    </components.SingleValue>
  );
};

export default SingleValue;
