export enum CursorActions {
  NEXT = "NEXT",
  PREVIOUS = "PREVIOUS",
  FIRST = "FIRST",
  LAST = "LAST",
}

export type TableCursorPaginationProps = {
  hasNext: boolean;
  hasPrevious: boolean;
  onAction: (action: keyof typeof CursorActions) => void;
};

export type TableOffsetPaginationProps = {
  currentPage: number;
  totalPages: number;
  onPageSelected: (pageNumber: number) => void;
};
