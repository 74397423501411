import React from "react";
import { FormikProps } from "formik";
import { Button } from "@figmentjs/web-core";
import { FormConfig } from "../../amount-step";
import { StakingFlowData } from "../../../types";

type Props = {
  formik: FormikProps<FormConfig>;
  flowData: StakingFlowData;
};

export const ContinueButton: React.FC<Props> = ({ formik, flowData }) => {
  return (
    <div className="flex mt-6 justify-center">
      <Button
        type="submit"
        fullWidth
        disabled={
          !(
            formik.isValid &&
            (formik.dirty || (flowData.amount && flowData.acceptedTerms))
          )
        }
        onClick={formik.handleSubmit}
      >
        Continue
      </Button>
    </div>
  );
};
