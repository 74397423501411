import React, { memo } from "react";
import { DayPicker, DayPickerRangeProps } from "react-day-picker";
import "react-day-picker/dist/style.css";
import "./styles/day-picker-monthly-view.css";

const DayPickerMonthView: React.FC<DayPickerRangeProps> = ({
  mode,
  defaultMonth,
  numberOfMonths = 2,
  selected,
  onSelect,
  fromDate,
  toDate,
}) => {
  return (
    <div className="border solid border-basic-300 rounded-lg w-fit select-none">
      <DayPicker
        mode={mode}
        defaultMonth={defaultMonth}
        selected={selected}
        onSelect={onSelect}
        numberOfMonths={numberOfMonths}
        modifiersClassNames={{
          range_start: "range-start",
          range_end: "range-end",
        }}
        fromDate={fromDate}
        toDate={toDate}
      />
    </div>
  );
};

export default memo(DayPickerMonthView);
