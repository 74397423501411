import React from "react";
import { BodyText, Spinner } from "@figmentjs/web-core";

export const ProcessingStep: React.FC = () => {
  return (
    <div className="flex flex-col sm:flex-row sm:h-full">
      <div className="py-32 sm:flex-1 sm:px-14 sm:py-44">
        <div className="flex flex-col items-center sm:pb-[3px]">
          <Spinner />
          <div className="mt-6 space-y-6 sm:mt-8 sm:space-y-4 text-center">
            <BodyText size="xl" weight="semibold">
              Processing Transaction...
            </BodyText>
          </div>
        </div>
      </div>
    </div>
  );
};
