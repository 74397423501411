import React from "react";
import { BodyText, Button } from "@figmentjs/web-core";

export const Resources: React.FC = () => {
  const links = [
    {
      title: `ETH Withdrawals: A Comprehensive Guide`,
      url: "https://figment.io/2023/02/22/ethereum-withdrawals-a-comprehensive-faq/",
    },
    {
      title: "Figment’s MEV Policy",
      url: "https://figment.io/insights/figments-mev-policy-supporting-neutral-secure-and-open-solutions/",
    },
    {
      title: `ETH FAQ’s`,
      url: "https://figment.io/staking/stake-eth/",
    },
  ];

  return (
    <>
      <div className="hidden sm:block">
        <BodyText size="lg" weight="bold">
          Resources
        </BodyText>
      </div>
      <div className="block sm:hidden">
        <BodyText size="base" weight="bold">
          Resources
        </BodyText>
      </div>
      <div className="pb-2" />
      <ul>
        {links.map((link, index) => (
          <li key={index} className="py-3">
            <Button
              palette="text"
              size="large"
              iconAfter="MdOpenInNew"
              openInNewTab
              href={link.url}
            >
              {link.title}
            </Button>
          </li>
        ))}
      </ul>
    </>
  );
};
