import React from "react";
import { components, GroupBase, OptionProps } from "react-select";
import { OptionType } from "../InputSelect.types";

const Option = <TOptionType extends OptionType>(
  props: OptionProps<TOptionType, false, GroupBase<TOptionType>>
) => {
  const { selectProps } = props;
  const { renderOption } = selectProps;

  return (
    <components.Option {...props}>
      {renderOption ? renderOption(props.data) : props.children}
    </components.Option>
  );
};

export default Option;
