"use client";

import React from "react";
import { useFormikContext } from "formik";
import { twMerge as tw } from "tailwind-merge";
import { BodyText, Switch, Tooltip, Icon } from "@figmentjs/web-core";
import { FormFields, FormConfig } from "../../amount-step";
import { EigenLayerRestaking } from "../eigenlayer-restaking";

export const RestakingToggle: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<FormConfig>();
  const { isRestakingEnabled } = values;

  return (
    <div>
      <div className="justify-between items-center flex pb-3">
        <div className="items-center gap-2 flex">
          <Icon icon="EigenLayerLogo" />
          <BodyText size="base" as="p">
            EigenLayer Restaking
          </BodyText>
          <Tooltip
            overlayContent={
              <div>
                <BodyText color="white" as="p">
                  Figment supports EigenLayer Restaking by allowing users to
                  deploy EigenPods.
                </BodyText>
              </div>
            }
          >
            <Icon icon="MdInfoOutline" />
          </Tooltip>
        </div>
        <Switch
          checked={isRestakingEnabled}
          onCheckedChange={(value: boolean) =>
            setFieldValue(FormFields.IS_RESTAKING_ENABLED, value)
          }
        />
      </div>
      <div
        className={tw(
          "transition-[max-height]",
          isRestakingEnabled ? "visible max-h-40 -mt-1" : "invisible max-h-0"
        )}
      >
        <BodyText size="xs">
          Native restaking on EigenLayer requires an EigenPod, a smart contract
          associated with your wallet address.{" "}
          <a
            href="https://figment.io/insights/figment-streamlines-the-eigenlayer-re-staking-process-with-direct-integration/"
            target="_blank"
            rel="noopener noreferrer"
            className="underline hover:no-underline leading-3"
          >
            Learn more about Restaking with EigenLayer
          </a>
        </BodyText>
        {isRestakingEnabled && (
          <EigenLayerRestaking
            setEigenPodAddress={(value) => {
              setFieldValue(FormFields.EIGENPOD_ADDRESS, value);
            }}
          />
        )}
      </div>
    </div>
  );
};
