import React from "react";
import * as Yup from "yup";
import { BigNumber } from "bignumber.js";
import { Formik, Form, FormikConfig } from "formik";
import { Protocol, protocols } from "@figmentjs/protocols";
import { fromWei } from "@figmentjs/utils";
import { BodyText } from "@figmentjs/web-core";
import Providers from "@figmentjs/web-core/src/components/providers";
import { useNetworkOverviewQuery } from "../../../../graphql/partners/generated/gql";
import { SliderInput } from "../../../components";
import { useFeeData, useValidateAccessToken } from "../../../hooks";
import { getEstimatedEthAggregateStakingTxFee } from "../../../utils";
import { StakingFlowData } from "../types";
import { TermsFormField, ContinueButton, StakingDetails } from "./components";

export type OnSubmit = (values: Partial<StakingFlowData>) => void;

type Props = {
  onSubmit: OnSubmit;
  flowData: StakingFlowData;
};

export enum FormFields {
  AMOUNT = "amount",
  ACCEPTED_TERMS = "acceptedTerms",
  IS_RESTAKING_ENABLED = "isRestakingEnabled",
  EIGENPOD_ADDRESS = "eigenPodAddress",
}

export type FormConfig = {
  amount: number;
  acceptedTerms: boolean;
  isRestakingEnabled: boolean;
  eigenPodAddress?: string;
};

export const AmountStep: React.FC<Props> = ({ onSubmit, flowData }) => {
  const { isLoading, data } = useNetworkOverviewQuery();
  // We're kicking this request off on this step to avoid the loading spinner in the next step.
  useValidateAccessToken();

  const { balance, account } = Providers.useEthereumWallet();
  const { fee } = useFeeData();

  const formConfig: FormikConfig<FormConfig> = {
    onSubmit: async ({
      amount,
      acceptedTerms,
      isRestakingEnabled,
      eigenPodAddress,
    }: FormConfig) => {
      const address = isRestakingEnabled
        ? eigenPodAddress
        : Providers.getEthereumAddress(account as string);

      onSubmit({
        amount,
        acceptedTerms: acceptedTerms,
        address: address,
        isRestakingEnabled: isRestakingEnabled,
      });
    },
    initialValues: {
      amount: flowData.amount,
      acceptedTerms: flowData.acceptedTerms,
      isRestakingEnabled: flowData.isRestakingEnabled,
      eigenPodAddress: "",
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number().required().positive(),
      acceptedTerms: Yup.boolean().required().oneOf([true]),
      isRestakingEnabled: Yup.boolean(),
      eigenPodAddress: Yup.string().when(FormFields.IS_RESTAKING_ENABLED, {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string().notRequired(),
      }),
    }),
  };

  const walletBalance = new BigNumber(fromWei(balance || "0"));
  const { ticker } = protocols[Protocol.ETHEREUM];

  return (
    <Formik {...formConfig}>
      {(formik) => (
        <Form>
          <div className="mt-6 flex flex-col">
            <div className="sm:flex-1">
              <div className="flex flex-col items-center pb-2">
                <div className="flex items-center gap-2 mb-6">
                  <div className="block sm:hidden">
                    <BodyText weight="semibold" size="base">
                      Select {ticker} Amount
                    </BodyText>
                  </div>
                  <div className="hidden sm:block">
                    <BodyText weight="semibold" size="lg">
                      Select {ticker} Amount
                    </BodyText>
                  </div>
                </div>
                <SliderInput
                  amount={formik.values[FormFields.AMOUNT]}
                  fee={getEstimatedEthAggregateStakingTxFee(
                    fee,
                    formik.values[FormFields.AMOUNT]
                  )}
                  ticker={ticker}
                  isLoading={isLoading}
                  price={data?.ethereumPrice?.value?.toString() || "0"}
                  fieldName={FormFields.AMOUNT}
                  onValueChange={(value) =>
                    formik.setFieldValue(FormFields.AMOUNT, value)
                  }
                  balance={walletBalance}
                  initialValue={flowData.amount}
                />
              </div>
              <StakingDetails />
              <TermsFormField
                formik={formik}
                balance={walletBalance}
                fee={getEstimatedEthAggregateStakingTxFee(
                  fee,
                  formik.values[FormFields.AMOUNT]
                )}
              />
              <ContinueButton formik={formik} flowData={flowData} />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
