import { useState } from "react";

import { CursorActions } from "../../TablePagination/TablePagination.types";
import { PageInfo } from "./use-offset-pagination.types";

export const useOffsetPagination = () => {
  const [page, setPage] = useState<number>(1);

  const handlePagination = (
    action: keyof typeof CursorActions,
    pageInfo: PageInfo | undefined
  ) => {
    switch (action) {
      case "FIRST":
        setPage(1);
        break;

      case "LAST":
        if (pageInfo) {
          setPage(pageInfo.totalPages);
        }
        break;

      case "NEXT":
        setPage((page) => ++page);
        break;

      case "PREVIOUS":
        setPage((page) => --page);
        break;
    }
  };

  return { page, handlePagination };
};
