import React, { memo } from "react";
import { Icon } from "../../graphics";
import { TableCellIconProps } from "./TableCells.types";

const TableCellIcon: React.FC<TableCellIconProps> = ({
  icon = "MdKeyboardArrowDown",
}) => {
  return (
    <div className="hover:cursor-pointer group hover:[&>*]:text-basic-600 flex justify-center">
      <Icon icon={icon} size="lg" color="black" />
    </div>
  );
};

export default memo(TableCellIcon);
