import React from "react";
import * as Yup from "yup";
import { BigNumber } from "bignumber.js";
import { Formik, Form, FormikConfig } from "formik";
import { Protocol, protocols } from "@figmentjs/protocols";
import { BodyText } from "@figmentjs/web-core";
import Providers from "@figmentjs/web-core/src/components/providers";
import {
  useExitableValidatorCountQuery,
  useNetworkOverviewQuery,
} from "../../../../graphql/partners/generated/gql";
import { SliderInput } from "../../../components";
import { UnstakingFlowData } from "../types";
import { ContinueButton } from "./components/continue-button";
import { UnstakingDetails } from "..";
import { InitiateUnstakingField } from "./components/initiate-unstaking-field";
import { ProcessingStep } from "../processing-step";
import { useUnstakeEthereum, useValidateAccessToken } from "../../../hooks";

type Props = {
  onContinue: (values: Partial<UnstakingFlowData>) => void;
  onSuccess: () => void;
  onError: (values: Partial<UnstakingFlowData>) => void;
  onAccessTokenInvalid: () => void;
  flowData: UnstakingFlowData;
};

export enum FormFields {
  AMOUNT = "amount",
  INITIATE_UNSTAKING = "initiateUnstaking",
}

export type FormConfig = {
  amount: number;
  initiateUnstaking: boolean;
};

export const AmountStep: React.FC<Props> = ({
  onContinue,
  onSuccess,
  onAccessTokenInvalid,
  onError,
  flowData,
}) => {
  const { isLoading: isNetworkOverviewLoading, data } =
    useNetworkOverviewQuery();

  const { account, network } = Providers.useEthereumWallet();

  const { data: exitableValidators, isLoading: isExitableValidatorsLoading } =
    useExitableValidatorCountQuery(
      {
        ethAccount: account!,
        network: network!,
      },
      {
        enabled: !!(account && network),
      }
    );

  const { unstakeValidators, isProcessingUnstake } = useUnstakeEthereum({
    onError,
    onUnstakeSuccess: onSuccess,
  });

  const {
    data: isAccessTokenValidData,
    isLoading: isIsAccessTokenValidLoading,
  } = useValidateAccessToken();

  const formConfig: FormikConfig<FormConfig> = {
    onSubmit: async ({ amount }) => {
      onContinue({
        amount,
        address: Providers.getEthereumAddress(account as string),
      });

      if (!isAccessTokenValidData?.isAccessTokenValid) {
        onAccessTokenInvalid();
        return;
      }

      await unstakeValidators({ amount });
    },
    initialValues: {
      amount: flowData.amount,
      initiateUnstaking: flowData.initiateUnstaking,
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number().required().positive(),
      initiateUnstaking: Yup.boolean().required().oneOf([true]),
    }),
  };

  const { ticker } = protocols[Protocol.ETHEREUM];
  const exitableEth = new BigNumber(
    exitableValidators?.validatorCounts.exitable || 0
  ).times(32);

  if (isProcessingUnstake) {
    return <ProcessingStep />;
  }

  return (
    <Formik {...formConfig}>
      {(formik) => (
        <Form>
          <div className="flex flex-col sm:h-full">
            <div className="sm:flex-1">
              <div className="flex flex-col items-center pb-2">
                <div className="flex items-center gap-2 mb-6">
                  <div className="block sm:hidden">
                    <BodyText weight="semibold" size="base">
                      Select {ticker} Amount
                    </BodyText>
                  </div>
                  <div className="hidden sm:block">
                    <BodyText weight="semibold" size="lg">
                      Select {ticker} Amount
                    </BodyText>
                  </div>
                </div>
                <SliderInput
                  amount={formik.values[FormFields.AMOUNT]}
                  fee="0"
                  ticker={ticker}
                  isLoading={isNetworkOverviewLoading}
                  price={data?.ethereumPrice?.value?.toString() || "0"}
                  fieldName={FormFields.AMOUNT}
                  onValueChange={(value) =>
                    formik.setFieldValue(FormFields.AMOUNT, value)
                  }
                  balance={
                    isExitableValidatorsLoading
                      ? // If data is being loaded, we'll set the balance to 100 to
                        // force the SliderView's error message to not appear.
                        new BigNumber(3200)
                      : exitableEth
                  }
                  initialValue={flowData.amount}
                />
              </div>
              <UnstakingDetails />
              <div className="py-2 px-3 rounded border border-yellow-1000 bg-yellow-400">
                <div className="leading-3 text-sm">
                  <span className="leading-3 text-sm font-semibold">
                    IMPORTANT:{" "}
                  </span>
                  <span className="leading-3 text-sm">
                    Unstaking your ETH is an irreversible action. Once your ETH
                    is unstaked, it will automatically be sent to the withdrawal
                    address which cannot be changed.
                  </span>
                </div>
              </div>
              <InitiateUnstakingField formik={formik} />
              <ContinueButton
                formik={formik}
                flowData={flowData}
                isIsAccessTokenValidLoading={isIsAccessTokenValidLoading}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
