import { MutationError } from "../../../../api-app/src";

export type UnstakingFlowData = {
  amount: number;
  address: string;
  initiateUnstaking: boolean;
  errors: MutationError[];
};

export enum UnstakingFlowStep {
  AMOUNT = "AMOUNT",
  VERIFY_OWNERSHIP = "VERIFY_OWNERSHIP",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}
