import React, { memo } from "react";
import { twMerge as tw } from "tailwind-merge";
import { FieldProps, Layout } from "./Field.types";

/**
 * The Field component is used to wrap a form field and its label.
 * @param FieldProps
 * @returns The Field component
 */
const Field: React.FC<FieldProps> = ({
  children,
  details,
  htmlFor,
  hideLabel,
  label,
  layout = "vertical",
  testId,
  // error, // TODO - is there an error state?
  // required, // TODO - should we show a required indicator in the label?
}) => {
  const horizontalLayoutStyles = "flex flex-row mr-4";
  const verticalLayoutStyles = "flex flex-col mb-3";
  const className = tw(
    "items-start font-sans text-base text-black font-semibold leading-5",
    layout === Layout.horizontal ? horizontalLayoutStyles : verticalLayoutStyles
  );

  const labelMarkup = label ? (
    <div
      className={tw(
        "flex gap-3",
        hideLabel && "sr-only",
        layout === Layout.vertical && "mb-1",
        layout === Layout.horizontal && "mr-2"
      )}
    >
      {label}
      {details && <p className="font-light text-basic-800">{details}</p>}
    </div>
  ) : null;

  return (
    <label htmlFor={htmlFor} className={className} data-testid={testId}>
      {labelMarkup}
      {children}
    </label>
  );
};

export default memo(Field);
