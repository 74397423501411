import React from "react";
import { FormikProps } from "formik";
import { Button } from "@figmentjs/web-core";
import { FormConfig } from "../../amount-step";
import { UnstakingFlowData } from "../../../types";

type Props = {
  formik: FormikProps<FormConfig>;
  flowData: UnstakingFlowData;
  isIsAccessTokenValidLoading: boolean;
};

export const ContinueButton: React.FC<Props> = ({
  formik,
  flowData,
  isIsAccessTokenValidLoading,
}) => {
  return (
    <div className="flex mt-6 justify-center">
      <Button
        type="submit"
        fullWidth
        disabled={
          !(
            formik.isValid &&
            (formik.dirty ||
              (flowData.amount &&
                flowData.initiateUnstaking &&
                !isIsAccessTokenValidLoading))
          )
        }
        onClick={formik.handleSubmit}
      >
        Continue
      </Button>
    </div>
  );
};
