import React from "react";
import { Protocol, protocols } from "@figmentjs/protocols";
import { BodyText, Icon, Button, CopyToClipboard } from "@figmentjs/web-core";
import { UnstakingFlowData } from "../types";
import { getTruncatedAddress } from "@figmentjs/utils";

type Props = {
  onDone: () => void;
  flowData: UnstakingFlowData;
};

export const SuccessStep: React.FC<Props> = ({ onDone, flowData }) => {
  const { ticker } = protocols[Protocol.ETHEREUM];
  const { amount, address } = flowData;

  return (
    <div className="flex flex-col sm:h-full">
      <div className="sm:flex-1">
        <div className="flex flex-col items-center h-full">
          <Icon icon="MdCheckCircle" size="7xl" color="green-800" />
          <div className="mt-6 sm:mt-8 space-y-4 sm:space-y-6 text-center">
            <BodyText size="lg" weight="semibold">
              Withdrawal Initiated{" "}
            </BodyText>
            <div>
              <BodyText as="p">
                You have successfully initiated the withdrawal of {amount}{" "}
                {ticker}.
              </BodyText>
            </div>
          </div>
          <div className="divide-y divide-basic-200 w-full mt-12 sm:mb-[6px]">
            <div />
            <div className="py-3">
              <div className="flex justify-between">
                <BodyText>Withdrawal Address</BodyText>
                <div className="flex items-center">
                  <BodyText weight="bold">
                    {getTruncatedAddress({
                      address,
                      protocol: Protocol.ETHEREUM,
                    })}
                  </BodyText>
                  <CopyToClipboard
                    contentToSave={address}
                    notificationMessage="Withdrawal Address copied"
                  />
                </div>
              </div>
            </div>
            <div />
          </div>
          <div className="pt-8 sm:pt-40 w-full max-w-sm">
            <Button
              onClick={() => {
                onDone();
              }}
              fullWidth
            >
              Done
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
