import React from "react";
import ReactPaginate from "react-paginate";
import { twMerge as tw } from "tailwind-merge";
import {
  BUTTON_STYLES as CORE_BUTTON_STYLES,
  DISABLED_BUTTON_STYLES,
} from "../utils";
import { BASE_LINK_STYLES } from "../utils/constants";
import { TableOffsetPaginationProps } from "./TablePagination.types";

const BUTTON_STYLES = `${CORE_BUTTON_STYLES} enabled:hover:bg-basic-200 enabled:hover:text-green cursor-pointer`;

const TablePagination: React.FC<TableOffsetPaginationProps> = ({
  currentPage,
  totalPages,
  onPageSelected,
}) => {
  if (totalPages == 1) {
    return null;
  }
  return (
    <ReactPaginate
      className="p-4 flex justify-center items-center gap-2"
      previousClassName={tw(BUTTON_STYLES)}
      nextClassName={tw(BUTTON_STYLES)}
      pageClassName={tw(BUTTON_STYLES, "hover:bg-basic-200 p-1")}
      disabledClassName={tw(BUTTON_STYLES, DISABLED_BUTTON_STYLES)}
      activeClassName={tw(
        BUTTON_STYLES,
        "text-white bg-green hover:text-green"
      )}
      marginPagesDisplayed={1}
      breakClassName={tw(BUTTON_STYLES)}
      pageLinkClassName={BASE_LINK_STYLES}
      nextLinkClassName={BASE_LINK_STYLES}
      previousLinkClassName={BASE_LINK_STYLES}
      breakLinkClassName={BASE_LINK_STYLES}
      activeLinkClassName={BASE_LINK_STYLES}
      disabledLinkClassName={tw(BASE_LINK_STYLES, "cursor-not-allowed")}
      forcePage={currentPage}
      breakLabel="..."
      nextLabel=">"
      onPageChange={(event) => {
        onPageSelected(event.selected);
      }}
      pageRangeDisplayed={3}
      pageCount={totalPages}
      previousLabel="<"
    />
  );
};

export default TablePagination;
