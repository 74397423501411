import React, { useEffect, useState } from "react";
import { BigNumber } from "bignumber.js";
import { useQueryClient } from "@tanstack/react-query";
import { useRollbar } from "@figmentjs/rollbar-client";
import { Protocol, protocols } from "@figmentjs/protocols";
import { BodyText, Spinner } from "@figmentjs/web-core";
import Providers from "@figmentjs/web-core/src/components/providers";
import {
  useStakeEthereumSetTxHashMutation,
  useStakingActivityV2Query,
} from "../../../../graphql/partners/generated/gql";
import { networkMap } from "../activity-table/activity-table";
import { StakingFlowData } from "../types";

type GetStatusText = (values: {
  isBroadcastingDeposit: boolean;
  depositTxHash: string;
  txPayload: string;
}) => string;

const getStatusText: GetStatusText = ({
  txPayload,
  isBroadcastingDeposit,
  depositTxHash,
}) => {
  if (!txPayload) {
    return "This may take up to a minute";
  } else if (isBroadcastingDeposit && !depositTxHash) {
    return "Waiting for signature";
  }

  return "Confirming transaction. Do not close or refresh the page.";
};

type Props = {
  onSuccess: (values: Partial<StakingFlowData>) => void;
  onFail: (values: Partial<StakingFlowData>) => void;
  onCanceled: () => void;
  flowData: StakingFlowData;
};

export const SigningStep: React.FC<Props> = ({
  onSuccess,
  onFail,
  onCanceled,
  flowData,
}) => {
  const { amount, flowId, address } = flowData;
  const rollbar = useRollbar();
  const { mutateAsync: confirmTransactionViaCoreAPI } =
    useStakeEthereumSetTxHashMutation();

  const [txPayload, setTxPayload] = useState(flowData.unsignedRawTransaction);
  const [isBroadcastTriggered, setIsBroadcastTriggered] = useState(false);
  const {
    broadcastDeposit,
    isBroadcastingDeposit,
    broadcastDepositError,
    depositTxHash,
    account,
    network,
  } = Providers.useEthereumWallet();

  const { ticker } = protocols[Protocol.ETHEREUM];

  const queryClient = useQueryClient();

  useEffect(() => {
    const main = async () => {
      if (!isBroadcastTriggered) {
        broadcastDeposit({
          amount: amount.toString(),
          payload: txPayload,
        });
        setIsBroadcastTriggered(true);
        return;
      }

      if (broadcastDepositError) {
        if (broadcastDepositError === "TX_CANCELED") {
          onCanceled();
          return;
        }

        if (broadcastDepositError === "TX_TIMEOUT") {
          onFail({
            errors: [
              {
                message:
                  "The wallet reported an error when signing the transaction, potentially due to a timeout.",
                code: "TX_TIMEOUT",
              },
            ],
          });
          return;
        }

        if (broadcastDepositError === "TX_NOT_SUCCESSFUL") {
          onFail({
            errors: [
              {
                message: "There was an error broadcasting the transaction.",
                code: "TX_NOT_SUCCESSFUL",
              },
            ],
          });
          return;
        }

        // This is the "unknown" case:
        // The wallet has thrown an error, so we'll pass it along to the user.
        onFail({
          errors: [
            {
              code: "ETHERS",
              message: broadcastDepositError,
            },
          ],
        });
        return;
      }

      if (!isBroadcastingDeposit && depositTxHash) {
        try {
          const {
            ethereumStakingRequestSetTxHash: { userErrors },
          } = await confirmTransactionViaCoreAPI({
            id: flowId,
            txHash: depositTxHash,
            amountEth: amount,
            fundingAddress: address,
          });

          queryClient.invalidateQueries(
            useStakingActivityV2Query.getKey({
              network: networkMap[network!]!,
            })
          );

          setTxPayload(txPayload);

          onSuccess({
            errors: userErrors,
          });
        } catch (error) {
          // This message is not shown on the success step, but we need to set it to show the "delayed activity" message on that step.
          onSuccess({
            errors: [
              {
                message:
                  "There will be a delay in your transaction appearing in the activity table.",
                code: "DELAYED_ACTIVITY",
              },
            ],
          });

          rollbar.error(
            "confirmBroadcastedTransaction() Error: ",
            JSON.stringify(error)
          );
        }
      }
    };

    main();
  }, [
    txPayload,
    amount,
    broadcastDeposit,
    isBroadcastingDeposit,
    depositTxHash,
    onFail,
    onSuccess,
    confirmTransactionViaCoreAPI,
    flowId,
    broadcastDepositError,
    isBroadcastTriggered,
    onCanceled,
    queryClient,
    account,
    network,
    rollbar,
    address,
  ]);

  const statusText = getStatusText({
    isBroadcastingDeposit,
    txPayload,
    depositTxHash: depositTxHash || "",
  });

  const validatorCount = new BigNumber(amount).div(32);

  return (
    <div className="flex flex-col sm:flex-row sm:h-full sm:items-center">
      <div className="py-40 sm:flex-1">
        <div className="flex flex-col items-center sm:py-4">
          <Spinner />
          <div className="mt-6 space-y-6 sm:mt-8 sm:space-y-4 text-center sm:mb-[13px]">
            <BodyText size="xl" weight="semibold">
              Depositing {amount.toLocaleString()} {ticker} to{" "}
              {validatorCount.toString()} validator
              {validatorCount.gt(1) ? "s" : ""}
            </BodyText>
            <div>
              <BodyText>{statusText}</BodyText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
