import { getErrorMessage } from "@figmentjs/utils";

interface ServerResponse<TData> {
  data: TData;
  errors: Error[];
}

export const useFetchData = <TData, TVariables>(
  query: string
): ((variables?: TVariables) => Promise<TData>) => {
  return async (variables?: TVariables) => {
    try {
      const res = await fetch("/api/graphql", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query, variables }),
      });

      // If an authorization error is returned, redirect to the login page.
      if (res.status === 401) {
        return Promise.reject(() => {
          window.location.href = "/api/auth/logout";
        });
      }

      const { data, errors }: ServerResponse<TData> = await res.json();

      if (!data && !errors) throw newError({ message: "Invalid JSON" }, query);
      if (errors) throw newError(errors[0], query);

      return data;
    } catch (error) {
      throw newError(error, query);
    }
  };
};

const newError = (error?: unknown, query?: string): Error =>
  new Error(
    `GraphQL fetching error while calling ${query} ${getErrorMessage(error)}`,
    {
      cause: error || undefined,
    }
  );
