import React, { useEffect, useState } from "react";
import { Field as FormikField } from "formik";
import { BigNumber } from "bignumber.js";

import { currencyFormatter } from "@figmentjs/utils";
import { Heading, BodyText, Slider } from "@figmentjs/web-core";

const MAX_VALIDATORS = 250;
export const TOKEN_STEP = 32;

type GetSliderMax = (props: { balance: BigNumber }) => BigNumber;

const getSliderMax: GetSliderMax = ({ balance }) => {
  const maxPossibleValidators = balance
    .div(TOKEN_STEP)
    .integerValue(BigNumber.ROUND_DOWN);
  const maxSafeValidators = BigNumber.min(
    maxPossibleValidators,
    MAX_VALIDATORS
  );

  return maxSafeValidators.times(TOKEN_STEP);
};

type Props = {
  balance: BigNumber;
  fee: string;
  amount: number;
  ticker: string;
  isLoading: boolean;
  price: string;
  fieldName: string;
  onValueChange: (value: number) => void;
  initialValue: number;
};

export const SliderInput: React.FC<Props> = ({
  balance,
  fee,
  amount,
  ticker,
  isLoading,
  price,
  fieldName,
  onValueChange,
  initialValue,
}) => {
  const [value, setValue] = useState(initialValue);

  const disabled =
    balance && fee ? balance.minus(new BigNumber(fee)).lt(TOKEN_STEP) : false;

  useEffect(() => {
    if (disabled) {
      setValue(0);
    } else {
      setValue(initialValue);
    }
  }, [disabled, setValue, initialValue]);

  return (
    <>
      <div className="block sm:hidden">
        <Heading level="h4" color={disabled ? "basic-600" : "green"}>
          {amount.toLocaleString("en-US")} {ticker}
        </Heading>
      </div>
      <div className="hidden sm:block">
        <Heading level="h3" color={disabled ? "basic-600" : "green"}>
          {disabled ? 0 : amount.toLocaleString("en-US")} {ticker}
        </Heading>
      </div>
      {isLoading ? (
        <div className="animate-pulse w-16 h-[16px] bg-basic-600 rounded" />
      ) : (
        <BodyText
          size="sm"
          weight="semibold"
          color={disabled ? "basic-600" : "green"}
        >
          {currencyFormatter.format(
            new BigNumber(price).times(amount).toNumber()
          )}{" "}
          USD
        </BodyText>
      )}
      <div className="w-full mt-8">
        <FormikField name={fieldName}>
          {() => (
            <Slider
              step={TOKEN_STEP}
              onValueChange={(value: number[]) => {
                onValueChange(value[0]);
                setValue(value[0]);
              }}
              min={0}
              max={getSliderMax({
                balance,
              }).toNumber()}
              disabled={disabled}
              displayMinAndMax={!disabled && !isLoading}
              defaultValue={[initialValue]}
              value={[value]}
            />
          )}
        </FormikField>
      </div>
      {disabled && !isLoading && (
        <div className="text-center">
          <BodyText color="error">
            {`Insufficient funds. Minimum of ${TOKEN_STEP} ${ticker} + transaction fee is required.`}
          </BodyText>
        </div>
      )}
    </>
  );
};
