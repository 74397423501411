import React from "react";
import { BigNumber } from "bignumber.js";
import { fromWei, getTruncatedAddress, tokenFormatter } from "@figmentjs/utils";
import { protocols, Protocol } from "@figmentjs/protocols";
import { BodyText, CopyToClipboard } from "@figmentjs/web-core";
import Providers from "@figmentjs/web-core/src/components/providers";

export const WalletDetails: React.FC = () => {
  const { account, balance } = Providers.useEthereumWallet();

  const { ticker } = protocols[Protocol.ETHEREUM];

  if (!account || !balance) {
    return <div className="h-7" />;
  }

  return (
    <div className="flex justify-between items-center sm:flex-col-reverse sm:gap-2 lg:items-center lg:flex-row lg:gap-4">
      <div className="flex gap-2">
        <BodyText color="basic-800" size="sm">
          Balance:
        </BodyText>
        <BodyText color="green" weight="bold" testId="wallet-balance">
          {balance &&
            tokenFormatter.format(
              new BigNumber(fromWei(balance)).toNumber()
            )}{" "}
          {ticker}
        </BodyText>
      </div>
      <div className="flex items-center bg-white rounded-full py-1 pl-2 pr-2 sm:pl-4 sm:pr-2 sm:py-2 overflow-hidden border border-basic-300 self-center">
        <div className="truncate text-sm font-bold">
          {getTruncatedAddress({
            address: account ?? "",
            protocol: Protocol.ETHEREUM,
          })}
        </div>
        <CopyToClipboard
          contentToSave={account}
          notificationMessage="Wallet Address copied"
        />
      </div>
    </div>
  );
};
