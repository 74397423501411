import React from "react";
import { BodyText, Spinner, Tooltip, Icon, Button } from "@figmentjs/web-core";
import { useNetworkOverviewQuery } from "../../../../graphql/partners/generated/gql";

const roundToDecimal = (number: number, decimal = 0) =>
  parseFloat(number.toFixed(decimal));

const Field: React.FC<{
  title: string;
  value: string;
  semibold?: boolean;
  isLoading?: boolean;
  tooltip?: string;
  href?: { url: string; text: string };
}> = ({ title, tooltip, value, semibold = false, isLoading = false, href }) => {
  return (
    <div className="py-3 flex items-center gap-1 justify-between">
      <div className="flex items-center gap-1">
        <div className="block sm:hidden">
          <BodyText color="black" size="sm">
            {title}
          </BodyText>
        </div>
        <div className="hidden sm:block">
          <BodyText color="black">{title}</BodyText>
        </div>
        {tooltip && (
          <Tooltip overlayText={tooltip}>
            <Icon icon="MdInfoOutline" />
          </Tooltip>
        )}
      </div>
      <div className="text-right">
        {isLoading ? (
          <Spinner size={26} />
        ) : (
          <div className="flex gap-1">
            {value && (
              <>
                <div className="block sm:hidden">
                  <BodyText size="sm" weight={semibold ? "semibold" : "normal"}>
                    {value}
                  </BodyText>
                </div>
                <div className="hidden sm:block">
                  <BodyText weight={semibold ? "semibold" : "normal"}>
                    {value}
                  </BodyText>
                </div>
              </>
            )}
            {href && (
              <>
                <div className="block sm:hidden">
                  <Button
                    palette="text"
                    size="tiny"
                    href={href.url}
                    openInNewTab
                  >
                    {href.text}
                  </Button>
                </div>
                <div className="hidden sm:block">
                  <Button
                    palette="text"
                    size="small"
                    href={href.url}
                    openInNewTab
                  >
                    {href.text}
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const WITHDRAWAL_TOOLTIP = `We use on-chain data to provide an estimate for when your ETH funds will
  reach your withdrawal address if you were to exit a validator right now. This updates every hour. A
  range is provided to indicate the best case and worst case scenarios for when your validator will be
  picked up by Ethereum's withdrawal sweep after exiting the active set.`;

export const UnstakingDetails: React.FC = () => {
  const { isLoading, data } = useNetworkOverviewQuery();

  const minWithdrawal =
    data?.ethereumNetworkEstimates?.estimatedWithdrawal?.hoursMin || 0;
  const maxWithdrawal =
    data?.ethereumNetworkEstimates?.estimatedWithdrawal?.hoursMax || 0;

  const minWithdrawalDisplayedValue = roundToDecimal(minWithdrawal / 24, 1);
  const maxWithdrawalDisplayedValue = roundToDecimal(maxWithdrawal / 24, 1);

  return (
    <div className="divide-y divide-basic-200 mt-4 mb-5">
      <div />
      <Field
        title="Estimated Exit Time"
        tooltip={WITHDRAWAL_TOOLTIP}
        isLoading={isLoading}
        value={`${minWithdrawalDisplayedValue} - ${maxWithdrawalDisplayedValue} days`}
        semibold
      />
      <div />
    </div>
  );
};
