import React, { memo } from "react";
import { twMerge as tw } from "tailwind-merge";
import { TableCellIcon } from "./index";
import { CellType, TableCellProps } from "./TableCells.types";
import { FontFamily } from "../../../theme/types";

const getSpecificStyle = (cellType: CellType) => {
  switch (cellType) {
    case CellType.BOLD:
      return "font-bold ";
    case CellType.SEMIBOLD:
      return "font-semibold";
    default: // CellType.BASE
      return "font-normal";
  }
};

const TableCellBase: React.FC<TableCellProps> = ({
  type = CellType.BASE,
  value,
  testId,
  className,
  font = "sans",
}) => {
  const styles = tw(
    "py-6 text-black text-base leading-6",
    FontFamily[font],
    getSpecificStyle(type as CellType),
    className
  );
  switch (type) {
    case CellType.ICON:
      return <TableCellIcon />;
  }
  return (
    <div className={styles} {...(testId ? { "data-testid": testId } : {})}>
      {value}
    </div>
  );
};

export default memo(TableCellBase);
