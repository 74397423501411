import React, { useState, useCallback } from "react";
import { twMerge as tw } from "tailwind-merge";
import { DateRange, DayPickerProvider } from "react-day-picker";
import { Popover } from "../../overlays";
import DayPickerMonthView from "./components/DayPickerMonthView";
// TODO: [FIGAPP-452] Add @figmentjs/utils to Web-core
import { format } from "date-fns";
import { Icon } from "../../graphics";
import { ErrorText } from "../../forms/shared/components";
import { DateRangePickerProps } from "./DateRangePicker.types";

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  defaultMonth,
  selected,
  onChange,
  error,
  defaultOpen,
  fromDate,
  toDate,
}) => {
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>(
    selected
  );
  const handleSelected = useCallback(
    (range: DateRange | undefined) => {
      setSelectedRange(range);
      onChange(range);
    },
    [onChange]
  );

  return (
    <div className="w-fit">
      <DayPickerProvider initialProps={{ mode: "range" }}>
        <Popover
          placement="bottom"
          trigger={
            <button
              className={tw(
                "w-96 cursor-pointer flex items-center select-none px-3 bg-white",
                "border solid rounded border-black hover:border-green-800 hover:black",
                "focus:shadow-md focus:outline-none focus:border-green focus:ring-1 focus:ring-green",
                "disabled:border-basic-300 disabled:shadow-none invalid:border-error focus:invalid:border-error focus:invalid:ring-error",
                error &&
                  "border-error focus:border-error focus:ring-error hover:border-error hover:text-black"
              )}
            >
              <div className="flex flex-grow">
                <span className="flex w-1/2 justify-start">
                  From:
                  <span className="font-semibold px-2">
                    {selectedRange?.from &&
                      format(selectedRange?.from, "MM/dd/y")}
                  </span>
                </span>
                <span className="flex w-1/2 justify-start">
                  To:
                  <span className="font-semibold px-2">
                    {selectedRange?.to && format(selectedRange?.to, "MM/dd/y")}
                  </span>
                </span>
              </div>
              <span className="py-2 flex justify-end">
                <Icon icon="MdCalendarToday" size="2xl" />
              </span>
            </button>
          }
          defaultOpen={defaultOpen}
          className="rounded-lg"
        >
          <DayPickerMonthView
            mode="range"
            defaultMonth={defaultMonth}
            selected={selectedRange}
            onSelect={handleSelected}
            fromDate={fromDate}
            toDate={toDate}
          />
        </Popover>

        {error && (
          <div className="mx-2 my-0">
            <ErrorText>{error}</ErrorText>
          </div>
        )}
      </DayPickerProvider>
    </div>
  );
};

export default DateRangePicker;
