import React, { memo } from "react";

import { Icon } from "../../../graphics";

interface Props {
  sortDirection?: "asc" | "desc";
}

const SortingIcon: React.FC<Props> = ({ sortDirection }) => (
  <div className="w-6">
    {!sortDirection ? (
      <div className="px-1 flex flex-col justify-center">
        <Icon icon="MdArrowDropUp" size="sm" color="black" />
        <div className="mt-[-7px]">
          <Icon icon="MdArrowDropDown" size="sm" color="black" />
        </div>
      </div>
    ) : (
      <Icon
        icon={sortDirection === "desc" ? "MdArrowDropDown" : "MdArrowDropUp"}
        size="xl"
        color="black"
      />
    )}
  </div>
);

export default memo(SortingIcon);
