import { Heading, Icon, BodyText } from "@figmentjs/web-core";
import { HeadingFont } from "@figmentjs/web-core/src/components/typography/Heading/Heading.types";
import { useBoolean } from "@figmentjs/utils";
import { useNetworkOverviewQuery } from "@figmentjs/staking-components/graphql/partners/generated/gql";

export const ValueProp: React.FC = () => {
  const { data, isLoading } = useNetworkOverviewQuery();
  const { value, toggle } = useBoolean(false);

  const pointsContent = [
    {
      title: "Instant Rewards",
      description:
        "All rewards are sent to your wallet immediately. No need to claim them. No hidden fees.",
    },
    {
      title: "Complete Control",
      description:
        "Your validator's withdrawal address is always set to your wallet. You maintain control of your funds at all times.",
    },
    {
      title: "No Pooling",
      description:
        "You get your own dedicated smart contract. Your ETH is never pooled with other users in a honey-pot contract.",
    },
  ];

  const points = (
    <div className="space-y-4">
      {pointsContent.map((point) => (
        <div className="flex gap-4" key={point.title}>
          <div className="rounded-full bg-green-300 h-6 min-w-[24px] flex items-center justify-center mt-1">
            <Icon icon="MdCheck" color="green-800" />
          </div>
          <div>
            <div className="hidden sm:block">
              <BodyText color="green" weight="semibold" size="lg" as="p">
                {point.title}
              </BodyText>
            </div>
            <div className="block sm:hidden">
              <BodyText color="green" weight="bold" as="p" size="base">
                {point.title}
              </BodyText>
            </div>
            <BodyText as="p">{point.description}</BodyText>
          </div>
        </div>
      ))}
    </div>
  );

  const headingContent = (
    <div className="text-center sm:text-left">
      Earn
      {isLoading ? (
        <span className="inline-block animate-pulse bg-basic-200 rounded-md w-20 h-4 sm:h-6 mx-2" />
      ) : (
        data?.ethereumRewardsRate?.value && (
          <span className="text-green-800 px-2">
            {data?.ethereumRewardsRate.value}%
          </span>
        )
      )}
      Staking Rewards Rate on Your ETH
    </div>
  );

  return (
    <div className="flex-1 flex flex-col sm:mt-16 lg:mt-32">
      <div className="hidden sm:block">
        <Heading level="h4" font={HeadingFont.serif} color="green-1100">
          {headingContent}
        </Heading>
      </div>
      <div className="block sm:hidden">
        <Heading level="h5" font={HeadingFont.serif} color="green-1100">
          {headingContent}
        </Heading>
      </div>
      <div className="hidden mt-0 sm:block sm:mt-8">{points}</div>
      <div className="block sm:hidden">
        <div
          role="button"
          tabIndex={0}
          className="flex items-center justify-center gap-1 mt-2 mb-4"
          onClick={toggle}
        >
          <BodyText color="green-800">More</BodyText>
          <Icon
            color="green"
            icon={value ? "MdKeyboardArrowUp" : "MdKeyboardArrowDown"}
            size="lg"
          />
        </div>
        {value && <div className="mb-10">{points}</div>}
      </div>
    </div>
  );
};
