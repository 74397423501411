import { useState } from "react";

import { SortedColumn } from "../Table/Table.types";
import useTableSorting from "./use-table-sorting";

// TODO: [FIGAPP-707] Jest tests for both useTable* hooks

type Obj = Partial<{ [key: string]: unknown }>;

type SortBy<DataList extends Obj[]> = SortedColumn & {
  sortFn: (rowA: DataList[number], rowB: DataList[number]) => number;
};

interface TableSortingResult<DataList> {
  /**
   * List of all columns that are manually sorted and the current sort direction.
   *
   * Pass this to the Table's `sorting` prop.
   */
  sorting: SortedColumn[];
  /**
   * Callback function when a column is sorted. It provides the column ID and sort
   * direction to set sorting state manually.
   *
   * Pass this to the Table's `onSorting` prop.
   */
  onSorting: (sorted: SortedColumn) => void;
  /**
   * Sorted data to be passed to the Table's `rows` prop.
   */
  sortedData: DataList;
}

/**
 * **Used to manually sort a table and its data.**
 *
 * Define all columns that can be sorted, their sorting state, and their sort functions.
 * The Table component will consume the return properties, including the sorted data.
 */
const useTableDataSorting = <DataList extends Obj[]>(
  /**
   * Initial sorting determines the sortable columns. Each column should have a field
   * (column ID), sort direction, and sorting function which is called after `onSorting`
   */
  initialSort: SortBy<DataList>[],
  /**
   * Initial data is the array of objects that will be sorted into `sortedData`
   */
  initialData: DataList
): TableSortingResult<DataList> => {
  const [sortedData, setSortedData] = useState<DataList>(() => {
    const sortDetails = initialSort.find((item) => item.sortDirection);
    return sortDetails
      ? sortData<DataList>(initialData, sortDetails)
      : initialData;
  });

  const { onSorting: onTableSort, sorting: tableSorting } =
    useTableSorting<SortedColumn>(initialSort);

  const sorting = tableSorting.map((item) => ({ ...item, sortFn: undefined }));

  const onSorting = (newSort: SortedColumn) => {
    const sortItem = initialSort.find((item) => item.field === newSort.field);
    if (!sortItem) return;

    const { sortFn } = sortItem;
    const { field, sortDirection } = newSort;
    const data = sortData<DataList>(initialData, {
      field,
      sortDirection,
      sortFn,
    });
    setSortedData(data);

    onTableSort(newSort);
  };

  return {
    sorting,
    onSorting,
    sortedData,
  };
};

function sortData<DataList extends Obj[]>(
  data: DataList,
  sortDetails: SortBy<DataList>
): DataList {
  const newData = [...data];
  const { sortDirection, sortFn } = sortDetails;

  if (sortDirection) {
    newData.sort((a, b) => sortFn(a, b));

    if (sortDirection === "desc") {
      newData.reverse();
    }
  }

  return newData as DataList;
}

export default useTableDataSorting;
