import { BigNumber } from "bignumber.js";
import { useFlags } from "launchdarkly-react-client-sdk";
import { BodyText, Spinner, Tooltip, Icon, Button } from "@figmentjs/web-core";
import React from "react";
import { useNetworkOverviewQuery } from "../../../../../../graphql/partners/generated/gql";
import { RestakingToggle } from "../restaking-toggle";

const roundToDecimal = (number: number, decimal = 0) =>
  parseFloat(number.toFixed(decimal));

const Field: React.FC<{
  title: string;
  value: string;
  semibold?: boolean;
  isLoading?: boolean;
  tooltip?: string;
  href?: { url: string; text: string };
}> = ({ title, tooltip, value, semibold = false, isLoading = false, href }) => {
  return (
    <div className="py-3 flex items-center gap-1 justify-between">
      <div className="flex items-center gap-1">
        <div className="block sm:hidden">
          <BodyText color="black" size="sm">
            {title}
          </BodyText>
        </div>
        <div className="hidden sm:block">
          <BodyText color="black">{title}</BodyText>
        </div>
        {tooltip && (
          <Tooltip overlayText={tooltip}>
            <Icon icon="MdInfoOutline" />
          </Tooltip>
        )}
      </div>
      <div className="text-right">
        {isLoading ? (
          <Spinner size={26} />
        ) : (
          <div className="flex gap-2 items-center">
            {value && (
              <>
                <div className="block sm:hidden">
                  <BodyText size="sm" weight={semibold ? "semibold" : "normal"}>
                    {value}
                  </BodyText>
                </div>
                <div className="hidden sm:block">
                  <BodyText weight={semibold ? "semibold" : "normal"}>
                    {value}
                  </BodyText>
                </div>
              </>
            )}
            {href && (
              <>
                <div className="block sm:hidden">
                  <Button
                    palette="text"
                    size="tiny"
                    href={href.url}
                    openInNewTab
                  >
                    {href.text}
                  </Button>
                </div>
                <div className="hidden sm:block">
                  <Button
                    palette="text"
                    size="small"
                    href={href.url}
                    openInNewTab
                  >
                    {href.text}
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const StakingDetails: React.FC = () => {
  const { isLoading, data } = useNetworkOverviewQuery();
  const { enableRestakingToggle } = useFlags();

  const srr = data?.ethereumRewardsRate?.value?.toString() || "0";
  const activationEstimate =
    data?.ethereumNetworkEstimates.estimatedActivation?.hours || 0;

  const activationEstimateDisplayedValue = roundToDecimal(
    activationEstimate / 24,
    1
  );

  return (
    <div className="divide-y divide-basic-200 mt-4">
      {enableRestakingToggle ? <RestakingToggle /> : <div />}
      <Field
        title="Staking Reward Rate"
        tooltip="This is a 7-day moving average of the annualized staking reward rate across all Figment validators."
        isLoading={isLoading}
        value={`${BigNumber(srr).toFormat(2)}%`}
        semibold
      />
      <Field
        title="Activation Estimate"
        tooltip="We use live on-chain data to provide an estimate of when an ETH validator would enter the active set, if it were to be funded right now."
        isLoading={isLoading}
        value={`${activationEstimateDisplayedValue} day${
          activationEstimateDisplayedValue === 1 ? "" : "s"
        }`}
        semibold
      />
      <Field
        title="Figment Fee"
        value="~8% of total rewards"
        semibold
        href={{
          url: "https://figment.io/insights/introducing-non-custodial-smart-contracts-for-eth-on-chain-billing",
          text: "Learn More",
        }}
      />
      <div />
    </div>
  );
};
