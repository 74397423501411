import { useEffect, useState } from "react";
import Providers from "@figmentjs/web-core/src/components/providers";

export const useFeeData = () => {
  const [fee, setFee] = useState("0");
  const { getFeeData } = Providers.useEthereumWallet();

  useEffect(() => {
    const getFee = async () => {
      const feeData = await getFeeData();
      if (feeData && feeData.maxFeePerGas) {
        setFee(feeData.maxFeePerGas.toString());
      }
    };
    getFee();
  }, [getFeeData]);

  return { fee };
};
