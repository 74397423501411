import { useBoolean } from "@figmentjs/utils";
import React, { useState } from "react";
import { Button } from "../../buttons";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "../../dialogs";
import {
  Field,
  InputCheckbox,
  InputSelect,
  InputText,
  Switch,
} from "../../forms";
import { Icon } from "../../graphics/Icon";
import { Badge, BodyText } from "../../typography";
import {
  CheckboxFilter,
  FilterProps,
  FilterValue,
  InputFilter,
  OnChangeProps,
  SelectFilter,
  SelectOption,
  AbstractFilter,
  ToggleFilter,
} from "./Filter.types";

const renderInputFilter = (
  props: InputFilter,
  handleOnChange: (field: OnChangeProps) => void,
  value?: string
) => {
  return (
    <Field key={props.name} label={props.label}>
      <InputText
        value={value || ""}
        onChange={(event: React.FormEvent<HTMLInputElement>) => {
          handleOnChange({
            key: props.name,
            value: event.currentTarget.value,
          });
        }}
      />
    </Field>
  );
};

const renderSelectFilter = (
  props: SelectFilter,
  handleOnChange: (field: OnChangeProps) => void,
  selected?: SelectOption
) => {
  return (
    <Field key={props.name} label={props.label}>
      <InputSelect
        clearable={true}
        options={props.options.map((option) => {
          return {
            label: option.key,
            value: option.value,
          };
        })}
        value={
          selected
            ? {
                value: selected.value,
                label: selected.key,
              }
            : undefined
        }
        onChange={({ option }) => {
          handleOnChange({
            key: props.name,
            value:
              option && option.value
                ? {
                    key: option.label,
                    value: option.value as string,
                  }
                : undefined,
          });
        }}
      />
    </Field>
  );
};

const renderCheckboxFilter = (
  props: CheckboxFilter,
  handleOnChange: (field: OnChangeProps) => void,
  selected = false
) => {
  return (
    <div className="my-5">
      <InputCheckbox
        name={props.name}
        onChange={({ value }) => {
          handleOnChange({
            key: props.name,
            value,
          });
        }}
        value={selected}
      >
        <BodyText>{props.label}</BodyText>
      </InputCheckbox>
    </div>
  );
};

const renderToggleFilter = (
  props: ToggleFilter,
  handleOnChange: (field: OnChangeProps) => void,
  selected = false
) => {
  return (
    <div className="my-5">
      <Switch
        name={props.name}
        label={props.label}
        onCheckedChange={(value) => {
          handleOnChange({
            key: props.name,
            value,
          });
        }}
        checked={selected}
      >
        <BodyText>{props.label}</BodyText>
      </Switch>
    </div>
  );
};

const Filter = <TValueType extends FilterValue>({
  filters,
  initialState,
  onChange,
  onClear,
  title = "Apply filters",
}: FilterProps<TValueType>) => {
  const isOpen = useBoolean();
  const [appliedFilters, setAppliedFilters] =
    useState<TValueType>(initialState);

  const handleOnChange = ({ key, value }: OnChangeProps) => {
    setAppliedFilters({
      ...appliedFilters,
      [key]: value,
    });
  };

  const handleOpenChange = (opening: boolean) => {
    if (!opening) {
      isOpen.setFalse();
    }
  };

  const renderFilter = (filter: AbstractFilter) => {
    switch (filter.type) {
      case "input":
        return renderInputFilter(
          filter,
          handleOnChange,
          appliedFilters[filter.name] as string
        );
      case "select":
        return renderSelectFilter(
          filter,
          handleOnChange,
          appliedFilters[filter.name] as SelectOption
        );
      case "checkbox":
        return renderCheckboxFilter(
          filter,
          handleOnChange,
          appliedFilters[filter.name] as boolean
        );
      case "toggle":
        return renderToggleFilter(
          filter,
          handleOnChange,
          appliedFilters[filter.name] as boolean
        );
    }
  };

  return (
    <div>
      <Dialog open={isOpen.value} onOpenChange={handleOpenChange}>
        <DialogTrigger>
          <Badge
            compact
            type="outline"
            onClick={() => {
              isOpen.setTrue();
            }}
          >
            <div className="flex justify-between items-center">
              <Icon icon="MdFilterList" color="green" />
              <span className="ml-1">{title}</span>
            </div>
          </Badge>
        </DialogTrigger>
        <DialogContent style="md:max-w-lg">
          <DialogHeader title={title} />
          {filters.map((filter, index) => (
            <div key={index}>{renderFilter(filter)}</div>
          ))}
          <div className="col-span-full flex justify-end gap-2 pt-5">
            <Button
              palette="tertiary"
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setAppliedFilters({} as any);
                onClear?.();
                isOpen.setFalse();
              }}
            >
              Clear all filters
            </Button>

            <Button
              palette="primary"
              type="submit"
              onClick={() => {
                onChange(appliedFilters);
                isOpen.setFalse();
              }}
            >
              Apply Filters
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Filter;
