import React from "react";
import { BigNumber } from "bignumber.js";
import {
  Protocol,
  protocols,
  getTransactionExplorerUrl,
} from "@figmentjs/protocols";
import { BodyText, Icon, Button } from "@figmentjs/web-core";
import Providers from "@figmentjs/web-core/src/components/providers";
import { TOKEN_STEP } from "../../..";
import { StakingFlowData } from "../types";

type Props = {
  onStakeMore: () => void;
  onSeeActivity: () => void;
  flowData: StakingFlowData;
};

export const SuccessStep: React.FC<Props> = ({
  onStakeMore,
  onSeeActivity,
  flowData,
}) => {
  const { setDepositTxHash, depositTxHash, network } =
    Providers.useEthereumWallet();

  const { ticker } = protocols[Protocol.ETHEREUM];
  const { amount, errors } = flowData;
  const validatorCount = new BigNumber(amount).div(TOKEN_STEP);

  return (
    <div className="mt-8 flex flex-col sm:h-[504px]">
      <div className="sm:flex-1">
        <div className="flex flex-col items-center h-full">
          <Icon icon="MdCheckCircle" size="7xl" color="green-800" />
          <div className="text-center mt-6 space-y-4 sm:space-y-6 sm:mt-8">
            <BodyText size="lg" weight="semibold">
              You successfully staked {amount.toLocaleString()} {ticker} to{" "}
              {validatorCount.toString()} validator
              {validatorCount.gt(1) ? "s" : ""}!
            </BodyText>
            <div>
              <BodyText>
                You can follow the status of your activating validators in the{" "}
              </BodyText>
              <BodyText weight="bold">&quot;Activity&quot; </BodyText>
              <BodyText>table.</BodyText>
              {depositTxHash && (
                <div className="mt-4 flex justify-center w-full">
                  <Button
                    palette="text"
                    size="small"
                    href={getTransactionExplorerUrl(
                      Protocol.ETHEREUM,
                      depositTxHash,
                      network
                    )}
                    iconAfter="MdOpenInNew"
                    openInNewTab
                  >
                    View transaction
                  </Button>
                </div>
              )}
              {!!errors?.length && (
                <div className="flex items-center border rounded border-pending-light gap-4 py-2 px-4 text-left mt-6">
                  <Icon icon="MdInfo" color="pending" size="lg" />
                  <BodyText as="p">
                    It may take up to 90 seconds for this transaction to appear
                    in your staking activity.
                  </BodyText>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-4 mt-20 w-full max-w-sm sm:mt-auto">
            <Button
              onClick={() => {
                onSeeActivity();
              }}
              fullWidth
              palette="primary"
            >
              See Activity
            </Button>
            <Button
              onClick={() => {
                setDepositTxHash(undefined);
                onStakeMore();
              }}
              fullWidth
              palette="tertiary"
            >
              Stake More
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
