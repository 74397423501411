import {
  useMutation,
  useQuery,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { partnersGQLFetcher } from "../fetcher";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type Account = {
  __typename?: "Account";
  /** The account address (e.g. 0xabc123) */
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  /** The same as address, but checksummed or formatted for the specific protocol. */
  formattedAddress: Scalars["String"];
  id: Scalars["ID"];
  /** Whether the account has active delegation (manually managed) */
  isActive: Scalars["Boolean"];
  /** A free-form note about the account */
  label?: Maybe<Scalars["String"]>;
  /** The protocol network name (e.g. mainnet) */
  network: Scalars["String"];
  /** The Salesforce Opportunity Token. */
  opToken?: Maybe<OpToken>;
  /** Options that are set per account type. This is generic JSON because all account types can have different options. */
  options: Scalars["JSON"];
  /** The protocol name (e.g. Ethereum) */
  protocol: Scalars["String"];
  /** Signals the need to report for a white label validator */
  reportForWhiteLabelValidator: Scalars["Boolean"];
  /** Where this account was first added from */
  source?: Maybe<Scalars["String"]>;
  /** The type of account */
  type?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for Account. */
export type AccountConnection = {
  __typename?: "AccountConnection";
  /** A list of edges. */
  edges: Array<AccountEdge>;
  /** A list of nodes. */
  nodes: Array<Account>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type AccountEdge = {
  __typename?: "AccountEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Account;
};

export enum AccountOrderByFields {
  /** The account address */
  Address = "address",
  /** Date the account was added */
  CreatedAt = "createdAt",
  /** The network of the protocol (e.g. mainnet) */
  Network = "network",
  /** The protocol the address is for (e.g. ethereum) */
  Protocol = "protocol",
}

export type Accounts = {
  field: AccountOrderByFields;
  sortDirection: SortDirection;
};

/** Attributes for filtering tracked accounts. */
export type AccountsFilter = {
  address_cont?: InputMaybe<Scalars["String"]>;
  address_eq?: InputMaybe<Scalars["String"]>;
  address_i_cont?: InputMaybe<Scalars["String"]>;
  network_eq?: InputMaybe<Scalars["String"]>;
  organization_id_eq?: InputMaybe<Scalars["String"]>;
  protocol_eq?: InputMaybe<Scalars["String"]>;
  source_eq?: InputMaybe<Scalars["String"]>;
  source_id_eq?: InputMaybe<Scalars["String"]>;
};

export type AccountsInvitation = {
  __typename?: "AccountsInvitation";
  invitation?: Maybe<Invitation>;
};

export type AccountsInvitationResendInput = {
  email: Scalars["String"];
  organizationId: Scalars["ID"];
};

export type AccountsSignUp = {
  __typename?: "AccountsSignUp";
  acceptedTos?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  isIndividual?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  organization?: Maybe<Organization>;
  organizationName?: Maybe<Scalars["String"]>;
};

export type AccountsSignUpInput = {
  acceptedTos: Scalars["Boolean"];
  email: Scalars["String"];
  isIndividual: Scalars["Boolean"];
  name: Scalars["String"];
  organizationName?: InputMaybe<Scalars["String"]>;
  recaptchaToken: Scalars["String"];
};

export enum ActivityTypes {
  /** Ethereum batch staking */
  Deposit = "deposit",
  /** Ethereum batch unstaking */
  ExitRequest = "exit_request",
}

export type Address = {
  __typename?: "Address";
  additionalDetails?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  streetAddress?: Maybe<Scalars["String"]>;
  zipCode?: Maybe<Scalars["String"]>;
};

/** Attributes for address */
export type AddressInput = {
  additionalDetails?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  streetAddress?: InputMaybe<Scalars["String"]>;
  zipCode?: InputMaybe<Scalars["String"]>;
};

export type AggregatedRewards = {
  __typename?: "AggregatedRewards";
  rewards?: Maybe<Array<AggregatedRewardsTuple>>;
};

export type AggregatedRewardsTuple = {
  __typename?: "AggregatedRewardsTuple";
  consensusTotal?: Maybe<Scalars["String"]>;
  consensusTotalUsd?: Maybe<Scalars["String"]>;
  executionTotal?: Maybe<Scalars["String"]>;
  executionTotalReceived?: Maybe<Scalars["String"]>;
  executionTotalReceivedUsd?: Maybe<Scalars["String"]>;
  executionTotalUsd?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["ISO8601DateTime"]>;
  total?: Maybe<Scalars["String"]>;
  totalReceived?: Maybe<Scalars["String"]>;
  totalReceivedUsd?: Maybe<Scalars["String"]>;
  totalUsd?: Maybe<Scalars["String"]>;
};

export type ApiKey = {
  __typename?: "ApiKey";
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  projectId: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  value: Scalars["String"];
};

/** The connection type for ApiKey. */
export type ApiKeyConnection = {
  __typename?: "ApiKeyConnection";
  /** A list of edges. */
  edges: Array<ApiKeyEdge>;
  /** A list of nodes. */
  nodes: Array<ApiKey>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** Attributes for creating an Api Key */
export type ApiKeyCreateInput = {
  name: Scalars["String"];
  projectId: Scalars["String"];
};

/** An edge in a connection. */
export type ApiKeyEdge = {
  __typename?: "ApiKeyEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: ApiKey;
};

export type ApiKeyMutationPayload = {
  __typename?: "ApiKeyMutationPayload";
  /** ApiKey object */
  data?: Maybe<ApiKey>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum ApiKeyOrderByFields {
  /** Date the Api Key was created */
  CreatedAt = "createdAt",
  /** The name of the Api Key */
  Name = "name",
  /** The Project ID that Api Key belongs to */
  ProjectId = "projectId",
}

/** Attributes for updating an Api Key */
export type ApiKeyUpdateInput = {
  name: Scalars["String"];
};

export type ApiKeys = {
  field: ApiKeyOrderByFields;
  sortDirection: SortDirection;
};

export type BabylonNetworkOverview = {
  __typename?: "BabylonNetworkOverview";
  finalityProviderAddress: Scalars["String"];
  maxStakingAmount: Scalars["Int"];
  maxStakingTime: Scalars["Int"];
  minStakingAmount: Scalars["Int"];
  minStakingTime: Scalars["Int"];
};

export type BabylonStake = {
  __typename?: "BabylonStake";
  address?: Maybe<Scalars["String"]>;
  /** Amount staked in BTC */
  amount?: Maybe<Scalars["String"]>;
  amountSatoshi?: Maybe<Scalars["String"]>;
  covenantPubkeys?: Maybe<Scalars["String"]>;
  covenantThreshold?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  minUnbondingTime?: Maybe<Scalars["Int"]>;
  network?: Maybe<Scalars["String"]>;
  organizationId: Scalars["String"];
  stakerPubkey?: Maybe<Scalars["String"]>;
  stakingDuration?: Maybe<Scalars["Int"]>;
  state?: Maybe<BabylonStates>;
  txHash?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Inputs adding tx hash to Babylon stake */
export type BabylonStakeBroadcastedInput = {
  stakeId: Scalars["String"];
  txHash: Scalars["String"];
};

/** The connection type for BabylonStake. */
export type BabylonStakeConnection = {
  __typename?: "BabylonStakeConnection";
  /** A list of edges. */
  edges: Array<BabylonStakeEdge>;
  /** A list of nodes. */
  nodes: Array<BabylonStake>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type BabylonStakeEdge = {
  __typename?: "BabylonStakeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: BabylonStake;
};

/** Inputs for stake id and doing Babylon mutations */
export type BabylonStakeIdInput = {
  stakeId: Scalars["String"];
};

/** Inputs for creating a BTC staking tx on Babylon */
export type BabylonStakingTxInput = {
  address: Scalars["String"];
  changeAddress: Scalars["String"];
  network: BitcoinNetworks;
  stakerPk: Scalars["String"];
  stakingAmount: Scalars["Int"];
  stakingDuration: Scalars["Int"];
  taprootPubkey?: InputMaybe<Scalars["String"]>;
  utxos: Array<BitcoinUtxo>;
};

export enum BabylonStates {
  Active = "active",
  Broadcasted = "broadcasted",
  Init = "init",
  Unbonded = "unbonded",
  UnbondingBroadcasted = "unbonding_broadcasted",
  UnbondingRequested = "unbonding_requested",
  Withdrawal = "withdrawal",
  WithdrawalBroadcasted = "withdrawal_broadcasted",
}

export type BabylonTx = {
  __typename?: "BabylonTx";
  signingPayload?: Maybe<Scalars["String"]>;
  stakeId: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
};

/** Inputs for getting an unsigned withdrawal tx */
export type BabylonWithdrawalInput = {
  stakeId: Scalars["String"];
  withdrawalAddress: Scalars["String"];
};

export enum BitcoinNetworks {
  Mainnet = "mainnet",
  Signet = "signet",
}

export type BitcoinUtxo = {
  /** script which provides the conditions that must be fulfilled for this UTXO to be spent */
  scriptPubKey: Scalars["String"];
  /** transaction ID */
  txid: Scalars["String"];
  /** value of the UTXO, in Satoshis */
  value: Scalars["Int"];
  /** output index */
  vout: Scalars["Int"];
};

export type CommssionRate = {
  __typename?: "CommssionRate";
  /** The commission rate. */
  rate?: Maybe<Scalars["Float"]>;
};

export enum CurrencyTickers {
  Btc = "BTC",
  Eth = "ETH",
  Sol = "SOL",
}

/** Attributes for scheduling a daily report */
export type DailyReportScheduleInput = {
  chainId?: InputMaybe<Scalars["String"]>;
  end: Scalars["String"];
  network: Scalars["String"];
  start: Scalars["String"];
  timeRollup: DailyReportTimeRollup;
};

export enum DailyReportStatus {
  /** Error */
  Error = "error",
  /** Finished */
  Finished = "finished",
  /** Running */
  Running = "running",
  /** Submitted */
  Submitted = "submitted",
  /** Unknown */
  Unknown = "unknown",
}

export enum DailyReportTimeRollup {
  /** Daily */
  Daily = "daily",
  /** Epoch */
  Epoch = "epoch",
  /** Era */
  Era = "era",
}

export type DailyReports = {
  __typename?: "DailyReports";
  chainId?: Maybe<Scalars["String"]>;
  end: Scalars["String"];
  expiresOn?: Maybe<Scalars["ISO8601DateTime"]>;
  file?: Maybe<Scalars["String"]>;
  fileExpiresOn?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  network: Scalars["String"];
  start: Scalars["String"];
  status: DailyReportStatus;
  timeRollup: DailyReportTimeRollup;
};

/** The connection type for DailyReports. */
export type DailyReportsConnection = {
  __typename?: "DailyReportsConnection";
  /** A list of edges. */
  edges: Array<DailyReportsEdge>;
  /** A list of nodes. */
  nodes: Array<DailyReports>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type DailyReportsEdge = {
  __typename?: "DailyReportsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: DailyReports;
};

export type DailyReportsMutationPayload = {
  __typename?: "DailyReportsMutationPayload";
  /** DailyReports object */
  data?: Maybe<DailyReports>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of DeactivateTransactionCreateMutation. */
export type DeactivateTransactionCreateMutationPayload = {
  __typename?: "DeactivateTransactionCreateMutationPayload";
  data?: Maybe<SolanaStakingTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of DelegateTransactionCreateMutation. */
export type DelegateTransactionCreateMutationPayload = {
  __typename?: "DelegateTransactionCreateMutationPayload";
  data?: Maybe<SolanaStakingTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type Deposit = {
  __typename?: "Deposit";
  address: Scalars["String"];
  amountEth: Scalars["Float"];
  network: Scalars["String"];
  unsignedTransactionHash: Scalars["String"];
  unsignedTransactionPayload: Scalars["String"];
};

export type DepositMutationPayload = {
  __typename?: "DepositMutationPayload";
  /** Deposit object */
  data?: Maybe<Deposit>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum DepositStatuses {
  Activated = "activated",
  Activating = "activating",
  Deposited = "deposited",
  Failed = "failed",
  Initialized = "initialized",
  Unfinalized = "unfinalized",
}

export type EigenlayerDelegateTo = {
  __typename?: "EigenlayerDelegateTo";
  network: Networks;
  operatorAddress: Scalars["String"];
  ownerAddress: Scalars["String"];
  txSigningPayload?: Maybe<Scalars["String"]>;
  unsignedRawTx?: Maybe<Scalars["String"]>;
};

/** Parameters to build a tx to delegate an Eigenpod to an Operator */
export type EigenlayerDelegateToInput = {
  network: EthereumNetworks;
  operatorAddress: Scalars["String"];
  ownerAddress: Scalars["String"];
};

export type EigenlayerDelegateToMutationPayload = {
  __typename?: "EigenlayerDelegateToMutationPayload";
  /** EigenlayerDelegateTo object */
  data?: Maybe<EigenlayerDelegateTo>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export type EigenlayerOperators = {
  __typename?: "EigenlayerOperators";
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  network: Scalars["String"];
  operatedBy: OperatedBy;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for EigenlayerOperators. */
export type EigenlayerOperatorsConnection = {
  __typename?: "EigenlayerOperatorsConnection";
  /** A list of edges. */
  edges: Array<EigenlayerOperatorsEdge>;
  /** A list of nodes. */
  nodes: Array<EigenlayerOperators>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EigenlayerOperatorsEdge = {
  __typename?: "EigenlayerOperatorsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: EigenlayerOperators;
};

export type Eigenpod = {
  __typename?: "Eigenpod";
  address: Scalars["String"];
  confirmedDeployedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  createdAt: Scalars["ISO8601DateTime"];
  createpodTxSigningPayload?: Maybe<Scalars["String"]>;
  deployed: Scalars["Boolean"];
  id: Scalars["ID"];
  network: Networks;
  ownerAddress: Scalars["String"];
  unsignedRawCreatepodTx?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Parameters to create an Eigenpod tracking record in our database, and possibly return a tx to create one onchain. */
export type EigenpodCreateInput = {
  network: EthereumNetworks;
  ownerAddress: Scalars["String"];
};

export type EigenpodMutationPayload = {
  __typename?: "EigenpodMutationPayload";
  /** Eigenpod object */
  data?: Maybe<Eigenpod>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export type EigenpodVerification = {
  __typename?: "EigenpodVerification";
  eigenpodAddress: Scalars["String"];
  network: Networks;
  proofs: Scalars["JSON"];
  transactionInputs: Scalars["JSON"];
  transactionPayload: Scalars["String"];
};

/** Parameters to build a tx to validate validators in the Eigenpod */
export type EigenpodVerificationInput = {
  network: EthereumNetworks;
  ownerAddress: Scalars["String"];
};

export type EigenpodVerificationMutationPayload = {
  __typename?: "EigenpodVerificationMutationPayload";
  /** EigenpodVerification object */
  data?: Maybe<EigenpodVerification>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type EthereumAccount = {
  __typename?: "EthereumAccount";
  /** The account address (e.g. 0xabc123) */
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  /** Address of Operator this account's Eigenpod is delegated to */
  delegatedTo?: Maybe<Scalars["String"]>;
  /** Address of the wallet that owns the Eigenpod */
  eigenpodOwnerAddress?: Maybe<Scalars["String"]>;
  /** The same as address, but checksummed or formatted for the specific protocol. */
  formattedAddress: Scalars["String"];
  id: Scalars["ID"];
  /** Whether the account has active delegation (manually managed) */
  isActive: Scalars["Boolean"];
  /** Is this address an Eigenpod? */
  isEigenpod: Scalars["Boolean"];
  /** Is this address a Vault? */
  isVault: Scalars["Boolean"];
  /** A free-form note about the account */
  label?: Maybe<Scalars["String"]>;
  /** The protocol network name (e.g. mainnet) */
  network: Scalars["String"];
  /** The Salesforce Opportunity Token. */
  opToken?: Maybe<OpToken>;
  /** The opportunity token for this account */
  opTokenId?: Maybe<Scalars["String"]>;
  /** Options that are set per account type. This is generic JSON because all account types can have different options. */
  options: Scalars["JSON"];
  /** The protocol name (e.g. Ethereum) */
  protocol: Scalars["String"];
  /** Signals the need to report for a white label validator */
  reportForWhiteLabelValidator: Scalars["Boolean"];
  /** Where this account was first added from */
  source?: Maybe<Scalars["String"]>;
  /** Sum of all staked balance */
  stakedBalance?: Maybe<Scalars["String"]>;
  /** Sum of all staked balance in USD */
  stakedBalanceUsd?: Maybe<Scalars["String"]>;
  /** total number of active validators */
  totalActiveValidators: Scalars["Int"];
  /** The number of validators eligible to exit */
  totalExitableValidators: Scalars["Int"];
  /** Sum of all gross/earned rewards */
  totalRewards?: Maybe<Scalars["String"]>;
  /** Sum of all rewards received */
  totalRewardsReceived?: Maybe<Scalars["String"]>;
  /** Sum of all rewards received in USD */
  totalRewardsReceivedUsd?: Maybe<Scalars["String"]>;
  /** Sum of all gross/earned rewards in USD */
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  /** The number of eigenlayer unverified validators within this pod */
  totalUnverifiedValidators: Scalars["Int"];
  /** The number of validators with a withdrawal address matching this account */
  totalValidators: Scalars["Int"];
  /** The number of eigenlayer verified validators within this pod */
  totalVerifiedValidators: Scalars["Int"];
  /** The type of account */
  type?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  /** Totals by validator status */
  validatorCountsByStatus?: Maybe<ValidatorCountsByStatus>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type EthereumAccountTotalActiveValidatorsArgs = {
  operatedBy?: InputMaybe<OperatedBy>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type EthereumAccountTotalExitableValidatorsArgs = {
  operatedBy?: InputMaybe<OperatedBy>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type EthereumAccountTotalValidatorsArgs = {
  operatedBy?: InputMaybe<OperatedBy>;
};

/** Autogenerated return type of EthereumAccountAddMutation. */
export type EthereumAccountAddMutationPayload = {
  __typename?: "EthereumAccountAddMutationPayload";
  data?: Maybe<Account>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** The connection type for EthereumAccount. */
export type EthereumAccountConnection = {
  __typename?: "EthereumAccountConnection";
  /** A list of edges. */
  edges: Array<EthereumAccountEdge>;
  /** A list of nodes. */
  nodes: Array<EthereumAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EthereumAccountEdge = {
  __typename?: "EthereumAccountEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: EthereumAccount;
};

export type EthereumBalanceSummary = {
  __typename?: "EthereumBalanceSummary";
  balance?: Maybe<Scalars["String"]>;
  balanceUsd?: Maybe<Scalars["String"]>;
};

export type EthereumExitRequest = {
  __typename?: "EthereumExitRequest";
  activity: Scalars["String"];
  activityAt: Scalars["ISO8601DateTime"];
  amountEth: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  estimatedPrincipalReturnAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedWithdrawableAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  network: Networks;
  pubkeys: Array<Scalars["String"]>;
  region?: Maybe<Regions>;
  status: ExitRequestStatuses;
  updatedAt: Scalars["ISO8601DateTime"];
  user?: Maybe<User>;
  validators: Array<EthereumStakingValidator>;
  withdrawalAddress: Scalars["String"];
};

/** Parameters to exit a set of validators. */
export type EthereumExitRequestCreateInput = {
  amountEth: Scalars["Int"];
  network: Networks;
  region?: InputMaybe<Regions>;
  withdrawalAddress: Scalars["String"];
};

export type EthereumExitRequestMutationPayload = {
  __typename?: "EthereumExitRequestMutationPayload";
  /** EthereumExitRequest object */
  data?: Maybe<EthereumExitRequest>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum EthereumNetworks {
  /** Ethereum holesky testnet */
  Holesky = "holesky",
  /** Ethereum mainnet */
  Mainnet = "mainnet",
}

export type EthereumStakingDeposit = {
  __typename?: "EthereumStakingDeposit";
  activity: Scalars["String"];
  activityAt: Scalars["ISO8601DateTime"];
  amountEth: Scalars["String"];
  blockNumber?: Maybe<Scalars["Int"]>;
  blockTime?: Maybe<Scalars["ISO8601DateTime"]>;
  createdAt: Scalars["ISO8601DateTime"];
  estimatedActiveAt?: Maybe<Scalars["ISO8601DateTime"]>;
  fundingAddress?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  network: Networks;
  provisioners?: Maybe<Array<User>>;
  status: DepositStatuses;
  trackingTransaction?: Maybe<TrackingTransaction>;
  txHash: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  validators: Array<EthereumStakingValidator>;
};

export type EthereumStakingRequest = {
  __typename?: "EthereumStakingRequest";
  amountEth: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  feeRecipientAddress?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  maxGasWei: Scalars["String"];
  network: Networks;
  region: Regions;
  unsignedTransactionHashed: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  validators: Array<EthereumStakingValidator>;
  withdrawalAddress: Scalars["String"];
};

/** Parameters to create a set of validators and staking transaction. */
export type EthereumStakingRequestCreateInput = {
  amountEth: Scalars["Int"];
  feeRecipientAddress?: InputMaybe<Scalars["String"]>;
  fundingAddress?: InputMaybe<Scalars["String"]>;
  network: Networks;
  region?: InputMaybe<Regions>;
  withdrawalAddress: Scalars["String"];
};

export type EthereumStakingRequestMutationPayload = {
  __typename?: "EthereumStakingRequestMutationPayload";
  /** EthereumStakingRequest object */
  data?: Maybe<EthereumStakingRequest>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** Parameters to set the transaction hash for a Staking Request. */
export type EthereumStakingRequestSetTxHashInput = {
  amountEth?: InputMaybe<Scalars["Int"]>;
  blockNumber?: InputMaybe<Scalars["Int"]>;
  blockTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  fundingAddress?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  txHash: Scalars["String"];
};

export type EthereumStakingValidator = {
  __typename?: "EthereumStakingValidator";
  amountEth: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  deposit?: Maybe<EthereumStakingDeposit>;
  estimatedActiveAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedExitAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedWithdrawalAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  network: Networks;
  pubkey: Scalars["String"];
  region: Regions;
  status: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  withdrawalAddress: Scalars["String"];
};

export type EthereumTransactionPayload = {
  __typename?: "EthereumTransactionPayload";
  signingPayload: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
};

export type EthereumTransactionPayloadMutationPayload = {
  __typename?: "EthereumTransactionPayloadMutationPayload";
  /** EthereumTransactionPayload object */
  data?: Maybe<EthereumTransactionPayload>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum ExitRequestStatuses {
  Completed = "completed",
  ExitRequested = "exit_requested",
  Exiting = "exiting",
  PendingWithdrawal = "pending_withdrawal",
}

export type FigmentValidatorCounts = {
  __typename?: "FigmentValidatorCounts";
  /** Totals by validator status */
  byStatus?: Maybe<ValidatorCountsByStatus>;
  /** Active validators which have already been requested to exit */
  exitPending?: Maybe<Scalars["Int"]>;
  /** Active validators which have not yet been requested to exit */
  exitable?: Maybe<Scalars["Int"]>;
  /** The total number of validators across all statuses */
  total?: Maybe<Scalars["Int"]>;
};

export type Invitation = {
  __typename?: "Invitation";
  createdAt: Scalars["ISO8601DateTime"];
  email: Scalars["String"];
  expiresAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  organization: Organization;
  role: Role;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for Invitation. */
export type InvitationConnection = {
  __typename?: "InvitationConnection";
  /** A list of edges. */
  edges: Array<InvitationEdge>;
  /** A list of nodes. */
  nodes: Array<Invitation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** Attributes for creating an invitation */
export type InvitationCreateInput = {
  clientId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  name: Scalars["String"];
  roleId: Scalars["ID"];
};

/** An edge in a connection. */
export type InvitationEdge = {
  __typename?: "InvitationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Invitation;
};

export type InvitationMutationPayload = {
  __typename?: "InvitationMutationPayload";
  /** Invitation object */
  data?: Maybe<Invitation>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum InvitationOrderByFields {
  /** Date the invitation was created */
  CreatedAt = "createdAt",
  /** Date the invitation was updated */
  UpdatedAt = "updatedAt",
}

export type Invitations = {
  field: InvitationOrderByFields;
  sortDirection: SortDirection;
};

/** Attributes for creating a Liquid Collective Claim Transaction */
export type LiquidCollectiveClaimTransactionCreateInput = {
  gasLimit?: InputMaybe<Scalars["Float"]>;
  gasPrice?: InputMaybe<Scalars["Float"]>;
  network: EthereumNetworks;
  redeemRequestId: Scalars["String"];
};

/** Attributes for creating a Liquid Collective Deposit */
export type LiquidCollectiveDepositCreateInput = {
  address: Scalars["String"];
  amountEth: Scalars["Float"];
  network: Scalars["String"];
};

/** Attributes for creating a Liquid Collective Redeem Request */
export type LiquidCollectiveRedeemRequestCreateInput = {
  address: Scalars["String"];
  amountLseth: Scalars["String"];
  gasLimit?: InputMaybe<Scalars["Float"]>;
  gasPrice?: InputMaybe<Scalars["Float"]>;
  network: EthereumNetworks;
};

/** Parameters to delegate SOL. */
export type MaxDelegationEstimateInput = {
  fundingAccountPubkey: Scalars["String"];
  network: SolanaNetworks;
  voteAccountPubkey: Scalars["String"];
};

export type Me = {
  __typename?: "Me";
  id: Scalars["ID"];
  organization: Organization;
  otherMemberships: MemberConnection;
  role: Role;
  user: User;
};

export type MeOtherMembershipsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type Member = {
  __typename?: "Member";
  id: Scalars["ID"];
  organization: Organization;
  role: Role;
  user: User;
};

/** The connection type for Member. */
export type MemberConnection = {
  __typename?: "MemberConnection";
  /** A list of edges. */
  edges: Array<MemberEdge>;
  /** A list of nodes. */
  nodes: Array<Member>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type MemberEdge = {
  __typename?: "MemberEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Member;
};

export type MemberMutationPayload = {
  __typename?: "MemberMutationPayload";
  /** Member object */
  data?: Maybe<Member>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum MemberOrderByFields {
  /** Date the member was created */
  CreatedAt = "createdAt",
  /** The email of the member */
  Email = "email",
  /** The name of the member */
  Name = "name",
  /** Date the member was updated last time */
  UpdatedAt = "updatedAt",
}

export type Members = {
  field: MemberOrderByFields;
  sortDirection: SortDirection;
};

export type MfaTicket = {
  __typename?: "MfaTicket";
  sendMail?: Maybe<Scalars["Boolean"]>;
  ticketUrl?: Maybe<Scalars["String"]>;
};

export type MfaTicketMutationPayload = {
  __typename?: "MfaTicketMutationPayload";
  /** MfaTicket object */
  data?: Maybe<MfaTicket>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export type MonthlyStatement = {
  __typename?: "MonthlyStatement";
  downloadUrl?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  generatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  month?: Maybe<Scalars["ISO8601Date"]>;
  statementType?: Maybe<Statement>;
};

/** The connection type for MonthlyStatement. */
export type MonthlyStatementConnection = {
  __typename?: "MonthlyStatementConnection";
  /** A list of edges. */
  edges: Array<MonthlyStatementEdge>;
  /** A list of nodes. */
  nodes: Array<MonthlyStatement>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type MonthlyStatementEdge = {
  __typename?: "MonthlyStatementEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: MonthlyStatement;
};

export enum MonthlyStatementOrderByFields {
  /** Date the report was created */
  GeneratedAt = "generatedAt",
  /** The month for which the report was generated */
  Month = "month",
  /** The type of monthly statement */
  StatementType = "statementType",
}

export type MonthlyStatements = {
  field: MonthlyStatementOrderByFields;
  sortDirection: SortDirection;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Create api key */
  apiKeyCreate: ApiKeyMutationPayload;
  /** Delete api key */
  apiKeyDelete: ApiKeyMutationPayload;
  /** Update Api Key */
  apiKeyUpdate: ApiKeyMutationPayload;
  /** After a Babylon stake tx is broadcasted, add the tx_hash to the stake record. */
  babylonStakeBroadcasted?: Maybe<StakeBroadcastedMutationPayload>;
  /** Get a tx to stake BTC to Babylon */
  babylonStakeBtc?: Maybe<StakeTransactionCreatePayload>;
  /** Get a tx to stake BTC to Babylon */
  babylonStakeTransactionCreate?: Maybe<StakeTransactionCreatePayload>;
  /** Get a transaction to unbond BTC from Babylon */
  babylonUnbondTransactionCreate?: Maybe<UnbondTransactionCreatePayload>;
  /** Get a transaction to withdraw BTC from Babylon */
  babylonWithdrawalTransactionCreate?: Maybe<WithdrawalTransactionCreatePayload>;
  /** Schedule a daily report */
  dailyReportSchedule: DailyReportsMutationPayload;
  /** Create an Eigenpod record and retrieve a createPod transaction if it has not yet been deployed onchain. */
  eigenlayerDelegateTo: EigenlayerDelegateToMutationPayload;
  /** A transaction payload to verify validator withdrawal credentials for an EigenPod */
  eigenlayerVerifyWithdrawalCredentialsTransactionCreate?: Maybe<VerifyWithdrawalCredentialsTransactionCreatePayload>;
  /** Create an Eigenpod record and retrieve a createPod transaction if it has not yet been deployed onchain. */
  eigenpodCreate: EigenpodMutationPayload;
  /** Create an Eigenpod Verification record and retrieve a verifyWithdrawalCredentials transaction. */
  eigenpodVerificationCreate: EigenpodVerificationMutationPayload;
  /** Request to exit Ethereum Validators. */
  ethereumExitRequestCreate: EthereumExitRequestMutationPayload;
  /** Create validators and retrieve a staking transaction. */
  ethereumStakingRequestCreate: EthereumStakingRequestMutationPayload;
  /** Set the transaction hash for a Staking Request. */
  ethereumStakingRequestSetTxHash: EthereumStakingRequestMutationPayload;
  /** Create Invitation */
  invitationCreate: InvitationMutationPayload;
  /** Delete Invitation */
  invitationDelete: InvitationMutationPayload;
  /** Resend an invitation to a user */
  invitationResend?: Maybe<MutationsAccountsInvitationResendPayload>;
  /** Create liquid collective claim transaction */
  liquidCollectiveClaimTransactionCreate: EthereumTransactionPayloadMutationPayload;
  /** Create liquid collective deposit */
  liquidCollectiveDepositCreate: DepositMutationPayload;
  /** Create liquid collective redeem request */
  liquidCollectiveRedeemRequestCreate: EthereumTransactionPayloadMutationPayload;
  /** Delete Member */
  memberDelete: MemberMutationPayload;
  /** Change Member Role */
  memberRoleChange: MemberMutationPayload;
  /** Update an organization */
  organizationUpdate: OrganizationMutationPayload;
  /** Create project */
  projectCreate: ProjectMutationPayload;
  /** Delete project */
  projectDelete: ProjectMutationPayload;
  /** Update project */
  projectUpdate: ProjectMutationPayload;
  /** Sign up a new user */
  signUpCreate?: Maybe<MutationsAccountsSignUpCreatePayload>;
  /** A transaction payload to deactivate a Stake Account */
  solanaStakingDeactivateTransactionCreate?: Maybe<DeactivateTransactionCreateMutationPayload>;
  /** A transaction payload to create and delegate to a stake account */
  solanaStakingDelegateTransactionCreate?: Maybe<DelegateTransactionCreateMutationPayload>;
  /** Save a transaction hash to the staking activity log. */
  solanaStakingTxSave?: Maybe<TxSaveMutationPayload>;
  /** A transaction payload to withdraw funds from an inactive Stake Account */
  solanaStakingWithdrawTransactionCreate?: Maybe<WithdrawTransactionCreateMutationPayload>;
  /** Tracks an Ethereum funding account */
  trackedEthereumAccountAdd?: Maybe<EthereumAccountAddMutationPayload>;
  /** Creates a url for the user to setup MFA */
  userCreateMfaTicket: MfaTicketMutationPayload;
  /** Resets the users MFA in case they are locked out. */
  userResetMfa: UserMutationPayload;
  /** Sends reset password email */
  userSendResetPasswordEmail: UserMutationPayload;
  /** Update a user's name or email. Also updates the user in Auth0. */
  userUpdate: UserMutationPayload;
};

export type MutationApiKeyCreateArgs = {
  input: ApiKeyCreateInput;
};

export type MutationApiKeyDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationApiKeyUpdateArgs = {
  id: Scalars["ID"];
  input: ApiKeyUpdateInput;
};

export type MutationBabylonStakeBroadcastedArgs = {
  input: BabylonStakeBroadcastedInput;
};

export type MutationBabylonStakeBtcArgs = {
  input: BabylonStakingTxInput;
};

export type MutationBabylonStakeTransactionCreateArgs = {
  input: BabylonStakingTxInput;
};

export type MutationBabylonUnbondTransactionCreateArgs = {
  input: BabylonStakeIdInput;
};

export type MutationBabylonWithdrawalTransactionCreateArgs = {
  input: BabylonWithdrawalInput;
};

export type MutationDailyReportScheduleArgs = {
  input: DailyReportScheduleInput;
};

export type MutationEigenlayerDelegateToArgs = {
  input: EigenlayerDelegateToInput;
};

export type MutationEigenlayerVerifyWithdrawalCredentialsTransactionCreateArgs =
  {
    input: EigenpodVerificationInput;
  };

export type MutationEigenpodCreateArgs = {
  input: EigenpodCreateInput;
};

export type MutationEigenpodVerificationCreateArgs = {
  input: EigenpodVerificationInput;
};

export type MutationEthereumExitRequestCreateArgs = {
  input: EthereumExitRequestCreateInput;
};

export type MutationEthereumStakingRequestCreateArgs = {
  input: EthereumStakingRequestCreateInput;
};

export type MutationEthereumStakingRequestSetTxHashArgs = {
  input: EthereumStakingRequestSetTxHashInput;
};

export type MutationInvitationCreateArgs = {
  input: InvitationCreateInput;
};

export type MutationInvitationDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationInvitationResendArgs = {
  input: AccountsInvitationResendInput;
};

export type MutationLiquidCollectiveClaimTransactionCreateArgs = {
  input: LiquidCollectiveClaimTransactionCreateInput;
};

export type MutationLiquidCollectiveDepositCreateArgs = {
  input: LiquidCollectiveDepositCreateInput;
};

export type MutationLiquidCollectiveRedeemRequestCreateArgs = {
  input: LiquidCollectiveRedeemRequestCreateInput;
};

export type MutationMemberDeleteArgs = {
  organizationId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationMemberRoleChangeArgs = {
  organizationId: Scalars["ID"];
  roleId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationOrganizationUpdateArgs = {
  input: OrganizationUpdateInput;
};

export type MutationProjectCreateArgs = {
  input: ProjectCreateInput;
};

export type MutationProjectDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationProjectUpdateArgs = {
  id: Scalars["ID"];
  input: ProjectUpdateInput;
};

export type MutationSignUpCreateArgs = {
  input: AccountsSignUpInput;
};

export type MutationSolanaStakingDeactivateTransactionCreateArgs = {
  input: SolanaStakingDeactivateTransactionCreateInput;
};

export type MutationSolanaStakingDelegateTransactionCreateArgs = {
  input: SolanaStakingDelegateTransactionCreateInput;
};

export type MutationSolanaStakingTxSaveArgs = {
  input: SolanaStakingTxSaveInput;
};

export type MutationSolanaStakingWithdrawTransactionCreateArgs = {
  input: SolanaStakingWithdrawTransactionCreateInput;
};

export type MutationTrackedEthereumAccountAddArgs = {
  input: TrackedAccountAddInput;
};

export type MutationUserCreateMfaTicketArgs = {
  sendMail: Scalars["Boolean"];
};

export type MutationUserResetMfaArgs = {
  userId: Scalars["ID"];
};

export type MutationUserSendResetPasswordEmailArgs = {
  userId: Scalars["ID"];
};

export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type MutationError = {
  __typename?: "MutationError";
  code: Scalars["String"];
  extras?: Maybe<Scalars["JSON"]>;
  field?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated return type of MutationsAccountsInvitationResend. */
export type MutationsAccountsInvitationResendPayload = {
  __typename?: "MutationsAccountsInvitationResendPayload";
  data?: Maybe<AccountsInvitation>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of MutationsAccountsSignUpCreate. */
export type MutationsAccountsSignUpCreatePayload = {
  __typename?: "MutationsAccountsSignUpCreatePayload";
  data?: Maybe<AccountsSignUp>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type NetworkEstimate = {
  __typename?: "NetworkEstimate";
  hours?: Maybe<Scalars["Int"]>;
  hoursMax?: Maybe<Scalars["Int"]>;
  hoursMin?: Maybe<Scalars["Int"]>;
};

export type NetworkEstimates = {
  __typename?: "NetworkEstimates";
  estimatedActivation?: Maybe<NetworkEstimate>;
  estimatedExit?: Maybe<NetworkEstimate>;
  estimatedWithdrawal?: Maybe<NetworkEstimate>;
};

export type NetworkOverview = {
  __typename?: "NetworkOverview";
  estimatedActivation?: Maybe<NetworkEstimate>;
  estimatedExit?: Maybe<NetworkEstimate>;
  estimatedWithdrawal?: Maybe<NetworkEstimate>;
  price?: Maybe<Price>;
  rewardsRate?: Maybe<RewardsRate>;
};

export enum Networks {
  /** Ethereum goerli testnet */
  Goerli = "goerli",
  /** Ethereum holesky testnet */
  Holesky = "holesky",
  /** Ethereum mainnet */
  Mainnet = "mainnet",
  /** Ethereum prater testnet */
  Prater = "prater",
}

export type OnDemandExit = {
  __typename?: "OnDemandExit";
  /** On demand exit approved at */
  approvedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** On demand exit request at */
  requestedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** On demand exit submitted at */
  submittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** A Salesforce opportunity token */
export type OpToken = {
  __typename?: "OpToken";
  /** The Salesforce ID for the op token */
  id: Scalars["String"];
  /** The name displayed for the token in Salesforce */
  name: Scalars["String"];
  /** The full URL to the Op Token page in Salesforce */
  url: Scalars["String"];
};

export enum OperatedBy {
  Figment = "figment",
  Other = "other",
}

export enum Operations {
  /** Ethereum batch staking */
  AggregatedStaking = "aggregated_staking",
  /** Ethereum batch unstaking */
  AggregatedUnstaking = "aggregated_unstaking",
  /** Staking operations */
  Staking = "staking",
  /** Unstaking operations */
  Unstaking = "unstaking",
}

export type Organization = {
  __typename?: "Organization";
  address?: Maybe<Address>;
  createdAt: Scalars["ISO8601DateTime"];
  devmodeEnabled: Scalars["Boolean"];
  displayName: Scalars["String"];
  id: Scalars["ID"];
  isIndividual: Scalars["Boolean"];
  members?: Maybe<MemberConnection>;
  name: Scalars["String"];
  riskAssessmentPassed?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  usesOnchainBilling: Scalars["Boolean"];
};

export type OrganizationMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type OrganizationMutationPayload = {
  __typename?: "OrganizationMutationPayload";
  /** Organization object */
  data?: Maybe<Organization>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** Attributes for updating an organization */
export type OrganizationUpdateInput = {
  address?: InputMaybe<AddressInput>;
  displayName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type Price = {
  __typename?: "Price";
  date?: Maybe<Scalars["ISO8601DateTime"]>;
  value?: Maybe<Scalars["Float"]>;
};

export type Project = {
  __typename?: "Project";
  apiKeys: ApiKeyConnection;
  createdAt: Scalars["ISO8601DateTime"];
  description?: Maybe<Scalars["String"]>;
  /** One of production, test */
  environment?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  organization: Organization;
  readOnly?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

export type ProjectApiKeysArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ApiKeys>>;
};

/** The connection type for Project. */
export type ProjectConnection = {
  __typename?: "ProjectConnection";
  /** A list of edges. */
  edges: Array<ProjectEdge>;
  /** A list of nodes. */
  nodes: Array<Project>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** Attributes for creating a project */
export type ProjectCreateInput = {
  description?: InputMaybe<Scalars["String"]>;
  environment?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  readOnly?: InputMaybe<Scalars["Boolean"]>;
};

/** An edge in a connection. */
export type ProjectEdge = {
  __typename?: "ProjectEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Project;
};

export type ProjectMutationPayload = {
  __typename?: "ProjectMutationPayload";
  /** Project object */
  data?: Maybe<Project>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum ProjectOrderByFields {
  /** Date the Project was created */
  CreatedAt = "createdAt",
  /** The description of the Project */
  Description = "description",
  /** The name of the Project */
  Name = "name",
  /** Date the Project was updated last time */
  UpdatedAt = "updatedAt",
}

/** Attributes for updating a project */
export type ProjectUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  environment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type Projects = {
  field: ProjectOrderByFields;
  sortDirection: SortDirection;
};

export type Query = {
  __typename?: "Query";
  /** Aggregated rewards by periods */
  aggregatedRewards: AggregatedRewards;
  /** Babylon Network Overview */
  babylonNetworkOverview: BabylonNetworkOverview;
  /** Babylon Stakes */
  babylonStakes: BabylonStakeConnection;
  /** All daily reports for an organization */
  dailyReports: DailyReportsConnection;
  /** Eigenlayer Operators */
  eigenlayerOperators?: Maybe<EigenlayerOperatorsConnection>;
  /** ETH balances aggregated across all tracked addresses for the org */
  ethereumBalanceSummary: EthereumBalanceSummary;
  /** Figment-calculated Ethereum network estimates */
  ethereumNetworkEstimates: NetworkEstimates;
  /** The latest Ethereum price */
  ethereumPrice: Price;
  /** Ethereum network rewards rate */
  ethereumRewardsRate: RewardsRate;
  /** Withdrawal addresses and other network wallet addresses tracked for rewards and balances. Protocol is always Ethereum. */
  ethereumWithdrawalAccount?: Maybe<EthereumAccount>;
  /** Withdrawal addresses and other network wallet addresses tracked for rewards and balances. Protocol is always Ethereum. */
  ethereumWithdrawalAccounts: EthereumAccountConnection;
  /** Number of Figment-operated validators for an organization (by status) */
  figmentValidatorCounts: FigmentValidatorCounts;
  /** All invitations for organization */
  invitations: InvitationConnection;
  /** The projected time for the Redeem Request to be fulfilled */
  liquidCollectiveRedeemManagerProjection: RedeemManagerProjection;
  /** A list of Liquid Collective Redeem Requests for a wallet address */
  liquidCollectiveRedeemRequests: Array<RedeemRequest>;
  me: Me;
  /** Organization members */
  members: MemberConnection;
  /** Monthly statements for all protocols supported for the org */
  monthlyStatements: MonthlyStatementConnection;
  /**
   * Figment-calculated Ethereum network estimates
   * @deprecated Use ethereumRewardsRate, ethereumNetworkEstimates, and ethereumPrice instead
   */
  networkOverview: NetworkOverview;
  /** Current organization */
  organization: Organization;
  /** All projects for the users organization. */
  projects: ProjectConnection;
  /** Protocol price */
  protocolPrice: Price;
  /** Balance and rewards */
  rewardsSummary: RewardsSummary;
  /** All roles used for access control. */
  roles: RoleConnection;
  /** Aggregated SOL rewards by periods */
  solanaAggregatedRewards: AggregatedRewards;
  /** SOL balances aggregated across all tracked addresses for the org */
  solanaBalanceSummary: SolanaBalanceSummary;
  /** Estimated time to activate stake (next epoch). */
  solanaEstimatedActivationTime: StakingTimeEstimate;
  /** Estimated time to deactivate stake (next epoch). */
  solanaEstimatedDeactivationTime: StakingTimeEstimate;
  /** Figment's public validator commission rate. */
  solanaFigmentPublicCommissionRate: CommssionRate;
  /** SOL price */
  solanaPrice: Price;
  /** Solana network rewards rate */
  solanaRewardsRate: RewardsRate;
  /** SOL total rewards for organization */
  solanaRewardsSummary: SolanaRewardsSummary;
  /** Solana stake accounts */
  solanaStakeAccounts: StakeAccountConnection;
  /** Details about the max value that can be delegated in a delegation transaction. */
  solanaStakingMaxDelegationEstimate: SolanaStakingMaxDelegationEstimate;
  /** Staking API activity */
  stakingActivity: StakingActivityConnection;
  /**
   * Staking API activity (Slate Flows)
   * @deprecated This is old Slate flows. Use stakingActivity (ETH) or stakingActivityLogs instead.
   */
  stakingActivityFlows: StakingActivityFlowConnection;
  /** Staking activity logs */
  stakingActivityLogs: StakingActivityLogConnection;
  /** Withdrawal addresses and other network wallet addresses tracked for rewards and balances. */
  trackedAccounts: AccountConnection;
  /** Validators tracked for rewards and balances. */
  trackedValidators: ValidatorConnection;
  /** Tracking Transactions */
  trackingTransaction?: Maybe<TrackingTransactionConnection>;
  /** Number of validators for an organization (by status) */
  validatorCounts: ValidatorCounts;
};

export type QueryAggregatedRewardsArgs = {
  ethAccount?: InputMaybe<Scalars["String"]>;
  network?: InputMaybe<Scalars["String"]>;
  timePeriod?: InputMaybe<TimePeriod>;
};

export type QueryBabylonNetworkOverviewArgs = {
  network: BitcoinNetworks;
};

export type QueryBabylonStakesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network: BitcoinNetworks;
  state?: InputMaybe<BabylonStates>;
};

export type QueryDailyReportsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryEigenlayerOperatorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  operatedBy?: InputMaybe<Array<OperatedBy>>;
};

export type QueryEthereumBalanceSummaryArgs = {
  network?: EthereumNetworks;
};

export type QueryEthereumWithdrawalAccountArgs = {
  address: Scalars["String"];
  network: EthereumNetworks;
};

export type QueryEthereumWithdrawalAccountsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AccountsFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Accounts>>;
};

export type QueryFigmentValidatorCountsArgs = {
  ethAccount?: InputMaybe<Scalars["String"]>;
  network?: InputMaybe<Scalars["String"]>;
};

export type QueryInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  excludeInternalMembers?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Invitations>>;
};

export type QueryLiquidCollectiveRedeemManagerProjectionArgs = {
  network?: EthereumNetworks;
};

export type QueryLiquidCollectiveRedeemRequestsArgs = {
  address: Scalars["String"];
  network?: EthereumNetworks;
};

export type QueryMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  excludeInternalMembers?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Members>>;
};

export type QueryMonthlyStatementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MonthlyStatements>>;
};

export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Projects>>;
};

export type QueryProtocolPriceArgs = {
  ticker: CurrencyTickers;
};

export type QueryRewardsSummaryArgs = {
  network?: InputMaybe<Scalars["String"]>;
};

export type QueryRolesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QuerySolanaAggregatedRewardsArgs = {
  network?: InputMaybe<Scalars["String"]>;
  timePeriod: TimePeriod;
};

export type QuerySolanaBalanceSummaryArgs = {
  network?: InputMaybe<SolanaNetworks>;
};

export type QuerySolanaEstimatedActivationTimeArgs = {
  network?: SolanaNetworks;
};

export type QuerySolanaEstimatedDeactivationTimeArgs = {
  network?: SolanaNetworks;
};

export type QuerySolanaRewardsSummaryArgs = {
  network?: InputMaybe<SolanaNetworks>;
};

export type QuerySolanaStakeAccountsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network?: SolanaNetworks;
  orderBy?: InputMaybe<Array<SolanaAccounts>>;
};

export type QuerySolanaStakingMaxDelegationEstimateArgs = {
  input: MaxDelegationEstimateInput;
};

export type QueryStakingActivityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  fundingAddress?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  minDepositStatus?: InputMaybe<DepositStatuses>;
  minExitRequestStatus?: InputMaybe<ExitRequestStatuses>;
  network?: InputMaybe<Networks>;
  operation?: InputMaybe<Array<ActivityTypes>>;
  page?: InputMaybe<Scalars["Int"]>;
  protocol?: InputMaybe<Scalars["String"]>;
  withdrawalAddress?: InputMaybe<Scalars["String"]>;
};

export type QueryStakingActivityFlowsArgs = {
  network?: InputMaybe<Networks>;
  operation?: InputMaybe<Array<Operations>>;
  page?: InputMaybe<Scalars["Int"]>;
  protocol?: InputMaybe<Scalars["String"]>;
  states?: InputMaybe<Array<States>>;
};

export type QueryStakingActivityLogsArgs = {
  activityType?: InputMaybe<StakingActivityActivityTypes>;
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network?: InputMaybe<Scalars["String"]>;
  protocol: StakingActivityProtocols;
  status?: InputMaybe<StakingActivityStatuses>;
};

export type QueryTrackedAccountsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AccountsFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Accounts>>;
};

export type QueryTrackedValidatorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<ValidatorsFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Validators>>;
};

export type QueryTrackingTransactionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network: Networks;
  protocol: Scalars["String"];
  status?: InputMaybe<TransactionStatuses>;
  txHash?: InputMaybe<Scalars["String"]>;
};

export type QueryValidatorCountsArgs = {
  ethAccount?: InputMaybe<Scalars["String"]>;
  network?: InputMaybe<Scalars["String"]>;
  operatedBy?: InputMaybe<Array<OperatedBy>>;
  provisionedByMyOrganization?: InputMaybe<Scalars["Boolean"]>;
};

export type RedeemManagerProjection = {
  __typename?: "RedeemManagerProjection";
  projectedFulfilledAt: Scalars["String"];
};

export type RedeemRequest = {
  __typename?: "RedeemRequest";
  claimableAmountLseth: Scalars["String"];
  claimedAmountLseth: Scalars["String"];
  height: Scalars["String"];
  id: Scalars["String"];
  maxRedeemableAmountEth: Scalars["String"];
  network: Scalars["String"];
  owner: Scalars["String"];
  projectedRedeemableAt?: Maybe<Scalars["String"]>;
  requestedAt: Scalars["Float"];
  requestedAtTimestamp: Scalars["String"];
  statusClaim: Scalars["String"];
  statusSatisfaction: Scalars["String"];
  totalAmountLseth: Scalars["String"];
  withdrawalEventId: Scalars["Float"];
};

export enum Regions {
  /** Canada Central 1 */
  CaCentral_1 = "ca_central_1",
  /** Europe West 1 */
  EuWest_1 = "eu_west_1",
}

export type RewardsRate = {
  __typename?: "RewardsRate";
  date?: Maybe<Scalars["ISO8601Date"]>;
  staking?: Maybe<Scalars["Float"]>;
  tips?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** ETH rewards summary for an organization */
export type RewardsSummary = {
  __typename?: "RewardsSummary";
  balance?: Maybe<Scalars["String"]>;
  balanceUsd?: Maybe<Scalars["String"]>;
  consensusEarned?: Maybe<Scalars["String"]>;
  consensusEarnedUsd?: Maybe<Scalars["String"]>;
  executionEarned?: Maybe<Scalars["String"]>;
  executionEarnedUsd?: Maybe<Scalars["String"]>;
  executionReceived?: Maybe<Scalars["String"]>;
  executionReceivedUsd?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use consensusRewards instead */
  stakingRewards?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use consensusRewardsUsd instead */
  stakingRewardsUsd?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use executionRewards instead */
  tipsRewards?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use executionRewardsUsd instead */
  tipsRewardsUsd?: Maybe<Scalars["String"]>;
  totalEarned?: Maybe<Scalars["String"]>;
  totalEarnedUsd?: Maybe<Scalars["String"]>;
  totalReceived?: Maybe<Scalars["String"]>;
  totalReceivedUsd?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use totalReceived instead */
  totalRewards?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use totalReceivedUsd instead */
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

export type Role = {
  __typename?: "Role";
  createdAt: Scalars["ISO8601DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for Role. */
export type RoleConnection = {
  __typename?: "RoleConnection";
  /** A list of edges. */
  edges: Array<RoleEdge>;
  /** A list of nodes. */
  nodes: Array<Role>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type RoleEdge = {
  __typename?: "RoleEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Role;
};

export enum SolanaAccountOrderByFields {
  /** Balance of the account in Lamports */
  ActiveBalanceLamports = "activeBalanceLamports",
  /** Date the Account was created */
  CreatedAt = "createdAt",
  /** Status of the account */
  Status = "status",
}

export type SolanaAccounts = {
  field: SolanaAccountOrderByFields;
  sortDirection: SortDirection;
};

export type SolanaBalanceSummary = {
  __typename?: "SolanaBalanceSummary";
  balance?: Maybe<Scalars["String"]>;
  balanceUsd?: Maybe<Scalars["String"]>;
};

export enum SolanaNetworks {
  /** Solana devnet */
  Devnet = "devnet",
  /** Solana mainnet */
  Mainnet = "mainnet",
  /** Solana testnet */
  Testnet = "testnet",
}

export type SolanaRewardsSummary = {
  __typename?: "SolanaRewardsSummary";
  balance?: Maybe<Scalars["String"]>;
  balanceUsd?: Maybe<Scalars["String"]>;
  mevRewards?: Maybe<Scalars["String"]>;
  mevRewardsUsd?: Maybe<Scalars["String"]>;
  protocolRewards?: Maybe<Scalars["String"]>;
  protocolRewardsUsd?: Maybe<Scalars["String"]>;
  totalRewards?: Maybe<Scalars["String"]>;
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** Parameters to generate a SOL deactivate transaction. */
export type SolanaStakingDeactivateTransactionCreateInput = {
  network: SolanaNetworks;
  stakeAccountPubkey: Scalars["String"];
};

/** Parameters to generate a SOL delegate transaction. */
export type SolanaStakingDelegateTransactionCreateInput = {
  amountSol: Scalars["Float"];
  fundingAccountPubkey: Scalars["String"];
  network: SolanaNetworks;
  voteAccountPubkey: Scalars["String"];
};

export type SolanaStakingMaxDelegationEstimate = {
  __typename?: "SolanaStakingMaxDelegationEstimate";
  /** in lamports */
  accountBalance: Scalars["String"];
  /** in lamports */
  estimatedFee: Scalars["String"];
  /** in lamports */
  maxDelegation: Scalars["String"];
  /** in lamports */
  minBalanceForRentExemption: Scalars["String"];
};

export type SolanaStakingTransactionPayload = {
  __typename?: "SolanaStakingTransactionPayload";
  lastValidBlockHeight: Scalars["Int"];
  network: SolanaNetworks;
  signingPayload: Scalars["String"];
  stakeAccountPubkey: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
};

/** Save a transaction hash for a Solana staking activity */
export type SolanaStakingTxSaveInput = {
  activityType: StakingActivityActivityTypes;
  amountSol: Scalars["Float"];
  network: SolanaNetworks;
  stakeAccountPubkey: Scalars["String"];
  txHash: Scalars["String"];
};

/** Parameters to generate a SOL withdraw transaction. */
export type SolanaStakingWithdrawTransactionCreateInput = {
  amountSol?: InputMaybe<Scalars["Float"]>;
  network: SolanaNetworks;
  recipientPubkey: Scalars["String"];
  stakeAccountPubkey: Scalars["String"];
};

/** Column ordering options */
export enum SortDirection {
  /** in the ascending order */
  Asc = "asc",
  /** in the descending order */
  Desc = "desc",
}

/** A tracked Solana stake account. */
export type StakeAccount = {
  __typename?: "StakeAccount";
  /** Total balance that is actively delegated to the validator in SOL */
  activeBalance?: Maybe<Scalars["String"]>;
  /** Total balance that is actively delegated to the validator in USD */
  activeBalanceUsd?: Maybe<Scalars["String"]>;
  /** The account address (e.g. BF2z...) */
  address: Scalars["String"];
  /** Total stake account balance in SOL (active, inactive, and any additional) */
  balance?: Maybe<Scalars["String"]>;
  /** Total stake account balance in USD (active, inactive, and any additional) */
  balanceUsd?: Maybe<Scalars["String"]>;
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** Total balance that is not delegated to the validator (activating / deactivating) in SOL */
  inactiveBalance?: Maybe<Scalars["String"]>;
  /** Total balance that is not delegated to the validator in USD */
  inactiveBalanceUsd?: Maybe<Scalars["String"]>;
  /** Whether the account has active delegation (manually managed) */
  isActive: Scalars["Boolean"];
  /** A free-form note about the account */
  label?: Maybe<Scalars["String"]>;
  /** The protocol network name (e.g. mainnet) */
  network: Scalars["String"];
  /** Stake authority for the stake account */
  stakeAuthorityAddress?: Maybe<Scalars["String"]>;
  /** The current status of the stake account */
  status?: Maybe<StakeAccountStatus>;
  /** Total rewards earned for the stake account */
  totalRewards?: Maybe<Scalars["String"]>;
  /** Total rewards for the stake account in USD */
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  /** The user that created the stake account (only applies when staking through Fig app) */
  user?: Maybe<User>;
  /** Withdraw authority for the stake account */
  withdrawAuthorityAddress?: Maybe<Scalars["String"]>;
};

/** The connection type for StakeAccount. */
export type StakeAccountConnection = {
  __typename?: "StakeAccountConnection";
  /** A list of edges. */
  edges: Array<StakeAccountEdge>;
  /** A list of nodes. */
  nodes: Array<StakeAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakeAccountEdge = {
  __typename?: "StakeAccountEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakeAccount;
};

export enum StakeAccountStatus {
  Activating = "activating",
  Active = "active",
  Deactivating = "deactivating",
  Inactive = "inactive",
  Withdrawn = "withdrawn",
}

/** Autogenerated return type of StakeBroadcastedMutation. */
export type StakeBroadcastedMutationPayload = {
  __typename?: "StakeBroadcastedMutationPayload";
  data?: Maybe<BabylonStake>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of StakeTransactionCreate. */
export type StakeTransactionCreatePayload = {
  __typename?: "StakeTransactionCreatePayload";
  data?: Maybe<BabylonTx>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type StakingActivity = EthereumExitRequest | EthereumStakingDeposit;

export enum StakingActivityActivityTypes {
  Delegation = "delegation",
  Stake = "stake",
  Unbond = "unbond",
  Undelegation = "undelegation",
  Withdrawal = "withdrawal",
}

/** The connection type for StakingActivity. */
export type StakingActivityConnection = {
  __typename?: "StakingActivityConnection";
  /** A list of edges. */
  edges: Array<StakingActivityEdge>;
  /** A list of nodes. */
  nodes: Array<StakingActivity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakingActivityEdge = {
  __typename?: "StakingActivityEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakingActivity;
};

export type StakingActivityFlow = {
  __typename?: "StakingActivityFlow";
  aggregatedStakingContractAddress?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["Float"]>;
  createdAt: Scalars["ISO8601DateTime"];
  estimatedActiveAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedExitAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedPrincipalReturnAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedWithdrawalAt?: Maybe<Scalars["ISO8601DateTime"]>;
  feeRecipientAddress?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  network?: Maybe<Networks>;
  operation?: Maybe<Operations>;
  region?: Maybe<Regions>;
  state?: Maybe<States>;
  updatedAt: Scalars["ISO8601DateTime"];
  withdrawalAddress?: Maybe<Scalars["String"]>;
};

/** The connection type for StakingActivityFlow. */
export type StakingActivityFlowConnection = {
  __typename?: "StakingActivityFlowConnection";
  /** A list of edges. */
  edges: Array<StakingActivityFlowEdge>;
  /** A list of nodes. */
  nodes: Array<StakingActivityFlow>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakingActivityFlowEdge = {
  __typename?: "StakingActivityFlowEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakingActivityFlow;
};

export type StakingActivityLog = {
  __typename?: "StakingActivityLog";
  activityAt: Scalars["ISO8601DateTime"];
  activityType: StakingActivityActivityTypes;
  amount: Scalars["String"];
  /** The currency for the amount (e.g. SOL or ETH) */
  amountCurrency: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  estimatedCompletedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  network: Scalars["String"];
  protocol: StakingActivityProtocols;
  source?: Maybe<Scalars["String"]>;
  status: StakingActivityStatuses;
  txHash?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  user?: Maybe<User>;
};

/** The connection type for StakingActivityLog. */
export type StakingActivityLogConnection = {
  __typename?: "StakingActivityLogConnection";
  /** A list of edges. */
  edges: Array<StakingActivityLogEdge>;
  /** A list of nodes. */
  nodes: Array<StakingActivityLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakingActivityLogEdge = {
  __typename?: "StakingActivityLogEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakingActivityLog;
};

export enum StakingActivityProtocols {
  Babylon = "babylon",
  Solana = "solana",
}

export enum StakingActivityStatuses {
  Complete = "complete",
  Failed = "failed",
  Pending = "pending",
}

export type StakingTimeEstimate = {
  __typename?: "StakingTimeEstimate";
  /** The date/time of the estimate. */
  date?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The number of seconds until the date occurs. */
  numSeconds?: Maybe<Scalars["Int"]>;
};

export enum Statement {
  /** Revenue Share Report */
  RevenueShare = "revenue_share",
  /** Staking Rewards Report */
  StakingRewards = "staking_rewards",
  /** White Label Validator Report */
  WhiteLabelValidator = "white_label_validator",
}

export enum States {
  Activating = "activating",
  Active = "active",
  AggregatedDepositTxSignature = "aggregated_deposit_tx_signature",
  AwaitingOcbDeployment = "awaiting_ocb_deployment",
  AwaitingProvision = "awaiting_provision",
  Broadcasting = "broadcasting",
  Deposited = "deposited",
  Exiting = "exiting",
  Failed = "failed",
  Initialized = "initialized",
  Unstaked = "unstaked",
  Withdrawing = "withdrawing",
}

/** timestamps of when validator transitioned into a given status */
export type StatusHistory = {
  __typename?: "StatusHistory";
  activeExitingAt?: Maybe<Scalars["ISO8601DateTime"]>;
  activeOngoingAt?: Maybe<Scalars["ISO8601DateTime"]>;
  activeSlashedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  depositedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  depositedNotFinalizedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  exitedSlashedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  exitedUnslashedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  fundingRequestedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  pendingInitializedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  pendingQueuedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  provisionedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  withdrawalDoneAt?: Maybe<Scalars["ISO8601DateTime"]>;
  withdrawalPossibleAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

export enum TimePeriod {
  /** One month */
  OneMonth = "one_month",
  /** Year */
  OneYear = "one_year",
  /** Six months */
  SixMonths = "six_months",
}

/** Input attributes for tracking an account */
export type TrackedAccountAddInput = {
  address: Scalars["String"];
  network: Scalars["String"];
};

export type TrackingTransaction = {
  __typename?: "TrackingTransaction";
  actionId: Scalars["String"];
  actionType: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  error?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  network: Scalars["String"];
  organizationId: Scalars["String"];
  protocol: Scalars["String"];
  signedPayload?: Maybe<Scalars["String"]>;
  signingPayload?: Maybe<Scalars["String"]>;
  status?: Maybe<TransactionStatuses>;
  transactionableId: Scalars["String"];
  transactionableType: Scalars["String"];
  txHash: Scalars["String"];
  unsignedRawTx?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for TrackingTransaction. */
export type TrackingTransactionConnection = {
  __typename?: "TrackingTransactionConnection";
  /** A list of edges. */
  edges: Array<TrackingTransactionEdge>;
  /** A list of nodes. */
  nodes: Array<TrackingTransaction>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type TrackingTransactionEdge = {
  __typename?: "TrackingTransactionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: TrackingTransaction;
};

export enum TransactionStatuses {
  Confirmed = "confirmed",
  Expired = "expired",
  Failed = "failed",
  InProgress = "in_progress",
  NotFound = "not_found",
}

/** Autogenerated return type of TxSaveMutation. */
export type TxSaveMutationPayload = {
  __typename?: "TxSaveMutationPayload";
  data?: Maybe<StakingActivityLog>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of UnbondTransactionCreate. */
export type UnbondTransactionCreatePayload = {
  __typename?: "UnbondTransactionCreatePayload";
  data?: Maybe<BabylonTx>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type User = {
  __typename?: "User";
  acceptedTosAt?: Maybe<Scalars["ISO8601DateTime"]>;
  auth0Id: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  email: Scalars["String"];
  id: Scalars["ID"];
  mfaMethods?: Maybe<Array<Scalars["String"]>>;
  name: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  riskAssessmentPassed?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

export type UserMutationPayload = {
  __typename?: "UserMutationPayload";
  /** User object */
  data?: Maybe<User>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** Attributes for updating an user */
export type UserUpdateInput = {
  acceptedTosAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

/** An Ethereum validator tracked for rewards and balances. */
export type Validator = {
  __typename?: "Validator";
  /** The validator pubkey (prefixed with 0x) */
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** A free-form note about the account */
  label?: Maybe<Scalars["String"]>;
  /** The Ethereum network name (e.g. mainnet, holesky, goerli) */
  network: Scalars["String"];
  /** On demand exit validator data */
  onDemandExit?: Maybe<OnDemandExit>;
  /** The region a Figment Validator is hosted in */
  region?: Maybe<Scalars["String"]>;
  /** Staked balance for the validator */
  stakedBalance?: Maybe<Scalars["String"]>;
  /** Staked balance in USD for the validator */
  stakedBalanceUsd?: Maybe<Scalars["String"]>;
  /** status of validator */
  status?: Maybe<ValidatorStatus>;
  /** timestamps of status transitions */
  statusHistory: StatusHistory;
  /** Total gross (earned) rewards this validator has received */
  totalRewards?: Maybe<Scalars["String"]>;
  /** Sum of all rewards received */
  totalRewardsReceived?: Maybe<Scalars["String"]>;
  /** Sum of all rewards received in USD */
  totalRewardsReceivedUsd?: Maybe<Scalars["String"]>;
  /** Total gross (earned) rewards in USD this validator has received */
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  /** Withdrawal account for the validator */
  withdrawalAccount?: Maybe<Account>;
};

/** The connection type for Validator. */
export type ValidatorConnection = {
  __typename?: "ValidatorConnection";
  /** A list of edges. */
  edges: Array<ValidatorEdge>;
  /** A list of nodes. */
  nodes: Array<Validator>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ValidatorCounts = {
  __typename?: "ValidatorCounts";
  /** Totals by validator status */
  byStatus?: Maybe<ValidatorCountsByStatus>;
  /** Active validators which have already been requested to exit */
  exitPending?: Maybe<Scalars["Int"]>;
  /** Active validators which have not yet been requested to exit */
  exitable?: Maybe<Scalars["Int"]>;
  /** The total number of validators across all statuses */
  total?: Maybe<Scalars["Int"]>;
  /** Total number of validators verified in Eigenlayer. */
  verified?: Maybe<Scalars["Int"]>;
};

export type ValidatorCountsByStatus = {
  __typename?: "ValidatorCountsByStatus";
  activeExiting?: Maybe<Scalars["Int"]>;
  activeOngoing?: Maybe<Scalars["Int"]>;
  activeSlashed?: Maybe<Scalars["Int"]>;
  deposited?: Maybe<Scalars["Int"]>;
  depositedNotFinalized?: Maybe<Scalars["Int"]>;
  exitedSlashed?: Maybe<Scalars["Int"]>;
  exitedUnslashed?: Maybe<Scalars["Int"]>;
  fundingRequested?: Maybe<Scalars["Int"]>;
  pendingInitialized?: Maybe<Scalars["Int"]>;
  pendingQueued?: Maybe<Scalars["Int"]>;
  provisioned?: Maybe<Scalars["Int"]>;
  withdrawalDone?: Maybe<Scalars["Int"]>;
  withdrawalPossible?: Maybe<Scalars["Int"]>;
};

/** An edge in a connection. */
export type ValidatorEdge = {
  __typename?: "ValidatorEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Validator;
};

export enum ValidatorOrderByFields {
  /** Date the validator was activated */
  ActivatedAt = "activatedAt",
  /** The validator pubkey */
  Address = "address",
  /** Date the validator was added */
  CreatedAt = "createdAt",
  /** The network of the protocol (e.g. mainnet) */
  Network = "network",
  /** The status of the validator */
  Status = "status",
}

export enum ValidatorStatus {
  Active = "active",
  ActiveExiting = "active_exiting",
  ActiveOngoing = "active_ongoing",
  ActiveSlashed = "active_slashed",
  Deposited = "deposited",
  DepositedNotFinalized = "deposited_not_finalized",
  Exited = "exited",
  ExitedSlashed = "exited_slashed",
  ExitedUnslashed = "exited_unslashed",
  FundingRequested = "funding_requested",
  Pending = "pending",
  PendingInitialized = "pending_initialized",
  PendingQueued = "pending_queued",
  Provisioned = "provisioned",
  Unfunded = "unfunded",
  Unknown = "unknown",
  WithdrawalDone = "withdrawal_done",
  WithdrawalPossible = "withdrawal_possible",
}

export type Validators = {
  field: ValidatorOrderByFields;
  sortDirection: SortDirection;
};

/** Attributes for filtering tracked validators. */
export type ValidatorsFilter = {
  address_cont?: InputMaybe<Scalars["String"]>;
  address_eq?: InputMaybe<Scalars["String"]>;
  address_i_cont?: InputMaybe<Scalars["String"]>;
  network_eq?: InputMaybe<Scalars["String"]>;
  organization_id_eq?: InputMaybe<Scalars["String"]>;
  source_eq?: InputMaybe<Scalars["String"]>;
  source_id_eq?: InputMaybe<Scalars["String"]>;
  status_in?: InputMaybe<Array<ValidatorStatus>>;
  status_not_in?: InputMaybe<Array<ValidatorStatus>>;
  withdrawal_account_address_eq?: InputMaybe<Scalars["String"]>;
  withdrawal_account_address_i_eq?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of VerifyWithdrawalCredentialsTransactionCreate. */
export type VerifyWithdrawalCredentialsTransactionCreatePayload = {
  __typename?: "VerifyWithdrawalCredentialsTransactionCreatePayload";
  data?: Maybe<EthereumTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of WithdrawTransactionCreateMutation. */
export type WithdrawTransactionCreateMutationPayload = {
  __typename?: "WithdrawTransactionCreateMutationPayload";
  data?: Maybe<SolanaStakingTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of WithdrawalTransactionCreate. */
export type WithdrawalTransactionCreatePayload = {
  __typename?: "WithdrawalTransactionCreatePayload";
  data?: Maybe<BabylonTx>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type StakeEthereumMutationVariables = Exact<{
  network: Networks;
  amountEth: Scalars["Int"];
  withdrawalAddress: Scalars["String"];
  fundingAddress?: InputMaybe<Scalars["String"]>;
  feeRecipientAddress?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Regions>;
}>;

export type StakeEthereumMutation = {
  __typename?: "Mutation";
  ethereumStakingRequestCreate: {
    __typename?: "EthereumStakingRequestMutationPayload";
    data?: {
      __typename?: "EthereumStakingRequest";
      id: string;
      amountEth: string;
      feeRecipientAddress?: string | null;
      maxGasWei: string;
      network: Networks;
      region: Regions;
      unsignedTransactionHashed: string;
      unsignedTransactionSerialized: string;
      withdrawalAddress: string;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      extras?: any | null;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
    }> | null;
  };
};

export type UnstakeEthereumMutationVariables = Exact<{
  network: Networks;
  amountEth: Scalars["Int"];
  withdrawalAddress: Scalars["String"];
  region?: InputMaybe<Regions>;
}>;

export type UnstakeEthereumMutation = {
  __typename?: "Mutation";
  ethereumExitRequestCreate: {
    __typename?: "EthereumExitRequestMutationPayload";
    data?: {
      __typename?: "EthereumExitRequest";
      amountEth: string;
      network: Networks;
      pubkeys: Array<string>;
      region?: Regions | null;
      withdrawalAddress: string;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      message: string;
      field?: string | null;
      code: string;
    }> | null;
  };
};

export type RewardsQueryVariables = Exact<{
  network?: InputMaybe<Scalars["String"]>;
  ethereumBalanceNetwork: EthereumNetworks;
}>;

export type RewardsQuery = {
  __typename?: "Query";
  rewardsSummary: {
    __typename?: "RewardsSummary";
    stakingRewards?: string | null;
    totalRewards?: string | null;
    totalRewardsUsd?: string | null;
  };
  ethereumBalanceSummary: {
    __typename?: "EthereumBalanceSummary";
    balance?: string | null;
    balanceUsd?: string | null;
  };
};

export type AggregatedRewardsQueryVariables = Exact<{
  network?: InputMaybe<Scalars["String"]>;
  timePeriod?: InputMaybe<TimePeriod>;
}>;

export type AggregatedRewardsQuery = {
  __typename?: "Query";
  aggregatedRewards: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      total?: string | null;
      timestamp?: any | null;
    }> | null;
  };
  ethereumPrice: { __typename?: "Price"; value?: number | null };
};

export type EigenPodCreateMutationVariables = Exact<{
  network: EthereumNetworks;
  ownerAddress: Scalars["String"];
}>;

export type EigenPodCreateMutation = {
  __typename?: "Mutation";
  eigenpodCreate: {
    __typename?: "EigenpodMutationPayload";
    data?: {
      __typename?: "Eigenpod";
      address: string;
      deployed: boolean;
      unsignedRawCreatepodTx?: string | null;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      message: string;
    }> | null;
  };
};

export type StakeEthereumSetTxHashMutationVariables = Exact<{
  id: Scalars["String"];
  txHash: Scalars["String"];
  amountEth: Scalars["Int"];
  fundingAddress: Scalars["String"];
}>;

export type StakeEthereumSetTxHashMutation = {
  __typename?: "Mutation";
  ethereumStakingRequestSetTxHash: {
    __typename?: "EthereumStakingRequestMutationPayload";
    data?: { __typename?: "EthereumStakingRequest"; id: string } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
    }> | null;
  };
};

export type StakingActivityQueryVariables = Exact<{
  network: Networks;
}>;

export type StakingActivityQuery = {
  __typename?: "Query";
  stakingActivityFlows: {
    __typename?: "StakingActivityFlowConnection";
    nodes: Array<{
      __typename?: "StakingActivityFlow";
      network?: Networks | null;
      operation?: Operations | null;
      amount?: number | null;
      state?: States | null;
      createdAt: any;
      estimatedActiveAt?: any | null;
      estimatedWithdrawalAt?: any | null;
      estimatedPrincipalReturnAt?: any | null;
    }>;
  };
};

export type StakingActivityV2QueryVariables = Exact<{
  network?: InputMaybe<Networks>;
}>;

export type StakingActivityV2Query = {
  __typename?: "Query";
  stakingActivity: {
    __typename?: "StakingActivityConnection";
    nodes: Array<
      | {
          __typename: "EthereumExitRequest";
          network: Networks;
          amountEth: string;
          createdAt: any;
          activityAt: any;
          estimatedWithdrawableAt?: any | null;
          estimatedPrincipalReturnAt?: any | null;
          exitStatus: ExitRequestStatuses;
        }
      | {
          __typename: "EthereumStakingDeposit";
          network: Networks;
          amountEth: string;
          createdAt: any;
          activityAt: any;
          estimatedActiveAt?: any | null;
          depositStatus: DepositStatuses;
        }
    >;
  };
};

type StakingActivityV2ConnectionNode_EthereumExitRequest_Fragment = {
  __typename: "EthereumExitRequest";
  network: Networks;
  amountEth: string;
  createdAt: any;
  activityAt: any;
  estimatedWithdrawableAt?: any | null;
  estimatedPrincipalReturnAt?: any | null;
  exitStatus: ExitRequestStatuses;
};

type StakingActivityV2ConnectionNode_EthereumStakingDeposit_Fragment = {
  __typename: "EthereumStakingDeposit";
  network: Networks;
  amountEth: string;
  createdAt: any;
  activityAt: any;
  estimatedActiveAt?: any | null;
  depositStatus: DepositStatuses;
};

export type StakingActivityV2ConnectionNodeFragment =
  | StakingActivityV2ConnectionNode_EthereumExitRequest_Fragment
  | StakingActivityV2ConnectionNode_EthereumStakingDeposit_Fragment;

export type NetworkOverviewQueryVariables = Exact<{ [key: string]: never }>;

export type NetworkOverviewQuery = {
  __typename?: "Query";
  ethereumNetworkEstimates: {
    __typename?: "NetworkEstimates";
    estimatedActivation?: {
      __typename?: "NetworkEstimate";
      hours?: number | null;
    } | null;
    estimatedWithdrawal?: {
      __typename?: "NetworkEstimate";
      hoursMin?: number | null;
      hoursMax?: number | null;
    } | null;
  };
  ethereumRewardsRate: { __typename?: "RewardsRate"; value?: number | null };
  ethereumPrice: { __typename?: "Price"; value?: number | null };
};

export type ExitableValidatorCountQueryVariables = Exact<{
  ethAccount: Scalars["String"];
  network: Scalars["String"];
}>;

export type ExitableValidatorCountQuery = {
  __typename?: "Query";
  validatorCounts: { __typename?: "ValidatorCounts"; exitable?: number | null };
};

export const StakingActivityV2ConnectionNodeFragmentDoc = `
    fragment StakingActivityV2ConnectionNode on StakingActivity {
  __typename
  ... on EthereumStakingDeposit {
    network
    amountEth
    createdAt
    activityAt
    depositStatus: status
    estimatedActiveAt
  }
  ... on EthereumExitRequest {
    network
    amountEth
    createdAt
    activityAt
    exitStatus: status
    estimatedWithdrawableAt
    estimatedPrincipalReturnAt
  }
}
    `;
export const StakeEthereumDocument = `
    mutation StakeEthereum($network: Networks!, $amountEth: Int!, $withdrawalAddress: String!, $fundingAddress: String, $feeRecipientAddress: String, $region: Regions) {
  ethereumStakingRequestCreate(
    input: {network: $network, amountEth: $amountEth, withdrawalAddress: $withdrawalAddress, fundingAddress: $fundingAddress, feeRecipientAddress: $feeRecipientAddress, region: $region}
  ) {
    data {
      id
      amountEth
      feeRecipientAddress
      maxGasWei
      network
      region
      unsignedTransactionHashed
      unsignedTransactionSerialized
      withdrawalAddress
    }
    userErrors {
      code
      extras
      field
      message
      path
    }
  }
}
    `;
export const useStakeEthereumMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    StakeEthereumMutation,
    TError,
    StakeEthereumMutationVariables,
    TContext
  >
) =>
  useMutation<
    StakeEthereumMutation,
    TError,
    StakeEthereumMutationVariables,
    TContext
  >(
    ["StakeEthereum"],
    (variables?: StakeEthereumMutationVariables) =>
      partnersGQLFetcher<StakeEthereumMutation, StakeEthereumMutationVariables>(
        StakeEthereumDocument,
        variables
      )(),
    options
  );
useStakeEthereumMutation.getKey = () => ["StakeEthereum"];

export const UnstakeEthereumDocument = `
    mutation UnstakeEthereum($network: Networks!, $amountEth: Int!, $withdrawalAddress: String!, $region: Regions) {
  ethereumExitRequestCreate(
    input: {network: $network, amountEth: $amountEth, withdrawalAddress: $withdrawalAddress, region: $region}
  ) {
    data {
      amountEth
      network
      pubkeys
      region
      withdrawalAddress
    }
    userErrors {
      message
      field
      code
    }
  }
}
    `;
export const useUnstakeEthereumMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UnstakeEthereumMutation,
    TError,
    UnstakeEthereumMutationVariables,
    TContext
  >
) =>
  useMutation<
    UnstakeEthereumMutation,
    TError,
    UnstakeEthereumMutationVariables,
    TContext
  >(
    ["UnstakeEthereum"],
    (variables?: UnstakeEthereumMutationVariables) =>
      partnersGQLFetcher<
        UnstakeEthereumMutation,
        UnstakeEthereumMutationVariables
      >(UnstakeEthereumDocument, variables)(),
    options
  );
useUnstakeEthereumMutation.getKey = () => ["UnstakeEthereum"];

export const RewardsDocument = `
    query Rewards($network: String, $ethereumBalanceNetwork: EthereumNetworks!) {
  rewardsSummary(network: $network) {
    stakingRewards
    totalRewards
    totalRewardsUsd
  }
  ethereumBalanceSummary(network: $ethereumBalanceNetwork) {
    balance
    balanceUsd
  }
}
    `;
export const useRewardsQuery = <TData = RewardsQuery, TError = unknown>(
  variables: RewardsQueryVariables,
  options?: UseQueryOptions<RewardsQuery, TError, TData>
) =>
  useQuery<RewardsQuery, TError, TData>(
    ["Rewards", variables],
    partnersGQLFetcher<RewardsQuery, RewardsQueryVariables>(
      RewardsDocument,
      variables
    ),
    options
  );

useRewardsQuery.getKey = (variables: RewardsQueryVariables) => [
  "Rewards",
  variables,
];
export const AggregatedRewardsDocument = `
    query AggregatedRewards($network: String, $timePeriod: TimePeriod) {
  aggregatedRewards(network: $network, timePeriod: $timePeriod) {
    rewards {
      total
      timestamp
    }
  }
  ethereumPrice {
    value
  }
}
    `;
export const useAggregatedRewardsQuery = <
  TData = AggregatedRewardsQuery,
  TError = unknown
>(
  variables?: AggregatedRewardsQueryVariables,
  options?: UseQueryOptions<AggregatedRewardsQuery, TError, TData>
) =>
  useQuery<AggregatedRewardsQuery, TError, TData>(
    variables === undefined
      ? ["AggregatedRewards"]
      : ["AggregatedRewards", variables],
    partnersGQLFetcher<AggregatedRewardsQuery, AggregatedRewardsQueryVariables>(
      AggregatedRewardsDocument,
      variables
    ),
    options
  );

useAggregatedRewardsQuery.getKey = (
  variables?: AggregatedRewardsQueryVariables
) =>
  variables === undefined
    ? ["AggregatedRewards"]
    : ["AggregatedRewards", variables];
export const EigenPodCreateDocument = `
    mutation EigenPodCreate($network: EthereumNetworks!, $ownerAddress: String!) {
  eigenpodCreate(input: {network: $network, ownerAddress: $ownerAddress}) {
    data {
      address
      deployed
      unsignedRawCreatepodTx
    }
    userErrors {
      code
      message
    }
  }
}
    `;
export const useEigenPodCreateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    EigenPodCreateMutation,
    TError,
    EigenPodCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    EigenPodCreateMutation,
    TError,
    EigenPodCreateMutationVariables,
    TContext
  >(
    ["EigenPodCreate"],
    (variables?: EigenPodCreateMutationVariables) =>
      partnersGQLFetcher<
        EigenPodCreateMutation,
        EigenPodCreateMutationVariables
      >(EigenPodCreateDocument, variables)(),
    options
  );
useEigenPodCreateMutation.getKey = () => ["EigenPodCreate"];

export const StakeEthereumSetTxHashDocument = `
    mutation StakeEthereumSetTxHash($id: String!, $txHash: String!, $amountEth: Int!, $fundingAddress: String!) {
  ethereumStakingRequestSetTxHash(
    input: {id: $id, txHash: $txHash, amountEth: $amountEth, fundingAddress: $fundingAddress}
  ) {
    data {
      id
    }
    userErrors {
      code
      field
      message
      path
    }
  }
}
    `;
export const useStakeEthereumSetTxHashMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    StakeEthereumSetTxHashMutation,
    TError,
    StakeEthereumSetTxHashMutationVariables,
    TContext
  >
) =>
  useMutation<
    StakeEthereumSetTxHashMutation,
    TError,
    StakeEthereumSetTxHashMutationVariables,
    TContext
  >(
    ["StakeEthereumSetTxHash"],
    (variables?: StakeEthereumSetTxHashMutationVariables) =>
      partnersGQLFetcher<
        StakeEthereumSetTxHashMutation,
        StakeEthereumSetTxHashMutationVariables
      >(StakeEthereumSetTxHashDocument, variables)(),
    options
  );
useStakeEthereumSetTxHashMutation.getKey = () => ["StakeEthereumSetTxHash"];

export const StakingActivityDocument = `
    query StakingActivity($network: Networks!) {
  stakingActivityFlows(
    network: $network
    operation: [aggregated_staking, aggregated_unstaking]
    states: [deposited, activating, active, broadcasting, exiting, withdrawing, unstaked, failed]
  ) {
    nodes {
      network
      operation
      amount
      state
      createdAt
      estimatedActiveAt
      estimatedWithdrawalAt
      estimatedPrincipalReturnAt
    }
  }
}
    `;
export const useStakingActivityQuery = <
  TData = StakingActivityQuery,
  TError = unknown
>(
  variables: StakingActivityQueryVariables,
  options?: UseQueryOptions<StakingActivityQuery, TError, TData>
) =>
  useQuery<StakingActivityQuery, TError, TData>(
    ["StakingActivity", variables],
    partnersGQLFetcher<StakingActivityQuery, StakingActivityQueryVariables>(
      StakingActivityDocument,
      variables
    ),
    options
  );

useStakingActivityQuery.getKey = (variables: StakingActivityQueryVariables) => [
  "StakingActivity",
  variables,
];
export const StakingActivityV2Document = `
    query StakingActivityV2($network: Networks) {
  stakingActivity(network: $network, minDepositStatus: unfinalized) {
    nodes {
      ...StakingActivityV2ConnectionNode
    }
  }
}
    ${StakingActivityV2ConnectionNodeFragmentDoc}`;
export const useStakingActivityV2Query = <
  TData = StakingActivityV2Query,
  TError = unknown
>(
  variables?: StakingActivityV2QueryVariables,
  options?: UseQueryOptions<StakingActivityV2Query, TError, TData>
) =>
  useQuery<StakingActivityV2Query, TError, TData>(
    variables === undefined
      ? ["StakingActivityV2"]
      : ["StakingActivityV2", variables],
    partnersGQLFetcher<StakingActivityV2Query, StakingActivityV2QueryVariables>(
      StakingActivityV2Document,
      variables
    ),
    options
  );

useStakingActivityV2Query.getKey = (
  variables?: StakingActivityV2QueryVariables
) =>
  variables === undefined
    ? ["StakingActivityV2"]
    : ["StakingActivityV2", variables];
export const NetworkOverviewDocument = `
    query NetworkOverview {
  ethereumNetworkEstimates {
    estimatedActivation {
      hours
    }
    estimatedWithdrawal {
      hoursMin
      hoursMax
    }
  }
  ethereumRewardsRate {
    value
  }
  ethereumPrice {
    value
  }
}
    `;
export const useNetworkOverviewQuery = <
  TData = NetworkOverviewQuery,
  TError = unknown
>(
  variables?: NetworkOverviewQueryVariables,
  options?: UseQueryOptions<NetworkOverviewQuery, TError, TData>
) =>
  useQuery<NetworkOverviewQuery, TError, TData>(
    variables === undefined
      ? ["NetworkOverview"]
      : ["NetworkOverview", variables],
    partnersGQLFetcher<NetworkOverviewQuery, NetworkOverviewQueryVariables>(
      NetworkOverviewDocument,
      variables
    ),
    options
  );

useNetworkOverviewQuery.getKey = (variables?: NetworkOverviewQueryVariables) =>
  variables === undefined
    ? ["NetworkOverview"]
    : ["NetworkOverview", variables];
export const ExitableValidatorCountDocument = `
    query exitableValidatorCount($ethAccount: String!, $network: String!) {
  validatorCounts(ethAccount: $ethAccount, network: $network) {
    exitable
  }
}
    `;
export const useExitableValidatorCountQuery = <
  TData = ExitableValidatorCountQuery,
  TError = unknown
>(
  variables: ExitableValidatorCountQueryVariables,
  options?: UseQueryOptions<ExitableValidatorCountQuery, TError, TData>
) =>
  useQuery<ExitableValidatorCountQuery, TError, TData>(
    ["exitableValidatorCount", variables],
    partnersGQLFetcher<
      ExitableValidatorCountQuery,
      ExitableValidatorCountQueryVariables
    >(ExitableValidatorCountDocument, variables),
    options
  );

useExitableValidatorCountQuery.getKey = (
  variables: ExitableValidatorCountQueryVariables
) => ["exitableValidatorCount", variables];
