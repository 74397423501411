import { Network } from "@figmentjs/protocols";
import { Networks } from "../../../../graphql/partners/generated/gql";

export const getCoreAPINetwork = (network?: Network): Networks => {
  switch (network) {
    case Network.MAINNET:
      return Networks.Mainnet;
    case Network.GOERLI:
      return Networks.Goerli;
    case Network.HOLESKY:
      return Networks.Holesky;
    default:
      return Networks.Mainnet;
  }
};
