import React from "react";

import { BodyText, Button, Icon } from "@figmentjs/web-core/src";

type Props = {
  setSelectedTabId: React.Dispatch<React.SetStateAction<string>>;
};

export const NoStakingActivity: React.FC<Props> = ({ setSelectedTabId }) => {
  return (
    <div className="mt-12 sm:mt-20 flex justify-center">
      <div className="sm:w-1/2">
        <div className="flex flex-col gap-6 items-center">
          <Icon size="2xl" icon="DappNoActivity" />
          <div className="block sm:hidden">
            <BodyText size="lg" weight="semibold">
              You haven&apos;t staked yet.
            </BodyText>
          </div>
          <div className="hidden sm:block">
            <BodyText size="2xl" weight="semibold">
              You haven&apos;t staked yet.
            </BodyText>
          </div>
          <div className="block sm:hidden text-center">
            <BodyText size="base">
              Maintain control of your digital assets, earn rewards, and track
              performance via Figment&apos;s easy-to-use interface.
            </BodyText>
          </div>
          <div className="hidden sm:block text-center">
            <BodyText size="lg">
              Maintain control of your digital assets, earn rewards, and track
              performance via Figment&apos;s easy-to-use interface.
            </BodyText>
          </div>
          <div className="w-4/5 flex justify-center">
            <Button
              fullWidth
              onClick={() => {
                setSelectedTabId("stake");
              }}
            >
              Start Staking
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
