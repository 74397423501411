import React, { memo } from "react";
import { InputAdornment } from "../../forms/shared/components";
import { Icon } from "../../graphics";
import ColumnFilter from "../../overlays/ColumnFilter";
import { BodyText } from "../../typography";
import { SortingIcon } from "./components";
import { TableHeaderCellProps } from "./TableHeaderCell.types";

/**
 * Renders a table header cell
 *
 * @param TableHeaderCellProps
 * @returns The TableHeader component
 */
const TableHeaderCell: React.FC<TableHeaderCellProps> = ({
  endAdornment,
  children,
  hasSorting,
  sortDirection,
  hasFiltering,
  onSorting,
  onFiltering,
  filterOptions,
}) => {
  return (
    <div className="w-full flex flex-row items-center py-2 text-left uppercase bg-white">
      <BodyText size="base" weight="semibold">
        {children}
      </BodyText>
      {endAdornment && (
        <div className="ml-1 mb-0.5">
          <InputAdornment placement="end" adornment={endAdornment} />
        </div>
      )}
      {hasSorting && (
        <button onClick={onSorting} aria-label="Sort button">
          <SortingIcon sortDirection={sortDirection} />
        </button>
      )}
      {hasFiltering && (
        <ColumnFilter options={filterOptions} onChange={onFiltering}>
          <button className="mt-1" aria-label="Filter button">
            <Icon icon="FilterIcon" />
          </button>
        </ColumnFilter>
      )}
    </div>
  );
};

export default memo(TableHeaderCell);
