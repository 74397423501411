import React from "react";
import { twMerge as tw } from "tailwind-merge";
import { FontColor, FontWeight } from "../../../theme/types";
import { Spinner } from "../../graphics";
import { ButtonPalette } from "../../buttons/Button/Button.types";
import { BodyText } from "../../typography";

type Props = {
  steps: Step[];
  animate?: boolean;
};

export enum Status {
  COMPLETE = "complete",
  ACTIVE = "active",
  INACTIVE = "inactive",
  ERRORED = "errored",
  LOADING = "loading",
}

type Step = {
  label: string;
  status: keyof typeof Status;
};

const stepIconStylesMap: { [key in Status]: string } = {
  [Status.COMPLETE]: "bg-green-800 w-2 h-2",
  [Status.ACTIVE]: "bg-green-100 border-2 border-green-800 w-5 h-5",
  [Status.INACTIVE]: "bg-green-100 w-4 h-4",
  [Status.ERRORED]: "bg-error w-2 h-2",
  [Status.LOADING]: "",
};

const labelFontColorMap: { [key in Status]: keyof typeof FontColor } = {
  [Status.COMPLETE]: "basic-800",
  [Status.ACTIVE]: "black",
  [Status.INACTIVE]: "basic-800",
  [Status.ERRORED]: "black",
  [Status.LOADING]: "black",
};

const labelWeightMap: { [key in Status]: keyof typeof FontWeight } = {
  [Status.COMPLETE]: "normal",
  [Status.ACTIVE]: "semibold",
  [Status.INACTIVE]: "normal",
  [Status.ERRORED]: "semibold",
  [Status.LOADING]: "semibold",
};

const Stepper: React.FC<Props> = ({ steps, animate = true }) => {
  return (
    <div className="flex items-start">
      {steps.map((step, index) => {
        return (
          <div className="flex-1 relative py-2" key={index}>
            {/* connector line */}
            {index < steps.length - 1 && (
              <>
                <div
                  className={tw(
                    "bg-green-300 absolute top-[17px] h-[1px] left-[calc(50%+15px)] right-[calc(-50%+15px)]"
                  )}
                />
                {/* "complete" status animation */}
                <div
                  style={{
                    width:
                      Status[step.status] === Status.COMPLETE
                        ? "calc(100% - 30px)"
                        : "0",
                  }}
                  className={tw(
                    "bg-green-800 absolute top-[17px] h-[1px] left-[calc(50%+15px)] right-[calc(-50%+15px)]",
                    animate && "transition-all duration-[1000ms] ease-out"
                  )}
                />
              </>
            )}

            {/* icon */}
            <div className="flex flex-col items-center">
              <div className="flex items-center justify-center w-5 h-5">
                {Status[step.status] == Status.LOADING ? (
                  <Spinner palette={ButtonPalette.secondary} size={20} />
                ) : (
                  <div
                    className={tw(
                      "flex items-center rounded-full justify-center",
                      animate && "transition-all duration-500",
                      stepIconStylesMap[Status[step.status]]
                    )}
                  />
                )}
              </div>

              {/* label */}
              <div className="w-full">
                <span className="block text-center">
                  <BodyText
                    weight={labelWeightMap[Status[step.status]]}
                    color={labelFontColorMap[Status[step.status]]}
                  >
                    {step.label}
                  </BodyText>
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(Stepper);
