import React from "react";
import { twMerge as tw } from "tailwind-merge";
import { BodyText, Icon, Heading, Button } from "@figmentjs/web-core";
import { StakingFlowData } from "../types";
import { UnstakingFlowData } from "../../../unstaking/components";

type Props = {
  onTryAgain: () => void;
  flowData: StakingFlowData | UnstakingFlowData;
};

export const ErrorStep: React.FC<Props> = ({ onTryAgain, flowData }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center py-8 sm:pt-36 sm:pb-[118px]">
        <Icon icon="MdCancel" size="7xl" color="error" />
        <div className="mt-8 space-y-6 text-center">
          <Heading level="h5">An Error Has Occurred</Heading>
          <div className="space-y-6">
            {flowData.errors?.map(({ code, message }) => {
              return (
                <div
                  key={code || message}
                  className={tw(
                    "text-base text-error leading-3 text-center",
                    code === "ETHERS" && "break-all"
                  )}
                >
                  {message}
                </div>
              );
            })}
          </div>
          <BodyText as="p">
            Please contact us at{" "}
            <a
              href="mailto:support@figment.io"
              className="text-green-800 underline hover:no-underline"
            >
              support@figment.io
            </a>
            . To practice wallet safety, disconnect from apps after using them
            in your wallet settings.
          </BodyText>
        </div>
      </div>
      <div className="flex justify-center w-full max-w-sm">
        <Button palette="tertiary" onClick={onTryAgain} fullWidth>
          Try Again
        </Button>
      </div>
    </div>
  );
};
