import { CommonInputTypes } from "../../forms/shared/types/Input.types";
import { IconProps } from "../../graphics/Icon/Icon.types";
import { FontFamily } from "../../../theme/types";

/**
 * A different cell type can be rendered by passing one of these types
 * in TableCellBase
 */
export enum CellType {
  BASE = "BASE",
  BOLD = "BOLD",
  SEMIBOLD = "SEMIBOLD",
  ICON = "ICON",
}

/**
 * Base cell props
 */
export type TableCellProps = {
  /**
   * An optional type defined in the enum CellType above
   */
  type?: keyof typeof CellType;
  /**
   * The value to render
   */
  value?: React.ReactNode;
  /**
   * An optional test id used for testing
   */
  testId?: string;
  /**
   * An optional prop to append className
   */
  className?: string;
  /**
   * An optional prop to choose font family.
   */
  font?: keyof typeof FontFamily;
};

/**
 * Props for the TableCellIcon cell type. Extends CommonInputTypes
 */
export type TableCellIconProps = TableCellProps &
  CommonInputTypes & {
    /**
     * A supported icon (defined in Icons.tsx)
     */
    icon?: IconProps["icon"];
  };
