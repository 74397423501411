import * as React from "react";

export interface Tab {
  /**
   * Each tab requires a unique ID. The ID is used to associate tabs
   * to the correct Tabs.Content component, using the `tabId` prop:
   *
   * ```
   * <Tabs.Content tabId="tabId"></Tabs.Content>
   * ```
   */
  id: string;
  /** Each tab requires a title */
  title: React.ReactNode;
  /** Set the tab as selected. By default, the first tab will be selected. */
  selected?: boolean;
  /** Set the tab as disabled */
  disabled?: boolean;
  /** Tooltip for the tab */
  tooltip?: string;
}

type TabId = Tab["id"];

export enum TabVariants {
  "primary" = "primary",
  "secondary" = "secondary",
  "tertiary" = "tertiary",
  "quaternary" = "quaternary",
}

export enum TabPlacements {
  "start" = "start",
  "center" = "center",
  "justify-evenly" = "justify-evenly",
  "end" = "end",
}

export enum TabSizes {
  "responsive" = "responsive",
  "xs" = "xs",
  "sm" = "sm",
  "compact_md" = "compact_md",
  "md" = "md",
  "lg" = "lg",
}

export interface TabsProps {
  children?: React.ReactNode;
  /** Tabs config: each tab object requires a title and ID */
  tabs: Tab[];
  /** Stylistic variant of the tabs */
  variant?: TabVariants;
  /** Positioning of the tabs */
  placement?: keyof typeof TabPlacements;
  /** Positioning of the tabs */
  showBorder?: boolean;
  /** Event handler called when the tab changes */
  onTabChange?: (id: TabId) => void;
  /** Tabs size */
  size?: keyof typeof TabSizes;
  /** Whether all tab buttons are disabled or not. */
  disabled?: boolean;
  /** Content to display before the tabs. */
  beforeTabs?: React.ReactNode;
  /** Content to display after the tabs. */
  afterTabs?: React.ReactNode;
}

export interface TabContentProps {
  /** The content to display for a specific tab */
  children: React.ReactNode;
  /** Unique ID prop to associate content to a specific tab */
  tabId: TabId;
}

/** The `<Tabs.Content>` component displays content for a specific tab */
export type ContentComponent = React.FC<TabContentProps>;

/** The `<Tabs>` component should wrap one/many `<Tabs.Content>` components  */
export type TabsComponent = React.FC<TabsProps> & {
  Content: ContentComponent;
};
