import { useCallback, useMemo, useState } from "react";

import { CursorActions } from "../../TablePagination/TablePagination.types";
import { PageInfo, Pagination } from "./use-cursor-pagination.types";

const DEFAULT_PAGE_SIZE = 10;

export const getCursorPagination = (
  pageSize: number = DEFAULT_PAGE_SIZE,
  action: keyof typeof CursorActions,
  pageInfo: PageInfo | undefined,
  totalCount: number
) => {
  switch (action) {
    case "FIRST":
      return {
        pageSize: pageSize,
        first: pageSize,
      };

    case "LAST":
      return {
        pageSize: pageSize,
        last: totalCount % pageSize || pageSize,
      };

    case "NEXT":
      return {
        pageSize: pageSize,
        first: pageSize,
        after: pageInfo?.endCursor,
      };

    case "PREVIOUS":
      return {
        pageSize: pageSize,
        last: pageSize,
        before: pageInfo?.startCursor,
      };
  }
};

export const useCursorPagination = (
  pageSize: number = DEFAULT_PAGE_SIZE,
  after?: string | null,
  before?: string | null
) => {
  const initialPagination = useMemo(
    () => ({
      pageSize: pageSize,
      first: pageSize,
      ...(after ? { after: after } : {}),
      ...(before ? { before: before } : {}),
    }),
    [after, before, pageSize]
  );

  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  const handlePagination = useCallback(
    (
      action: keyof typeof CursorActions,
      pageInfo: PageInfo | undefined,
      totalCount: number
    ) => {
      setPagination(
        getCursorPagination(pageSize, action, pageInfo, totalCount)
      );
    },
    [pageSize]
  );

  const resetPagination = useCallback(() => {
    setPagination(initialPagination);
  }, [initialPagination]);

  return { pagination, handlePagination, resetPagination };
};
