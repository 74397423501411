import { BigNumber } from "bignumber.js";
import { fromWei } from "@figmentjs/utils";

// For the time being we don't have a way to calculate accurate transaction fees.
// A more precise way to calculate them will be handled in the ticket below
// TODO: [FIGAPP-581] Revisit Estimated Transaction Fee Calculation
const ESTIMATED_GAS_PER_VALIDATOR = 42000;
const ESTIMATED_BASE_GAS = 30000;

export const getEstimatedEthAggregateStakingTxFee = (
  maxFeePerGas: string,
  amount: number
): string => {
  if (maxFeePerGas !== "0" && amount > 0) {
    const validators = amount / 32;
    return fromWei(
      new BigNumber(maxFeePerGas)
        .times(ESTIMATED_BASE_GAS + ESTIMATED_GAS_PER_VALIDATOR * validators)
        .toString()
    );
  }
  return "0";
};
