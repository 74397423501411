"use client";

import { QueryClient } from "@tanstack/react-query";

const apiAppQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export { apiAppQueryClient };
