"use client";

import React from "react";
import { Icon, BodyText } from "@figmentjs/web-core";

const NoRewardsData: React.FC = () => {
  return (
    <div className="flex flex-col mt-20 items-center text-center">
      <Icon icon="ChartNoData" />
      <div className="mt-6 sm:mt-10 mb-2">
        <div className="block sm:hidden">
          <BodyText size="lg" weight="semibold">
            Rewards data coming soon
          </BodyText>
        </div>
        <div className="hidden sm:block">
          <BodyText size="xl" weight="semibold">
            Rewards data coming soon
          </BodyText>
        </div>
      </div>
      <div className="block sm:hidden">
        <BodyText>
          Once you start earning rewards, you will see a comprehensive breakdown
          here.
        </BodyText>
      </div>
      <div className="hidden sm:block">
        <BodyText size="lg">
          Once you start earning rewards, you will see a comprehensive breakdown
          here.
        </BodyText>
      </div>
    </div>
  );
};

export default NoRewardsData;
