import React from "react";
import BigNumber from "bignumber.js";
import { Field as FormikField, FormikProps } from "formik";
import { InputCheckbox, BodyText } from "@figmentjs/web-core";
import { FormFields, FormConfig } from "../../amount-step";
import { TOKEN_STEP } from "../../../../../components";

type Props = {
  formik: FormikProps<FormConfig>;
  balance: BigNumber;
  fee: string;
};

export const TermsFormField: React.FC<Props> = ({ formik, balance, fee }) => {
  const disabled = balance.minus(new BigNumber(fee)).lt(TOKEN_STEP);

  return (
    <div className="mt-6 w-full">
      <FormikField name={FormFields.ACCEPTED_TERMS}>
        {() => (
          <div className="flex">
            <div className="w-10">
              <InputCheckbox
                id={FormFields.ACCEPTED_TERMS}
                name={FormFields.ACCEPTED_TERMS}
                value={formik.values.acceptedTerms || false}
                onBlur={() =>
                  formik.setFieldTouched(FormFields.ACCEPTED_TERMS, true)
                }
                onChange={({ value }) =>
                  formik.setFieldValue(FormFields.ACCEPTED_TERMS, value)
                }
                disabled={disabled}
              />
            </div>
            <div className="block sm:hidden">
              <BodyText size="sm">
                I agree to the Figment app{" "}
                <b>
                  <a
                    href="https://figment.io/figment-app-terms-of-use/"
                    className="text-green-800"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>
                </b>{" "}
                and Figment&apos;s{" "}
                <b>
                  <a
                    href="https://figment.io/privacy-policy/"
                    className="text-green-800"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </b>
                .
              </BodyText>
            </div>
            <div className="hidden sm:block">
              <BodyText>
                I agree to the Figment app{" "}
                <b>
                  <a
                    href="https://figment.io/figment-app-terms-of-use/"
                    className="text-green-800"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>
                </b>{" "}
                and Figment&apos;s{" "}
                <b>
                  <a
                    href="https://figment.io/privacy-policy/"
                    className="text-green-800"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </b>
                .
              </BodyText>
            </div>
          </div>
        )}
      </FormikField>
    </div>
  );
};
