import React from "react";

import BigNumber from "bignumber.js";
import { BodyText } from "@figmentjs/web-core";
import { currencyFormatter, tokenFormatter } from "@figmentjs/utils";
import { Network, Protocol, protocols } from "@figmentjs/protocols";
import Providers from "@figmentjs/web-core/src/components/providers";

import {
  EthereumNetworks,
  useRewardsQuery,
} from "../../../../graphql/partners/generated/gql";

export const BalanceAndRewardsCards = () => {
  const { network } = Providers.useEthereumWallet();
  const { data, isLoading } = useRewardsQuery({
    network,
    ethereumBalanceNetwork:
      network === Network.HOLESKY
        ? EthereumNetworks.Holesky
        : EthereumNetworks.Mainnet,
  });
  const { ticker } = protocols[Protocol.ETHEREUM];

  const stakedBalanceEth = tokenFormatter.format(
    new BigNumber(data?.ethereumBalanceSummary.balance || 0).toNumber()
  );
  const stakedBalanceUsd = currencyFormatter.format(
    data?.ethereumBalanceSummary.balanceUsd || 0
  );
  const allTimeRewardsEth = tokenFormatter.format(
    new BigNumber(data?.rewardsSummary.totalRewards || 0).toNumber()
  );
  const allTimeRewardsUsd = currencyFormatter.format(
    data?.rewardsSummary.totalRewardsUsd || 0
  );

  return (
    <div className="flex flex-col gap-4 mt-8 sm:flex-row sm:gap-10 sm:mt-10">
      <div className="py-4 px-4 sm:px-6 border border-basic-300 rounded-lg basis-1/2">
        <div className="block sm:hidden">
          <BodyText weight="semibold" size="sm">
            Staked Balance
          </BodyText>
        </div>
        <div className="hidden sm:block">
          <BodyText weight="semibold">Staked Balance</BodyText>
        </div>
        <div className="sm:mt-9">
          {isLoading ? (
            <div className="animate-pulse w-32 sm:w-64 h-[30.5px] sm:h-[34px] bg-basic-600 rounded" />
          ) : (
            <>
              <div className="block sm:hidden">
                <BodyText size="2xl" weight="semibold">
                  {stakedBalanceEth}
                </BodyText>
                <BodyText size="sm" weight="bold">
                  {" "}
                  {ticker}
                </BodyText>
              </div>
              <div className="hidden sm:block">
                <BodyText size="4xl" weight="bold">
                  {stakedBalanceEth}
                </BodyText>
                <BodyText size="lg" weight="bold">
                  {" "}
                  {ticker}
                </BodyText>
              </div>
            </>
          )}
        </div>
        <div className="sm:mt-2">
          {isLoading ? (
            <div className="h-[26.5px] sm:h-[26px]" />
          ) : (
            <>
              <div className="block sm:hidden">
                <BodyText size="sm">{stakedBalanceUsd}</BodyText>
              </div>
              <div className="hidden sm:block">
                <BodyText size="lg">{stakedBalanceUsd}</BodyText>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="py-4 px-4 sm:px-6 border border-basic-300 rounded-lg basis-1/2">
        <div className="block sm:hidden">
          <BodyText weight="semibold" size="sm">
            All-Time Rewards
          </BodyText>
        </div>
        <div className="hidden sm:block">
          <BodyText weight="semibold">All-Time Rewards</BodyText>
        </div>
        <div className="sm:mt-9">
          {isLoading ? (
            <div className="animate-pulse w-32 sm:w-64 h-[30.5px] sm:h-[34px] bg-basic-600 rounded w-" />
          ) : (
            <>
              <div className="block sm:hidden">
                <BodyText size="2xl" weight="semibold" color="green-800">
                  {allTimeRewardsEth}
                </BodyText>
                <BodyText size="sm" weight="bold" color="green-800">
                  {" "}
                  {ticker}
                </BodyText>
              </div>
              <div className="hidden sm:block">
                <BodyText size="4xl" weight="bold" color="green-800">
                  {allTimeRewardsEth}
                </BodyText>
                <BodyText size="lg" weight="bold" color="green-800">
                  {" "}
                  {ticker}
                </BodyText>
              </div>
            </>
          )}
        </div>
        <div className="sm:mt-2">
          {isLoading ? (
            <div className="h-[26.5px] sm:h-[26px]" />
          ) : (
            <>
              <div className="block sm:hidden">
                <BodyText size="sm">{allTimeRewardsUsd}</BodyText>
              </div>
              <div className="hidden sm:block">
                <BodyText size="lg">{allTimeRewardsUsd}</BodyText>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
