import React from "react";

import { BigNumber } from "bignumber.js";

import { BodyText } from "@figmentjs/web-core";
import { currencyFormatter, dateFormatter } from "@figmentjs/utils";
import { AggregatedRewardsTuple } from "../../../../graphql/partners/generated/gql";
import { Protocol, protocols } from "@figmentjs/protocols";

type Props = {
  tooltipData: AggregatedRewardsTuple;
  price?: number | null;
  dateLabelFormat: string;
};

const ChartTooltip: React.FC<Props> = ({
  tooltipData,
  price,
  dateLabelFormat,
}) => {
  const rewardsEth = new BigNumber(tooltipData.total || "0");
  const rewardsUsd = currencyFormatter.format(
    new BigNumber(rewardsEth).times(price || "0").toNumber()
  );

  return (
    <div className="divide-y divide-basic-800">
      <div className="py-2 px-4">
        <BodyText color="basic-600" size="sm">
          {dateFormatter({
            inputDate: tooltipData.timestamp,
            format: dateLabelFormat,
            timeZone: "UTC",
          })}
        </BodyText>
      </div>
      <div className="py-2 px-4">
        <BodyText color="white" size="sm" weight="bold" as="p">
          Rewards Earned
        </BodyText>
        <div className="mb-1">
          <div className="flex items-center flex-nowrap gap-2">
            <BodyText color="white" size="sm" weight="semibold">
              {new BigNumber(tooltipData.total || "0").toFormat(3)}{" "}
              {protocols[Protocol.ETHEREUM].ticker}
            </BodyText>
            <BodyText color="basic-600" size="sm">
              {rewardsUsd}
            </BodyText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartTooltip;
