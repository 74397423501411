import React from "react";
import { Field as FormikField, FormikProps } from "formik";
import { InputCheckbox, BodyText } from "@figmentjs/web-core";
import { FormFields, FormConfig } from "../../amount-step";
import { Protocol, protocols } from "@figmentjs/protocols";

type Props = {
  formik: FormikProps<FormConfig>;
};

export const InitiateUnstakingField: React.FC<Props> = ({ formik }) => {
  const { ticker } = protocols[Protocol.ETHEREUM];

  return (
    <div className="mt-5 w-full">
      <FormikField name={FormFields.INITIATE_UNSTAKING}>
        {() => (
          <div className="flex items-center">
            <div className="w-10">
              <InputCheckbox
                id={FormFields.INITIATE_UNSTAKING}
                name={FormFields.INITIATE_UNSTAKING}
                value={formik.values.initiateUnstaking || false}
                onBlur={() =>
                  formik.setFieldTouched(FormFields.INITIATE_UNSTAKING, true)
                }
                onChange={({ value }) =>
                  formik.setFieldValue(FormFields.INITIATE_UNSTAKING, value)
                }
              />
            </div>
            <div className="block sm:hidden">
              <span>
                <BodyText size="sm">I want to initiate unstaking</BodyText>
                <BodyText size="sm" weight="bold">
                  {" "}
                  {formik.values.amount} {ticker}
                </BodyText>
              </span>
            </div>
            <div className="hidden sm:block">
              <span>
                <BodyText>I want to initiate unstaking</BodyText>
                <BodyText weight="semibold">
                  {" "}
                  {formik.values.amount} {ticker}
                </BodyText>
              </span>
            </div>
          </div>
        )}
      </FormikField>
    </div>
  );
};
