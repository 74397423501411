import { request } from "graphql-request";
import { CURRENT_ETH_ADDRESS_STORAGE_KEY } from "@figmentjs/web-core/src/components/providers/EthereumWalletProvider/hooks";
import { SIGNATURE_ACCESS_TOKEN_STORAGE_KEY } from "../../src/hooks/use-signature-access-token";

// When running tests, the API endpoint needs to be an absolute URL.
const baseUrl = ["test", "ci"].includes(process.env.NODE_ENV!)
  ? "http://localhost:6009"
  : "";

const ENDPOINT = `${baseUrl}/api/graphql-partners`;

/**
 * Custom fetcher to append auth token to request.
 *
 * When using `graphql-request` directly with `graphql-codegen` (`fetcher: graphql-request` in codegen.yml),
 * it is required to pass `client` on each query, which is troublesome.
 * Therefore we create a custom fetcher to provide the endpoint directly to simplify code.
 */
export const partnersGQLFetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  headers?: HeadersInit
) => {
  return async () => {
    const requestHeaders = new Headers(headers);

    const currentETHAddress = window.localStorage.getItem(
      CURRENT_ETH_ADDRESS_STORAGE_KEY
    );

    if (currentETHAddress && currentETHAddress !== "undefined") {
      requestHeaders.set(
        "X-Figment-Wallet-Address",
        JSON.parse(currentETHAddress)
      );
      const signatureAccessToken = window.localStorage.getItem(
        `${SIGNATURE_ACCESS_TOKEN_STORAGE_KEY}:${JSON.parse(currentETHAddress)}`
      );
      if (signatureAccessToken && signatureAccessToken !== "undefined") {
        requestHeaders.set(
          `X-Figment-Signature-Access-Token-${JSON.parse(currentETHAddress!)}`,
          JSON.parse(signatureAccessToken)
        );
      }
    }

    const data = await request<TData, TVariables>({
      url: ENDPOINT,
      document: query,
      variables,
      requestHeaders,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);

    return data;
  };
};
