import React, { useEffect, useState } from "react";
import { BodyText, Button, CopyToClipboard } from "@figmentjs/web-core";
import { useToast } from "@app/../../packages/web-core/src";
import { useEigenPodCreateMutation } from "../../../../../../graphql/partners/generated/gql";
import Providers from "@figmentjs/web-core/src/components/providers";
import { getTruncatedAddress } from "@figmentjs/utils";
import { useRollbar } from "@figmentjs/rollbar-client";
import { Network, Protocol } from "@figmentjs/protocols";
import { EthereumNetworks } from "../../../../../../../api-app/src";

type Props = {
  setEigenPodAddress: (value: string | undefined) => void;
};

export const EigenLayerRestaking: React.FC<Props> = ({
  setEigenPodAddress,
}) => {
  const [isBroadcastTriggered, setIsBroadcastTriggered] = useState(false);
  const {
    account,
    network,
    broadcastDeposit,
    isBroadcastingDeposit: isSendingTransaction,
    broadcastDepositError,
    depositTxHash,
    setDepositTxHash,
  } = Providers.useEthereumWallet();
  const { addToast } = useToast();
  const rollbar = useRollbar();

  const { mutateAsync, data, isLoading } = useEigenPodCreateMutation({
    retry: 5,
    onSuccess: (data) => {
      if (data?.eigenpodCreate.data?.deployed) {
        setEigenPodAddress(data?.eigenpodCreate.data?.address);
      }

      if (data?.eigenpodCreate.userErrors?.length) {
        rollbar.warn(
          "EigenPodCreateMutation User Errors",
          JSON.stringify(data.eigenpodCreate.userErrors)
        );
      }
    },
  });

  useEffect(() => {
    const main = async () => {
      if (account && network) {
        mutateAsync({
          network:
            network === Network.HOLESKY
              ? EthereumNetworks.Holesky
              : EthereumNetworks.Mainnet,
          ownerAddress: account,
        });
      }
    };

    main();
  }, [account, network, mutateAsync, rollbar]);

  useEffect(
    () => {
      if (
        isBroadcastTriggered &&
        depositTxHash &&
        !isSendingTransaction &&
        !broadcastDepositError
      ) {
        // At this point, we've confirmed the transaction, so we will poll until we've confirmed that the EigenPod is deployed.
        const interval = setInterval(async () => {
          const response = await mutateAsync({
            network:
              network === Network.HOLESKY
                ? EthereumNetworks.Holesky
                : EthereumNetworks.Mainnet,
            ownerAddress: account!,
          });

          if (response?.eigenpodCreate.userErrors?.length) {
            setDepositTxHash(undefined);
            setIsBroadcastTriggered(false);
            clearInterval(interval);
            return;
          }

          if (response?.eigenpodCreate.data?.deployed) {
            setDepositTxHash(undefined);
            setIsBroadcastTriggered(false);
            addToast({
              palette: "success",
              message: "Your EigenPod was deployed.",
              duration: 3000,
            });

            clearInterval(interval);
          }
        }, 1000);
      }
    },
    // We're purposely omitting addToast from the dependencies array, since it uses randomUUID.
    [
      isSendingTransaction,
      depositTxHash,
      account,
      network,
      mutateAsync,
      setDepositTxHash,
      broadcastDepositError,
      isBroadcastTriggered,
    ]
  );

  useEffect(
    () => {
      if (broadcastDepositError && isBroadcastTriggered) {
        setIsBroadcastTriggered(false);

        addToast({
          palette: "error",
          message: "Your EigenPod was not deployed. Please try again.",
          duration: 3000,
        });
      }
    },
    // We're purposely omitting addToast from the dependencies array, since it uses randomUUID.
    [broadcastDepositError, isBroadcastTriggered]
  );

  return (
    <div className="flex justify-between pt-2 pb-3 items-center">
      <div className="leading-8">
        <BodyText>EigenPod Address</BodyText>
      </div>
      {data?.eigenpodCreate.data?.deployed ? (
        <div className="flex flex-nowrap">
          <BodyText weight="bold">
            {getTruncatedAddress({
              address: data?.eigenpodCreate.data?.address,
              protocol: Protocol.ETHEREUM,
            })}
          </BodyText>
          <CopyToClipboard
            contentToSave={data?.eigenpodCreate.data?.address}
            notificationMessage="EigenPod Address copied"
          />
        </div>
      ) : (
        <Button
          loading={
            isLoading ||
            isSendingTransaction ||
            // This bridges the gap between when the TX is confirmed and when the contract is deployed.
            // Otherwise, the loading state flashes on and off.
            (isBroadcastTriggered && !!depositTxHash)
          }
          size="tiny"
          onClick={async () => {
            const payload = data?.eigenpodCreate.data?.unsignedRawCreatepodTx;

            if (payload) {
              broadcastDeposit({
                payload: payload as `0x${string}`,
                amount: "0",
              });

              setIsBroadcastTriggered(true);
              return;
            }

            addToast({
              palette: "error",
              message: "Your EigenPod was not deployed. Please try again.",
              duration: 3000,
            });

            mutateAsync({
              network:
                network === Network.HOLESKY
                  ? EthereumNetworks.Holesky
                  : EthereumNetworks.Mainnet,
              ownerAddress: account!,
            });
          }}
        >
          Deploy EigenPod
        </Button>
      )}
    </div>
  );
};
