import { useState } from "react";

import { SortedColumn } from "../Table/Table.types";

// TODO: [FIGAPP-707] Jest tests for both useTable* hooks

interface TableSortingResult<TSort> {
  /**
   * List of all columns that are manually sorted and the current sort direction.
   *
   * Pass this to the Table's `sorting` prop.
   */
  sorting: SortedColumn[];
  /**
   * Callback function when a column is sorted. It provides the column ID and sort
   * direction to set sorting state manually.
   *
   * Pass this to the Table's `onSorting` prop.
   */
  onSorting: (sorted: SortedColumn) => void;
  /**
   * List of columns that are currently sorted. Helpful property for passing directly
   * to API mutations that search and filter resources (using the `orderBy` variable).
   */
  sortBy?: TSort;
}

/**
 * **Used to manually sort a table for server-side sorting.**
 *
 * Define all columns that can be sorted and their sorting state. The Table component
 * will consume the return properties. Data should be pre-sorted before passing it to
 * the table.
 */
const useTableSorting = <TSort extends SortedColumn>(
  sort: SortedColumn[]
): TableSortingResult<TSort> => {
  const [sorting, setSorting] = useState<SortedColumn[]>(sort);
  const [sortBy, setSortBy] = useState<TSort | undefined>(() =>
    getSortBy<TSort>(sort)
  );

  const onSorting = (newSort: SortedColumn) => {
    const newSorting = sorting.map(({ field }) => ({
      field: field,
      sortDirection: field === newSort.field ? newSort.sortDirection : false,
    }));
    setSorting(newSorting);
    setSortBy(() => getSortBy<TSort>(newSorting));
  };

  return {
    sortBy,
    sorting,
    onSorting,
  };
};

function getSortBy<TSort extends SortedColumn>(
  sorting: SortedColumn[]
): TSort | undefined {
  return (sorting.find((item) => item.sortDirection) as TSort) || undefined;
}

export default useTableSorting;
