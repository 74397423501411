import React, { useCallback } from "react";
import { StakingFlowData } from "@figmentjs/staking-components";

export const initialValues: StakingFlowData = {
  amount: 32,
  address: "",
  acceptedTerms: false,
  flowId: "",
  unsignedRawTransaction: "",
  isRestakingEnabled: false,
  errors: [],
};

type UpdateFlowData = (newFlowData: Partial<StakingFlowData>) => void;

export const useFlowData = (initialFlowData = initialValues) => {
  const [flowData, setFlowData] =
    React.useState<StakingFlowData>(initialFlowData);

  const updateFlowData: UpdateFlowData = useCallback(
    (newFlowData) => {
      setFlowData({ ...flowData, ...newFlowData });
    },
    [flowData]
  );

  return { flowData, updateFlowData };
};
