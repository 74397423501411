import { TimePeriod } from "../../../../graphql/partners/generated/gql";
import { dateFormatter } from "@figmentjs/utils";

const SMALL_CHART_WIDTH = 560;

export type XTickFormatterFunction = (params: {
  date: string;
  index: number;
  width: number;
  dateLabelFormat: string;
  timePeriod: TimePeriod;
}) => string;

export const xTickFormatter: XTickFormatterFunction = ({
  date,
  index,
  width,
  dateLabelFormat,
  timePeriod,
}) => {
  if (
    width > SMALL_CHART_WIDTH ||
    index % 2 === 0 ||
    timePeriod !== TimePeriod.OneMonth
  ) {
    return dateFormatter({
      inputDate: date,
      format: dateLabelFormat,
      timeZone: "UTC",
    });
  }

  return "";
};
