import React from "react";
import { components, GroupBase, IndicatorSeparatorProps } from "react-select";
import { OptionType } from "../InputSelect.types";

const IndicatorSeparator = <TOptionType extends OptionType>(
  props: IndicatorSeparatorProps<TOptionType, false, GroupBase<TOptionType>>
) => {
  return <components.IndicatorSeparator {...props} className="bg-white" />;
};

export default IndicatorSeparator;
