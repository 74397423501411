"use client";
import React from "react";
import Select, { GroupBase } from "react-select";
import twConfig from "../../../theme/tailwind.config";
import { ErrorText, HelperText } from "../shared/components";
import { InputSelectProps, OptionType } from "./InputSelect.types";
import { Control, SingleValue, IndicatorSeparator, Option } from "./components";

// Extending react-select's props to also include our custom
// InputSelectProps type. See https://react-select.com/typescript#custom-select-props
// for more info.
declare module "react-select/dist/declarations/src/Select" {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  > extends InputSelectProps<OptionType> {
    option?: Option;
    isMulti: IsMulti;
    group?: Group;
  }
}

/**
 * Render's a select/dropdown input.
 *
 * @param InputSelectProps
 * @returns The InputSelect component
 */
const InputSelect = <TOptionType extends OptionType>({
  defaultValue,
  disabled,
  error,
  helperText,
  id,
  name,
  options,
  onBlur,
  onChange,
  onFocus,
  renderOption,
  renderSelectedOption,
  searchable,
  clearable,
  size,
  testId,
  value,
  placeholder,
  menuListMaxHeight,
}: InputSelectProps<TOptionType>) => {
  return (
    <>
      <Select<TOptionType>
        id={id}
        instanceId={id}
        inputId={testId}
        className="w-full"
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        name={name}
        isDisabled={disabled}
        isSearchable={searchable !== undefined ? true : false}
        isClearable={clearable}
        options={options}
        onBlur={onBlur ? (evt) => onBlur(evt) : undefined}
        onChange={onChange ? (option) => onChange({ option }) : undefined}
        onFocus={onFocus ? (evt) => onFocus(evt) : undefined}
        error={error}
        renderOption={renderOption}
        renderSelectedOption={renderSelectedOption}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            text: twConfig.theme.colors.black.DEFAULT,
            primary25: twConfig.theme.colors.teal.DEFAULT,
            primary: twConfig.theme.colors.black.DEFAULT,
            primary75: twConfig.theme.colors.error.DEFAULT,
            primary50: twConfig.theme.colors.basic[300],
            danger: twConfig.theme.colors.error.DEFAULT,
            dangerLight: twConfig.theme.colors.error.DEFAULT,
            neutral0: twConfig.theme.colors.white.DEFAULT,
            // TODO - where theme colors that are `figment-yellow`
            // are used is unknown.  We will need to set these
            // appropriately as we discover them.
            neutral5: twConfig.theme.colors.yellow.DEFAULT,
            neutral10: twConfig.theme.colors.yellow.DEFAULT,
            neutral20: twConfig.theme.colors.basic[300], // Arrow Icon
            neutral30: twConfig.theme.colors.basic[600],
            neutral40: twConfig.theme.colors.black.DEFAULT,
            neutral50: twConfig.theme.colors.basic[600], // Placeholder Text
            neutral60: twConfig.theme.colors.basic[600],
            neutral70: twConfig.theme.colors.yellow.DEFAULT,
            neutral80: twConfig.theme.colors.black.DEFAULT, // Selected Value Text
            neutral90: twConfig.theme.colors.yellow.DEFAULT,
          },
        })}
        components={{
          Control,
          SingleValue,
          IndicatorSeparator,
          Option,
        }}
        styles={{
          menuList: (provided) => ({
            ...(provided as { [key: string]: string | number | undefined }),
            padding: 0,
            maxHeight: (menuListMaxHeight || provided.maxHeight) as any,
          }),
          control: (provided) => ({
            ...(provided as { [key: string]: string | number | undefined }),
            margin: 0,
            padding: 0,
            height: size === "small" ? 42 : size === "base" ? 48 : 56,
            pointerEvents: "all",
          }),
          option: (provided, state) => ({
            ...(provided as { [key: string]: string | number | undefined }),
            height: 42,
            backgroundColor: state.isSelected
              ? twConfig.theme.colors["light-ice"]
              : state.isFocused
              ? twConfig.theme.colors.basic[100]
              : twConfig.theme.colors.white.DEFAULT,
            color: twConfig.theme.colors.black.DEFAULT,
            padding: "10px 18px",
            ":active": { backgroundColor: twConfig.theme.colors.basic[300] },
          }),
          input: (provided) => ({
            ...(provided as { [key: string]: string | number | undefined }),
          }),
          placeholder: (provided) => ({
            ...(provided as { [key: string]: string | number | undefined }),
            color: twConfig.theme.colors.basic[600],
            margin: 0,
            fontSize: twConfig.theme.fontSize.sm,
          }),
          singleValue: (provided) => ({
            ...(provided as { [key: string]: string | number | undefined }),
            margin: 0,
          }),
          dropdownIndicator: (provided) => ({
            ...(provided as { [key: string]: string | number | undefined }),
            color: twConfig.theme.colors.black.DEFAULT,
          }),
          menuPortal: (base) => ({
            ...(base as { [key: string]: string | number | undefined }),
            zIndex: 9999,
          }),
        }}
        formatOptionLabel={({ icon, label }) => (
          <div className="flex items-center h-full w-full text-small text-black font-normal">
            {icon && <div className="mr-2">{icon}</div>}
            {React.isValidElement(label) ? (
              label
            ) : (
              <div className="text-sm">{label}</div>
            )}
          </div>
        )}
        menuPortalTarget={document.body}
        onMenuOpen={() => (document.body.style.pointerEvents = "")}
        onMenuClose={() => (document.body.style.pointerEvents = "auto")}
      />
      {helperText && (
        <div className="mx-2 my-0">
          <HelperText>{helperText}</HelperText>
        </div>
      )}
      {error && (
        <div className="mx-2 my-0">
          <ErrorText>{error}</ErrorText>
        </div>
      )}
    </>
  );
};

export default InputSelect;
