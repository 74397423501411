import { MutationError } from "../../../graphql/partners/generated/gql";

export type StakingFlowData = {
  amount: number;
  address: string;
  acceptedTerms: boolean;
  flowId: string;
  unsignedRawTransaction: string;
  errors: MutationError[] | null;
  isRestakingEnabled: boolean;
};

export enum StakingFlowStep {
  AMOUNT = "AMOUNT",
  VERIFY_STAKING = "VERIFY_STAKING",
  VERIFY_OWNERSHIP = "VERIFY_OWNERSHIP",
  SIGNING = "SIGNING",
  SUCCESS = "SUCCESS",
  CANCELLED = "CANCELLED",
  ERROR = "ERROR",
}
