"use client";

import React from "react";
import { BodyText, Tabs } from "@figmentjs/web-core";
import {
  EthereumNetworks,
  TimePeriod,
  useRewardsQuery,
} from "../../../../graphql/partners/generated/gql";
import NoRewardsData from "./no-rewards-data";
import Providers from "@figmentjs/web-core/src/components/providers";
import { Chart } from "./chart";
import { Network } from "@figmentjs/protocols";

const tabs = [
  {
    id: TimePeriod.OneMonth,
    title: "1M",
    dateLabelFormat: "MMM dd",
  },
  {
    id: TimePeriod.SixMonths,
    title: "6M",
    dateLabelFormat: "MMM",
  },
  {
    id: TimePeriod.OneYear,
    title: "1Y",
    dateLabelFormat: "MMM",
  },
];

const RewardsEarnedChart: React.FC = () => {
  const { network } = Providers.useEthereumWallet();
  const { data, isLoading } = useRewardsQuery({
    network,
    ethereumBalanceNetwork:
      network === Network.HOLESKY
        ? EthereumNetworks.Holesky
        : EthereumNetworks.Mainnet,
  });

  if (isLoading) {
    return null;
  }

  return (
    <div className="mt-12 sm:mt-10 sm:pt-6 sm:pr-6">
      <div className="flex flex-col justify-between">
        <div className="flex sm:hidden absolute z-tooltip mt-3">
          <BodyText weight="bold">Rewards Earned</BodyText>
        </div>
        <div className="hidden sm:flex absolute z-tooltip mt-3">
          <BodyText size="lg" weight="bold">
            Rewards Earned
          </BodyText>
        </div>
        {!data?.rewardsSummary.stakingRewards ? (
          <NoRewardsData />
        ) : (
          <div className="sm:pl-6">
            <Tabs tabs={tabs} placement="end" showBorder={false} size="xs">
              {tabs.map(({ id, dateLabelFormat }) => (
                <Tabs.Content key={id} tabId={id}>
                  <Chart timePeriod={id} dateLabelFormat={dateLabelFormat} />
                </Tabs.Content>
              ))}
            </Tabs>
          </div>
        )}
      </div>
    </div>
  );
};

export default RewardsEarnedChart;
