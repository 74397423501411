import React from "react";
import { BigNumber } from "bignumber.js";
import { currencyFormatter, getTruncatedAddress } from "@figmentjs/utils";
import { Protocol, protocols } from "@figmentjs/protocols";
import {
  Icon,
  Button,
  BodyText,
  CopyToClipboard,
  Tooltip,
} from "@figmentjs/web-core";
import { useNetworkOverviewQuery } from "../../../../graphql/partners/generated/gql";
import {
  useFeeData,
  useStakeEthereum,
  useValidateAccessToken,
} from "../../../hooks";
import { getEstimatedEthAggregateStakingTxFee } from "../../../utils";
import { StakingFlowData } from "../types";
import Providers from "@figmentjs/web-core/src/components/providers";
import { getCoreAPINetwork } from "../utils/get-core-api-network";

type OnVerifyProps = {
  isAccessTokenValid: boolean;
  values: Partial<StakingFlowData>;
};

type Props = {
  onVerify: ({ isAccessTokenValid, values }: OnVerifyProps) => void;
  onError: (values: Partial<StakingFlowData>) => void;
  onBack: () => void;
  flowData: StakingFlowData;
};

export const VerifyStakingStep: React.FC<Props> = ({
  onVerify,
  onBack,
  onError,
  flowData,
}) => {
  const {
    isLoading: isValidateAccessTokenLoading,
    data: validateAccessTokenData,
  } = useValidateAccessToken();

  const { data } = useNetworkOverviewQuery();

  const { fee } = useFeeData();
  const { network } = Providers.useEthereumWallet();

  const { stake, isLoading: isStakeEthereumLoading } = useStakeEthereum({
    input: {
      fundingAddress: flowData.address,
      amountEth: flowData.amount,
      network: getCoreAPINetwork(network),
      withdrawalAddress: flowData.address,
    },
    onError,
    onCreation: (values) => onVerify({ isAccessTokenValid: true, values }),
  });

  const isLoading = isValidateAccessTokenLoading || isStakeEthereumLoading;

  const { address, amount } = flowData;
  const { ticker } = protocols[Protocol.ETHEREUM];
  const price = data?.ethereumPrice?.value;
  const estimatedFee = getEstimatedEthAggregateStakingTxFee(fee, amount);

  const handleContinue = async () => {
    if (validateAccessTokenData?.isValid) {
      await stake();
      return;
    }

    onVerify({ isAccessTokenValid: false, values: {} });
  };

  const addressText = flowData.isRestakingEnabled ? "EigenPod" : "Withdrawal";
  const disclaimerText = flowData.isRestakingEnabled
    ? "When signing on your device, you will receive a warning that the withdrawal address doesn't match your wallet address. This is expected for EigenLayer restaking, as the withdrawal address is set to an EigenPod."
    : "Your rewards will be sent to the Withdrawal Address, as well as your initial 32 ETH when you unstake. Double-check this address before continuing.";

  return (
    <div className="mt-8 flex flex-col sm:h-full">
      <div className="sm:flex-1">
        <div className="flex flex-col items-center">
          <div className="gap-6 mb-8 flex flex-col text-center items-center">
            <Icon icon="MagnifyingGlass" />
            <div className="block sm:hidden">
              <BodyText size="base" weight="semibold">
                Verify Staking Information
              </BodyText>
            </div>
            <div className="hidden sm:block">
              <BodyText size="lg" weight="semibold">
                Verify Staking Information
              </BodyText>
            </div>
          </div>
          <div className="divide-y divide-basic-200 w-full">
            <div />
            <div className="flex justify-between py-3">
              <BodyText>Amount</BodyText>
              <div className="flex items-center gap-1">
                <BodyText weight="bold">
                  {amount} {ticker}
                </BodyText>
                /
                <BodyText>
                  {price
                    ? currencyFormatter.format(
                        new BigNumber(price).times(amount).toNumber()
                      )
                    : ""}
                </BodyText>
              </div>
            </div>
            <div className="flex justify-between py-3">
              <div className="flex items-center gap-1">
                <BodyText>Estimated Transaction Fee</BodyText>
                <Tooltip
                  overlayText={`Determined by market demand and network congestion. Ensure your wallet has enough ${ticker} to cover the transaction.`}
                >
                  <Icon icon="MdInfoOutline" />
                </Tooltip>
              </div>
              <div className="flex items-center gap-1">
                <BodyText weight="bold">
                  {estimatedFee === "0"
                    ? "—"
                    : `${BigNumber(estimatedFee).toFormat(4)} ${ticker}`}
                </BodyText>
              </div>
            </div>
            <div className="pt-3">
              <div className="flex justify-between">
                <div className="flex items-center gap-2">
                  {flowData.isRestakingEnabled && (
                    <Icon icon="EigenLayerLogo" />
                  )}
                  <BodyText>{addressText} Address</BodyText>
                </div>
                <div className="flex items-center">
                  <BodyText weight="bold">
                    {getTruncatedAddress({
                      address,
                      protocol: Protocol.ETHEREUM,
                    })}
                  </BodyText>
                  <CopyToClipboard
                    contentToSave={address}
                    notificationMessage={`${addressText} Address copied`}
                  />
                </div>
              </div>
              <div className="pt-6">
                <div className="py-2 px-3 bg-green-100 rounded border border-green">
                  <div className="leading-3 text-sm">
                    <span className="leading-3 text-sm font-semibold">
                      IMPORTANT:{" "}
                    </span>
                    <span className="leading-3 text-sm">{disclaimerText}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:mt-[43px] flex justify-center w-full max-w-sm">
            <Button onClick={handleContinue} fullWidth loading={isLoading}>
              Continue
            </Button>
          </div>
          <div className="mt-3 sm:mt-4">
            <Button palette="text" size="small" onClick={onBack}>
              Back to Edit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
