"use client";

import React from "react";
import { QueryClientProvider } from "@tanstack/react-query";

import { apiAppQueryClient } from "./query-client";

const ApiAppClientProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <QueryClientProvider client={apiAppQueryClient}>
      {children}
    </QueryClientProvider>
  );
};

export { ApiAppClientProvider };
