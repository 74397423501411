import React from "react";
import { twMerge as tw } from "tailwind-merge";

import { Icon } from "../../graphics";
import { BUTTON_STYLES, DISABLED_BUTTON_STYLES } from "../utils";
import {
  CursorActions,
  TableCursorPaginationProps,
} from "./TablePagination.types";

const TableCursorPagination: React.FC<TableCursorPaginationProps> = ({
  hasNext,
  hasPrevious,
  onAction,
}) => {
  const disablePrev = !hasPrevious;
  const disableNext = !hasNext;

  if (disableNext && disablePrev) {
    return null;
  }

  return (
    <div className="p-4 flex justify-center items-center gap-2">
      <button
        title="First page"
        className={tw(BUTTON_STYLES, disablePrev && DISABLED_BUTTON_STYLES)}
        onClick={() => onAction(CursorActions.FIRST)}
        disabled={disablePrev}
      >
        <Icon icon="MdFirstPage" color="green" size="xs" />
      </button>
      <button
        title="Previous page"
        className={tw(BUTTON_STYLES, disablePrev && DISABLED_BUTTON_STYLES)}
        onClick={() => onAction(CursorActions.PREVIOUS)}
        disabled={disablePrev}
      >
        <span className="ml-1.5">
          <Icon icon="MdArrowBackIos" color="green" size="xs" />
        </span>
      </button>
      <button
        title="Next page"
        className={tw(BUTTON_STYLES, disableNext && DISABLED_BUTTON_STYLES)}
        onClick={() => onAction(CursorActions.NEXT)}
        disabled={disableNext}
      >
        <span className="ml-0.5">
          <Icon icon="MdArrowForwardIos" color="green" size="xs" />
        </span>
      </button>
      <button
        title="Last page"
        className={tw(BUTTON_STYLES, disableNext && DISABLED_BUTTON_STYLES)}
        onClick={() => onAction(CursorActions.LAST)}
        disabled={disableNext}
      >
        <Icon icon="MdLastPage" color="green" size="xs" />
      </button>
    </div>
  );
};

export default TableCursorPagination;
