import React, { useState } from "react";
import * as Siwe from "siwe";
import { protocols } from "@figmentjs/protocols";
import { Button, BodyText, Icon } from "@figmentjs/web-core";
import Providers from "@figmentjs/web-core/src/components/providers";
import { IFrameProvider } from "@figmentjs/web-core/src/components/providers/EthereumWalletProvider/hooks";
import { StakingFlowData } from "../types";
import { getCoreAPINetwork } from "../utils/get-core-api-network";
import {
  useStakeEthereum,
  useNonce,
  useSignatureAccessToken,
  useGenerateNonce,
} from "../../../hooks";

type Props = {
  onVerification: (values: Partial<StakingFlowData>) => void;
  onError: (values: Partial<StakingFlowData>) => void;
  onBack: () => void;
  flowData: StakingFlowData;
};

export const VerifyOwnershipStep: React.FC<Props> = ({
  onVerification,
  onBack,
  onError,
  flowData,
}) => {
  const { setNonce } = useNonce();
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [isSignInWithEthereumLoading, setIsSignInWithEthereumLoading] =
    useState(false);

  const { provider: providerManager, network } = Providers.useEthereumWallet();

  const { setSignatureAccessToken } = useSignatureAccessToken({
    account: flowData.address,
  });

  const { stake, isLoading: isStakeEthereumLoading } = useStakeEthereum({
    input: {
      fundingAddress: flowData.address,
      amountEth: flowData.amount,
      network: getCoreAPINetwork(network),
      withdrawalAddress: flowData.address,
    },
    onError,
    onCreation: onVerification,
  });

  const { isLoading: isGenerateNonceLoading, mutateAsync: generateNonce } =
    useGenerateNonce();

  const isLoading =
    isSignInWithEthereumLoading ||
    isGenerateNonceLoading ||
    isStakeEthereumLoading;

  const verifyOwnership = async () => {
    setVerificationFailed(false);

    const nonceData = await generateNonce();
    const { encryptedNonce, nonce } = nonceData;

    setNonce(encryptedNonce);

    const chainId = protocols.ETHEREUM.networks[network!]?.chainId;
    const message = createSiweMessage(nonce, chainId!);
    const hexMessage = `0x${message
      .split("")
      .map((c: string) => c.charCodeAt(0).toString(16).padStart(2, "0"))
      .join("")}`;

    let signature;
    try {
      signature = await (providerManager?.provider as IFrameProvider).send(
        "personal_sign",
        [hexMessage, flowData.address]
      );
    } catch (e) {
      // The user declined the TX, so we won't proceed.
      return;
    }

    setIsSignInWithEthereumLoading(true);

    const response = await fetch("/api/sign-in-with-ethereum", {
      method: "POST",
      body: JSON.stringify({
        message,
        signature,
        address: flowData.address,
      }),
      headers: {
        "content-type": "application/json",
        "X-Figment-Nonce": encryptedNonce,
        "X-Figment-Parent-Url": "ledger-live",
      },
    });
    const data = await response.json();

    setIsSignInWithEthereumLoading(false);
    setSignatureAccessToken(data?.accessToken);

    if (!data?.accessToken) {
      setVerificationFailed(true);
      return;
    }

    stake();
  };

  const createSiweMessage = (nonce: string, chainId: number) => {
    const message = new Siwe.SiweMessage({
      domain: process.env.NEXT_PUBLIC_SIGN_IN_WITH_ETHEREUM_DOMAIN,
      address: flowData.address,
      statement: "",
      uri: "https://figment.io",
      version: "1",
      chainId,
      nonce,
    });

    return message.prepareMessage();
  };

  return (
    <div className="mt-8 flex flex-col sm:h-full">
      <div className="flex-1">
        <div className="flex flex-col items-center">
          <Icon icon="Profile" />
          <div className="mt-6 sm:mt-8 text-center mb-3 sm:mb-1 sm:px-10">
            <div className="block sm:hidden">
              <BodyText size="base" weight="semibold">
                We need to verify your ownership of the wallet address
              </BodyText>
            </div>
            <div className="hidden sm:block">
              <BodyText size="lg" weight="semibold">
                We need to verify your ownership of the wallet address
              </BodyText>
            </div>
            <div className="mt-4">
              <BodyText as="p">
                To avoid malicious use, Figment needs to confirm that we&apos;re
                dealing with the owner of this address. Click “Continue” to sign
                a message using your private key and Ledger device.
              </BodyText>
            </div>
          </div>
          {verificationFailed && (
            <div>
              <BodyText>
                Verification failed. If you&apos;re the owner of{" "}
                <BodyText weight="bold">{flowData.address}</BodyText> and you
                believe something isn&apos;t working properly, please contact us
                at{" "}
                <a
                  href="mailto:support@figment.io"
                  className="text-green-800 underline hover:no-underline"
                >
                  support@figment.io
                </a>
                .
              </BodyText>
            </div>
          )}
          <div className="mt-10 sm:mt-40 w-full max-w-sm">
            <Button
              onClick={() => verifyOwnership()}
              fullWidth
              loading={isLoading}
            >
              Continue
            </Button>
          </div>
          <div className="mt-4">
            <Button palette="text" size="small" onClick={onBack}>
              Back
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
