"use client";
import Rollbar from "@figmentjs/rollbar-client/src/config";
import { AuthUserQuery, useAuthUserQuery } from "../graphql/generated";
import React, { useContext } from "react";
import { BugBountyUtils } from "@figmentjs/utils";
import { useRollbarConfiguration } from "@figmentjs/rollbar-client";

type UserContextType = {
  data: AuthUserQuery | undefined;
};

export const UserContext = React.createContext<UserContextType>({
  data: undefined,
});

export const BodyWrapper = ({ useDivTag }: { useDivTag?: boolean }) => {
  if (useDivTag) {
    return (
      <div className="bg-green-100 h-[100vh] w-[100vw] flex items-center justify-center"></div>
    );
  }
  return (
    <body className="bg-green-100 h-[100vh] w-[100vw] flex items-center justify-center"></body>
  );
};

export const UserProvider: React.FC<{
  useDivTag?: boolean;
  rollbar: Rollbar;
  children: React.ReactNode;
}> = ({ useDivTag, rollbar, children }) => {
  const { data, isLoading } = useAuthUserQuery();
  // Update the two Rollbar instances:
  //  1. The instance in the RollbarProvider context.
  //  2. The instance used by the provided rollbar prop.
  useRollbarConfiguration({
    ...(BugBountyUtils.isBugBountyHunter(data?.me.user.email)
      ? {
          environment: BugBountyUtils.ROLLBAR_BUG_BOUNTY_ENVIRONMENT,
        }
      : {}),
  });
  rollbar.configure({
    ...(BugBountyUtils.isBugBountyHunter(data?.me.user.email)
      ? {
          environment: BugBountyUtils.ROLLBAR_BUG_BOUNTY_ENVIRONMENT,
        }
      : {}),
  });

  if (isLoading) {
    return <BodyWrapper useDivTag={useDivTag} />;
  }

  // If user query is done but there's no data, throw error
  if (!isLoading && !data) {
    rollbar.error("AuthUserQuery failed unexpectedly");
    throw new Error("Something went wrong, please try again");
  }

  return (
    <UserContext.Provider value={{ data }}>{children}</UserContext.Provider>
  );
};

export const useUser = () => {
  const { data } = useContext(UserContext);

  return {
    data,
  };
};
