import React from "react";
import {
  Badge,
  BadgeTypes,
  CellType,
  ColumnDef,
  Icon,
  Tooltip,
  TableCellBase,
  Timestamp,
  BodyText,
  Button,
} from "@figmentjs/web-core/src/components";
import {
  ExitRequestStatuses,
  DepositStatuses,
  StakingActivityV2ConnectionNodeFragment,
} from "../../../../graphql/partners/generated/gql";
import { Protocol, protocols } from "@figmentjs/protocols";

const UTC_TOOLTIP = "All date times in UTC";

export const columns: ColumnDef<StakingActivityV2ConnectionNodeFragment>[] = [
  {
    header: "Action",
    accessorKey: "__typename",
    cell: (info) => {
      const action =
        info.getValue() === "EthereumExitRequest"
          ? "Unstake"
          : info.getValue() === "EthereumStakingDeposit"
          ? "Stake"
          : undefined;

      return <TableCellBase type={CellType.SEMIBOLD} value={action} />;
    },
    enableSorting: false,
  },
  {
    header: "Amount",
    accessorKey: "amountEth",
    cell: (info) => {
      const ethereumProtocol = protocols[Protocol.ETHEREUM];
      const network = info.row.original.network!;

      const networkTicker = ethereumProtocol.networks[network]?.ticker;
      const ticker = networkTicker || ethereumProtocol.ticker;

      const value = info.getValue()
        ? `${Number(info.getValue()).toLocaleString()} ${ticker}`
        : "";

      return (
        <TableCellBase
          type={CellType.SEMIBOLD}
          value={<div className="whitespace-nowrap">{value}</div>}
        />
      );
    },
    enableSorting: false,
  },
  {
    header: () => (
      <div className="flex items-center gap-1">
        <span className="text-base font-semibold uppercase">Date</span>
        <Tooltip overlayText={UTC_TOOLTIP}>
          <Icon icon="MdInfoOutline" />
        </Tooltip>
      </div>
    ),
    accessorKey: "activityAt",
    cell: (info) => (
      <TableCellBase
        type={CellType.SEMIBOLD}
        value={
          <div className="whitespace-nowrap">
            <Timestamp
              dateTime={info.getValue<string>() || info.row.original.createdAt}
              format="LLL dd, h:mm aa"
            />
          </div>
        }
      />
    ),
  },
  {
    header: "Status",
    cell: (info) => {
      const activityRecord = info.row.original;
      const rawStatus = getActivityStatus(activityRecord);
      const status = getStatus(rawStatus);
      const isActivating = rawStatus === DepositStatuses.Activating;
      const isWithdrawing = rawStatus === ExitRequestStatuses.PendingWithdrawal;
      const isExiting = rawStatus === ExitRequestStatuses.Exiting;

      const eta = getActivityEta(activityRecord);

      return (
        <TableCellBase
          type={CellType.SEMIBOLD}
          value={
            <div className="flex flex-row items-center gap-3">
              <Badge type={getBadgeType(rawStatus)} compact>
                <div className="whitespace-nowrap">{status}</div>
              </Badge>
              {isActivating && eta.start && (
                <div className="whitespace-nowrap">
                  ETA: <Timestamp dateTime={eta.start} format="LLL dd" />
                </div>
              )}
              {(isWithdrawing || isExiting) && (eta.start || eta.end) && (
                <div className="w-fit">
                  <Tooltip
                    compact={true}
                    placement="top"
                    overlayContent={
                      <div className="m-3">
                        <div className="flex items-center h-5 mb-2">
                          {eta.start && (
                            <TableCellBase
                              value={
                                <div className="whitespace-nowrap font-bold text-white">
                                  <Timestamp
                                    dateTime={eta.start}
                                    format="LLL dd h:mm"
                                  />{" "}
                                  UTC
                                </div>
                              }
                            />
                          )}
                          {eta.start && eta.end && eta.start !== eta.end && (
                            <div className="mx-1 text-white">-</div>
                          )}
                          {eta.end && eta.start !== eta.end && (
                            <TableCellBase
                              value={
                                <div className="whitespace-nowrap font-bold text-white">
                                  <Timestamp
                                    dateTime={eta.end}
                                    format="LLL dd h:mm"
                                  />{" "}
                                  UTC
                                </div>
                              }
                            />
                          )}
                        </div>
                        <div>
                          <BodyText color="white" size="sm">
                            When your funds will be withdrawn varies depending
                            on the position of the validators in the exit queue.
                          </BodyText>
                          <div className="inline ml-2">
                            <Button
                              palette="text"
                              size="tiny"
                              textColor="text-white"
                              openInNewTab
                              href="https://figment.io/insights/ethereum-withdrawals-a-comprehensive-faq/"
                            >
                              Learn More
                            </Button>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <div className="flex items-center h-5 cursor-help">
                      <div className="mr-1">Withdrawal ETA:</div>
                      {eta.start && (
                        <TableCellBase
                          value={
                            <div className="whitespace-nowrap font-bold">
                              <Timestamp dateTime={eta.start} format="LLL dd" />
                            </div>
                          }
                        />
                      )}
                      {eta.start && eta.end && eta.start !== eta.end && (
                        <div className="mx-1">-</div>
                      )}
                      {eta.end && eta.start !== eta.end && (
                        <TableCellBase
                          value={
                            <div className="whitespace-nowrap font-bold">
                              <Timestamp dateTime={eta.end} format="LLL dd" />
                            </div>
                          }
                        />
                      )}
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          }
        />
      );
    },
    enableSorting: false,
  },
];

const getStatus = (status?: ExitRequestStatuses | DepositStatuses): string => {
  switch (status) {
    case DepositStatuses.Deposited:
      return "Deposited";
    case DepositStatuses.Activating:
      return "Activating";
    case DepositStatuses.Activated:
      return "Complete";
    case ExitRequestStatuses.Exiting:
      return "Exiting";
    case ExitRequestStatuses.PendingWithdrawal:
      return "Withdrawal Pending";
    case ExitRequestStatuses.Completed:
      return "Complete";
    default:
      return "In Progress";
  }
};

const getBadgeType = (
  status?: ExitRequestStatuses | DepositStatuses
): keyof typeof BadgeTypes => {
  switch (status) {
    case ExitRequestStatuses.PendingWithdrawal:
    case ExitRequestStatuses.Exiting:
    case DepositStatuses.Activating:
    case DepositStatuses.Deposited:
      return BadgeTypes.pending;
    case DepositStatuses.Activated:
    case ExitRequestStatuses.Completed:
      return BadgeTypes.success;
    default:
      return BadgeTypes.pending;
  }
};

const getActivityStatus = (
  activityRecord: StakingActivityV2ConnectionNodeFragment
) =>
  activityRecord.__typename === "EthereumExitRequest"
    ? activityRecord.exitStatus
    : activityRecord.__typename === "EthereumStakingDeposit"
    ? activityRecord.depositStatus
    : undefined;

const getActivityEta = (
  activityRecord: StakingActivityV2ConnectionNodeFragment
): { start?: string | null; end?: string | null } =>
  activityRecord.__typename === "EthereumExitRequest"
    ? {
        start: activityRecord.estimatedWithdrawableAt,
        end: activityRecord.estimatedPrincipalReturnAt,
      }
    : activityRecord.__typename === "EthereumStakingDeposit"
    ? { start: activityRecord.estimatedActiveAt }
    : {
        start: undefined,
        end: undefined,
      };
