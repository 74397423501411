import React, { useEffect, useRef, useState } from "react";
import {
  DAppActionEnum as Actions,
  DAppObjectsEnum,
  DAppTrackEventProperties,
} from "@figmentjs/analytics/apps";
import Providers from "@figmentjs/web-core/src/components/providers";
import { useSegment } from "@app/app/hooks";
import {
  AmountStep,
  VerifyStakingStep,
  VerifyOwnershipStep,
  SigningStep,
  SuccessStep,
  CancelledStep,
  ErrorStep,
  StakingFlowStep,
} from "@figmentjs/staking-components";
import { useFlowData, initialValues } from "./hooks";
import tailwindConfig from "tailwind.config";

const smallScreenWidth = parseInt(tailwindConfig.theme.screens.sm);

export const StakingFlow: React.FC = () => {
  const [step, setStep] = useState<StakingFlowStep>(StakingFlowStep.AMOUNT);
  const { flowData, updateFlowData } = useFlowData();
  const { trackEvent } = useSegment();
  const activityRef = useRef<null | HTMLDivElement>(null);
  const { network } = Providers.useEthereumWallet();

  const trackStakingFlowEvent = (
    action: Actions,
    properties: DAppTrackEventProperties
  ) => {
    trackEvent(
      { object: DAppObjectsEnum.STAKING_FLOW, action },
      {
        network,
        amountToStake: flowData.amount,
        isRestakingMode: flowData.isRestakingEnabled,
        isMobile: window.innerWidth < smallScreenWidth,
        ...properties,
      }
    );
  };

  const reset = () => {
    updateFlowData(initialValues);
    setStep(StakingFlowStep.AMOUNT);
  };

  useEffect(() => {
    trackStakingFlowEvent(Actions.STEP_RENDERED, { step });
    window.scrollTo(0, 0);
  }, [step]);

  return (
    <div>
      {((): React.ReactNode => {
        switch (step) {
          case StakingFlowStep.AMOUNT:
            return (
              <AmountStep
                onSubmit={(values) => {
                  trackStakingFlowEvent(Actions.CONTINUE_BTN_CLICKED, { step });
                  updateFlowData(values);
                  setStep(StakingFlowStep.VERIFY_STAKING);
                }}
                flowData={flowData}
              />
            );
          case StakingFlowStep.VERIFY_STAKING:
            return (
              <VerifyStakingStep
                onVerify={({ isAccessTokenValid, values }) => {
                  trackStakingFlowEvent(Actions.CONTINUE_BTN_CLICKED, { step });

                  if (!isAccessTokenValid) {
                    setStep(StakingFlowStep.VERIFY_OWNERSHIP);
                    return;
                  }

                  updateFlowData(values);
                  setStep(StakingFlowStep.SIGNING);
                }}
                onBack={() => {
                  trackStakingFlowEvent(Actions.BACK_BTN_CLICKED, { step });
                  setStep(StakingFlowStep.AMOUNT);
                }}
                onError={(values) => {
                  updateFlowData(values);
                  setStep(StakingFlowStep.ERROR);
                }}
                flowData={flowData}
              />
            );
          case StakingFlowStep.VERIFY_OWNERSHIP:
            return (
              <VerifyOwnershipStep
                onVerification={(values) => {
                  trackStakingFlowEvent(Actions.CONTINUE_BTN_CLICKED, { step });
                  updateFlowData(values);
                  setStep(StakingFlowStep.SIGNING);
                }}
                onBack={() => {
                  trackStakingFlowEvent(Actions.BACK_BTN_CLICKED, { step });
                  setStep(StakingFlowStep.VERIFY_STAKING);
                }}
                onError={(values) => {
                  updateFlowData(values);
                  setStep(StakingFlowStep.ERROR);
                }}
                flowData={flowData}
              />
            );
          case StakingFlowStep.SIGNING:
            return (
              <SigningStep
                onSuccess={(values) => {
                  trackStakingFlowEvent(Actions.TX_SIGNED_AND_BROADCASTED, {
                    step,
                  });
                  updateFlowData(values);
                  setStep(StakingFlowStep.SUCCESS);
                }}
                onFail={(values) => {
                  updateFlowData(values);
                  setStep(StakingFlowStep.ERROR);
                }}
                onCanceled={() => setStep(StakingFlowStep.CANCELLED)}
                flowData={flowData}
              />
            );
          case StakingFlowStep.ERROR:
            return (
              <ErrorStep
                onTryAgain={() => {
                  trackStakingFlowEvent(Actions.TRY_AGAIN_BTN_CLICKED, {
                    step,
                  });
                  reset();
                }}
                flowData={flowData}
              />
            );
          case StakingFlowStep.CANCELLED:
            return (
              <CancelledStep
                onTryAgain={() => {
                  trackStakingFlowEvent(Actions.TRY_AGAIN_BTN_CLICKED, {
                    step,
                  });
                  reset();
                }}
              />
            );
          case StakingFlowStep.SUCCESS:
            return (
              <SuccessStep
                onStakeMore={() => {
                  trackStakingFlowEvent(Actions.STAKE_MORE_BTN_CLICKED, {
                    step,
                  });
                  reset();
                }}
                onSeeActivity={() => {
                  trackStakingFlowEvent(Actions.SEE_ACTIVITY_BTN_CLICKED, {
                    step,
                  });
                  activityRef.current?.scrollIntoView({ behavior: "smooth" });
                }}
                flowData={flowData}
              />
            );
          default:
            return null;
        }
      })()}
      <div className="-scroll-mt-10" ref={activityRef} />
    </div>
  );
};
