import React from "react";
import { Icon, BodyText, Table } from "@figmentjs/web-core";
import { StakingActivityV2ConnectionNodeFragment } from "../../../../graphql/partners/generated/gql";
import { columns } from "./activity-table.columns";

const NoActivityData: React.FC = () => {
  return (
    <div className="mt-10">
      <div className="hidden sm:block">
        <BodyText size="lg" weight="bold">
          Activity
        </BodyText>
      </div>
      <div className="block sm:hidden">
        <BodyText size="base" weight="bold">
          Activity
        </BodyText>
      </div>
      <Table<StakingActivityV2ConnectionNodeFragment>
        cols={columns}
        rows={[]}
        containerClassName="pt-6 overflow-x-scroll"
        darkenOddRows={false}
        cellSpacing
      />
      <div className="flex flex-col mt-8 items-center text-center">
        <Icon icon="DappNoActivity" />
        <div className="mt-6 sm:mt-10 mb-2">
          <div className="block sm:hidden">
            <BodyText size="lg" weight="semibold">
              No Activity yet
            </BodyText>
          </div>
          <div className="hidden sm:block">
            <BodyText size="xl" weight="semibold">
              No Activity yet
            </BodyText>
          </div>
        </div>
        <div className="block sm:hidden">
          <BodyText>Once you stake, your activity will appear here.</BodyText>
        </div>
        <div className="hidden sm:block">
          <BodyText size="lg">
            Once you stake, your activity will appear here.
          </BodyText>
        </div>
        <div className="mt-8 border-t border-basic-200 w-full" />
      </div>
    </div>
  );
};

export default NoActivityData;
