import React from "react";
import { BodyText, Icon, Heading, Button } from "@figmentjs/web-core";

type Props = {
  onTryAgain: () => void;
};

export const CancelledStep: React.FC<Props> = ({ onTryAgain }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center py-8 sm:pt-40 sm:pb-[124px]">
        <Icon icon="MdCancel" size="7xl" color="error" />
        <div className="mt-8 space-y-6 text-center sm:px-8">
          <Heading level="h5">Transaction Canceled</Heading>
          <BodyText as="p">
            When ready, you can start at the beginning and try staking again.
          </BodyText>
        </div>
      </div>
      <div className="flex justify-center w-full max-w-sm">
        <Button onClick={onTryAgain} fullWidth>
          Try Again
        </Button>
      </div>
    </div>
  );
};
