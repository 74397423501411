import React from "react";
import { hashQueryKey } from "@tanstack/react-query";

import Providers from "@figmentjs/web-core/src/components/providers";

import { useStakingActivityV2Query } from "../../../../graphql/partners/generated/gql";
import { networkMap } from "../../../staking/components/activity-table/activity-table";
import { BalanceAndRewardsCards } from "../balance-and-rewards-cards";
import { NoStakingActivity } from "../no-staking-activity";
import RewardsEarnedChart from "../chart/rewards-earned-chart";

type Props = {
  setSelectedTabId: React.Dispatch<React.SetStateAction<string>>;
};

export const Rewards: React.FC<Props> = ({ setSelectedTabId }) => {
  const { account, network } = Providers.useEthereumWallet();

  const { data: stakingActivityData, isLoading: isStakingActivityLoading } =
    useStakingActivityV2Query(
      {
        network: networkMap[network!]!,
      },
      {
        enabled: !!(account && network),
        queryKeyHashFn: () =>
          hashQueryKey(["StakingActivityV2", networkMap[network!], account]),
      }
    );

  const isNoActivity = !stakingActivityData?.stakingActivity.nodes.length;

  if (isStakingActivityLoading) {
    return null;
  }

  if (isNoActivity) {
    return <NoStakingActivity setSelectedTabId={setSelectedTabId} />;
  }

  return (
    <>
      <BalanceAndRewardsCards />
      <RewardsEarnedChart />
    </>
  );
};
