import React, { memo, useState } from "react";

import { twMerge as tw } from "tailwind-merge";
import { Popover } from "..";
import { InputCheckbox } from "../../forms";

export type FilteredColumnOption = {
  label: string;
  count: number;
  tagColor: string;
  selected: boolean;
};

type Props = {
  options?: Array<FilteredColumnOption>;
  children: React.ReactElement;
  onChange?: (updatedOptions: Array<FilteredColumnOption>) => void;
};

const ColumnFilter: React.FC<Props> = ({
  children,
  onChange,
  options = [],
}) => {
  const [updatedOptions, setUpdatedOptions] = useState<
    Array<FilteredColumnOption>
  >([...options]);

  if (!options.length) {
    return children;
  }

  return (
    <div className="w-fit">
      <Popover
        trigger={children}
        className="w-fit flex flex-col drop-shadow-lg p-5 z-modal"
      >
        <>
          {options.map((option) => (
            <InputCheckbox
              key={option.label}
              onChange={({ value }) => {
                const clickedOption = updatedOptions.find(
                  (s) => s.label === option.label
                );
                if (clickedOption) {
                  clickedOption.selected = value;
                }
                const updated = [...updatedOptions];
                setUpdatedOptions(updated);
                onChange?.(updated);
              }}
              value={option.selected}
            >
              <div className="flex items-center cursor-pointer">
                <div
                  className={tw(
                    "rounded-full w-3 h-3 mx-3 my-4",
                    option.tagColor
                  )}
                />
                <span className="font-semibold text-base">
                  {option.label} ({option.count})
                </span>
              </div>
            </InputCheckbox>
          ))}
        </>
      </Popover>
    </div>
  );
};

export default memo(ColumnFilter);
