import {
  useMutation,
  useQuery,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useFetchData } from "../config";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601Date: any;
  ISO8601DateTime: string;
  JSON: Record<string, unknown>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type Account = {
  __typename?: "Account";
  /** The account address (e.g. 0xabc123) */
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  /** The same as address, but checksummed or formatted for the specific protocol. */
  formattedAddress: Scalars["String"];
  id: Scalars["ID"];
  /** Whether the account has active delegation (manually managed) */
  isActive: Scalars["Boolean"];
  /** A free-form note about the account */
  label?: Maybe<Scalars["String"]>;
  /** The protocol network name (e.g. mainnet) */
  network: Scalars["String"];
  /** The Salesforce Opportunity Token. */
  opToken?: Maybe<OpToken>;
  /** Options that are set per account type. This is generic JSON because all account types can have different options. */
  options: Scalars["JSON"];
  /** The protocol name (e.g. Ethereum) */
  protocol: Scalars["String"];
  /** Signals the need to report for a white label validator */
  reportForWhiteLabelValidator: Scalars["Boolean"];
  /** Where this account was first added from */
  source?: Maybe<Scalars["String"]>;
  /** The type of account */
  type?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for Account. */
export type AccountConnection = {
  __typename?: "AccountConnection";
  /** A list of edges. */
  edges: Array<AccountEdge>;
  /** A list of nodes. */
  nodes: Array<Account>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type AccountEdge = {
  __typename?: "AccountEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Account;
};

export enum AccountOrderByFields {
  /** The account address */
  Address = "address",
  /** Date the account was added */
  CreatedAt = "createdAt",
  /** The network of the protocol (e.g. mainnet) */
  Network = "network",
  /** The protocol the address is for (e.g. ethereum) */
  Protocol = "protocol",
}

export type Accounts = {
  field: AccountOrderByFields;
  sortDirection: SortDirection;
};

/** Attributes for filtering tracked accounts. */
export type AccountsFilter = {
  address_cont?: InputMaybe<Scalars["String"]>;
  address_eq?: InputMaybe<Scalars["String"]>;
  address_i_cont?: InputMaybe<Scalars["String"]>;
  network_eq?: InputMaybe<Scalars["String"]>;
  organization_id_eq?: InputMaybe<Scalars["String"]>;
  protocol_eq?: InputMaybe<Scalars["String"]>;
  source_eq?: InputMaybe<Scalars["String"]>;
  source_id_eq?: InputMaybe<Scalars["String"]>;
};

export type AccountsInvitation = {
  __typename?: "AccountsInvitation";
  invitation?: Maybe<Invitation>;
};

export type AccountsInvitationResendInput = {
  email: Scalars["String"];
  organizationId: Scalars["ID"];
};

export type AccountsSignUp = {
  __typename?: "AccountsSignUp";
  acceptedTos?: Maybe<Scalars["Boolean"]>;
  email?: Maybe<Scalars["String"]>;
  isIndividual?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  organization?: Maybe<Organization>;
  organizationName?: Maybe<Scalars["String"]>;
};

export type AccountsSignUpInput = {
  acceptedTos: Scalars["Boolean"];
  email: Scalars["String"];
  isIndividual: Scalars["Boolean"];
  name: Scalars["String"];
  organizationName?: InputMaybe<Scalars["String"]>;
  recaptchaToken: Scalars["String"];
};

export enum ActivityTypes {
  /** Ethereum batch staking */
  Deposit = "deposit",
  /** Ethereum batch unstaking */
  ExitRequest = "exit_request",
}

export type Address = {
  __typename?: "Address";
  additionalDetails?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  state?: Maybe<Scalars["String"]>;
  streetAddress?: Maybe<Scalars["String"]>;
  zipCode?: Maybe<Scalars["String"]>;
};

/** Attributes for address */
export type AddressInput = {
  additionalDetails?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  country?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<Scalars["String"]>;
  streetAddress?: InputMaybe<Scalars["String"]>;
  zipCode?: InputMaybe<Scalars["String"]>;
};

export type AggregatedRewards = {
  __typename?: "AggregatedRewards";
  rewards?: Maybe<Array<AggregatedRewardsTuple>>;
};

export type AggregatedRewardsTuple = {
  __typename?: "AggregatedRewardsTuple";
  consensusTotal?: Maybe<Scalars["String"]>;
  consensusTotalUsd?: Maybe<Scalars["String"]>;
  executionTotal?: Maybe<Scalars["String"]>;
  executionTotalReceived?: Maybe<Scalars["String"]>;
  executionTotalReceivedUsd?: Maybe<Scalars["String"]>;
  executionTotalUsd?: Maybe<Scalars["String"]>;
  timestamp?: Maybe<Scalars["ISO8601DateTime"]>;
  total?: Maybe<Scalars["String"]>;
  totalReceived?: Maybe<Scalars["String"]>;
  totalReceivedUsd?: Maybe<Scalars["String"]>;
  totalUsd?: Maybe<Scalars["String"]>;
};

export type ApiKey = {
  __typename?: "ApiKey";
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  projectId: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  value: Scalars["String"];
};

/** The connection type for ApiKey. */
export type ApiKeyConnection = {
  __typename?: "ApiKeyConnection";
  /** A list of edges. */
  edges: Array<ApiKeyEdge>;
  /** A list of nodes. */
  nodes: Array<ApiKey>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** Attributes for creating an Api Key */
export type ApiKeyCreateInput = {
  name: Scalars["String"];
  projectId: Scalars["String"];
};

/** An edge in a connection. */
export type ApiKeyEdge = {
  __typename?: "ApiKeyEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: ApiKey;
};

export type ApiKeyMutationPayload = {
  __typename?: "ApiKeyMutationPayload";
  /** ApiKey object */
  data?: Maybe<ApiKey>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum ApiKeyOrderByFields {
  /** Date the Api Key was created */
  CreatedAt = "createdAt",
  /** The name of the Api Key */
  Name = "name",
  /** The Project ID that Api Key belongs to */
  ProjectId = "projectId",
}

/** Attributes for updating an Api Key */
export type ApiKeyUpdateInput = {
  name: Scalars["String"];
};

export type ApiKeys = {
  field: ApiKeyOrderByFields;
  sortDirection: SortDirection;
};

export type BabylonNetworkOverview = {
  __typename?: "BabylonNetworkOverview";
  finalityProviderAddress: Scalars["String"];
  maxStakingAmount: Scalars["Int"];
  maxStakingTime: Scalars["Int"];
  minStakingAmount: Scalars["Int"];
  minStakingTime: Scalars["Int"];
};

export type BabylonStake = {
  __typename?: "BabylonStake";
  address?: Maybe<Scalars["String"]>;
  /** Amount staked in BTC */
  amount?: Maybe<Scalars["String"]>;
  amountSatoshi?: Maybe<Scalars["String"]>;
  covenantPubkeys?: Maybe<Scalars["String"]>;
  covenantThreshold?: Maybe<Scalars["Int"]>;
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  minUnbondingTime?: Maybe<Scalars["Int"]>;
  network?: Maybe<Scalars["String"]>;
  organizationId: Scalars["String"];
  stakerPubkey?: Maybe<Scalars["String"]>;
  stakingDuration?: Maybe<Scalars["Int"]>;
  state?: Maybe<BabylonStates>;
  txHash?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Inputs adding tx hash to Babylon stake */
export type BabylonStakeBroadcastedInput = {
  stakeId: Scalars["String"];
  txHash: Scalars["String"];
};

/** The connection type for BabylonStake. */
export type BabylonStakeConnection = {
  __typename?: "BabylonStakeConnection";
  /** A list of edges. */
  edges: Array<BabylonStakeEdge>;
  /** A list of nodes. */
  nodes: Array<BabylonStake>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type BabylonStakeEdge = {
  __typename?: "BabylonStakeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: BabylonStake;
};

/** Inputs for stake id and doing Babylon mutations */
export type BabylonStakeIdInput = {
  stakeId: Scalars["String"];
};

/** Inputs for creating a BTC staking tx on Babylon */
export type BabylonStakingTxInput = {
  address: Scalars["String"];
  changeAddress: Scalars["String"];
  network: BitcoinNetworks;
  stakerPk: Scalars["String"];
  stakingAmount: Scalars["Int"];
  stakingDuration: Scalars["Int"];
  taprootPubkey?: InputMaybe<Scalars["String"]>;
  utxos: Array<BitcoinUtxo>;
};

export enum BabylonStates {
  Active = "active",
  Broadcasted = "broadcasted",
  Init = "init",
  Unbonded = "unbonded",
  UnbondingBroadcasted = "unbonding_broadcasted",
  UnbondingRequested = "unbonding_requested",
  Withdrawal = "withdrawal",
  WithdrawalBroadcasted = "withdrawal_broadcasted",
}

export type BabylonTx = {
  __typename?: "BabylonTx";
  signingPayload?: Maybe<Scalars["String"]>;
  stakeId: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
};

/** Inputs for getting an unsigned withdrawal tx */
export type BabylonWithdrawalInput = {
  stakeId: Scalars["String"];
  withdrawalAddress: Scalars["String"];
};

export enum BitcoinNetworks {
  Mainnet = "mainnet",
  Signet = "signet",
}

export type BitcoinUtxo = {
  /** script which provides the conditions that must be fulfilled for this UTXO to be spent */
  scriptPubKey: Scalars["String"];
  /** transaction ID */
  txid: Scalars["String"];
  /** value of the UTXO, in Satoshis */
  value: Scalars["Int"];
  /** output index */
  vout: Scalars["Int"];
};

export type CommssionRate = {
  __typename?: "CommssionRate";
  /** The commission rate. */
  rate?: Maybe<Scalars["Float"]>;
};

/** Autogenerated return type of ConfigureFigmentVault. */
export type ConfigureFigmentVaultPayload = {
  __typename?: "ConfigureFigmentVaultPayload";
  data?: Maybe<EthereumTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

export enum CurrencyTickers {
  Btc = "BTC",
  Eth = "ETH",
  Sol = "SOL",
}

/** Attributes for scheduling a daily report */
export type DailyReportScheduleInput = {
  chainId?: InputMaybe<Scalars["String"]>;
  end: Scalars["String"];
  network: Scalars["String"];
  start: Scalars["String"];
  timeRollup: DailyReportTimeRollup;
};

export enum DailyReportStatus {
  /** Error */
  Error = "error",
  /** Finished */
  Finished = "finished",
  /** Running */
  Running = "running",
  /** Submitted */
  Submitted = "submitted",
  /** Unknown */
  Unknown = "unknown",
}

export enum DailyReportTimeRollup {
  /** Daily */
  Daily = "daily",
  /** Epoch */
  Epoch = "epoch",
  /** Era */
  Era = "era",
}

export type DailyReports = {
  __typename?: "DailyReports";
  chainId?: Maybe<Scalars["String"]>;
  end: Scalars["String"];
  expiresOn?: Maybe<Scalars["ISO8601DateTime"]>;
  file?: Maybe<Scalars["String"]>;
  fileExpiresOn?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  network: Scalars["String"];
  start: Scalars["String"];
  status: DailyReportStatus;
  timeRollup: DailyReportTimeRollup;
};

/** The connection type for DailyReports. */
export type DailyReportsConnection = {
  __typename?: "DailyReportsConnection";
  /** A list of edges. */
  edges: Array<DailyReportsEdge>;
  /** A list of nodes. */
  nodes: Array<DailyReports>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type DailyReportsEdge = {
  __typename?: "DailyReportsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: DailyReports;
};

export type DailyReportsMutationPayload = {
  __typename?: "DailyReportsMutationPayload";
  /** DailyReports object */
  data?: Maybe<DailyReports>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of DeactivateTransactionCreateMutation. */
export type DeactivateTransactionCreateMutationPayload = {
  __typename?: "DeactivateTransactionCreateMutationPayload";
  data?: Maybe<SolanaStakingTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of DelegateTransactionCreateMutation. */
export type DelegateTransactionCreateMutationPayload = {
  __typename?: "DelegateTransactionCreateMutationPayload";
  data?: Maybe<SolanaStakingTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of DelegateTransactionCreate. */
export type DelegateTransactionCreatePayload = {
  __typename?: "DelegateTransactionCreatePayload";
  data?: Maybe<UnsignedTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type DelegateTransactionInput = {
  accountNumber?: InputMaybe<Scalars["Int"]>;
  amountInj: Scalars["Float"];
  blockTimeoutHeight?: InputMaybe<Scalars["Int"]>;
  delegatorAddress: Scalars["String"];
  gasLimit?: InputMaybe<Scalars["String"]>;
  gasPrice?: InputMaybe<Scalars["String"]>;
  network: InjectiveNetworks;
  publicKey?: InputMaybe<Scalars["String"]>;
  sequence?: InputMaybe<Scalars["Int"]>;
  validatorAddress: Scalars["String"];
};

export type Deposit = {
  __typename?: "Deposit";
  address: Scalars["String"];
  amountEth: Scalars["Float"];
  network: Scalars["String"];
  unsignedTransactionHash: Scalars["String"];
  unsignedTransactionPayload: Scalars["String"];
};

export type DepositMutationPayload = {
  __typename?: "DepositMutationPayload";
  /** Deposit object */
  data?: Maybe<Deposit>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum DepositStatuses {
  Activated = "activated",
  Activating = "activating",
  Deposited = "deposited",
  Failed = "failed",
  Initialized = "initialized",
  Unfinalized = "unfinalized",
}

export type EigenlayerDelegateTo = {
  __typename?: "EigenlayerDelegateTo";
  network: Networks;
  operatorAddress: Scalars["String"];
  ownerAddress: Scalars["String"];
  txSigningPayload?: Maybe<Scalars["String"]>;
  unsignedRawTx?: Maybe<Scalars["String"]>;
};

/** Parameters to build a tx to delegate an Eigenpod to an Operator */
export type EigenlayerDelegateToInput = {
  network: EthereumNetworks;
  operatorAddress: Scalars["String"];
  ownerAddress: Scalars["String"];
};

export type EigenlayerDelegateToMutationPayload = {
  __typename?: "EigenlayerDelegateToMutationPayload";
  /** EigenlayerDelegateTo object */
  data?: Maybe<EigenlayerDelegateTo>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export type EigenlayerOperators = {
  __typename?: "EigenlayerOperators";
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  network: Scalars["String"];
  operatedBy: OperatedBy;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for EigenlayerOperators. */
export type EigenlayerOperatorsConnection = {
  __typename?: "EigenlayerOperatorsConnection";
  /** A list of edges. */
  edges: Array<EigenlayerOperatorsEdge>;
  /** A list of nodes. */
  nodes: Array<EigenlayerOperators>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EigenlayerOperatorsEdge = {
  __typename?: "EigenlayerOperatorsEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: EigenlayerOperators;
};

export type Eigenpod = {
  __typename?: "Eigenpod";
  address: Scalars["String"];
  confirmedDeployedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  createdAt: Scalars["ISO8601DateTime"];
  createpodTxSigningPayload?: Maybe<Scalars["String"]>;
  deployed: Scalars["Boolean"];
  id: Scalars["ID"];
  network: Networks;
  ownerAddress: Scalars["String"];
  unsignedRawCreatepodTx?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Parameters to create an Eigenpod tracking record in our database, and possibly return a tx to create one onchain. */
export type EigenpodCreateInput = {
  network: EthereumNetworks;
  ownerAddress: Scalars["String"];
};

export type EigenpodMutationPayload = {
  __typename?: "EigenpodMutationPayload";
  /** Eigenpod object */
  data?: Maybe<Eigenpod>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export type EigenpodVerification = {
  __typename?: "EigenpodVerification";
  eigenpodAddress: Scalars["String"];
  network: Networks;
  proofs: Scalars["JSON"];
  transactionInputs: Scalars["JSON"];
  transactionPayload: Scalars["String"];
};

/** Parameters to build a tx to validate validators in the Eigenpod */
export type EigenpodVerificationInput = {
  network: EthereumNetworks;
  ownerAddress: Scalars["String"];
};

export type EigenpodVerificationMutationPayload = {
  __typename?: "EigenpodVerificationMutationPayload";
  /** EigenpodVerification object */
  data?: Maybe<EigenpodVerification>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type EthereumAccount = {
  __typename?: "EthereumAccount";
  /** The account address (e.g. 0xabc123) */
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  /** Address of Operator this account's Eigenpod is delegated to */
  delegatedTo?: Maybe<Scalars["String"]>;
  /** Address of the wallet that owns the Eigenpod */
  eigenpodOwnerAddress?: Maybe<Scalars["String"]>;
  /** The same as address, but checksummed or formatted for the specific protocol. */
  formattedAddress: Scalars["String"];
  id: Scalars["ID"];
  /** Whether the account has active delegation (manually managed) */
  isActive: Scalars["Boolean"];
  /** Is this address an Eigenpod? */
  isEigenpod: Scalars["Boolean"];
  /** Is this address a Vault? */
  isVault: Scalars["Boolean"];
  /** A free-form note about the account */
  label?: Maybe<Scalars["String"]>;
  /** The protocol network name (e.g. mainnet) */
  network: Scalars["String"];
  /** The Salesforce Opportunity Token. */
  opToken?: Maybe<OpToken>;
  /** The opportunity token for this account */
  opTokenId?: Maybe<Scalars["String"]>;
  /** Options that are set per account type. This is generic JSON because all account types can have different options. */
  options: Scalars["JSON"];
  /** The protocol name (e.g. Ethereum) */
  protocol: Scalars["String"];
  /** Signals the need to report for a white label validator */
  reportForWhiteLabelValidator: Scalars["Boolean"];
  /** Where this account was first added from */
  source?: Maybe<Scalars["String"]>;
  /** Sum of all staked balance */
  stakedBalance?: Maybe<Scalars["String"]>;
  /** Sum of all staked balance in USD */
  stakedBalanceUsd?: Maybe<Scalars["String"]>;
  /** total number of active validators */
  totalActiveValidators: Scalars["Int"];
  /** The number of validators eligible to exit */
  totalExitableValidators: Scalars["Int"];
  /** Sum of all gross/earned rewards */
  totalRewards?: Maybe<Scalars["String"]>;
  /** Sum of all rewards received */
  totalRewardsReceived?: Maybe<Scalars["String"]>;
  /** Sum of all rewards received in USD */
  totalRewardsReceivedUsd?: Maybe<Scalars["String"]>;
  /** Sum of all gross/earned rewards in USD */
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  /** The number of eigenlayer unverified validators within this pod */
  totalUnverifiedValidators: Scalars["Int"];
  /** The number of validators with a withdrawal address matching this account */
  totalValidators: Scalars["Int"];
  /** The number of eigenlayer verified validators within this pod */
  totalVerifiedValidators: Scalars["Int"];
  /** The type of account */
  type?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  /** Totals by validator status */
  validatorCountsByStatus?: Maybe<ValidatorCountsByStatus>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type EthereumAccountTotalActiveValidatorsArgs = {
  operatedBy?: InputMaybe<OperatedBy>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type EthereumAccountTotalExitableValidatorsArgs = {
  operatedBy?: InputMaybe<OperatedBy>;
};

/** A wallet address or withdrawal account address tracked for rewards and balances. */
export type EthereumAccountTotalValidatorsArgs = {
  operatedBy?: InputMaybe<OperatedBy>;
};

/** Autogenerated return type of EthereumAccountAddMutation. */
export type EthereumAccountAddMutationPayload = {
  __typename?: "EthereumAccountAddMutationPayload";
  data?: Maybe<Account>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** The connection type for EthereumAccount. */
export type EthereumAccountConnection = {
  __typename?: "EthereumAccountConnection";
  /** A list of edges. */
  edges: Array<EthereumAccountEdge>;
  /** A list of nodes. */
  nodes: Array<EthereumAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type EthereumAccountEdge = {
  __typename?: "EthereumAccountEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: EthereumAccount;
};

export type EthereumBalanceSummary = {
  __typename?: "EthereumBalanceSummary";
  balance?: Maybe<Scalars["String"]>;
  balanceUsd?: Maybe<Scalars["String"]>;
};

export type EthereumExitRequest = {
  __typename?: "EthereumExitRequest";
  activity: Scalars["String"];
  activityAt: Scalars["ISO8601DateTime"];
  amountEth: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  estimatedPrincipalReturnAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedWithdrawableAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  network: Networks;
  pubkeys: Array<Scalars["String"]>;
  region?: Maybe<Regions>;
  status: ExitRequestStatuses;
  updatedAt: Scalars["ISO8601DateTime"];
  user?: Maybe<User>;
  validators: Array<EthereumStakingValidator>;
  withdrawalAddress: Scalars["String"];
};

/** Parameters to exit a set of validators. */
export type EthereumExitRequestCreateInput = {
  amountEth: Scalars["Int"];
  network: Networks;
  region?: InputMaybe<Regions>;
  withdrawalAddress: Scalars["String"];
};

export type EthereumExitRequestMutationPayload = {
  __typename?: "EthereumExitRequestMutationPayload";
  /** EthereumExitRequest object */
  data?: Maybe<EthereumExitRequest>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum EthereumNetworks {
  /** Ethereum holesky testnet */
  Holesky = "holesky",
  /** Ethereum mainnet */
  Mainnet = "mainnet",
}

export type EthereumStakingDeposit = {
  __typename?: "EthereumStakingDeposit";
  activity: Scalars["String"];
  activityAt: Scalars["ISO8601DateTime"];
  amountEth: Scalars["String"];
  blockNumber?: Maybe<Scalars["Int"]>;
  blockTime?: Maybe<Scalars["ISO8601DateTime"]>;
  createdAt: Scalars["ISO8601DateTime"];
  estimatedActiveAt?: Maybe<Scalars["ISO8601DateTime"]>;
  fundingAddress?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  network: Networks;
  provisioners?: Maybe<Array<User>>;
  status: DepositStatuses;
  trackingTransaction?: Maybe<TrackingTransaction>;
  txHash: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  validators: Array<EthereumStakingValidator>;
};

export type EthereumStakingRequest = {
  __typename?: "EthereumStakingRequest";
  amountEth: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  feeRecipientAddress?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  maxGasWei: Scalars["String"];
  network: Networks;
  region: Regions;
  unsignedTransactionHashed: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  validators: Array<EthereumStakingValidator>;
  withdrawalAddress: Scalars["String"];
};

/** Parameters to create a set of validators and staking transaction. */
export type EthereumStakingRequestCreateInput = {
  amountEth: Scalars["Int"];
  feeRecipientAddress?: InputMaybe<Scalars["String"]>;
  fundingAddress?: InputMaybe<Scalars["String"]>;
  network: Networks;
  region?: InputMaybe<Regions>;
  withdrawalAddress: Scalars["String"];
};

export type EthereumStakingRequestMutationPayload = {
  __typename?: "EthereumStakingRequestMutationPayload";
  /** EthereumStakingRequest object */
  data?: Maybe<EthereumStakingRequest>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** Parameters to set the transaction hash for a Staking Request. */
export type EthereumStakingRequestSetTxHashInput = {
  amountEth?: InputMaybe<Scalars["Int"]>;
  blockNumber?: InputMaybe<Scalars["Int"]>;
  blockTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  fundingAddress?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  txHash: Scalars["String"];
};

export type EthereumStakingValidator = {
  __typename?: "EthereumStakingValidator";
  amountEth: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  deposit?: Maybe<EthereumStakingDeposit>;
  estimatedActiveAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedExitAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedWithdrawalAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  network: Networks;
  pubkey: Scalars["String"];
  region: Regions;
  status: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  withdrawalAddress: Scalars["String"];
};

export type EthereumTransactionPayload = {
  __typename?: "EthereumTransactionPayload";
  calldata: Scalars["String"];
  gasLimit: Scalars["String"];
  maxFeePerGas: Scalars["String"];
  maxTransactionCost: Scalars["String"];
  signingPayload: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
};

export type EthereumTransactionPayloadMutationPayload = {
  __typename?: "EthereumTransactionPayloadMutationPayload";
  /** EthereumTransactionPayload object */
  data?: Maybe<EthereumTransactionPayload>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum ExitRequestStatuses {
  Completed = "completed",
  ExitRequested = "exit_requested",
  Exiting = "exiting",
  PendingWithdrawal = "pending_withdrawal",
}

export type FigmentValidatorCounts = {
  __typename?: "FigmentValidatorCounts";
  /** Totals by validator status */
  byStatus?: Maybe<ValidatorCountsByStatus>;
  /** Active validators which have already been requested to exit */
  exitPending?: Maybe<Scalars["Int"]>;
  /** Active validators which have not yet been requested to exit */
  exitable?: Maybe<Scalars["Int"]>;
  /** The total number of validators across all statuses */
  total?: Maybe<Scalars["Int"]>;
};

export enum InjectiveNetworks {
  /** Injective mainnet */
  Mainnet = "mainnet",
  /** Injective testnet */
  Testnet = "testnet",
}

export type Invitation = {
  __typename?: "Invitation";
  createdAt: Scalars["ISO8601DateTime"];
  email: Scalars["String"];
  expiresAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  organization: Organization;
  role: Role;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for Invitation. */
export type InvitationConnection = {
  __typename?: "InvitationConnection";
  /** A list of edges. */
  edges: Array<InvitationEdge>;
  /** A list of nodes. */
  nodes: Array<Invitation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** Attributes for creating an invitation */
export type InvitationCreateInput = {
  clientId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  name: Scalars["String"];
  roleId: Scalars["ID"];
};

/** An edge in a connection. */
export type InvitationEdge = {
  __typename?: "InvitationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Invitation;
};

export type InvitationMutationPayload = {
  __typename?: "InvitationMutationPayload";
  /** Invitation object */
  data?: Maybe<Invitation>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum InvitationOrderByFields {
  /** Date the invitation was created */
  CreatedAt = "createdAt",
  /** Date the invitation was updated */
  UpdatedAt = "updatedAt",
}

export type Invitations = {
  field: InvitationOrderByFields;
  sortDirection: SortDirection;
};

/** Attributes for creating a Liquid Collective Claim Transaction */
export type LiquidCollectiveClaimTransactionCreateInput = {
  gasLimit?: InputMaybe<Scalars["Float"]>;
  gasPrice?: InputMaybe<Scalars["Float"]>;
  network: EthereumNetworks;
  redeemRequestId: Scalars["String"];
};

/** Attributes for creating a Liquid Collective Deposit */
export type LiquidCollectiveDepositCreateInput = {
  address: Scalars["String"];
  amountEth: Scalars["Float"];
  network: Scalars["String"];
};

/** Attributes for creating a Liquid Collective Redeem Request */
export type LiquidCollectiveRedeemRequestCreateInput = {
  address: Scalars["String"];
  amountLseth: Scalars["String"];
  gasLimit?: InputMaybe<Scalars["Float"]>;
  gasPrice?: InputMaybe<Scalars["Float"]>;
  network: EthereumNetworks;
};

/** Parameters to delegate SOL. */
export type MaxDelegationEstimateInput = {
  fundingAccountPubkey: Scalars["String"];
  network: SolanaNetworks;
  voteAccountPubkey: Scalars["String"];
};

export type Me = {
  __typename?: "Me";
  id: Scalars["ID"];
  organization: Organization;
  otherMemberships: MemberConnection;
  role: Role;
  user: User;
};

export type MeOtherMembershipsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type Member = {
  __typename?: "Member";
  id: Scalars["ID"];
  organization: Organization;
  role: Role;
  user: User;
};

/** The connection type for Member. */
export type MemberConnection = {
  __typename?: "MemberConnection";
  /** A list of edges. */
  edges: Array<MemberEdge>;
  /** A list of nodes. */
  nodes: Array<Member>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type MemberEdge = {
  __typename?: "MemberEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Member;
};

export type MemberMutationPayload = {
  __typename?: "MemberMutationPayload";
  /** Member object */
  data?: Maybe<Member>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum MemberOrderByFields {
  /** Date the member was created */
  CreatedAt = "createdAt",
  /** The email of the member */
  Email = "email",
  /** The name of the member */
  Name = "name",
  /** Date the member was updated last time */
  UpdatedAt = "updatedAt",
}

export type Members = {
  field: MemberOrderByFields;
  sortDirection: SortDirection;
};

export type MfaTicket = {
  __typename?: "MfaTicket";
  sendMail?: Maybe<Scalars["Boolean"]>;
  ticketUrl?: Maybe<Scalars["String"]>;
};

export type MfaTicketMutationPayload = {
  __typename?: "MfaTicketMutationPayload";
  /** MfaTicket object */
  data?: Maybe<MfaTicket>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export type MonthlyStatement = {
  __typename?: "MonthlyStatement";
  downloadUrl?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  generatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  month?: Maybe<Scalars["ISO8601Date"]>;
  statementType?: Maybe<Statement>;
};

/** The connection type for MonthlyStatement. */
export type MonthlyStatementConnection = {
  __typename?: "MonthlyStatementConnection";
  /** A list of edges. */
  edges: Array<MonthlyStatementEdge>;
  /** A list of nodes. */
  nodes: Array<MonthlyStatement>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type MonthlyStatementEdge = {
  __typename?: "MonthlyStatementEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: MonthlyStatement;
};

export enum MonthlyStatementOrderByFields {
  /** Date the report was created */
  GeneratedAt = "generatedAt",
  /** The month for which the report was generated */
  Month = "month",
  /** The type of monthly statement */
  StatementType = "statementType",
}

export type MonthlyStatements = {
  field: MonthlyStatementOrderByFields;
  sortDirection: SortDirection;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Create api key */
  apiKeyCreate: ApiKeyMutationPayload;
  /** Delete api key */
  apiKeyDelete: ApiKeyMutationPayload;
  /** Update Api Key */
  apiKeyUpdate: ApiKeyMutationPayload;
  /** After a Babylon stake tx is broadcasted, add the tx_hash to the stake record. */
  babylonStakeBroadcasted?: Maybe<StakeBroadcastedMutationPayload>;
  /** Get a tx to stake BTC to Babylon */
  babylonStakeBtc?: Maybe<StakeTransactionCreatePayload>;
  /** Get a tx to stake BTC to Babylon */
  babylonStakeTransactionCreate?: Maybe<StakeTransactionCreatePayload>;
  /** Get a transaction to unbond BTC from Babylon */
  babylonUnbondTransactionCreate?: Maybe<UnbondTransactionCreatePayload>;
  /** Get a transaction to withdraw BTC from Babylon */
  babylonWithdrawalTransactionCreate?: Maybe<WithdrawalTransactionCreatePayload>;
  /** Configure the figment vault */
  configureFigmentVault?: Maybe<ConfigureFigmentVaultPayload>;
  /** Schedule a daily report */
  dailyReportSchedule: DailyReportsMutationPayload;
  /** Create an Eigenpod record and retrieve a createPod transaction if it has not yet been deployed onchain. */
  eigenlayerDelegateTo: EigenlayerDelegateToMutationPayload;
  /** A transaction payload to verify validator withdrawal credentials for an EigenPod */
  eigenlayerVerifyWithdrawalCredentialsTransactionCreate?: Maybe<VerifyWithdrawalCredentialsTransactionCreatePayload>;
  /** Create an Eigenpod record and retrieve a createPod transaction if it has not yet been deployed onchain. */
  eigenpodCreate: EigenpodMutationPayload;
  /** Create an Eigenpod Verification record and retrieve a verifyWithdrawalCredentials transaction. */
  eigenpodVerificationCreate: EigenpodVerificationMutationPayload;
  /** Request to exit Ethereum Validators. */
  ethereumExitRequestCreate: EthereumExitRequestMutationPayload;
  /** Create validators and retrieve a staking transaction. */
  ethereumStakingRequestCreate: EthereumStakingRequestMutationPayload;
  /** Set the transaction hash for a Staking Request. */
  ethereumStakingRequestSetTxHash: EthereumStakingRequestMutationPayload;
  /** Creates a transaction payload to delegate INJ */
  injectiveDelegateTransaction?: Maybe<DelegateTransactionCreatePayload>;
  /** Create Invitation */
  invitationCreate: InvitationMutationPayload;
  /** Delete Invitation */
  invitationDelete: InvitationMutationPayload;
  /** Resend an invitation to a user */
  invitationResend?: Maybe<MutationsAccountsInvitationResendPayload>;
  /** Create liquid collective claim transaction */
  liquidCollectiveClaimTransactionCreate: EthereumTransactionPayloadMutationPayload;
  /** Create liquid collective deposit */
  liquidCollectiveDepositCreate: DepositMutationPayload;
  /** Create liquid collective redeem request */
  liquidCollectiveRedeemRequestCreate: EthereumTransactionPayloadMutationPayload;
  /** Delete Member */
  memberDelete: MemberMutationPayload;
  /** Change Member Role */
  memberRoleChange: MemberMutationPayload;
  /** Update an organization */
  organizationUpdate: OrganizationMutationPayload;
  /** Create project */
  projectCreate: ProjectMutationPayload;
  /** Delete project */
  projectDelete: ProjectMutationPayload;
  /** Update project */
  projectUpdate: ProjectMutationPayload;
  /** Sign up a new user */
  signUpCreate?: Maybe<MutationsAccountsSignUpCreatePayload>;
  /** A transaction payload to deactivate a Stake Account */
  solanaStakingDeactivateTransactionCreate?: Maybe<DeactivateTransactionCreateMutationPayload>;
  /** A transaction payload to create and delegate to a stake account */
  solanaStakingDelegateTransactionCreate?: Maybe<DelegateTransactionCreateMutationPayload>;
  /** Save a transaction hash to the staking activity log. */
  solanaStakingTxSave?: Maybe<TxSaveMutationPayload>;
  /** A transaction payload to withdraw funds from an inactive Stake Account */
  solanaStakingWithdrawTransactionCreate?: Maybe<WithdrawTransactionCreateMutationPayload>;
  /** Tracks an Ethereum funding account */
  trackedEthereumAccountAdd?: Maybe<EthereumAccountAddMutationPayload>;
  /** Creates a url for the user to setup MFA */
  userCreateMfaTicket: MfaTicketMutationPayload;
  /** Resets the users MFA in case they are locked out. */
  userResetMfa: UserMutationPayload;
  /** Sends reset password email */
  userSendResetPasswordEmail: UserMutationPayload;
  /** Update a user's name or email. Also updates the user in Auth0. */
  userUpdate: UserMutationPayload;
  /** Set the region where the validators will be provisioned */
  vaultSetRegion?: Maybe<VaultSetRegionPayload>;
  /** Creates a transaction payload to deploy a Vault */
  vaultTransactionCreate?: Maybe<VaultTransactionCreatePayload>;
};

export type MutationApiKeyCreateArgs = {
  input: ApiKeyCreateInput;
};

export type MutationApiKeyDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationApiKeyUpdateArgs = {
  id: Scalars["ID"];
  input: ApiKeyUpdateInput;
};

export type MutationBabylonStakeBroadcastedArgs = {
  input: BabylonStakeBroadcastedInput;
};

export type MutationBabylonStakeBtcArgs = {
  input: BabylonStakingTxInput;
};

export type MutationBabylonStakeTransactionCreateArgs = {
  input: BabylonStakingTxInput;
};

export type MutationBabylonUnbondTransactionCreateArgs = {
  input: BabylonStakeIdInput;
};

export type MutationBabylonWithdrawalTransactionCreateArgs = {
  input: BabylonWithdrawalInput;
};

export type MutationConfigureFigmentVaultArgs = {
  input: StakeWiseConfigureFigmentVaultInput;
};

export type MutationDailyReportScheduleArgs = {
  input: DailyReportScheduleInput;
};

export type MutationEigenlayerDelegateToArgs = {
  input: EigenlayerDelegateToInput;
};

export type MutationEigenlayerVerifyWithdrawalCredentialsTransactionCreateArgs =
  {
    input: EigenpodVerificationInput;
  };

export type MutationEigenpodCreateArgs = {
  input: EigenpodCreateInput;
};

export type MutationEigenpodVerificationCreateArgs = {
  input: EigenpodVerificationInput;
};

export type MutationEthereumExitRequestCreateArgs = {
  input: EthereumExitRequestCreateInput;
};

export type MutationEthereumStakingRequestCreateArgs = {
  input: EthereumStakingRequestCreateInput;
};

export type MutationEthereumStakingRequestSetTxHashArgs = {
  input: EthereumStakingRequestSetTxHashInput;
};

export type MutationInjectiveDelegateTransactionArgs = {
  input: DelegateTransactionInput;
};

export type MutationInvitationCreateArgs = {
  input: InvitationCreateInput;
};

export type MutationInvitationDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationInvitationResendArgs = {
  input: AccountsInvitationResendInput;
};

export type MutationLiquidCollectiveClaimTransactionCreateArgs = {
  input: LiquidCollectiveClaimTransactionCreateInput;
};

export type MutationLiquidCollectiveDepositCreateArgs = {
  input: LiquidCollectiveDepositCreateInput;
};

export type MutationLiquidCollectiveRedeemRequestCreateArgs = {
  input: LiquidCollectiveRedeemRequestCreateInput;
};

export type MutationMemberDeleteArgs = {
  organizationId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationMemberRoleChangeArgs = {
  organizationId: Scalars["ID"];
  roleId: Scalars["ID"];
  userId: Scalars["ID"];
};

export type MutationOrganizationUpdateArgs = {
  input: OrganizationUpdateInput;
};

export type MutationProjectCreateArgs = {
  input: ProjectCreateInput;
};

export type MutationProjectDeleteArgs = {
  id: Scalars["ID"];
};

export type MutationProjectUpdateArgs = {
  id: Scalars["ID"];
  input: ProjectUpdateInput;
};

export type MutationSignUpCreateArgs = {
  input: AccountsSignUpInput;
};

export type MutationSolanaStakingDeactivateTransactionCreateArgs = {
  input: SolanaStakingDeactivateTransactionCreateInput;
};

export type MutationSolanaStakingDelegateTransactionCreateArgs = {
  input: SolanaStakingDelegateTransactionCreateInput;
};

export type MutationSolanaStakingTxSaveArgs = {
  input: SolanaStakingTxSaveInput;
};

export type MutationSolanaStakingWithdrawTransactionCreateArgs = {
  input: SolanaStakingWithdrawTransactionCreateInput;
};

export type MutationTrackedEthereumAccountAddArgs = {
  input: TrackedAccountAddInput;
};

export type MutationUserCreateMfaTicketArgs = {
  sendMail: Scalars["Boolean"];
};

export type MutationUserResetMfaArgs = {
  userId: Scalars["ID"];
};

export type MutationUserSendResetPasswordEmailArgs = {
  userId: Scalars["ID"];
};

export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
  userId?: InputMaybe<Scalars["ID"]>;
};

export type MutationVaultSetRegionArgs = {
  input: StakeWiseVaultSetRegionInput;
};

export type MutationVaultTransactionCreateArgs = {
  input: StakeWiseVaultTransactionCreateInput;
};

export type MutationError = {
  __typename?: "MutationError";
  code: Scalars["String"];
  extras?: Maybe<Scalars["JSON"]>;
  field?: Maybe<Scalars["String"]>;
  message: Scalars["String"];
  /** Which input value this error came from */
  path?: Maybe<Array<Scalars["String"]>>;
};

/** Autogenerated return type of MutationsAccountsInvitationResend. */
export type MutationsAccountsInvitationResendPayload = {
  __typename?: "MutationsAccountsInvitationResendPayload";
  data?: Maybe<AccountsInvitation>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of MutationsAccountsSignUpCreate. */
export type MutationsAccountsSignUpCreatePayload = {
  __typename?: "MutationsAccountsSignUpCreatePayload";
  data?: Maybe<AccountsSignUp>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type NetworkEstimate = {
  __typename?: "NetworkEstimate";
  hours?: Maybe<Scalars["Int"]>;
  hoursMax?: Maybe<Scalars["Int"]>;
  hoursMin?: Maybe<Scalars["Int"]>;
};

export type NetworkEstimates = {
  __typename?: "NetworkEstimates";
  estimatedActivation?: Maybe<NetworkEstimate>;
  estimatedExit?: Maybe<NetworkEstimate>;
  estimatedWithdrawal?: Maybe<NetworkEstimate>;
};

export type NetworkOverview = {
  __typename?: "NetworkOverview";
  estimatedActivation?: Maybe<NetworkEstimate>;
  estimatedExit?: Maybe<NetworkEstimate>;
  estimatedWithdrawal?: Maybe<NetworkEstimate>;
  price?: Maybe<Price>;
  rewardsRate?: Maybe<RewardsRate>;
};

export enum Networks {
  /** Ethereum goerli testnet */
  Goerli = "goerli",
  /** Ethereum holesky testnet */
  Holesky = "holesky",
  /** Ethereum mainnet */
  Mainnet = "mainnet",
  /** Ethereum prater testnet */
  Prater = "prater",
}

export type OnDemandExit = {
  __typename?: "OnDemandExit";
  /** On demand exit approved at */
  approvedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** On demand exit request at */
  requestedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** On demand exit submitted at */
  submittedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** A Salesforce opportunity token */
export type OpToken = {
  __typename?: "OpToken";
  /** The Salesforce ID for the op token */
  id: Scalars["String"];
  /** The name displayed for the token in Salesforce */
  name: Scalars["String"];
  /** The full URL to the Op Token page in Salesforce */
  url: Scalars["String"];
};

export enum OperatedBy {
  Figment = "figment",
  Other = "other",
}

export enum Operations {
  /** Ethereum batch staking */
  AggregatedStaking = "aggregated_staking",
  /** Ethereum batch unstaking */
  AggregatedUnstaking = "aggregated_unstaking",
  /** Staking operations */
  Staking = "staking",
  /** Unstaking operations */
  Unstaking = "unstaking",
}

export type Organization = {
  __typename?: "Organization";
  address?: Maybe<Address>;
  createdAt: Scalars["ISO8601DateTime"];
  devmodeEnabled: Scalars["Boolean"];
  displayName: Scalars["String"];
  id: Scalars["ID"];
  isIndividual: Scalars["Boolean"];
  members?: Maybe<MemberConnection>;
  name: Scalars["String"];
  riskAssessmentPassed?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  usesOnchainBilling: Scalars["Boolean"];
};

export type OrganizationMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type OrganizationMutationPayload = {
  __typename?: "OrganizationMutationPayload";
  /** Organization object */
  data?: Maybe<Organization>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** Attributes for updating an organization */
export type OrganizationUpdateInput = {
  address?: InputMaybe<AddressInput>;
  displayName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type Price = {
  __typename?: "Price";
  date?: Maybe<Scalars["ISO8601DateTime"]>;
  value?: Maybe<Scalars["Float"]>;
};

export type Project = {
  __typename?: "Project";
  apiKeys: ApiKeyConnection;
  createdAt: Scalars["ISO8601DateTime"];
  description?: Maybe<Scalars["String"]>;
  /** One of production, test */
  environment?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  organization: Organization;
  readOnly?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

export type ProjectApiKeysArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ApiKeys>>;
};

/** The connection type for Project. */
export type ProjectConnection = {
  __typename?: "ProjectConnection";
  /** A list of edges. */
  edges: Array<ProjectEdge>;
  /** A list of nodes. */
  nodes: Array<Project>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** Attributes for creating a project */
export type ProjectCreateInput = {
  description?: InputMaybe<Scalars["String"]>;
  environment?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  readOnly?: InputMaybe<Scalars["Boolean"]>;
};

/** An edge in a connection. */
export type ProjectEdge = {
  __typename?: "ProjectEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Project;
};

export type ProjectMutationPayload = {
  __typename?: "ProjectMutationPayload";
  /** Project object */
  data?: Maybe<Project>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

export enum ProjectOrderByFields {
  /** Date the Project was created */
  CreatedAt = "createdAt",
  /** The description of the Project */
  Description = "description",
  /** The name of the Project */
  Name = "name",
  /** Date the Project was updated last time */
  UpdatedAt = "updatedAt",
}

/** Attributes for updating a project */
export type ProjectUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  environment?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type Projects = {
  field: ProjectOrderByFields;
  sortDirection: SortDirection;
};

export type Query = {
  __typename?: "Query";
  /** Aggregated rewards by periods */
  aggregatedRewards: AggregatedRewards;
  /** Babylon Network Overview */
  babylonNetworkOverview: BabylonNetworkOverview;
  /** Babylon Stakes */
  babylonStakes: BabylonStakeConnection;
  /** Vault commission info */
  commission?: Maybe<StakeWiseCommission>;
  /** All daily reports for an organization */
  dailyReports: DailyReportsConnection;
  /** Eigenlayer Operators */
  eigenlayerOperators?: Maybe<EigenlayerOperatorsConnection>;
  /** ETH balances aggregated across all tracked addresses for the org */
  ethereumBalanceSummary: EthereumBalanceSummary;
  /** Figment-calculated Ethereum network estimates */
  ethereumNetworkEstimates: NetworkEstimates;
  /** The latest Ethereum price */
  ethereumPrice: Price;
  /** Ethereum network rewards rate */
  ethereumRewardsRate: RewardsRate;
  /** Withdrawal addresses and other network wallet addresses tracked for rewards and balances. Protocol is always Ethereum. */
  ethereumWithdrawalAccount?: Maybe<EthereumAccount>;
  /** Withdrawal addresses and other network wallet addresses tracked for rewards and balances. Protocol is always Ethereum. */
  ethereumWithdrawalAccounts: EthereumAccountConnection;
  /** Number of Figment-operated validators for an organization (by status) */
  figmentValidatorCounts: FigmentValidatorCounts;
  /** All invitations for organization */
  invitations: InvitationConnection;
  /** The projected time for the Redeem Request to be fulfilled */
  liquidCollectiveRedeemManagerProjection: RedeemManagerProjection;
  /** A list of Liquid Collective Redeem Requests for a wallet address */
  liquidCollectiveRedeemRequests: Array<RedeemRequest>;
  me: Me;
  /** Organization members */
  members: MemberConnection;
  /** Monthly statements for all protocols supported for the org */
  monthlyStatements: MonthlyStatementConnection;
  /**
   * Figment-calculated Ethereum network estimates
   * @deprecated Use ethereumRewardsRate, ethereumNetworkEstimates, and ethereumPrice instead
   */
  networkOverview: NetworkOverview;
  /** Current organization */
  organization: Organization;
  /** All projects for the users organization. */
  projects: ProjectConnection;
  /** Protocol price */
  protocolPrice: Price;
  /** Balance and rewards */
  rewardsSummary: RewardsSummary;
  /** All roles used for access control. */
  roles: RoleConnection;
  /** Aggregated SOL rewards by periods */
  solanaAggregatedRewards: AggregatedRewards;
  /** SOL balances aggregated across all tracked addresses for the org */
  solanaBalanceSummary: SolanaBalanceSummary;
  /** Estimated time to activate stake (next epoch). */
  solanaEstimatedActivationTime: StakingTimeEstimate;
  /** Estimated time to deactivate stake (next epoch). */
  solanaEstimatedDeactivationTime: StakingTimeEstimate;
  /** Figment's public validator commission rate. */
  solanaFigmentPublicCommissionRate: CommssionRate;
  /** SOL price */
  solanaPrice: Price;
  /** Solana network rewards rate */
  solanaRewardsRate: RewardsRate;
  /** SOL total rewards for organization */
  solanaRewardsSummary: SolanaRewardsSummary;
  /** Solana stake accounts */
  solanaStakeAccounts: StakeAccountConnection;
  /** Details about the max value that can be delegated in a delegation transaction. */
  solanaStakingMaxDelegationEstimate: SolanaStakingMaxDelegationEstimate;
  /** Staking API activity */
  stakingActivity: StakingActivityConnection;
  /**
   * Staking API activity (Slate Flows)
   * @deprecated This is old Slate flows. Use stakingActivity (ETH) or stakingActivityLogs instead.
   */
  stakingActivityFlows: StakingActivityFlowConnection;
  /** Staking activity logs */
  stakingActivityLogs: StakingActivityLogConnection;
  /** Withdrawal addresses and other network wallet addresses tracked for rewards and balances. */
  trackedAccounts: AccountConnection;
  /** Validators tracked for rewards and balances. */
  trackedValidators: ValidatorConnection;
  /** Tracking Transactions */
  trackingTransaction?: Maybe<TrackingTransactionConnection>;
  /** Number of validators for an organization (by status) */
  validatorCounts: ValidatorCounts;
  /** Vault info */
  vault?: Maybe<StakeWiseVault>;
  /** Vaults by organization */
  vaults?: Maybe<StakeWiseVaultConnection>;
};

export type QueryAggregatedRewardsArgs = {
  ethAccount?: InputMaybe<Scalars["String"]>;
  network?: InputMaybe<Scalars["String"]>;
  timePeriod?: InputMaybe<TimePeriod>;
};

export type QueryBabylonNetworkOverviewArgs = {
  network: BitcoinNetworks;
};

export type QueryBabylonStakesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network: BitcoinNetworks;
  state?: InputMaybe<BabylonStates>;
};

export type QueryDailyReportsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QueryEigenlayerOperatorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  operatedBy?: InputMaybe<Array<OperatedBy>>;
};

export type QueryEthereumBalanceSummaryArgs = {
  network?: EthereumNetworks;
};

export type QueryEthereumWithdrawalAccountArgs = {
  address: Scalars["String"];
  network: EthereumNetworks;
};

export type QueryEthereumWithdrawalAccountsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AccountsFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Accounts>>;
};

export type QueryFigmentValidatorCountsArgs = {
  ethAccount?: InputMaybe<Scalars["String"]>;
  network?: InputMaybe<Scalars["String"]>;
};

export type QueryInvitationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  excludeInternalMembers?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Invitations>>;
};

export type QueryLiquidCollectiveRedeemManagerProjectionArgs = {
  network?: EthereumNetworks;
};

export type QueryLiquidCollectiveRedeemRequestsArgs = {
  address: Scalars["String"];
  network?: EthereumNetworks;
};

export type QueryMembersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  excludeInternalMembers?: InputMaybe<Scalars["Boolean"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Members>>;
};

export type QueryMonthlyStatementsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MonthlyStatements>>;
};

export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Projects>>;
};

export type QueryProtocolPriceArgs = {
  ticker: CurrencyTickers;
};

export type QueryRewardsSummaryArgs = {
  network?: InputMaybe<Scalars["String"]>;
};

export type QueryRolesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
};

export type QuerySolanaAggregatedRewardsArgs = {
  network?: InputMaybe<Scalars["String"]>;
  timePeriod: TimePeriod;
};

export type QuerySolanaBalanceSummaryArgs = {
  network?: InputMaybe<SolanaNetworks>;
};

export type QuerySolanaEstimatedActivationTimeArgs = {
  network?: SolanaNetworks;
};

export type QuerySolanaEstimatedDeactivationTimeArgs = {
  network?: SolanaNetworks;
};

export type QuerySolanaRewardsSummaryArgs = {
  network?: InputMaybe<SolanaNetworks>;
};

export type QuerySolanaStakeAccountsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network?: SolanaNetworks;
  orderBy?: InputMaybe<Array<SolanaAccounts>>;
};

export type QuerySolanaStakingMaxDelegationEstimateArgs = {
  input: MaxDelegationEstimateInput;
};

export type QueryStakingActivityArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  fundingAddress?: InputMaybe<Scalars["String"]>;
  last?: InputMaybe<Scalars["Int"]>;
  minDepositStatus?: InputMaybe<DepositStatuses>;
  minExitRequestStatus?: InputMaybe<ExitRequestStatuses>;
  network?: InputMaybe<Networks>;
  operation?: InputMaybe<Array<ActivityTypes>>;
  page?: InputMaybe<Scalars["Int"]>;
  protocol?: InputMaybe<Scalars["String"]>;
  withdrawalAddress?: InputMaybe<Scalars["String"]>;
};

export type QueryStakingActivityFlowsArgs = {
  network?: InputMaybe<Networks>;
  operation?: InputMaybe<Array<Operations>>;
  page?: InputMaybe<Scalars["Int"]>;
  protocol?: InputMaybe<Scalars["String"]>;
  states?: InputMaybe<Array<States>>;
};

export type QueryStakingActivityLogsArgs = {
  activityType?: InputMaybe<StakingActivityActivityTypes>;
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network?: InputMaybe<Scalars["String"]>;
  protocol: StakingActivityProtocols;
  status?: InputMaybe<StakingActivityStatuses>;
};

export type QueryTrackedAccountsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<AccountsFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Accounts>>;
};

export type QueryTrackedValidatorsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  filter?: InputMaybe<ValidatorsFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Validators>>;
};

export type QueryTrackingTransactionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network: Networks;
  protocol: Scalars["String"];
  status?: InputMaybe<TransactionStatuses>;
  txHash?: InputMaybe<Scalars["String"]>;
};

export type QueryValidatorCountsArgs = {
  ethAccount?: InputMaybe<Scalars["String"]>;
  network?: InputMaybe<Scalars["String"]>;
  operatedBy?: InputMaybe<Array<OperatedBy>>;
  provisionedByMyOrganization?: InputMaybe<Scalars["Boolean"]>;
};

export type QueryVaultArgs = {
  filter: VaultsFilter;
};

export type QueryVaultsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  network: EthereumNetworks;
};

export type RedeemManagerProjection = {
  __typename?: "RedeemManagerProjection";
  projectedFulfilledAt: Scalars["String"];
};

export type RedeemRequest = {
  __typename?: "RedeemRequest";
  claimableAmountLseth: Scalars["String"];
  claimedAmountLseth: Scalars["String"];
  height: Scalars["String"];
  id: Scalars["String"];
  maxRedeemableAmountEth: Scalars["String"];
  network: Scalars["String"];
  owner: Scalars["String"];
  projectedRedeemableAt?: Maybe<Scalars["String"]>;
  requestedAt: Scalars["Float"];
  requestedAtTimestamp: Scalars["String"];
  statusClaim: Scalars["String"];
  statusSatisfaction: Scalars["String"];
  totalAmountLseth: Scalars["String"];
  withdrawalEventId: Scalars["Float"];
};

export enum Regions {
  /** Canada Central 1 */
  CaCentral_1 = "ca_central_1",
  /** Europe West 1 */
  EuWest_1 = "eu_west_1",
}

export type RewardsRate = {
  __typename?: "RewardsRate";
  date?: Maybe<Scalars["ISO8601Date"]>;
  staking?: Maybe<Scalars["Float"]>;
  tips?: Maybe<Scalars["Float"]>;
  value?: Maybe<Scalars["Float"]>;
};

/** ETH rewards summary for an organization */
export type RewardsSummary = {
  __typename?: "RewardsSummary";
  balance?: Maybe<Scalars["String"]>;
  balanceUsd?: Maybe<Scalars["String"]>;
  consensusEarned?: Maybe<Scalars["String"]>;
  consensusEarnedUsd?: Maybe<Scalars["String"]>;
  executionEarned?: Maybe<Scalars["String"]>;
  executionEarnedUsd?: Maybe<Scalars["String"]>;
  executionReceived?: Maybe<Scalars["String"]>;
  executionReceivedUsd?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use consensusRewards instead */
  stakingRewards?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use consensusRewardsUsd instead */
  stakingRewardsUsd?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use executionRewards instead */
  tipsRewards?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use executionRewardsUsd instead */
  tipsRewardsUsd?: Maybe<Scalars["String"]>;
  totalEarned?: Maybe<Scalars["String"]>;
  totalEarnedUsd?: Maybe<Scalars["String"]>;
  totalReceived?: Maybe<Scalars["String"]>;
  totalReceivedUsd?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use totalReceived instead */
  totalRewards?: Maybe<Scalars["String"]>;
  /** DEPRECATED: use totalReceivedUsd instead */
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

export type Role = {
  __typename?: "Role";
  createdAt: Scalars["ISO8601DateTime"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for Role. */
export type RoleConnection = {
  __typename?: "RoleConnection";
  /** A list of edges. */
  edges: Array<RoleEdge>;
  /** A list of nodes. */
  nodes: Array<Role>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type RoleEdge = {
  __typename?: "RoleEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Role;
};

export enum SolanaAccountOrderByFields {
  /** Balance of the account in Lamports */
  ActiveBalanceLamports = "activeBalanceLamports",
  /** Date the Account was created */
  CreatedAt = "createdAt",
  /** Status of the account */
  Status = "status",
}

export type SolanaAccounts = {
  field: SolanaAccountOrderByFields;
  sortDirection: SortDirection;
};

export type SolanaBalanceSummary = {
  __typename?: "SolanaBalanceSummary";
  balance?: Maybe<Scalars["String"]>;
  balanceUsd?: Maybe<Scalars["String"]>;
};

export enum SolanaNetworks {
  /** Solana devnet */
  Devnet = "devnet",
  /** Solana mainnet */
  Mainnet = "mainnet",
  /** Solana testnet */
  Testnet = "testnet",
}

export type SolanaRewardsSummary = {
  __typename?: "SolanaRewardsSummary";
  balance?: Maybe<Scalars["String"]>;
  balanceUsd?: Maybe<Scalars["String"]>;
  mevRewards?: Maybe<Scalars["String"]>;
  mevRewardsUsd?: Maybe<Scalars["String"]>;
  protocolRewards?: Maybe<Scalars["String"]>;
  protocolRewardsUsd?: Maybe<Scalars["String"]>;
  totalRewards?: Maybe<Scalars["String"]>;
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

/** Parameters to generate a SOL deactivate transaction. */
export type SolanaStakingDeactivateTransactionCreateInput = {
  network: SolanaNetworks;
  stakeAccountPubkey: Scalars["String"];
};

/** Parameters to generate a SOL delegate transaction. */
export type SolanaStakingDelegateTransactionCreateInput = {
  amountSol: Scalars["Float"];
  fundingAccountPubkey: Scalars["String"];
  network: SolanaNetworks;
  voteAccountPubkey: Scalars["String"];
};

export type SolanaStakingMaxDelegationEstimate = {
  __typename?: "SolanaStakingMaxDelegationEstimate";
  /** in lamports */
  accountBalance: Scalars["String"];
  /** in lamports */
  estimatedFee: Scalars["String"];
  /** in lamports */
  maxDelegation: Scalars["String"];
  /** in lamports */
  minBalanceForRentExemption: Scalars["String"];
};

export type SolanaStakingTransactionPayload = {
  __typename?: "SolanaStakingTransactionPayload";
  lastValidBlockHeight: Scalars["Int"];
  network: SolanaNetworks;
  signingPayload: Scalars["String"];
  stakeAccountPubkey: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
};

/** Save a transaction hash for a Solana staking activity */
export type SolanaStakingTxSaveInput = {
  activityType: StakingActivityActivityTypes;
  amountSol: Scalars["Float"];
  network: SolanaNetworks;
  stakeAccountPubkey: Scalars["String"];
  txHash: Scalars["String"];
};

/** Parameters to generate a SOL withdraw transaction. */
export type SolanaStakingWithdrawTransactionCreateInput = {
  amountSol?: InputMaybe<Scalars["Float"]>;
  network: SolanaNetworks;
  recipientPubkey: Scalars["String"];
  stakeAccountPubkey: Scalars["String"];
};

/** Column ordering options */
export enum SortDirection {
  /** in the ascending order */
  Asc = "asc",
  /** in the descending order */
  Desc = "desc",
}

/** A tracked Solana stake account. */
export type StakeAccount = {
  __typename?: "StakeAccount";
  /** Total balance that is actively delegated to the validator in SOL */
  activeBalance?: Maybe<Scalars["String"]>;
  /** Total balance that is actively delegated to the validator in USD */
  activeBalanceUsd?: Maybe<Scalars["String"]>;
  /** The account address (e.g. BF2z...) */
  address: Scalars["String"];
  /** Total stake account balance in SOL (active, inactive, and any additional) */
  balance?: Maybe<Scalars["String"]>;
  /** Total stake account balance in USD (active, inactive, and any additional) */
  balanceUsd?: Maybe<Scalars["String"]>;
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** Total balance that is not delegated to the validator (activating / deactivating) in SOL */
  inactiveBalance?: Maybe<Scalars["String"]>;
  /** Total balance that is not delegated to the validator in USD */
  inactiveBalanceUsd?: Maybe<Scalars["String"]>;
  /** Whether the account has active delegation (manually managed) */
  isActive: Scalars["Boolean"];
  /** A free-form note about the account */
  label?: Maybe<Scalars["String"]>;
  /** The protocol network name (e.g. mainnet) */
  network: Scalars["String"];
  /** Stake authority for the stake account */
  stakeAuthorityAddress?: Maybe<Scalars["String"]>;
  /** The current status of the stake account */
  status?: Maybe<StakeAccountStatus>;
  /** Total rewards earned for the stake account */
  totalRewards?: Maybe<Scalars["String"]>;
  /** Total rewards for the stake account in USD */
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  /** The user that created the stake account (only applies when staking through Fig app) */
  user?: Maybe<User>;
  /** Withdraw authority for the stake account */
  withdrawAuthorityAddress?: Maybe<Scalars["String"]>;
};

/** The connection type for StakeAccount. */
export type StakeAccountConnection = {
  __typename?: "StakeAccountConnection";
  /** A list of edges. */
  edges: Array<StakeAccountEdge>;
  /** A list of nodes. */
  nodes: Array<StakeAccount>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakeAccountEdge = {
  __typename?: "StakeAccountEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakeAccount;
};

export enum StakeAccountStatus {
  Activating = "activating",
  Active = "active",
  Deactivating = "deactivating",
  Inactive = "inactive",
  Withdrawn = "withdrawn",
}

/** Autogenerated return type of StakeBroadcastedMutation. */
export type StakeBroadcastedMutationPayload = {
  __typename?: "StakeBroadcastedMutationPayload";
  data?: Maybe<BabylonStake>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of StakeTransactionCreate. */
export type StakeTransactionCreatePayload = {
  __typename?: "StakeTransactionCreatePayload";
  data?: Maybe<BabylonTx>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Vault commission information */
export type StakeWiseCommission = {
  __typename?: "StakeWiseCommission";
  /** The shares of the figment */
  figmentShares?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** The organization configuration id of the shareholder */
  organizationConfigurationId?: Maybe<Scalars["String"]>;
  /** The fee recipient address of the shareholder */
  shareholderFeeRecipientAddress?: Maybe<Scalars["String"]>;
  /** The shares of the shareholder */
  shareholderShares?: Maybe<Scalars["String"]>;
};

/** Parameters to configure the figment vault */
export type StakeWiseConfigureFigmentVaultInput = {
  address: Scalars["String"];
  network: EthereumNetworks;
};

/** Vault reward splitter information */
export type StakeWiseRewardSplitter = {
  __typename?: "StakeWiseRewardSplitter";
  /** The address of the vault admin */
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** The network the vault is on */
  network: EthereumNetworks;
  /** The organization the vault admin is for */
  organization: Organization;
  /** The address of the owner */
  ownerAddress: Scalars["String"];
  updatedAt: Scalars["ISO8601DateTime"];
  /** The id of the vault */
  vaultId: Scalars["String"];
};

/** Vault information */
export type StakeWiseVault = {
  __typename?: "StakeWiseVault";
  /** The address of the vault */
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  /** The fee in basis points */
  feeBps: Scalars["Int"];
  /** The address of the fee recipient */
  feeRecipientAddress: Scalars["String"];
  id: Scalars["ID"];
  /** The network the vault is on */
  network: EthereumNetworks;
  /** The region of the validators will be provisioned in */
  region?: Maybe<Scalars["String"]>;
  /** The reward splitter for the vault */
  rewardSplitter?: Maybe<StakeWiseRewardSplitter>;
  /** The status of the vault */
  status: StakeWiseVaultStatus;
  /** The transaction hash of the vault */
  transactionHash?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  /** The address of the validators manager contract */
  validatorsManagerAddress?: Maybe<Scalars["String"]>;
  /** The vault admin for the vault */
  vaultAdmin?: Maybe<StakeWiseVaultAdmin>;
};

/** Vault admin information */
export type StakeWiseVaultAdmin = {
  __typename?: "StakeWiseVaultAdmin";
  /** The address of the vault admin */
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** The network the vault is on */
  network: EthereumNetworks;
  /** The organization the vault admin is for */
  organization: Organization;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for StakeWiseVault. */
export type StakeWiseVaultConnection = {
  __typename?: "StakeWiseVaultConnection";
  /** A list of edges. */
  edges: Array<StakeWiseVaultEdge>;
  /** A list of nodes. */
  nodes: Array<StakeWiseVault>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakeWiseVaultEdge = {
  __typename?: "StakeWiseVaultEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakeWiseVault;
};

/** Parameters to set region to the vault */
export type StakeWiseVaultSetRegionInput = {
  address: Scalars["String"];
  network: EthereumNetworks;
  region?: InputMaybe<Regions>;
};

export enum StakeWiseVaultStatus {
  /** Vault ready for provision */
  Active = "active",
  /** Vault not ready for provision */
  Inactive = "inactive",
}

/** Parameters to generate a unsigned vault create transaction. */
export type StakeWiseVaultTransactionCreateInput = {
  adminAddress: Scalars["String"];
  network: EthereumNetworks;
};

export type StakingActivity = EthereumExitRequest | EthereumStakingDeposit;

export enum StakingActivityActivityTypes {
  Delegation = "delegation",
  Stake = "stake",
  Unbond = "unbond",
  Undelegation = "undelegation",
  Withdrawal = "withdrawal",
}

/** The connection type for StakingActivity. */
export type StakingActivityConnection = {
  __typename?: "StakingActivityConnection";
  /** A list of edges. */
  edges: Array<StakingActivityEdge>;
  /** A list of nodes. */
  nodes: Array<StakingActivity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakingActivityEdge = {
  __typename?: "StakingActivityEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakingActivity;
};

export type StakingActivityFlow = {
  __typename?: "StakingActivityFlow";
  aggregatedStakingContractAddress?: Maybe<Scalars["String"]>;
  amount?: Maybe<Scalars["Float"]>;
  createdAt: Scalars["ISO8601DateTime"];
  estimatedActiveAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedExitAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedPrincipalReturnAt?: Maybe<Scalars["ISO8601DateTime"]>;
  estimatedWithdrawalAt?: Maybe<Scalars["ISO8601DateTime"]>;
  feeRecipientAddress?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  network?: Maybe<Networks>;
  operation?: Maybe<Operations>;
  region?: Maybe<Regions>;
  state?: Maybe<States>;
  updatedAt: Scalars["ISO8601DateTime"];
  withdrawalAddress?: Maybe<Scalars["String"]>;
};

/** The connection type for StakingActivityFlow. */
export type StakingActivityFlowConnection = {
  __typename?: "StakingActivityFlowConnection";
  /** A list of edges. */
  edges: Array<StakingActivityFlowEdge>;
  /** A list of nodes. */
  nodes: Array<StakingActivityFlow>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakingActivityFlowEdge = {
  __typename?: "StakingActivityFlowEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakingActivityFlow;
};

export type StakingActivityLog = {
  __typename?: "StakingActivityLog";
  activityAt: Scalars["ISO8601DateTime"];
  activityType: StakingActivityActivityTypes;
  amount: Scalars["String"];
  /** The currency for the amount (e.g. SOL or ETH) */
  amountCurrency: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  estimatedCompletedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  network: Scalars["String"];
  protocol: StakingActivityProtocols;
  source?: Maybe<Scalars["String"]>;
  status: StakingActivityStatuses;
  txHash?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  user?: Maybe<User>;
};

/** The connection type for StakingActivityLog. */
export type StakingActivityLogConnection = {
  __typename?: "StakingActivityLogConnection";
  /** A list of edges. */
  edges: Array<StakingActivityLogEdge>;
  /** A list of nodes. */
  nodes: Array<StakingActivityLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type StakingActivityLogEdge = {
  __typename?: "StakingActivityLogEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: StakingActivityLog;
};

export enum StakingActivityProtocols {
  Babylon = "babylon",
  Solana = "solana",
}

export enum StakingActivityStatuses {
  Complete = "complete",
  Failed = "failed",
  Pending = "pending",
}

export type StakingTimeEstimate = {
  __typename?: "StakingTimeEstimate";
  /** The date/time of the estimate. */
  date?: Maybe<Scalars["ISO8601DateTime"]>;
  /** The number of seconds until the date occurs. */
  numSeconds?: Maybe<Scalars["Int"]>;
};

export enum Statement {
  /** Revenue Share Report */
  RevenueShare = "revenue_share",
  /** Staking Rewards Report */
  StakingRewards = "staking_rewards",
  /** White Label Validator Report */
  WhiteLabelValidator = "white_label_validator",
}

export enum States {
  Activating = "activating",
  Active = "active",
  AggregatedDepositTxSignature = "aggregated_deposit_tx_signature",
  AwaitingOcbDeployment = "awaiting_ocb_deployment",
  AwaitingProvision = "awaiting_provision",
  Broadcasting = "broadcasting",
  Deposited = "deposited",
  Exiting = "exiting",
  Failed = "failed",
  Initialized = "initialized",
  Unstaked = "unstaked",
  Withdrawing = "withdrawing",
}

/** timestamps of when validator transitioned into a given status */
export type StatusHistory = {
  __typename?: "StatusHistory";
  activeExitingAt?: Maybe<Scalars["ISO8601DateTime"]>;
  activeOngoingAt?: Maybe<Scalars["ISO8601DateTime"]>;
  activeSlashedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  depositedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  depositedNotFinalizedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  exitedSlashedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  exitedUnslashedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  fundingRequestedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  pendingInitializedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  pendingQueuedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  provisionedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  withdrawalDoneAt?: Maybe<Scalars["ISO8601DateTime"]>;
  withdrawalPossibleAt?: Maybe<Scalars["ISO8601DateTime"]>;
};

export enum TimePeriod {
  /** All time */
  AllTime = "all_time",
  /** One month */
  OneMonth = "one_month",
  /** Year */
  OneYear = "one_year",
  /** Six months */
  SixMonths = "six_months",
}

/** Input attributes for tracking an account */
export type TrackedAccountAddInput = {
  address: Scalars["String"];
  network: Scalars["String"];
};

export type TrackingTransaction = {
  __typename?: "TrackingTransaction";
  actionId: Scalars["String"];
  actionType: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  error?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  network: Scalars["String"];
  organizationId: Scalars["String"];
  protocol: Scalars["String"];
  signedPayload?: Maybe<Scalars["String"]>;
  signingPayload?: Maybe<Scalars["String"]>;
  status?: Maybe<TransactionStatuses>;
  transactionableId: Scalars["String"];
  transactionableType: Scalars["String"];
  txHash: Scalars["String"];
  unsignedRawTx?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

/** The connection type for TrackingTransaction. */
export type TrackingTransactionConnection = {
  __typename?: "TrackingTransactionConnection";
  /** A list of edges. */
  edges: Array<TrackingTransactionEdge>;
  /** A list of nodes. */
  nodes: Array<TrackingTransaction>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type TrackingTransactionEdge = {
  __typename?: "TrackingTransactionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: TrackingTransaction;
};

export enum TransactionStatuses {
  Confirmed = "confirmed",
  Expired = "expired",
  Failed = "failed",
  InProgress = "in_progress",
  NotFound = "not_found",
}

/** Autogenerated return type of TxSaveMutation. */
export type TxSaveMutationPayload = {
  __typename?: "TxSaveMutationPayload";
  data?: Maybe<StakingActivityLog>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of UnbondTransactionCreate. */
export type UnbondTransactionCreatePayload = {
  __typename?: "UnbondTransactionCreatePayload";
  data?: Maybe<BabylonTx>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type UnsignedTransactionPayload = {
  __typename?: "UnsignedTransactionPayload";
  network: Scalars["String"];
  signingPayload: Scalars["String"];
  unsignedTransactionSerialized: Scalars["String"];
};

export type User = {
  __typename?: "User";
  acceptedTosAt?: Maybe<Scalars["ISO8601DateTime"]>;
  auth0Id: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  email: Scalars["String"];
  id: Scalars["ID"];
  mfaMethods?: Maybe<Array<Scalars["String"]>>;
  name: Scalars["String"];
  phoneNumber?: Maybe<Scalars["String"]>;
  riskAssessmentPassed?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["ISO8601DateTime"];
};

export type UserMutationPayload = {
  __typename?: "UserMutationPayload";
  /** User object */
  data?: Maybe<User>;
  /** Mutation errors */
  userErrors?: Maybe<Array<MutationError>>;
};

/** Attributes for updating an user */
export type UserUpdateInput = {
  acceptedTosAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
};

/** An Ethereum validator tracked for rewards and balances. */
export type Validator = {
  __typename?: "Validator";
  /** The validator pubkey (prefixed with 0x) */
  address: Scalars["String"];
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** A free-form note about the account */
  label?: Maybe<Scalars["String"]>;
  /** The Ethereum network name (e.g. mainnet, holesky, goerli) */
  network: Scalars["String"];
  /** On demand exit validator data */
  onDemandExit?: Maybe<OnDemandExit>;
  /** The region a Figment Validator is hosted in */
  region?: Maybe<Scalars["String"]>;
  /** Staked balance for the validator */
  stakedBalance?: Maybe<Scalars["String"]>;
  /** Staked balance in USD for the validator */
  stakedBalanceUsd?: Maybe<Scalars["String"]>;
  /** status of validator */
  status?: Maybe<ValidatorStatus>;
  /** timestamps of status transitions */
  statusHistory: StatusHistory;
  /** Total gross (earned) rewards this validator has received */
  totalRewards?: Maybe<Scalars["String"]>;
  /** Sum of all rewards received */
  totalRewardsReceived?: Maybe<Scalars["String"]>;
  /** Sum of all rewards received in USD */
  totalRewardsReceivedUsd?: Maybe<Scalars["String"]>;
  /** Total gross (earned) rewards in USD this validator has received */
  totalRewardsUsd?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["ISO8601DateTime"];
  /** Withdrawal account for the validator */
  withdrawalAccount?: Maybe<Account>;
};

/** The connection type for Validator. */
export type ValidatorConnection = {
  __typename?: "ValidatorConnection";
  /** A list of edges. */
  edges: Array<ValidatorEdge>;
  /** A list of nodes. */
  nodes: Array<Validator>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"];
};

export type ValidatorCounts = {
  __typename?: "ValidatorCounts";
  /** Totals by validator status */
  byStatus?: Maybe<ValidatorCountsByStatus>;
  /** Active validators which have already been requested to exit */
  exitPending?: Maybe<Scalars["Int"]>;
  /** Active validators which have not yet been requested to exit */
  exitable?: Maybe<Scalars["Int"]>;
  /** The total number of validators across all statuses */
  total?: Maybe<Scalars["Int"]>;
  /** Total number of validators verified in Eigenlayer. */
  verified?: Maybe<Scalars["Int"]>;
};

export type ValidatorCountsByStatus = {
  __typename?: "ValidatorCountsByStatus";
  activeExiting?: Maybe<Scalars["Int"]>;
  activeOngoing?: Maybe<Scalars["Int"]>;
  activeSlashed?: Maybe<Scalars["Int"]>;
  deposited?: Maybe<Scalars["Int"]>;
  depositedNotFinalized?: Maybe<Scalars["Int"]>;
  exitedSlashed?: Maybe<Scalars["Int"]>;
  exitedUnslashed?: Maybe<Scalars["Int"]>;
  fundingRequested?: Maybe<Scalars["Int"]>;
  pendingInitialized?: Maybe<Scalars["Int"]>;
  pendingQueued?: Maybe<Scalars["Int"]>;
  provisioned?: Maybe<Scalars["Int"]>;
  withdrawalDone?: Maybe<Scalars["Int"]>;
  withdrawalPossible?: Maybe<Scalars["Int"]>;
};

/** An edge in a connection. */
export type ValidatorEdge = {
  __typename?: "ValidatorEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Validator;
};

export enum ValidatorOrderByFields {
  /** Date the validator was activated */
  ActivatedAt = "activatedAt",
  /** The validator pubkey */
  Address = "address",
  /** Date the validator was added */
  CreatedAt = "createdAt",
  /** The network of the protocol (e.g. mainnet) */
  Network = "network",
  /** The status of the validator */
  Status = "status",
}

export enum ValidatorStatus {
  Active = "active",
  ActiveExiting = "active_exiting",
  ActiveOngoing = "active_ongoing",
  ActiveSlashed = "active_slashed",
  Deposited = "deposited",
  DepositedNotFinalized = "deposited_not_finalized",
  Exited = "exited",
  ExitedSlashed = "exited_slashed",
  ExitedUnslashed = "exited_unslashed",
  FundingRequested = "funding_requested",
  Pending = "pending",
  PendingInitialized = "pending_initialized",
  PendingQueued = "pending_queued",
  Provisioned = "provisioned",
  Unfunded = "unfunded",
  Unknown = "unknown",
  WithdrawalDone = "withdrawal_done",
  WithdrawalPossible = "withdrawal_possible",
}

export type Validators = {
  field: ValidatorOrderByFields;
  sortDirection: SortDirection;
};

/** Attributes for filtering tracked validators. */
export type ValidatorsFilter = {
  address_cont?: InputMaybe<Scalars["String"]>;
  address_eq?: InputMaybe<Scalars["String"]>;
  address_i_cont?: InputMaybe<Scalars["String"]>;
  network_eq?: InputMaybe<Scalars["String"]>;
  organization_id_eq?: InputMaybe<Scalars["String"]>;
  source_eq?: InputMaybe<Scalars["String"]>;
  source_id_eq?: InputMaybe<Scalars["String"]>;
  status_in?: InputMaybe<Array<ValidatorStatus>>;
  status_not_in?: InputMaybe<Array<ValidatorStatus>>;
  withdrawal_account_address_eq?: InputMaybe<Scalars["String"]>;
  withdrawal_account_address_i_eq?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of VaultSetRegion. */
export type VaultSetRegionPayload = {
  __typename?: "VaultSetRegionPayload";
  data?: Maybe<StakeWiseVault>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of VaultTransactionCreate. */
export type VaultTransactionCreatePayload = {
  __typename?: "VaultTransactionCreatePayload";
  data?: Maybe<EthereumTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Attributes for filtering vaults. */
export type VaultsFilter = {
  address?: InputMaybe<Scalars["String"]>;
  network: EthereumNetworks;
  transaction_hash?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of VerifyWithdrawalCredentialsTransactionCreate. */
export type VerifyWithdrawalCredentialsTransactionCreatePayload = {
  __typename?: "VerifyWithdrawalCredentialsTransactionCreatePayload";
  data?: Maybe<EthereumTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of WithdrawTransactionCreateMutation. */
export type WithdrawTransactionCreateMutationPayload = {
  __typename?: "WithdrawTransactionCreateMutationPayload";
  data?: Maybe<SolanaStakingTransactionPayload>;
  userErrors?: Maybe<Array<MutationError>>;
};

/** Autogenerated return type of WithdrawalTransactionCreate. */
export type WithdrawalTransactionCreatePayload = {
  __typename?: "WithdrawalTransactionCreatePayload";
  data?: Maybe<BabylonTx>;
  userErrors?: Maybe<Array<MutationError>>;
};

export type AcceptTermsMutationVariables = Exact<{
  input: UserUpdateInput;
}>;

export type AcceptTermsMutation = {
  __typename?: "Mutation";
  userUpdate: {
    __typename?: "UserMutationPayload";
    data?: { __typename?: "User"; acceptedTosAt?: string | null } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      message: string;
      field?: string | null;
      code: string;
    }> | null;
  };
};

export type ActivateVaultMutationVariables = Exact<{
  network: EthereumNetworks;
  address: Scalars["String"];
}>;

export type ActivateVaultMutation = {
  __typename?: "Mutation";
  configureFigmentVault?: {
    __typename?: "ConfigureFigmentVaultPayload";
    data?: {
      __typename?: "EthereumTransactionPayload";
      unsignedTransactionSerialized: string;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      field?: string | null;
      message: string;
      code: string;
      path?: Array<string> | null;
    }> | null;
  } | null;
};

export type AddEthereumAddressMutationVariables = Exact<{
  input: TrackedAccountAddInput;
}>;

export type AddEthereumAddressMutation = {
  __typename?: "Mutation";
  trackedEthereumAccountAdd?: {
    __typename?: "EthereumAccountAddMutationPayload";
    data?: { __typename?: "Account"; network: string; address: string } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      extras?: Record<string, unknown> | null;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
    }> | null;
  } | null;
};

export type CreateMfaTicketMutationVariables = Exact<{
  sendMail: Scalars["Boolean"];
}>;

export type CreateMfaTicketMutation = {
  __typename?: "Mutation";
  userCreateMfaTicket: {
    __typename?: "MfaTicketMutationPayload";
    data?: { __typename?: "MfaTicket"; ticketUrl?: string | null } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      message: string;
      field?: string | null;
      code: string;
    }> | null;
  };
};

export type DailyReportScheduleMutationMutationVariables = Exact<{
  input: DailyReportScheduleInput;
}>;

export type DailyReportScheduleMutationMutation = {
  __typename?: "Mutation";
  dailyReportSchedule: {
    __typename?: "DailyReportsMutationPayload";
    data?: {
      __typename?: "DailyReports";
      id: string;
      network: string;
      chainId?: string | null;
      status: DailyReportStatus;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      message: string;
    }> | null;
  };
};

export type VaultTransactionCreateMutationVariables = Exact<{
  adminAddress: Scalars["String"];
  network: EthereumNetworks;
}>;

export type VaultTransactionCreateMutation = {
  __typename?: "Mutation";
  vaultTransactionCreate?: {
    __typename?: "VaultTransactionCreatePayload";
    data?: {
      __typename?: "EthereumTransactionPayload";
      unsignedTransactionSerialized: string;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      field?: string | null;
      message: string;
      code: string;
      path?: Array<string> | null;
    }> | null;
  } | null;
};

export type EigenPodCreateMutationVariables = Exact<{
  network: EthereumNetworks;
  ownerAddress: Scalars["String"];
}>;

export type EigenPodCreateMutation = {
  __typename?: "Mutation";
  eigenpodCreate: {
    __typename?: "EigenpodMutationPayload";
    data?: {
      __typename?: "Eigenpod";
      address: string;
      deployed: boolean;
      unsignedRawCreatepodTx?: string | null;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      message: string;
    }> | null;
  };
};

export type InvitationCreateMutationVariables = Exact<{
  name: Scalars["String"];
  email: Scalars["String"];
  roleId: Scalars["ID"];
}>;

export type InvitationCreateMutation = {
  __typename?: "Mutation";
  invitationCreate: {
    __typename?: "InvitationMutationPayload";
    data?: { __typename?: "Invitation"; email: string } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      message: string;
    }> | null;
  };
};

export type InvitationDeleteMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InvitationDeleteMutation = {
  __typename?: "Mutation";
  invitationDelete: {
    __typename?: "InvitationMutationPayload";
    data?: {
      __typename?: "Invitation";
      id: string;
      name: string;
      email: string;
      role: { __typename?: "Role"; id: string; name: string };
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      message: string;
    }> | null;
  };
};

export type LiquidCollectiveDepositCreateMutationVariables = Exact<{
  input: LiquidCollectiveDepositCreateInput;
}>;

export type LiquidCollectiveDepositCreateMutation = {
  __typename?: "Mutation";
  liquidCollectiveDepositCreate: {
    __typename?: "DepositMutationPayload";
    data?: {
      __typename?: "Deposit";
      address: string;
      amountEth: number;
      network: string;
      unsignedTransactionHash: string;
      unsignedTransactionPayload: string;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      message: string;
    }> | null;
  };
};

export type MemberDeleteMutationVariables = Exact<{
  userId: Scalars["ID"];
  organizationId: Scalars["ID"];
}>;

export type MemberDeleteMutation = {
  __typename?: "Mutation";
  memberDelete: {
    __typename?: "MemberMutationPayload";
    data?: { __typename?: "Member"; id: string } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      message: string;
    }> | null;
  };
};

export type MemberRoleChangeMutationVariables = Exact<{
  userId: Scalars["ID"];
  organizationId: Scalars["ID"];
  roleId: Scalars["ID"];
}>;

export type MemberRoleChangeMutation = {
  __typename?: "Mutation";
  memberRoleChange: {
    __typename?: "MemberMutationPayload";
    userErrors?: Array<{
      __typename?: "MutationError";
      message: string;
    }> | null;
  };
};

export type ProjectCreateMutationVariables = Exact<{
  input: ProjectCreateInput;
}>;

export type ProjectCreateMutation = {
  __typename?: "Mutation";
  projectCreate: {
    __typename?: "ProjectMutationPayload";
    data?: {
      __typename?: "Project";
      id: string;
      name: string;
      environment?: string | null;
      readOnly?: boolean | null;
      apiKeys: {
        __typename?: "ApiKeyConnection";
        nodes: Array<{ __typename?: "ApiKey"; value: string }>;
      };
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      message: string;
    }> | null;
  };
};

export type ProjectDeleteMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ProjectDeleteMutation = {
  __typename?: "Mutation";
  projectDelete: {
    __typename?: "ProjectMutationPayload";
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      extras?: Record<string, unknown> | null;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
    }> | null;
  };
};

export type SendResetPasswordEmailMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type SendResetPasswordEmailMutation = {
  __typename?: "Mutation";
  userSendResetPasswordEmail: {
    __typename?: "UserMutationPayload";
    data?: { __typename?: "User"; id: string; email: string } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      message: string;
      field?: string | null;
      code: string;
    }> | null;
  };
};

export type SetVaultRegionMutationVariables = Exact<{
  address: Scalars["String"];
  network: EthereumNetworks;
  region: Regions;
}>;

export type SetVaultRegionMutation = {
  __typename?: "Mutation";
  vaultSetRegion?: {
    __typename?: "VaultSetRegionPayload";
    data?: { __typename?: "StakeWiseVault"; id: string } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      message: string;
      code: string;
    }> | null;
  } | null;
};

export type SignUpMutationVariables = Exact<{
  input: AccountsSignUpInput;
}>;

export type SignUpMutation = {
  __typename?: "Mutation";
  signUpCreate?: {
    __typename?: "MutationsAccountsSignUpCreatePayload";
    data?: {
      __typename?: "AccountsSignUp";
      email?: string | null;
      organization?: {
        __typename?: "Organization";
        id: string;
        name: string;
        displayName: string;
      } | null;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
    }> | null;
  } | null;
};

export type InvitationResendMutationVariables = Exact<{
  input: AccountsInvitationResendInput;
}>;

export type InvitationResendMutation = {
  __typename?: "Mutation";
  invitationResend?: {
    __typename?: "MutationsAccountsInvitationResendPayload";
    data?: {
      __typename?: "AccountsInvitation";
      invitation?: {
        __typename?: "Invitation";
        id: string;
        email: string;
      } | null;
    } | null;
  } | null;
};

export type StakeEthereumMutationVariables = Exact<{
  network: Networks;
  amountEth: Scalars["Int"];
  withdrawalAddress: Scalars["String"];
  fundingAddress?: InputMaybe<Scalars["String"]>;
  feeRecipientAddress?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Regions>;
}>;

export type StakeEthereumMutation = {
  __typename?: "Mutation";
  ethereumStakingRequestCreate: {
    __typename?: "EthereumStakingRequestMutationPayload";
    data?: {
      __typename?: "EthereumStakingRequest";
      id: string;
      amountEth: string;
      feeRecipientAddress?: string | null;
      maxGasWei: string;
      network: Networks;
      region: Regions;
      unsignedTransactionHashed: string;
      unsignedTransactionSerialized: string;
      withdrawalAddress: string;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      extras?: Record<string, unknown> | null;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
    }> | null;
  };
};

export type StakeEthereumSetTxHashMutationVariables = Exact<{
  id: Scalars["String"];
  txHash: Scalars["String"];
  amountEth: Scalars["Int"];
  fundingAddress: Scalars["String"];
}>;

export type StakeEthereumSetTxHashMutation = {
  __typename?: "Mutation";
  ethereumStakingRequestSetTxHash: {
    __typename?: "EthereumStakingRequestMutationPayload";
    data?: { __typename?: "EthereumStakingRequest"; id: string } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
    }> | null;
  };
};

export type UnstakeEthereumMutationVariables = Exact<{
  network: Networks;
  amountEth: Scalars["Int"];
  withdrawalAddress: Scalars["String"];
}>;

export type UnstakeEthereumMutation = {
  __typename?: "Mutation";
  ethereumExitRequestCreate: {
    __typename?: "EthereumExitRequestMutationPayload";
    data?: {
      __typename?: "EthereumExitRequest";
      amountEth: string;
      network: Networks;
      withdrawalAddress: string;
    } | null;
    userErrors?: Array<{
      __typename?: "MutationError";
      code: string;
      extras?: Record<string, unknown> | null;
      field?: string | null;
      message: string;
      path?: Array<string> | null;
    }> | null;
  };
};

export type AuthUserQueryVariables = Exact<{ [key: string]: never }>;

export type AuthUserQuery = {
  __typename?: "Query";
  me: {
    __typename?: "Me";
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email: string;
      auth0Id: string;
      acceptedTosAt?: string | null;
      riskAssessmentPassed?: boolean | null;
      createdAt: string;
    };
    organization: {
      __typename?: "Organization";
      id: string;
      name: string;
      displayName: string;
      isIndividual: boolean;
      riskAssessmentPassed?: boolean | null;
      usesOnchainBilling: boolean;
      devmodeEnabled: boolean;
    };
    role: { __typename?: "Role"; name: string };
  };
  commission?: {
    __typename?: "StakeWiseCommission";
    organizationConfigurationId?: string | null;
  } | null;
  vaults?: {
    __typename?: "StakeWiseVaultConnection";
    nodes: Array<{
      __typename?: "StakeWiseVault";
      id: string;
      address: string;
    }>;
  } | null;
};

export type ConfirmActivatedVaultQueryVariables = Exact<{
  address: Scalars["String"];
  network: EthereumNetworks;
}>;

export type ConfirmActivatedVaultQuery = {
  __typename?: "Query";
  vault?: {
    __typename?: "StakeWiseVault";
    id: string;
    feeRecipientAddress: string;
    status: StakeWiseVaultStatus;
  } | null;
};

export type ConfirmDeployedVaultQueryVariables = Exact<{
  transactionHash: Scalars["String"];
  network: EthereumNetworks;
}>;

export type ConfirmDeployedVaultQuery = {
  __typename?: "Query";
  vault?: { __typename?: "StakeWiseVault"; id: string; address: string } | null;
};

export type DailyReportsQueryVariables = Exact<{ [key: string]: never }>;

export type DailyReportsQuery = {
  __typename?: "Query";
  dailyReports: {
    __typename?: "DailyReportsConnection";
    nodes: Array<{
      __typename?: "DailyReports";
      id: string;
      network: string;
      chainId?: string | null;
      start: string;
      status: DailyReportStatus;
      timeRollup: DailyReportTimeRollup;
      end: string;
      file?: string | null;
      fileExpiresOn?: string | null;
      expiresOn?: string | null;
    }>;
  };
};

export type EigenLayerRestakingButtonQueryVariables = Exact<{
  address: Scalars["String"];
  network: EthereumNetworks;
}>;

export type EigenLayerRestakingButtonQuery = {
  __typename?: "Query";
  ethereumWithdrawalAccount?: {
    __typename?: "EthereumAccount";
    id: string;
    eigenpodOwnerAddress?: string | null;
    isEigenpod: boolean;
    delegatedTo?: string | null;
    totalActiveValidators: number;
    totalVerifiedValidators: number;
  } | null;
};

export type EigenLayerRestakingButtonEthereumWithdrawalAccountFragment = {
  __typename?: "EthereumAccount";
  id: string;
  eigenpodOwnerAddress?: string | null;
  isEigenpod: boolean;
  delegatedTo?: string | null;
  totalActiveValidators: number;
  totalVerifiedValidators: number;
};

export type EthereumStakingPositionsTableQueryVariables = Exact<{
  network: Scalars["String"];
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
}>;

export type EthereumStakingPositionsTableQuery = {
  __typename?: "Query";
  ethereumWithdrawalAccounts: {
    __typename?: "EthereumAccountConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    nodes: Array<{
      __typename?: "EthereumAccount";
      id: string;
      network: string;
      protocol: string;
      address: string;
      totalRewards?: string | null;
      totalRewardsUsd?: string | null;
      stakedBalance?: string | null;
      stakedBalanceUsd?: string | null;
      eigenpodOwnerAddress?: string | null;
      isEigenpod: boolean;
      delegatedTo?: string | null;
      totalActiveValidators: number;
      totalVerifiedValidators: number;
    }>;
  };
};

export type MfaMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type MfaMethodsQuery = {
  __typename?: "Query";
  me: {
    __typename?: "Me";
    user: { __typename?: "User"; mfaMethods?: Array<string> | null };
  };
};

export type MonthlyStatementsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<MonthlyStatements> | MonthlyStatements>;
}>;

export type MonthlyStatementsQuery = {
  __typename?: "Query";
  monthlyStatements: {
    __typename?: "MonthlyStatementConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "MonthlyStatement";
      filename?: string | null;
      statementType?: Statement | null;
      downloadUrl?: string | null;
      generatedAt?: string | null;
      month?: any | null;
      id: string;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      endCursor?: string | null;
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type MonthlyStatementFragment = {
  __typename?: "MonthlyStatement";
  filename?: string | null;
  statementType?: Statement | null;
  downloadUrl?: string | null;
  generatedAt?: string | null;
  month?: any | null;
  id: string;
};

export type NetworkOverviewQueryVariables = Exact<{ [key: string]: never }>;

export type NetworkOverviewQuery = {
  __typename?: "Query";
  ethereumNetworkEstimates: {
    __typename?: "NetworkEstimates";
    estimatedActivation?: {
      __typename?: "NetworkEstimate";
      hoursMin?: number | null;
      hoursMax?: number | null;
    } | null;
    estimatedWithdrawal?: {
      __typename?: "NetworkEstimate";
      hoursMin?: number | null;
      hoursMax?: number | null;
    } | null;
    estimatedExit?: {
      __typename?: "NetworkEstimate";
      hoursMin?: number | null;
      hoursMax?: number | null;
    } | null;
  };
};

export type DashboardInitializationQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DashboardInitializationQuery = {
  __typename?: "Query";
  devnetSolanaStakeAccounts: {
    __typename?: "StakeAccountConnection";
    totalCount: number;
  };
  mainnetSolanaStakeAccounts: {
    __typename?: "StakeAccountConnection";
    totalCount: number;
  };
  testnetEthereumWithdrawalAccounts: {
    __typename?: "EthereumAccountConnection";
    totalCount: number;
  };
  mainnetEthereumWithdrawalAccounts: {
    __typename?: "EthereumAccountConnection";
    totalCount: number;
  };
  devnetSolanaRewardsSummary: {
    __typename?: "SolanaRewardsSummary";
    totalRewards?: string | null;
  };
  mainnetSolanaRewardsSummary: {
    __typename?: "SolanaRewardsSummary";
    totalRewards?: string | null;
  };
  testnetEthereumRewardsSummary: {
    __typename?: "RewardsSummary";
    totalRewards?: string | null;
  };
  mainnetEthereumRewardsSummary: {
    __typename?: "RewardsSummary";
    totalRewards?: string | null;
  };
};

export type MembersQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Members> | Members>;
  excludeInternalMembers?: InputMaybe<Scalars["Boolean"]>;
}>;

export type MembersQuery = {
  __typename?: "Query";
  members: {
    __typename?: "MemberConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Member";
      role: { __typename?: "Role"; name: string; id: string };
      organization: {
        __typename?: "Organization";
        id: string;
        displayName: string;
      };
      user: {
        __typename?: "User";
        createdAt: string;
        email: string;
        name: string;
        id: string;
      };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      endCursor?: string | null;
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type RolesQueryVariables = Exact<{ [key: string]: never }>;

export type RolesQuery = {
  __typename?: "Query";
  roles: {
    __typename?: "RoleConnection";
    nodes: Array<{ __typename?: "Role"; id: string; name: string }>;
  };
};

export type InvitationsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Invitations> | Invitations>;
  excludeInternalMembers?: InputMaybe<Scalars["Boolean"]>;
}>;

export type InvitationsQuery = {
  __typename?: "Query";
  invitations: {
    __typename?: "InvitationConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Invitation";
      id: string;
      name: string;
      email: string;
      createdAt: string;
      expiresAt?: string | null;
      role: { __typename?: "Role"; id: string; name: string };
      organization: { __typename?: "Organization"; id: string; name: string };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      endCursor?: string | null;
      startCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export type InvitationDetailFragment = {
  __typename?: "Invitation";
  id: string;
  name: string;
  email: string;
  createdAt: string;
  expiresAt?: string | null;
  role: { __typename?: "Role"; id: string; name: string };
  organization: { __typename?: "Organization"; id: string; name: string };
};

export type ProjectsQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<Projects> | Projects>;
}>;

export type ProjectsQuery = {
  __typename?: "Query";
  projects: {
    __typename?: "ProjectConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Project";
      id: string;
      name: string;
      environment?: string | null;
      readOnly?: boolean | null;
      apiKeys: {
        __typename?: "ApiKeyConnection";
        nodes: Array<{ __typename?: "ApiKey"; value: string }>;
      };
    }>;
  };
};

export type SolanaStakingPositionsTableQueryVariables = Exact<{
  network: SolanaNetworks;
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
}>;

export type SolanaStakingPositionsTableQuery = {
  __typename?: "Query";
  solanaStakeAccounts: {
    __typename?: "StakeAccountConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    nodes: Array<{
      __typename?: "StakeAccount";
      id: string;
      network: string;
      address: string;
      totalRewards?: string | null;
      totalRewardsUsd?: string | null;
      balance?: string | null;
      balanceUsd?: string | null;
      status?: StakeAccountStatus | null;
      stakeAuthorityAddress?: string | null;
      withdrawAuthorityAddress?: string | null;
      user?: { __typename?: "User"; email: string } | null;
    }>;
  };
};

export type GetStakingActivityQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  protocol?: InputMaybe<Scalars["String"]>;
  network?: InputMaybe<Networks>;
}>;

export type GetStakingActivityQuery = {
  __typename?: "Query";
  stakingActivity: {
    __typename?: "StakingActivityConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      startCursor?: string | null;
      endCursor?: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
    nodes: Array<
      | {
          __typename: "EthereumExitRequest";
          id: string;
          network: Networks;
          amountEth: string;
          createdAt: string;
          activityAt: string;
          estimatedWithdrawableAt?: string | null;
          estimatedPrincipalReturnAt?: string | null;
          exitStatus: ExitRequestStatuses;
          user?: { __typename?: "User"; email: string } | null;
        }
      | {
          __typename: "EthereumStakingDeposit";
          id: string;
          network: Networks;
          amountEth: string;
          createdAt: string;
          activityAt: string;
          txHash: string;
          estimatedActiveAt?: string | null;
          depositStatus: DepositStatuses;
          provisioners?: Array<{ __typename?: "User"; email: string }> | null;
        }
    >;
  };
};

export type GetStakingActivityConnectionFragment = {
  __typename?: "StakingActivityConnection";
  totalCount: number;
  pageInfo: {
    __typename?: "PageInfo";
    startCursor?: string | null;
    endCursor?: string | null;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  nodes: Array<
    | {
        __typename: "EthereumExitRequest";
        id: string;
        network: Networks;
        amountEth: string;
        createdAt: string;
        activityAt: string;
        estimatedWithdrawableAt?: string | null;
        estimatedPrincipalReturnAt?: string | null;
        exitStatus: ExitRequestStatuses;
        user?: { __typename?: "User"; email: string } | null;
      }
    | {
        __typename: "EthereumStakingDeposit";
        id: string;
        network: Networks;
        amountEth: string;
        createdAt: string;
        activityAt: string;
        txHash: string;
        estimatedActiveAt?: string | null;
        depositStatus: DepositStatuses;
        provisioners?: Array<{ __typename?: "User"; email: string }> | null;
      }
  >;
};

type GetStakingActivityConnectionNode_EthereumExitRequest_Fragment = {
  __typename: "EthereumExitRequest";
  id: string;
  network: Networks;
  amountEth: string;
  createdAt: string;
  activityAt: string;
  estimatedWithdrawableAt?: string | null;
  estimatedPrincipalReturnAt?: string | null;
  exitStatus: ExitRequestStatuses;
  user?: { __typename?: "User"; email: string } | null;
};

type GetStakingActivityConnectionNode_EthereumStakingDeposit_Fragment = {
  __typename: "EthereumStakingDeposit";
  id: string;
  network: Networks;
  amountEth: string;
  createdAt: string;
  activityAt: string;
  txHash: string;
  estimatedActiveAt?: string | null;
  depositStatus: DepositStatuses;
  provisioners?: Array<{ __typename?: "User"; email: string }> | null;
};

export type GetStakingActivityConnectionNodeFragment =
  | GetStakingActivityConnectionNode_EthereumExitRequest_Fragment
  | GetStakingActivityConnectionNode_EthereumStakingDeposit_Fragment;

export type StakingDashboardPageV2QueryVariables = Exact<{
  network: Scalars["String"];
}>;

export type StakingDashboardPageV2Query = {
  __typename?: "Query";
  ethereumPrice: {
    __typename?: "Price";
    date?: string | null;
    value?: number | null;
  };
  rewardsSummary: {
    __typename?: "RewardsSummary";
    stakingRewards?: string | null;
    stakingRewardsUsd?: string | null;
    balance?: string | null;
    balanceUsd?: string | null;
    totalRewards?: string | null;
    totalRewardsUsd?: string | null;
  };
  validatorCounts: {
    __typename?: "ValidatorCounts";
    total?: number | null;
    exitable?: number | null;
    exitPending?: number | null;
    byStatus?: {
      __typename?: "ValidatorCountsByStatus";
      activeExiting?: number | null;
      activeOngoing?: number | null;
      activeSlashed?: number | null;
      deposited?: number | null;
      depositedNotFinalized?: number | null;
      exitedSlashed?: number | null;
      exitedUnslashed?: number | null;
      fundingRequested?: number | null;
      pendingInitialized?: number | null;
      pendingQueued?: number | null;
      provisioned?: number | null;
      withdrawalDone?: number | null;
      withdrawalPossible?: number | null;
    } | null;
  };
  rewardsEarnedOneMonth: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      timestamp?: string | null;
      total?: string | null;
      totalUsd?: string | null;
      consensusTotal?: string | null;
      consensusTotalUsd?: string | null;
      executionTotal?: string | null;
      executionTotalUsd?: string | null;
    }> | null;
  };
  rewardsEarnedSixMonths: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      timestamp?: string | null;
      total?: string | null;
      totalUsd?: string | null;
      consensusTotal?: string | null;
      consensusTotalUsd?: string | null;
      executionTotal?: string | null;
      executionTotalUsd?: string | null;
    }> | null;
  };
  rewardsEarnedOneYear: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      timestamp?: string | null;
      total?: string | null;
      totalUsd?: string | null;
      consensusTotal?: string | null;
      consensusTotalUsd?: string | null;
      executionTotal?: string | null;
      executionTotalUsd?: string | null;
    }> | null;
  };
};

export type ValidatorSummaryPriceFragment = {
  __typename?: "Price";
  date?: string | null;
  value?: number | null;
};

export type ValidatorSummaryFigmentValidatorCountsFragment = {
  __typename?: "FigmentValidatorCounts";
  total?: number | null;
  exitable?: number | null;
  exitPending?: number | null;
  byStatus?: {
    __typename?: "ValidatorCountsByStatus";
    activeExiting?: number | null;
    activeOngoing?: number | null;
    activeSlashed?: number | null;
    deposited?: number | null;
    depositedNotFinalized?: number | null;
    exitedSlashed?: number | null;
    exitedUnslashed?: number | null;
    fundingRequested?: number | null;
    pendingInitialized?: number | null;
    pendingQueued?: number | null;
    provisioned?: number | null;
    withdrawalDone?: number | null;
    withdrawalPossible?: number | null;
  } | null;
};

export type ValidatorSummaryValidatorCountsFragment = {
  __typename?: "ValidatorCounts";
  total?: number | null;
  exitable?: number | null;
  exitPending?: number | null;
  byStatus?: {
    __typename?: "ValidatorCountsByStatus";
    activeExiting?: number | null;
    activeOngoing?: number | null;
    activeSlashed?: number | null;
    deposited?: number | null;
    depositedNotFinalized?: number | null;
    exitedSlashed?: number | null;
    exitedUnslashed?: number | null;
    fundingRequested?: number | null;
    pendingInitialized?: number | null;
    pendingQueued?: number | null;
    provisioned?: number | null;
    withdrawalDone?: number | null;
    withdrawalPossible?: number | null;
  } | null;
};

export type TotalRewardsEarnedRewardsSummaryFragment = {
  __typename?: "RewardsSummary";
  stakingRewards?: string | null;
  stakingRewardsUsd?: string | null;
  balance?: string | null;
  balanceUsd?: string | null;
  totalRewards?: string | null;
  totalRewardsUsd?: string | null;
};

export type RewardsEarnedChartFragment = {
  __typename?: "Query";
  rewardsEarnedOneMonth: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      timestamp?: string | null;
      total?: string | null;
      totalUsd?: string | null;
      consensusTotal?: string | null;
      consensusTotalUsd?: string | null;
      executionTotal?: string | null;
      executionTotalUsd?: string | null;
    }> | null;
  };
  rewardsEarnedSixMonths: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      timestamp?: string | null;
      total?: string | null;
      totalUsd?: string | null;
      consensusTotal?: string | null;
      consensusTotalUsd?: string | null;
      executionTotal?: string | null;
      executionTotalUsd?: string | null;
    }> | null;
  };
  rewardsEarnedOneYear: {
    __typename?: "AggregatedRewards";
    rewards?: Array<{
      __typename?: "AggregatedRewardsTuple";
      timestamp?: string | null;
      total?: string | null;
      totalUsd?: string | null;
      consensusTotal?: string | null;
      consensusTotalUsd?: string | null;
      executionTotal?: string | null;
      executionTotalUsd?: string | null;
    }> | null;
  };
};

export type RewardsEarnedChartAggregatedRewardsFragment = {
  __typename?: "AggregatedRewards";
  rewards?: Array<{
    __typename?: "AggregatedRewardsTuple";
    timestamp?: string | null;
    total?: string | null;
    totalUsd?: string | null;
    consensusTotal?: string | null;
    consensusTotalUsd?: string | null;
    executionTotal?: string | null;
    executionTotalUsd?: string | null;
  }> | null;
};

export type RewardsEarnedChartAggregatedRewardsTupleFragment = {
  __typename?: "AggregatedRewardsTuple";
  timestamp?: string | null;
  total?: string | null;
  totalUsd?: string | null;
  consensusTotal?: string | null;
  consensusTotalUsd?: string | null;
  executionTotal?: string | null;
  executionTotalUsd?: string | null;
};

export type StakingModalQueryVariables = Exact<{ [key: string]: never }>;

export type StakingModalQuery = {
  __typename?: "Query";
  ethereumPrice: {
    __typename?: "Price";
    date?: string | null;
    value?: number | null;
  };
  ethereumRewardsRate: { __typename?: "RewardsRate"; value?: number | null };
};

export type StakingPositionModalQueryVariables = Exact<{
  address: Scalars["String"];
  network: Scalars["String"];
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<Validators> | Validators>;
  status_in?: InputMaybe<Array<ValidatorStatus> | ValidatorStatus>;
}>;

export type StakingPositionModalQuery = {
  __typename?: "Query";
  trackedValidators: {
    __typename?: "ValidatorConnection";
    totalCount: number;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
    nodes: Array<{
      __typename?: "Validator";
      id: string;
      network: string;
      address: string;
      status?: ValidatorStatus | null;
      totalRewards?: string | null;
      totalRewardsUsd?: string | null;
      stakedBalance?: string | null;
      stakedBalanceUsd?: string | null;
      region?: string | null;
      onDemandExit?: {
        __typename?: "OnDemandExit";
        approvedAt?: string | null;
        requestedAt?: string | null;
        submittedAt?: string | null;
      } | null;
    }>;
  };
  validatorCounts: {
    __typename?: "ValidatorCounts";
    total?: number | null;
    exitable?: number | null;
    exitPending?: number | null;
    byStatus?: {
      __typename?: "ValidatorCountsByStatus";
      activeExiting?: number | null;
      activeOngoing?: number | null;
      activeSlashed?: number | null;
      deposited?: number | null;
      depositedNotFinalized?: number | null;
      exitedSlashed?: number | null;
      exitedUnslashed?: number | null;
      fundingRequested?: number | null;
      pendingInitialized?: number | null;
      pendingQueued?: number | null;
      provisioned?: number | null;
      withdrawalDone?: number | null;
      withdrawalPossible?: number | null;
    } | null;
  };
};

export type StakingPositionModalValidatorFragment = {
  __typename?: "Validator";
  id: string;
  network: string;
  address: string;
  status?: ValidatorStatus | null;
  totalRewards?: string | null;
  totalRewardsUsd?: string | null;
  stakedBalance?: string | null;
  stakedBalanceUsd?: string | null;
  region?: string | null;
  onDemandExit?: {
    __typename?: "OnDemandExit";
    approvedAt?: string | null;
    requestedAt?: string | null;
    submittedAt?: string | null;
  } | null;
};

export type StakingPositionsQueryVariables = Exact<{
  ethereumRewardsNetwork: Scalars["String"];
  ethereumBalanceNetwork: EthereumNetworks;
  solanaNetwork: SolanaNetworks;
}>;

export type StakingPositionsQuery = {
  __typename?: "Query";
  ethereumRewardsRate: { __typename?: "RewardsRate"; value?: number | null };
  rewardsSummary: {
    __typename?: "RewardsSummary";
    totalRewards?: string | null;
    totalRewardsUsd?: string | null;
  };
  ethereumBalanceSummary: {
    __typename?: "EthereumBalanceSummary";
    balance?: string | null;
    balanceUsd?: string | null;
  };
  solanaRewardsRate: { __typename?: "RewardsRate"; value?: number | null };
  solanaRewardsSummary: {
    __typename?: "SolanaRewardsSummary";
    totalRewards?: string | null;
    totalRewardsUsd?: string | null;
  };
  solanaBalanceSummary: {
    __typename?: "SolanaBalanceSummary";
    balance?: string | null;
    balanceUsd?: string | null;
  };
};

export type UnstakeEthButtonAggregateValidatorInfoQueryVariables = Exact<{
  ethAccount: Scalars["String"];
  network: Scalars["String"];
  ethereumNetwork: EthereumNetworks;
}>;

export type UnstakeEthButtonAggregateValidatorInfoQuery = {
  __typename?: "Query";
  validatorCounts: { __typename?: "ValidatorCounts"; exitable?: number | null };
  ethereumWithdrawalAccount?: {
    __typename?: "EthereumAccount";
    id: string;
    isVault: boolean;
  } | null;
};

export type UnstakeEthStakingEthTokenInfoQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UnstakeEthStakingEthTokenInfoQuery = {
  __typename?: "Query";
  ethereumPrice: { __typename?: "Price"; value?: number | null };
};

export type CreateVaultFlowQueryVariables = Exact<{
  address: Scalars["String"];
  network: EthereumNetworks;
}>;

export type CreateVaultFlowQuery = {
  __typename?: "Query";
  vault?: {
    __typename?: "StakeWiseVault";
    id: string;
    status: StakeWiseVaultStatus;
    address: string;
    createdAt: string;
    feeBps: number;
    feeRecipientAddress: string;
    network: EthereumNetworks;
    region?: string | null;
    transactionHash?: string | null;
    updatedAt: string;
    validatorsManagerAddress?: string | null;
    vaultAdmin?: {
      __typename?: "StakeWiseVaultAdmin";
      id: string;
      address: string;
      organization: { __typename?: "Organization"; id: string; name: string };
    } | null;
  } | null;
};

export type UnstakeEthAggregateValidatorInfoQueryVariables = Exact<{
  ethAccount: Scalars["String"];
  network: Scalars["String"];
}>;

export type UnstakeEthAggregateValidatorInfoQuery = {
  __typename?: "Query";
  validatorCounts: { __typename?: "ValidatorCounts"; exitable?: number | null };
};

export type VaultDetailsQueryVariables = Exact<{
  network: EthereumNetworks;
  address: Scalars["String"];
}>;

export type VaultDetailsQuery = {
  __typename?: "Query";
  vault?: {
    __typename?: "StakeWiseVault";
    network: EthereumNetworks;
    region?: string | null;
    createdAt: string;
    feeBps: number;
    validatorsManagerAddress?: string | null;
    status: StakeWiseVaultStatus;
    vaultAdmin?: { __typename?: "StakeWiseVaultAdmin"; address: string } | null;
    rewardSplitter?: {
      __typename?: "StakeWiseRewardSplitter";
      address: string;
    } | null;
  } | null;
  commission?: {
    __typename?: "StakeWiseCommission";
    figmentShares?: string | null;
    shareholderShares?: string | null;
    shareholderFeeRecipientAddress?: string | null;
  } | null;
};

export type VaultsQueryVariables = Exact<{
  network: EthereumNetworks;
}>;

export type VaultsQuery = {
  __typename?: "Query";
  firstTestnetVault?: {
    __typename?: "StakeWiseVaultConnection";
    nodes: Array<{ __typename?: "StakeWiseVault"; address: string }>;
  } | null;
  firstMainnetVault?: {
    __typename?: "StakeWiseVaultConnection";
    nodes: Array<{ __typename?: "StakeWiseVault"; address: string }>;
  } | null;
  vaults?: {
    __typename?: "StakeWiseVaultConnection";
    nodes: Array<{ __typename?: "StakeWiseVault"; address: string }>;
  } | null;
};

export const EigenLayerRestakingButtonEthereumWithdrawalAccountFragmentDoc = `
    fragment EigenLayerRestakingButtonEthereumWithdrawalAccount on EthereumAccount {
  id
  eigenpodOwnerAddress
  isEigenpod
  delegatedTo
  totalActiveValidators
  totalVerifiedValidators
}
    `;
export const MonthlyStatementFragmentDoc = `
    fragment MonthlyStatement on MonthlyStatement {
  filename
  statementType
  downloadUrl
  generatedAt
  month
  id
}
    `;
export const InvitationDetailFragmentDoc = `
    fragment InvitationDetail on Invitation {
  id
  name
  email
  role {
    id
    name
  }
  createdAt
  expiresAt
  organization {
    id
    name
  }
}
    `;
export const GetStakingActivityConnectionNodeFragmentDoc = `
    fragment GetStakingActivityConnectionNode on StakingActivity {
  __typename
  ... on EthereumStakingDeposit {
    id
    network
    amountEth
    createdAt
    activityAt
    depositStatus: status
    txHash
    estimatedActiveAt
    provisioners {
      email
    }
  }
  ... on EthereumExitRequest {
    id
    network
    amountEth
    createdAt
    activityAt
    exitStatus: status
    estimatedWithdrawableAt
    estimatedPrincipalReturnAt
    user {
      email
    }
  }
}
    `;
export const GetStakingActivityConnectionFragmentDoc = `
    fragment GetStakingActivityConnection on StakingActivityConnection {
  totalCount
  pageInfo {
    startCursor
    endCursor
    hasNextPage
    hasPreviousPage
  }
  nodes {
    ...GetStakingActivityConnectionNode
  }
}
    ${GetStakingActivityConnectionNodeFragmentDoc}`;
export const ValidatorSummaryPriceFragmentDoc = `
    fragment ValidatorSummaryPrice on Price {
  date
  value
}
    `;
export const ValidatorSummaryFigmentValidatorCountsFragmentDoc = `
    fragment ValidatorSummaryFigmentValidatorCounts on FigmentValidatorCounts {
  total
  exitable
  exitPending
  byStatus {
    activeExiting
    activeOngoing
    activeSlashed
    deposited
    depositedNotFinalized
    exitedSlashed
    exitedUnslashed
    fundingRequested
    pendingInitialized
    pendingQueued
    provisioned
    withdrawalDone
    withdrawalPossible
  }
}
    `;
export const ValidatorSummaryValidatorCountsFragmentDoc = `
    fragment ValidatorSummaryValidatorCounts on ValidatorCounts {
  total
  exitable
  exitPending
  byStatus {
    activeExiting
    activeOngoing
    activeSlashed
    deposited
    depositedNotFinalized
    exitedSlashed
    exitedUnslashed
    fundingRequested
    pendingInitialized
    pendingQueued
    provisioned
    withdrawalDone
    withdrawalPossible
  }
}
    `;
export const TotalRewardsEarnedRewardsSummaryFragmentDoc = `
    fragment TotalRewardsEarnedRewardsSummary on RewardsSummary {
  stakingRewards
  stakingRewardsUsd
  balance
  balanceUsd
  totalRewards
  totalRewardsUsd
}
    `;
export const RewardsEarnedChartAggregatedRewardsTupleFragmentDoc = `
    fragment RewardsEarnedChartAggregatedRewardsTuple on AggregatedRewardsTuple {
  timestamp
  total
  totalUsd
  consensusTotal
  consensusTotalUsd
  executionTotal
  executionTotalUsd
}
    `;
export const RewardsEarnedChartAggregatedRewardsFragmentDoc = `
    fragment RewardsEarnedChartAggregatedRewards on AggregatedRewards {
  rewards {
    ...RewardsEarnedChartAggregatedRewardsTuple
  }
}
    ${RewardsEarnedChartAggregatedRewardsTupleFragmentDoc}`;
export const RewardsEarnedChartFragmentDoc = `
    fragment RewardsEarnedChart on Query {
  rewardsEarnedOneMonth: aggregatedRewards(
    network: $network
    timePeriod: one_month
  ) {
    ...RewardsEarnedChartAggregatedRewards
  }
  rewardsEarnedSixMonths: aggregatedRewards(
    network: $network
    timePeriod: six_months
  ) {
    ...RewardsEarnedChartAggregatedRewards
  }
  rewardsEarnedOneYear: aggregatedRewards(network: $network, timePeriod: one_year) {
    ...RewardsEarnedChartAggregatedRewards
  }
}
    ${RewardsEarnedChartAggregatedRewardsFragmentDoc}`;
export const StakingPositionModalValidatorFragmentDoc = `
    fragment StakingPositionModalValidator on Validator {
  id
  network
  address
  status
  totalRewards
  totalRewardsUsd
  stakedBalance
  stakedBalanceUsd
  onDemandExit {
    approvedAt
    requestedAt
    submittedAt
  }
  region
}
    `;
export const AcceptTermsDocument = `
    mutation AcceptTerms($input: UserUpdateInput!) {
  userUpdate(input: $input) {
    data {
      acceptedTosAt
    }
    userErrors {
      message
      field
      code
    }
  }
}
    `;
export const useAcceptTermsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    AcceptTermsMutation,
    TError,
    AcceptTermsMutationVariables,
    TContext
  >
) =>
  useMutation<
    AcceptTermsMutation,
    TError,
    AcceptTermsMutationVariables,
    TContext
  >(
    ["AcceptTerms"],
    useFetchData<AcceptTermsMutation, AcceptTermsMutationVariables>(
      AcceptTermsDocument
    ),
    options
  );
useAcceptTermsMutation.getKey = () => ["AcceptTerms"];

export const ActivateVaultDocument = `
    mutation ActivateVault($network: EthereumNetworks!, $address: String!) {
  configureFigmentVault(input: {network: $network, address: $address}) {
    data {
      unsignedTransactionSerialized
    }
    userErrors {
      field
      message
      code
      field
      message
      path
    }
  }
}
    `;
export const useActivateVaultMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ActivateVaultMutation,
    TError,
    ActivateVaultMutationVariables,
    TContext
  >
) =>
  useMutation<
    ActivateVaultMutation,
    TError,
    ActivateVaultMutationVariables,
    TContext
  >(
    ["ActivateVault"],
    useFetchData<ActivateVaultMutation, ActivateVaultMutationVariables>(
      ActivateVaultDocument
    ),
    options
  );
useActivateVaultMutation.getKey = () => ["ActivateVault"];

export const AddEthereumAddressDocument = `
    mutation AddEthereumAddress($input: TrackedAccountAddInput!) {
  trackedEthereumAccountAdd(input: $input) {
    data {
      network
      address
    }
    userErrors {
      code
      extras
      field
      message
      path
    }
  }
}
    `;
export const useAddEthereumAddressMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    AddEthereumAddressMutation,
    TError,
    AddEthereumAddressMutationVariables,
    TContext
  >
) =>
  useMutation<
    AddEthereumAddressMutation,
    TError,
    AddEthereumAddressMutationVariables,
    TContext
  >(
    ["AddEthereumAddress"],
    useFetchData<
      AddEthereumAddressMutation,
      AddEthereumAddressMutationVariables
    >(AddEthereumAddressDocument),
    options
  );
useAddEthereumAddressMutation.getKey = () => ["AddEthereumAddress"];

export const CreateMfaTicketDocument = `
    mutation CreateMFATicket($sendMail: Boolean!) {
  userCreateMfaTicket(sendMail: $sendMail) {
    data {
      ticketUrl
    }
    userErrors {
      message
      field
      code
    }
  }
}
    `;
export const useCreateMfaTicketMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateMfaTicketMutation,
    TError,
    CreateMfaTicketMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateMfaTicketMutation,
    TError,
    CreateMfaTicketMutationVariables,
    TContext
  >(
    ["CreateMFATicket"],
    useFetchData<CreateMfaTicketMutation, CreateMfaTicketMutationVariables>(
      CreateMfaTicketDocument
    ),
    options
  );
useCreateMfaTicketMutation.getKey = () => ["CreateMFATicket"];

export const DailyReportScheduleMutationDocument = `
    mutation DailyReportScheduleMutation($input: DailyReportScheduleInput!) {
  dailyReportSchedule(input: $input) {
    data {
      id
      network
      chainId
      status
    }
    userErrors {
      message
    }
  }
}
    `;
export const useDailyReportScheduleMutationMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DailyReportScheduleMutationMutation,
    TError,
    DailyReportScheduleMutationMutationVariables,
    TContext
  >
) =>
  useMutation<
    DailyReportScheduleMutationMutation,
    TError,
    DailyReportScheduleMutationMutationVariables,
    TContext
  >(
    ["DailyReportScheduleMutation"],
    useFetchData<
      DailyReportScheduleMutationMutation,
      DailyReportScheduleMutationMutationVariables
    >(DailyReportScheduleMutationDocument),
    options
  );
useDailyReportScheduleMutationMutation.getKey = () => [
  "DailyReportScheduleMutation",
];

export const VaultTransactionCreateDocument = `
    mutation VaultTransactionCreate($adminAddress: String!, $network: EthereumNetworks!) {
  vaultTransactionCreate(input: {network: $network, adminAddress: $adminAddress}) {
    data {
      unsignedTransactionSerialized
    }
    userErrors {
      field
      message
      code
      field
      message
      path
    }
  }
}
    `;
export const useVaultTransactionCreateMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    VaultTransactionCreateMutation,
    TError,
    VaultTransactionCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    VaultTransactionCreateMutation,
    TError,
    VaultTransactionCreateMutationVariables,
    TContext
  >(
    ["VaultTransactionCreate"],
    useFetchData<
      VaultTransactionCreateMutation,
      VaultTransactionCreateMutationVariables
    >(VaultTransactionCreateDocument),
    options
  );
useVaultTransactionCreateMutation.getKey = () => ["VaultTransactionCreate"];

export const EigenPodCreateDocument = `
    mutation EigenPodCreate($network: EthereumNetworks!, $ownerAddress: String!) {
  eigenpodCreate(input: {network: $network, ownerAddress: $ownerAddress}) {
    data {
      address
      deployed
      unsignedRawCreatepodTx
    }
    userErrors {
      code
      message
    }
  }
}
    `;
export const useEigenPodCreateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    EigenPodCreateMutation,
    TError,
    EigenPodCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    EigenPodCreateMutation,
    TError,
    EigenPodCreateMutationVariables,
    TContext
  >(
    ["EigenPodCreate"],
    useFetchData<EigenPodCreateMutation, EigenPodCreateMutationVariables>(
      EigenPodCreateDocument
    ),
    options
  );
useEigenPodCreateMutation.getKey = () => ["EigenPodCreate"];

export const InvitationCreateDocument = `
    mutation InvitationCreate($name: String!, $email: String!, $roleId: ID!) {
  invitationCreate(input: {name: $name, email: $email, roleId: $roleId}) {
    data {
      email
    }
    userErrors {
      message
    }
  }
}
    `;
export const useInvitationCreateMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    InvitationCreateMutation,
    TError,
    InvitationCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    InvitationCreateMutation,
    TError,
    InvitationCreateMutationVariables,
    TContext
  >(
    ["InvitationCreate"],
    useFetchData<InvitationCreateMutation, InvitationCreateMutationVariables>(
      InvitationCreateDocument
    ),
    options
  );
useInvitationCreateMutation.getKey = () => ["InvitationCreate"];

export const InvitationDeleteDocument = `
    mutation InvitationDelete($id: ID!) {
  invitationDelete(id: $id) {
    data {
      id
      name
      email
      role {
        id
        name
      }
    }
    userErrors {
      message
    }
  }
}
    `;
export const useInvitationDeleteMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    InvitationDeleteMutation,
    TError,
    InvitationDeleteMutationVariables,
    TContext
  >
) =>
  useMutation<
    InvitationDeleteMutation,
    TError,
    InvitationDeleteMutationVariables,
    TContext
  >(
    ["InvitationDelete"],
    useFetchData<InvitationDeleteMutation, InvitationDeleteMutationVariables>(
      InvitationDeleteDocument
    ),
    options
  );
useInvitationDeleteMutation.getKey = () => ["InvitationDelete"];

export const LiquidCollectiveDepositCreateDocument = `
    mutation LiquidCollectiveDepositCreate($input: LiquidCollectiveDepositCreateInput!) {
  liquidCollectiveDepositCreate(input: $input) {
    data {
      address
      amountEth
      network
      unsignedTransactionHash
      unsignedTransactionPayload
    }
    userErrors {
      message
    }
  }
}
    `;
export const useLiquidCollectiveDepositCreateMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    LiquidCollectiveDepositCreateMutation,
    TError,
    LiquidCollectiveDepositCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    LiquidCollectiveDepositCreateMutation,
    TError,
    LiquidCollectiveDepositCreateMutationVariables,
    TContext
  >(
    ["LiquidCollectiveDepositCreate"],
    useFetchData<
      LiquidCollectiveDepositCreateMutation,
      LiquidCollectiveDepositCreateMutationVariables
    >(LiquidCollectiveDepositCreateDocument),
    options
  );
useLiquidCollectiveDepositCreateMutation.getKey = () => [
  "LiquidCollectiveDepositCreate",
];

export const MemberDeleteDocument = `
    mutation MemberDelete($userId: ID!, $organizationId: ID!) {
  memberDelete(userId: $userId, organizationId: $organizationId) {
    data {
      id
    }
    userErrors {
      message
    }
  }
}
    `;
export const useMemberDeleteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    MemberDeleteMutation,
    TError,
    MemberDeleteMutationVariables,
    TContext
  >
) =>
  useMutation<
    MemberDeleteMutation,
    TError,
    MemberDeleteMutationVariables,
    TContext
  >(
    ["MemberDelete"],
    useFetchData<MemberDeleteMutation, MemberDeleteMutationVariables>(
      MemberDeleteDocument
    ),
    options
  );
useMemberDeleteMutation.getKey = () => ["MemberDelete"];

export const MemberRoleChangeDocument = `
    mutation MemberRoleChange($userId: ID!, $organizationId: ID!, $roleId: ID!) {
  memberRoleChange(
    userId: $userId
    organizationId: $organizationId
    roleId: $roleId
  ) {
    userErrors {
      message
    }
  }
}
    `;
export const useMemberRoleChangeMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    MemberRoleChangeMutation,
    TError,
    MemberRoleChangeMutationVariables,
    TContext
  >
) =>
  useMutation<
    MemberRoleChangeMutation,
    TError,
    MemberRoleChangeMutationVariables,
    TContext
  >(
    ["MemberRoleChange"],
    useFetchData<MemberRoleChangeMutation, MemberRoleChangeMutationVariables>(
      MemberRoleChangeDocument
    ),
    options
  );
useMemberRoleChangeMutation.getKey = () => ["MemberRoleChange"];

export const ProjectCreateDocument = `
    mutation ProjectCreate($input: ProjectCreateInput!) {
  projectCreate(input: $input) {
    data {
      id
      name
      environment
      readOnly
      apiKeys(last: 1) {
        nodes {
          value
        }
      }
    }
    userErrors {
      code
      message
    }
  }
}
    `;
export const useProjectCreateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ProjectCreateMutation,
    TError,
    ProjectCreateMutationVariables,
    TContext
  >
) =>
  useMutation<
    ProjectCreateMutation,
    TError,
    ProjectCreateMutationVariables,
    TContext
  >(
    ["ProjectCreate"],
    useFetchData<ProjectCreateMutation, ProjectCreateMutationVariables>(
      ProjectCreateDocument
    ),
    options
  );
useProjectCreateMutation.getKey = () => ["ProjectCreate"];

export const ProjectDeleteDocument = `
    mutation ProjectDelete($id: ID!) {
  projectDelete(id: $id) {
    userErrors {
      code
      extras
      field
      message
      path
    }
  }
}
    `;
export const useProjectDeleteMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ProjectDeleteMutation,
    TError,
    ProjectDeleteMutationVariables,
    TContext
  >
) =>
  useMutation<
    ProjectDeleteMutation,
    TError,
    ProjectDeleteMutationVariables,
    TContext
  >(
    ["ProjectDelete"],
    useFetchData<ProjectDeleteMutation, ProjectDeleteMutationVariables>(
      ProjectDeleteDocument
    ),
    options
  );
useProjectDeleteMutation.getKey = () => ["ProjectDelete"];

export const SendResetPasswordEmailDocument = `
    mutation SendResetPasswordEmail($userId: ID!) {
  userSendResetPasswordEmail(userId: $userId) {
    data {
      id
      email
    }
    userErrors {
      message
      field
      code
    }
  }
}
    `;
export const useSendResetPasswordEmailMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    SendResetPasswordEmailMutation,
    TError,
    SendResetPasswordEmailMutationVariables,
    TContext
  >
) =>
  useMutation<
    SendResetPasswordEmailMutation,
    TError,
    SendResetPasswordEmailMutationVariables,
    TContext
  >(
    ["SendResetPasswordEmail"],
    useFetchData<
      SendResetPasswordEmailMutation,
      SendResetPasswordEmailMutationVariables
    >(SendResetPasswordEmailDocument),
    options
  );
useSendResetPasswordEmailMutation.getKey = () => ["SendResetPasswordEmail"];

export const SetVaultRegionDocument = `
    mutation SetVaultRegion($address: String!, $network: EthereumNetworks!, $region: Regions!) {
  vaultSetRegion(input: {address: $address, network: $network, region: $region}) {
    data {
      id
    }
    userErrors {
      message
      code
    }
  }
}
    `;
export const useSetVaultRegionMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SetVaultRegionMutation,
    TError,
    SetVaultRegionMutationVariables,
    TContext
  >
) =>
  useMutation<
    SetVaultRegionMutation,
    TError,
    SetVaultRegionMutationVariables,
    TContext
  >(
    ["SetVaultRegion"],
    useFetchData<SetVaultRegionMutation, SetVaultRegionMutationVariables>(
      SetVaultRegionDocument
    ),
    options
  );
useSetVaultRegionMutation.getKey = () => ["SetVaultRegion"];

export const SignUpDocument = `
    mutation SignUp($input: AccountsSignUpInput!) {
  signUpCreate(input: $input) {
    data {
      email
      organization {
        id
        name
        displayName
      }
    }
    userErrors {
      code
      field
      message
      path
    }
  }
}
    `;
export const useSignUpMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SignUpMutation,
    TError,
    SignUpMutationVariables,
    TContext
  >
) =>
  useMutation<SignUpMutation, TError, SignUpMutationVariables, TContext>(
    ["SignUp"],
    useFetchData<SignUpMutation, SignUpMutationVariables>(SignUpDocument),
    options
  );
useSignUpMutation.getKey = () => ["SignUp"];

export const InvitationResendDocument = `
    mutation InvitationResend($input: AccountsInvitationResendInput!) {
  invitationResend(input: $input) {
    data {
      invitation {
        id
        email
      }
    }
  }
}
    `;
export const useInvitationResendMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    InvitationResendMutation,
    TError,
    InvitationResendMutationVariables,
    TContext
  >
) =>
  useMutation<
    InvitationResendMutation,
    TError,
    InvitationResendMutationVariables,
    TContext
  >(
    ["InvitationResend"],
    useFetchData<InvitationResendMutation, InvitationResendMutationVariables>(
      InvitationResendDocument
    ),
    options
  );
useInvitationResendMutation.getKey = () => ["InvitationResend"];

export const StakeEthereumDocument = `
    mutation StakeEthereum($network: Networks!, $amountEth: Int!, $withdrawalAddress: String!, $fundingAddress: String, $feeRecipientAddress: String, $region: Regions) {
  ethereumStakingRequestCreate(
    input: {network: $network, amountEth: $amountEth, withdrawalAddress: $withdrawalAddress, fundingAddress: $fundingAddress, feeRecipientAddress: $feeRecipientAddress, region: $region}
  ) {
    data {
      id
      amountEth
      feeRecipientAddress
      maxGasWei
      network
      region
      unsignedTransactionHashed
      unsignedTransactionSerialized
      withdrawalAddress
    }
    userErrors {
      code
      extras
      field
      message
      path
    }
  }
}
    `;
export const useStakeEthereumMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    StakeEthereumMutation,
    TError,
    StakeEthereumMutationVariables,
    TContext
  >
) =>
  useMutation<
    StakeEthereumMutation,
    TError,
    StakeEthereumMutationVariables,
    TContext
  >(
    ["StakeEthereum"],
    useFetchData<StakeEthereumMutation, StakeEthereumMutationVariables>(
      StakeEthereumDocument
    ),
    options
  );
useStakeEthereumMutation.getKey = () => ["StakeEthereum"];

export const StakeEthereumSetTxHashDocument = `
    mutation StakeEthereumSetTxHash($id: String!, $txHash: String!, $amountEth: Int!, $fundingAddress: String!) {
  ethereumStakingRequestSetTxHash(
    input: {id: $id, txHash: $txHash, amountEth: $amountEth, fundingAddress: $fundingAddress}
  ) {
    data {
      id
    }
    userErrors {
      code
      field
      message
      path
    }
  }
}
    `;
export const useStakeEthereumSetTxHashMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    StakeEthereumSetTxHashMutation,
    TError,
    StakeEthereumSetTxHashMutationVariables,
    TContext
  >
) =>
  useMutation<
    StakeEthereumSetTxHashMutation,
    TError,
    StakeEthereumSetTxHashMutationVariables,
    TContext
  >(
    ["StakeEthereumSetTxHash"],
    useFetchData<
      StakeEthereumSetTxHashMutation,
      StakeEthereumSetTxHashMutationVariables
    >(StakeEthereumSetTxHashDocument),
    options
  );
useStakeEthereumSetTxHashMutation.getKey = () => ["StakeEthereumSetTxHash"];

export const UnstakeEthereumDocument = `
    mutation UnstakeEthereum($network: Networks!, $amountEth: Int!, $withdrawalAddress: String!) {
  ethereumExitRequestCreate(
    input: {network: $network, amountEth: $amountEth, withdrawalAddress: $withdrawalAddress}
  ) {
    data {
      amountEth
      network
      withdrawalAddress
    }
    userErrors {
      code
      extras
      field
      message
      path
    }
  }
}
    `;
export const useUnstakeEthereumMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UnstakeEthereumMutation,
    TError,
    UnstakeEthereumMutationVariables,
    TContext
  >
) =>
  useMutation<
    UnstakeEthereumMutation,
    TError,
    UnstakeEthereumMutationVariables,
    TContext
  >(
    ["UnstakeEthereum"],
    useFetchData<UnstakeEthereumMutation, UnstakeEthereumMutationVariables>(
      UnstakeEthereumDocument
    ),
    options
  );
useUnstakeEthereumMutation.getKey = () => ["UnstakeEthereum"];

export const AuthUserDocument = `
    query AuthUser {
  me {
    user {
      id
      name
      email
      auth0Id
      acceptedTosAt
      riskAssessmentPassed
      createdAt
    }
    organization {
      id
      name
      displayName
      isIndividual
      riskAssessmentPassed
      usesOnchainBilling
      devmodeEnabled
    }
    role {
      name
    }
  }
  commission {
    organizationConfigurationId
  }
  vaults(network: mainnet, first: 1) {
    nodes {
      id
      address
    }
  }
}
    `;
export const useAuthUserQuery = <TData = AuthUserQuery, TError = unknown>(
  variables?: AuthUserQueryVariables,
  options?: UseQueryOptions<AuthUserQuery, TError, TData>
) =>
  useQuery<AuthUserQuery, TError, TData>(
    variables === undefined ? ["AuthUser"] : ["AuthUser", variables],
    useFetchData<AuthUserQuery, AuthUserQueryVariables>(AuthUserDocument).bind(
      null,
      variables
    ),
    options
  );

useAuthUserQuery.getKey = (variables?: AuthUserQueryVariables) =>
  variables === undefined ? ["AuthUser"] : ["AuthUser", variables];
export const ConfirmActivatedVaultDocument = `
    query ConfirmActivatedVault($address: String!, $network: EthereumNetworks!) {
  vault(filter: {address: $address, network: $network}) {
    id
    feeRecipientAddress
    status
  }
}
    `;
export const useConfirmActivatedVaultQuery = <
  TData = ConfirmActivatedVaultQuery,
  TError = unknown
>(
  variables: ConfirmActivatedVaultQueryVariables,
  options?: UseQueryOptions<ConfirmActivatedVaultQuery, TError, TData>
) =>
  useQuery<ConfirmActivatedVaultQuery, TError, TData>(
    ["ConfirmActivatedVault", variables],
    useFetchData<
      ConfirmActivatedVaultQuery,
      ConfirmActivatedVaultQueryVariables
    >(ConfirmActivatedVaultDocument).bind(null, variables),
    options
  );

useConfirmActivatedVaultQuery.getKey = (
  variables: ConfirmActivatedVaultQueryVariables
) => ["ConfirmActivatedVault", variables];
export const ConfirmDeployedVaultDocument = `
    query ConfirmDeployedVault($transactionHash: String!, $network: EthereumNetworks!) {
  vault(filter: {transaction_hash: $transactionHash, network: $network}) {
    id
    address
  }
}
    `;
export const useConfirmDeployedVaultQuery = <
  TData = ConfirmDeployedVaultQuery,
  TError = unknown
>(
  variables: ConfirmDeployedVaultQueryVariables,
  options?: UseQueryOptions<ConfirmDeployedVaultQuery, TError, TData>
) =>
  useQuery<ConfirmDeployedVaultQuery, TError, TData>(
    ["ConfirmDeployedVault", variables],
    useFetchData<ConfirmDeployedVaultQuery, ConfirmDeployedVaultQueryVariables>(
      ConfirmDeployedVaultDocument
    ).bind(null, variables),
    options
  );

useConfirmDeployedVaultQuery.getKey = (
  variables: ConfirmDeployedVaultQueryVariables
) => ["ConfirmDeployedVault", variables];
export const DailyReportsDocument = `
    query DailyReports {
  dailyReports {
    nodes {
      id
      network
      chainId
      start
      status
      timeRollup
      end
      file
      fileExpiresOn
      expiresOn
    }
  }
}
    `;
export const useDailyReportsQuery = <
  TData = DailyReportsQuery,
  TError = unknown
>(
  variables?: DailyReportsQueryVariables,
  options?: UseQueryOptions<DailyReportsQuery, TError, TData>
) =>
  useQuery<DailyReportsQuery, TError, TData>(
    variables === undefined ? ["DailyReports"] : ["DailyReports", variables],
    useFetchData<DailyReportsQuery, DailyReportsQueryVariables>(
      DailyReportsDocument
    ).bind(null, variables),
    options
  );

useDailyReportsQuery.getKey = (variables?: DailyReportsQueryVariables) =>
  variables === undefined ? ["DailyReports"] : ["DailyReports", variables];
export const EigenLayerRestakingButtonDocument = `
    query EigenLayerRestakingButton($address: String!, $network: EthereumNetworks!) {
  ethereumWithdrawalAccount(address: $address, network: $network) {
    ...EigenLayerRestakingButtonEthereumWithdrawalAccount
  }
}
    ${EigenLayerRestakingButtonEthereumWithdrawalAccountFragmentDoc}`;
export const useEigenLayerRestakingButtonQuery = <
  TData = EigenLayerRestakingButtonQuery,
  TError = unknown
>(
  variables: EigenLayerRestakingButtonQueryVariables,
  options?: UseQueryOptions<EigenLayerRestakingButtonQuery, TError, TData>
) =>
  useQuery<EigenLayerRestakingButtonQuery, TError, TData>(
    ["EigenLayerRestakingButton", variables],
    useFetchData<
      EigenLayerRestakingButtonQuery,
      EigenLayerRestakingButtonQueryVariables
    >(EigenLayerRestakingButtonDocument).bind(null, variables),
    options
  );

useEigenLayerRestakingButtonQuery.getKey = (
  variables: EigenLayerRestakingButtonQueryVariables
) => ["EigenLayerRestakingButton", variables];
export const EthereumStakingPositionsTableDocument = `
    query EthereumStakingPositionsTable($network: String!, $after: String, $before: String, $first: Int, $last: Int) {
  ethereumWithdrawalAccounts(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: {protocol_eq: "ethereum", network_eq: $network}
    orderBy: {field: createdAt, sortDirection: desc}
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      network
      protocol
      address
      totalRewards
      totalRewardsUsd
      stakedBalance
      stakedBalanceUsd
      eigenpodOwnerAddress
      isEigenpod
      delegatedTo
      totalActiveValidators
      totalVerifiedValidators
    }
  }
}
    `;
export const useEthereumStakingPositionsTableQuery = <
  TData = EthereumStakingPositionsTableQuery,
  TError = unknown
>(
  variables: EthereumStakingPositionsTableQueryVariables,
  options?: UseQueryOptions<EthereumStakingPositionsTableQuery, TError, TData>
) =>
  useQuery<EthereumStakingPositionsTableQuery, TError, TData>(
    ["EthereumStakingPositionsTable", variables],
    useFetchData<
      EthereumStakingPositionsTableQuery,
      EthereumStakingPositionsTableQueryVariables
    >(EthereumStakingPositionsTableDocument).bind(null, variables),
    options
  );

useEthereumStakingPositionsTableQuery.getKey = (
  variables: EthereumStakingPositionsTableQueryVariables
) => ["EthereumStakingPositionsTable", variables];
export const MfaMethodsDocument = `
    query MFAMethods {
  me {
    user {
      mfaMethods
    }
  }
}
    `;
export const useMfaMethodsQuery = <TData = MfaMethodsQuery, TError = unknown>(
  variables?: MfaMethodsQueryVariables,
  options?: UseQueryOptions<MfaMethodsQuery, TError, TData>
) =>
  useQuery<MfaMethodsQuery, TError, TData>(
    variables === undefined ? ["MFAMethods"] : ["MFAMethods", variables],
    useFetchData<MfaMethodsQuery, MfaMethodsQueryVariables>(
      MfaMethodsDocument
    ).bind(null, variables),
    options
  );

useMfaMethodsQuery.getKey = (variables?: MfaMethodsQueryVariables) =>
  variables === undefined ? ["MFAMethods"] : ["MFAMethods", variables];
export const MonthlyStatementsDocument = `
    query MonthlyStatements($after: String, $before: String, $first: Int, $last: Int, $orderBy: [MonthlyStatements!]) {
  monthlyStatements(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
  ) {
    nodes {
      ...MonthlyStatement
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${MonthlyStatementFragmentDoc}`;
export const useMonthlyStatementsQuery = <
  TData = MonthlyStatementsQuery,
  TError = unknown
>(
  variables?: MonthlyStatementsQueryVariables,
  options?: UseQueryOptions<MonthlyStatementsQuery, TError, TData>
) =>
  useQuery<MonthlyStatementsQuery, TError, TData>(
    variables === undefined
      ? ["MonthlyStatements"]
      : ["MonthlyStatements", variables],
    useFetchData<MonthlyStatementsQuery, MonthlyStatementsQueryVariables>(
      MonthlyStatementsDocument
    ).bind(null, variables),
    options
  );

useMonthlyStatementsQuery.getKey = (
  variables?: MonthlyStatementsQueryVariables
) =>
  variables === undefined
    ? ["MonthlyStatements"]
    : ["MonthlyStatements", variables];
export const NetworkOverviewDocument = `
    query NetworkOverview {
  ethereumNetworkEstimates {
    estimatedActivation {
      hoursMin
      hoursMax
    }
    estimatedWithdrawal {
      hoursMin
      hoursMax
    }
    estimatedExit {
      hoursMin
      hoursMax
    }
  }
}
    `;
export const useNetworkOverviewQuery = <
  TData = NetworkOverviewQuery,
  TError = unknown
>(
  variables?: NetworkOverviewQueryVariables,
  options?: UseQueryOptions<NetworkOverviewQuery, TError, TData>
) =>
  useQuery<NetworkOverviewQuery, TError, TData>(
    variables === undefined
      ? ["NetworkOverview"]
      : ["NetworkOverview", variables],
    useFetchData<NetworkOverviewQuery, NetworkOverviewQueryVariables>(
      NetworkOverviewDocument
    ).bind(null, variables),
    options
  );

useNetworkOverviewQuery.getKey = (variables?: NetworkOverviewQueryVariables) =>
  variables === undefined
    ? ["NetworkOverview"]
    : ["NetworkOverview", variables];
export const DashboardInitializationDocument = `
    query DashboardInitialization {
  devnetSolanaStakeAccounts: solanaStakeAccounts(network: devnet) {
    totalCount
  }
  mainnetSolanaStakeAccounts: solanaStakeAccounts(network: mainnet) {
    totalCount
  }
  testnetEthereumWithdrawalAccounts: ethereumWithdrawalAccounts(
    filter: {protocol_eq: "ethereum", network_eq: "holesky"}
  ) {
    totalCount
  }
  mainnetEthereumWithdrawalAccounts: ethereumWithdrawalAccounts(
    filter: {protocol_eq: "ethereum", network_eq: "mainnet"}
  ) {
    totalCount
  }
  devnetSolanaRewardsSummary: solanaRewardsSummary(network: devnet) {
    totalRewards
  }
  mainnetSolanaRewardsSummary: solanaRewardsSummary(network: mainnet) {
    totalRewards
  }
  testnetEthereumRewardsSummary: rewardsSummary(network: "holesky") {
    totalRewards
  }
  mainnetEthereumRewardsSummary: rewardsSummary(network: "mainnet") {
    totalRewards
  }
}
    `;
export const useDashboardInitializationQuery = <
  TData = DashboardInitializationQuery,
  TError = unknown
>(
  variables?: DashboardInitializationQueryVariables,
  options?: UseQueryOptions<DashboardInitializationQuery, TError, TData>
) =>
  useQuery<DashboardInitializationQuery, TError, TData>(
    variables === undefined
      ? ["DashboardInitialization"]
      : ["DashboardInitialization", variables],
    useFetchData<
      DashboardInitializationQuery,
      DashboardInitializationQueryVariables
    >(DashboardInitializationDocument).bind(null, variables),
    options
  );

useDashboardInitializationQuery.getKey = (
  variables?: DashboardInitializationQueryVariables
) =>
  variables === undefined
    ? ["DashboardInitialization"]
    : ["DashboardInitialization", variables];
export const MembersDocument = `
    query Members($after: String, $before: String, $first: Int, $last: Int, $orderBy: [Members!], $excludeInternalMembers: Boolean) {
  members(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    excludeInternalMembers: $excludeInternalMembers
  ) {
    nodes {
      role {
        name
        id
      }
      organization {
        id
        displayName
      }
      user {
        createdAt
        email
        name
        id
      }
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    `;
export const useMembersQuery = <TData = MembersQuery, TError = unknown>(
  variables?: MembersQueryVariables,
  options?: UseQueryOptions<MembersQuery, TError, TData>
) =>
  useQuery<MembersQuery, TError, TData>(
    variables === undefined ? ["Members"] : ["Members", variables],
    useFetchData<MembersQuery, MembersQueryVariables>(MembersDocument).bind(
      null,
      variables
    ),
    options
  );

useMembersQuery.getKey = (variables?: MembersQueryVariables) =>
  variables === undefined ? ["Members"] : ["Members", variables];
export const RolesDocument = `
    query Roles {
  roles {
    nodes {
      id
      name
    }
  }
}
    `;
export const useRolesQuery = <TData = RolesQuery, TError = unknown>(
  variables?: RolesQueryVariables,
  options?: UseQueryOptions<RolesQuery, TError, TData>
) =>
  useQuery<RolesQuery, TError, TData>(
    variables === undefined ? ["Roles"] : ["Roles", variables],
    useFetchData<RolesQuery, RolesQueryVariables>(RolesDocument).bind(
      null,
      variables
    ),
    options
  );

useRolesQuery.getKey = (variables?: RolesQueryVariables) =>
  variables === undefined ? ["Roles"] : ["Roles", variables];
export const InvitationsDocument = `
    query Invitations($after: String, $before: String, $first: Int, $last: Int, $orderBy: [Invitations!], $excludeInternalMembers: Boolean) {
  invitations(
    after: $after
    before: $before
    first: $first
    last: $last
    orderBy: $orderBy
    excludeInternalMembers: $excludeInternalMembers
  ) {
    nodes {
      ...InvitationDetail
    }
    pageInfo {
      endCursor
      startCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${InvitationDetailFragmentDoc}`;
export const useInvitationsQuery = <TData = InvitationsQuery, TError = unknown>(
  variables?: InvitationsQueryVariables,
  options?: UseQueryOptions<InvitationsQuery, TError, TData>
) =>
  useQuery<InvitationsQuery, TError, TData>(
    variables === undefined ? ["Invitations"] : ["Invitations", variables],
    useFetchData<InvitationsQuery, InvitationsQueryVariables>(
      InvitationsDocument
    ).bind(null, variables),
    options
  );

useInvitationsQuery.getKey = (variables?: InvitationsQueryVariables) =>
  variables === undefined ? ["Invitations"] : ["Invitations", variables];
export const ProjectsDocument = `
    query Projects($orderBy: [Projects!]) {
  projects(orderBy: $orderBy) {
    totalCount
    nodes {
      id
      name
      environment
      readOnly
      apiKeys {
        nodes {
          value
        }
      }
    }
  }
}
    `;
export const useProjectsQuery = <TData = ProjectsQuery, TError = unknown>(
  variables?: ProjectsQueryVariables,
  options?: UseQueryOptions<ProjectsQuery, TError, TData>
) =>
  useQuery<ProjectsQuery, TError, TData>(
    variables === undefined ? ["Projects"] : ["Projects", variables],
    useFetchData<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument).bind(
      null,
      variables
    ),
    options
  );

useProjectsQuery.getKey = (variables?: ProjectsQueryVariables) =>
  variables === undefined ? ["Projects"] : ["Projects", variables];
export const SolanaStakingPositionsTableDocument = `
    query SolanaStakingPositionsTable($network: SolanaNetworks!, $after: String, $before: String, $first: Int, $last: Int) {
  solanaStakeAccounts(
    first: $first
    last: $last
    after: $after
    before: $before
    network: $network
  ) {
    totalCount
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    nodes {
      id
      network
      address
      totalRewards
      totalRewardsUsd
      balance
      balanceUsd
      status
      stakeAuthorityAddress
      withdrawAuthorityAddress
      user {
        email
      }
    }
  }
}
    `;
export const useSolanaStakingPositionsTableQuery = <
  TData = SolanaStakingPositionsTableQuery,
  TError = unknown
>(
  variables: SolanaStakingPositionsTableQueryVariables,
  options?: UseQueryOptions<SolanaStakingPositionsTableQuery, TError, TData>
) =>
  useQuery<SolanaStakingPositionsTableQuery, TError, TData>(
    ["SolanaStakingPositionsTable", variables],
    useFetchData<
      SolanaStakingPositionsTableQuery,
      SolanaStakingPositionsTableQueryVariables
    >(SolanaStakingPositionsTableDocument).bind(null, variables),
    options
  );

useSolanaStakingPositionsTableQuery.getKey = (
  variables: SolanaStakingPositionsTableQueryVariables
) => ["SolanaStakingPositionsTable", variables];
export const GetStakingActivityDocument = `
    query GetStakingActivity($after: String, $before: String, $first: Int, $last: Int, $protocol: String, $network: Networks) {
  stakingActivity(
    after: $after
    before: $before
    first: $first
    last: $last
    protocol: $protocol
    network: $network
    minDepositStatus: unfinalized
  ) {
    ...GetStakingActivityConnection
  }
}
    ${GetStakingActivityConnectionFragmentDoc}`;
export const useGetStakingActivityQuery = <
  TData = GetStakingActivityQuery,
  TError = unknown
>(
  variables?: GetStakingActivityQueryVariables,
  options?: UseQueryOptions<GetStakingActivityQuery, TError, TData>
) =>
  useQuery<GetStakingActivityQuery, TError, TData>(
    variables === undefined
      ? ["GetStakingActivity"]
      : ["GetStakingActivity", variables],
    useFetchData<GetStakingActivityQuery, GetStakingActivityQueryVariables>(
      GetStakingActivityDocument
    ).bind(null, variables),
    options
  );

useGetStakingActivityQuery.getKey = (
  variables?: GetStakingActivityQueryVariables
) =>
  variables === undefined
    ? ["GetStakingActivity"]
    : ["GetStakingActivity", variables];
export const StakingDashboardPageV2Document = `
    query StakingDashboardPageV2($network: String!) {
  ethereumPrice {
    ...ValidatorSummaryPrice
  }
  rewardsSummary(network: $network) {
    ...TotalRewardsEarnedRewardsSummary
  }
  validatorCounts(network: $network) {
    ...ValidatorSummaryValidatorCounts
  }
  ...RewardsEarnedChart
}
    ${ValidatorSummaryPriceFragmentDoc}
${TotalRewardsEarnedRewardsSummaryFragmentDoc}
${ValidatorSummaryValidatorCountsFragmentDoc}
${RewardsEarnedChartFragmentDoc}`;
export const useStakingDashboardPageV2Query = <
  TData = StakingDashboardPageV2Query,
  TError = unknown
>(
  variables: StakingDashboardPageV2QueryVariables,
  options?: UseQueryOptions<StakingDashboardPageV2Query, TError, TData>
) =>
  useQuery<StakingDashboardPageV2Query, TError, TData>(
    ["StakingDashboardPageV2", variables],
    useFetchData<
      StakingDashboardPageV2Query,
      StakingDashboardPageV2QueryVariables
    >(StakingDashboardPageV2Document).bind(null, variables),
    options
  );

useStakingDashboardPageV2Query.getKey = (
  variables: StakingDashboardPageV2QueryVariables
) => ["StakingDashboardPageV2", variables];
export const StakingModalDocument = `
    query StakingModal {
  ethereumPrice {
    date
    value
  }
  ethereumRewardsRate {
    value
  }
}
    `;
export const useStakingModalQuery = <
  TData = StakingModalQuery,
  TError = unknown
>(
  variables?: StakingModalQueryVariables,
  options?: UseQueryOptions<StakingModalQuery, TError, TData>
) =>
  useQuery<StakingModalQuery, TError, TData>(
    variables === undefined ? ["StakingModal"] : ["StakingModal", variables],
    useFetchData<StakingModalQuery, StakingModalQueryVariables>(
      StakingModalDocument
    ).bind(null, variables),
    options
  );

useStakingModalQuery.getKey = (variables?: StakingModalQueryVariables) =>
  variables === undefined ? ["StakingModal"] : ["StakingModal", variables];
export const StakingPositionModalDocument = `
    query StakingPositionModal($address: String!, $network: String!, $after: String, $before: String, $first: Int, $last: Int, $orderBy: [Validators!], $status_in: [ValidatorStatus!]) {
  trackedValidators(
    filter: {withdrawal_account_address_i_eq: $address, network_eq: $network, status_not_in: [provisioned, funding_requested], status_in: $status_in}
    orderBy: $orderBy
    first: $first
    after: $after
    before: $before
    last: $last
  ) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    nodes {
      ...StakingPositionModalValidator
    }
  }
  validatorCounts(network: $network, ethAccount: $address) {
    ...ValidatorSummaryValidatorCounts
  }
}
    ${StakingPositionModalValidatorFragmentDoc}
${ValidatorSummaryValidatorCountsFragmentDoc}`;
export const useStakingPositionModalQuery = <
  TData = StakingPositionModalQuery,
  TError = unknown
>(
  variables: StakingPositionModalQueryVariables,
  options?: UseQueryOptions<StakingPositionModalQuery, TError, TData>
) =>
  useQuery<StakingPositionModalQuery, TError, TData>(
    ["StakingPositionModal", variables],
    useFetchData<StakingPositionModalQuery, StakingPositionModalQueryVariables>(
      StakingPositionModalDocument
    ).bind(null, variables),
    options
  );

useStakingPositionModalQuery.getKey = (
  variables: StakingPositionModalQueryVariables
) => ["StakingPositionModal", variables];
export const StakingPositionsDocument = `
    query StakingPositions($ethereumRewardsNetwork: String!, $ethereumBalanceNetwork: EthereumNetworks!, $solanaNetwork: SolanaNetworks!) {
  ethereumRewardsRate {
    value
  }
  rewardsSummary(network: $ethereumRewardsNetwork) {
    totalRewards
    totalRewardsUsd
  }
  ethereumBalanceSummary(network: $ethereumBalanceNetwork) {
    balance
    balanceUsd
  }
  solanaRewardsRate {
    value
  }
  solanaRewardsSummary(network: $solanaNetwork) {
    totalRewards
    totalRewardsUsd
  }
  solanaBalanceSummary(network: $solanaNetwork) {
    balance
    balanceUsd
  }
}
    `;
export const useStakingPositionsQuery = <
  TData = StakingPositionsQuery,
  TError = unknown
>(
  variables: StakingPositionsQueryVariables,
  options?: UseQueryOptions<StakingPositionsQuery, TError, TData>
) =>
  useQuery<StakingPositionsQuery, TError, TData>(
    ["StakingPositions", variables],
    useFetchData<StakingPositionsQuery, StakingPositionsQueryVariables>(
      StakingPositionsDocument
    ).bind(null, variables),
    options
  );

useStakingPositionsQuery.getKey = (
  variables: StakingPositionsQueryVariables
) => ["StakingPositions", variables];
export const UnstakeEthButtonAggregateValidatorInfoDocument = `
    query UnstakeEthButtonAggregateValidatorInfo($ethAccount: String!, $network: String!, $ethereumNetwork: EthereumNetworks!) {
  validatorCounts(
    ethAccount: $ethAccount
    network: $network
    provisionedByMyOrganization: true
  ) {
    exitable
  }
  ethereumWithdrawalAccount(address: $ethAccount, network: $ethereumNetwork) {
    id
    isVault
  }
}
    `;
export const useUnstakeEthButtonAggregateValidatorInfoQuery = <
  TData = UnstakeEthButtonAggregateValidatorInfoQuery,
  TError = unknown
>(
  variables: UnstakeEthButtonAggregateValidatorInfoQueryVariables,
  options?: UseQueryOptions<
    UnstakeEthButtonAggregateValidatorInfoQuery,
    TError,
    TData
  >
) =>
  useQuery<UnstakeEthButtonAggregateValidatorInfoQuery, TError, TData>(
    ["UnstakeEthButtonAggregateValidatorInfo", variables],
    useFetchData<
      UnstakeEthButtonAggregateValidatorInfoQuery,
      UnstakeEthButtonAggregateValidatorInfoQueryVariables
    >(UnstakeEthButtonAggregateValidatorInfoDocument).bind(null, variables),
    options
  );

useUnstakeEthButtonAggregateValidatorInfoQuery.getKey = (
  variables: UnstakeEthButtonAggregateValidatorInfoQueryVariables
) => ["UnstakeEthButtonAggregateValidatorInfo", variables];
export const UnstakeEthStakingEthTokenInfoDocument = `
    query UnstakeEthStakingEthTokenInfo {
  ethereumPrice {
    value
  }
}
    `;
export const useUnstakeEthStakingEthTokenInfoQuery = <
  TData = UnstakeEthStakingEthTokenInfoQuery,
  TError = unknown
>(
  variables?: UnstakeEthStakingEthTokenInfoQueryVariables,
  options?: UseQueryOptions<UnstakeEthStakingEthTokenInfoQuery, TError, TData>
) =>
  useQuery<UnstakeEthStakingEthTokenInfoQuery, TError, TData>(
    variables === undefined
      ? ["UnstakeEthStakingEthTokenInfo"]
      : ["UnstakeEthStakingEthTokenInfo", variables],
    useFetchData<
      UnstakeEthStakingEthTokenInfoQuery,
      UnstakeEthStakingEthTokenInfoQueryVariables
    >(UnstakeEthStakingEthTokenInfoDocument).bind(null, variables),
    options
  );

useUnstakeEthStakingEthTokenInfoQuery.getKey = (
  variables?: UnstakeEthStakingEthTokenInfoQueryVariables
) =>
  variables === undefined
    ? ["UnstakeEthStakingEthTokenInfo"]
    : ["UnstakeEthStakingEthTokenInfo", variables];
export const CreateVaultFlowDocument = `
    query CreateVaultFlow($address: String!, $network: EthereumNetworks!) {
  vault(filter: {network: $network, address: $address}) {
    id
    status
    address
    createdAt
    feeBps
    feeRecipientAddress
    network
    region
    transactionHash
    updatedAt
    validatorsManagerAddress
    vaultAdmin {
      id
      address
      organization {
        id
        name
      }
    }
  }
}
    `;
export const useCreateVaultFlowQuery = <
  TData = CreateVaultFlowQuery,
  TError = unknown
>(
  variables: CreateVaultFlowQueryVariables,
  options?: UseQueryOptions<CreateVaultFlowQuery, TError, TData>
) =>
  useQuery<CreateVaultFlowQuery, TError, TData>(
    ["CreateVaultFlow", variables],
    useFetchData<CreateVaultFlowQuery, CreateVaultFlowQueryVariables>(
      CreateVaultFlowDocument
    ).bind(null, variables),
    options
  );

useCreateVaultFlowQuery.getKey = (variables: CreateVaultFlowQueryVariables) => [
  "CreateVaultFlow",
  variables,
];
export const UnstakeEthAggregateValidatorInfoDocument = `
    query UnstakeEthAggregateValidatorInfo($ethAccount: String!, $network: String!) {
  validatorCounts(
    ethAccount: $ethAccount
    network: $network
    provisionedByMyOrganization: true
  ) {
    exitable
  }
}
    `;
export const useUnstakeEthAggregateValidatorInfoQuery = <
  TData = UnstakeEthAggregateValidatorInfoQuery,
  TError = unknown
>(
  variables: UnstakeEthAggregateValidatorInfoQueryVariables,
  options?: UseQueryOptions<
    UnstakeEthAggregateValidatorInfoQuery,
    TError,
    TData
  >
) =>
  useQuery<UnstakeEthAggregateValidatorInfoQuery, TError, TData>(
    ["UnstakeEthAggregateValidatorInfo", variables],
    useFetchData<
      UnstakeEthAggregateValidatorInfoQuery,
      UnstakeEthAggregateValidatorInfoQueryVariables
    >(UnstakeEthAggregateValidatorInfoDocument).bind(null, variables),
    options
  );

useUnstakeEthAggregateValidatorInfoQuery.getKey = (
  variables: UnstakeEthAggregateValidatorInfoQueryVariables
) => ["UnstakeEthAggregateValidatorInfo", variables];
export const VaultDetailsDocument = `
    query VaultDetails($network: EthereumNetworks!, $address: String!) {
  vault(filter: {address: $address, network: $network}) {
    network
    region
    createdAt
    feeBps
    validatorsManagerAddress
    vaultAdmin {
      address
    }
    rewardSplitter {
      address
    }
    status
  }
  commission {
    figmentShares
    shareholderShares
    shareholderFeeRecipientAddress
  }
}
    `;
export const useVaultDetailsQuery = <
  TData = VaultDetailsQuery,
  TError = unknown
>(
  variables: VaultDetailsQueryVariables,
  options?: UseQueryOptions<VaultDetailsQuery, TError, TData>
) =>
  useQuery<VaultDetailsQuery, TError, TData>(
    ["VaultDetails", variables],
    useFetchData<VaultDetailsQuery, VaultDetailsQueryVariables>(
      VaultDetailsDocument
    ).bind(null, variables),
    options
  );

useVaultDetailsQuery.getKey = (variables: VaultDetailsQueryVariables) => [
  "VaultDetails",
  variables,
];
export const VaultsDocument = `
    query Vaults($network: EthereumNetworks!) {
  firstTestnetVault: vaults(network: holesky, first: 1) {
    nodes {
      address
    }
  }
  firstMainnetVault: vaults(network: mainnet, first: 1) {
    nodes {
      address
    }
  }
  vaults: vaults(network: $network) {
    nodes {
      address
    }
  }
}
    `;
export const useVaultsQuery = <TData = VaultsQuery, TError = unknown>(
  variables: VaultsQueryVariables,
  options?: UseQueryOptions<VaultsQuery, TError, TData>
) =>
  useQuery<VaultsQuery, TError, TData>(
    ["Vaults", variables],
    useFetchData<VaultsQuery, VaultsQueryVariables>(VaultsDocument).bind(
      null,
      variables
    ),
    options
  );

useVaultsQuery.getKey = (variables: VaultsQueryVariables) => [
  "Vaults",
  variables,
];
