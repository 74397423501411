import React from "react";
import { components, ControlProps, GroupBase } from "react-select";
import { twMerge as tw } from "tailwind-merge";
import { OptionType } from "../InputSelect.types";

const Control = <TOptionType extends OptionType>(
  props: ControlProps<TOptionType, false, GroupBase<TOptionType>>
) => {
  const { selectProps } = props;
  const { error } = selectProps;

  return (
    <components.Control
      {...props}
      className={tw(
        "appearance-none bg-white rounded border text-small text-black font-normal ring-green",
        props.isDisabled && "cursor-not-allowed border-basic-300 shadow-none",
        error ? "border-error ring-error" : "border-black",
        props.isFocused && "shadow-md outline-none ring-1"
      )}
    >
      <div className="flex h-full w-full text-small text-black font-normal mx-2">
        {props.children}
      </div>
    </components.Control>
  );
};

export default Control;
