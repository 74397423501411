import * as React from "react";

export enum Layout {
  horizontal = "horizontal",
  vertical = "vertical",
}

type StandardLabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;

export type FieldProps = StandardLabelProps & {
  /**
   * The field label.
   */
  label?: React.ReactNode;
  /**
   * The children to render inside the field.
   */
  children: React.ReactNode;
  /**
   * Display additional details next to the label.
   */
  details?: React.ReactNode;
  /**
   * The layout of the field.
   * Defaults to `vertical`.
   */
  layout?: keyof typeof Layout;
  /**
   * If `true`, the label is displayed in an error state.
   */
  error?: boolean;
  /**
   * if `true`, the label will indicate that the `input` is required.
   */
  required?: boolean;
  /**
   * Visually hide the label (still visible to screen-readers).
   */
  hideLabel?: boolean;
  /**
   * The ID of the corresponding input element.
   */
  htmlFor?: string;
  /**
   * The data-testid attribute for testing purposes.
   */
  testId?: string;
};
