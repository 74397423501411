"use client";

import React, { useRef } from "react";

import { BigNumber } from "bignumber.js";
import { compareAsc } from "date-fns";
import { BarChart, BodyText, Icon } from "@figmentjs/web-core";
import { currencyFormatterCompact } from "@figmentjs/utils";

import {
  AggregatedRewardsTuple,
  TimePeriod,
  useAggregatedRewardsQuery,
} from "../../../../graphql/partners/generated/gql";
import Providers from "@figmentjs/web-core/src/components/providers";
import { xTickFormatter } from "./chart.utils";
import ChartTooltip from "./chart-tooltip";

type Props = {
  timePeriod: TimePeriod;
  dateLabelFormat: string;
};

export const Chart: React.FC<Props> = ({
  timePeriod,
  dateLabelFormat = "MMM d",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { network } = Providers.useEthereumWallet();

  const { data, isLoading } = useAggregatedRewardsQuery({
    network,
    timePeriod,
  });

  if (isLoading) {
    return null;
  }

  if (!data?.aggregatedRewards.rewards?.length) {
    return (
      <div className="mt-12 grid place-items-center">
        <Icon icon="ChartNoData" />
        <div className="pt-12 pb-8 max-w-[520px] text-center">
          <BodyText size="lg" as="p">
            No data for this duration.
          </BodyText>
        </div>
      </div>
    );
  }

  const getDate = ({ timestamp }: AggregatedRewardsTuple) => timestamp;

  const yDomainFunc = (rewards: AggregatedRewardsTuple[]) => {
    const totalsUsd = rewards.map((d) =>
      new BigNumber(d?.total || "0")
        .times(data.ethereumPrice.value || "0")
        .toString()
    );
    return [0, BigNumber.max(...(totalsUsd || [])).toNumber()];
  };

  return (
    <div
      className="mt-7"
      ref={containerRef}
      data-testid={`chart-${timePeriod}`}
    >
      <BarChart
        data={data.aggregatedRewards.rewards}
        keys={["ETHEREUM"]}
        xDomainFunc={(data) =>
          data.map(getDate).sort((a, b) => compareAsc(new Date(a), new Date(b)))
        }
        xTickFormatter={(date, index) =>
          xTickFormatter({
            date,
            index,
            width: containerRef?.current?.clientWidth || 0,
            dateLabelFormat,
            timePeriod,
          })
        }
        getXValue={getDate}
        yDomainFunc={yDomainFunc}
        yTickFormatter={(value: number) =>
          currencyFormatterCompact.format(value)
        }
        getValue={(dataPoint) => {
          const rewardUsd = new BigNumber(dataPoint.total || "0")
            .times(data.ethereumPrice.value || "0")
            .toString();
          return parseFloat(rewardUsd || "0");
        }}
        showLegend={false}
        renderTooltipChildren={({ tooltipData }) => {
          return (
            <ChartTooltip
              tooltipData={tooltipData}
              price={data.ethereumPrice.value}
              dateLabelFormat={dateLabelFormat}
            />
          );
        }}
      />
    </div>
  );
};
