import React from "react";
import { hashQueryKey } from "@tanstack/react-query";
import { Network } from "@figmentjs/protocols";
import {
  Table,
  TableShimmer,
  BodyText,
} from "@figmentjs/web-core/src/components";
import Providers from "@figmentjs/web-core/src/components/providers";
import {
  useStakingActivityV2Query,
  StakingActivityV2ConnectionNodeFragment,
  Networks,
} from "../../../../graphql/partners/generated/gql";
import { columns } from "./activity-table.columns";
import NoActivityData from "./no-activity-data";

export const networkMap: {
  [key in Network]?: Networks;
} = {
  [Network.MAINNET]: Networks.Mainnet,
  [Network.GOERLI]: Networks.Goerli,
  [Network.HOLESKY]: Networks.Holesky,
};

export const ActivityTable: React.FC = () => {
  const { account, network } = Providers.useEthereumWallet();

  const { data: stakingActivityData, isLoading: isStakingActivityLoading } =
    useStakingActivityV2Query(
      {
        network: networkMap[network!]!,
      },
      {
        refetchInterval: 60 * 1000,
        enabled: !!(account && network),
        queryKeyHashFn: () =>
          hashQueryKey(["StakingActivityV2", networkMap[network!], account]),
      }
    );

  if (isStakingActivityLoading) {
    return (
      <div className="mt-10">
        <TableShimmer rows={5} />
      </div>
    );
  }

  const stakingActivities = stakingActivityData?.stakingActivity.nodes ?? [];

  if (!stakingActivities.length) {
    return <NoActivityData />;
  }

  return (
    <div className="mt-10">
      <div className="hidden sm:block">
        <BodyText size="lg" weight="bold">
          Activity
        </BodyText>
      </div>
      <div className="block sm:hidden">
        <BodyText size="base" weight="bold">
          Activity
        </BodyText>
      </div>
      <Table<StakingActivityV2ConnectionNodeFragment>
        cols={columns}
        rows={stakingActivities}
        containerClassName="pt-6 overflow-x-scroll"
        darkenOddRows={false}
        cellSpacing
      />
    </div>
  );
};
