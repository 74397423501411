import * as React from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { twMerge as tw } from "tailwind-merge";

import {
  ContentComponent,
  Tab,
  TabPlacements,
  TabsComponent,
  TabSizes,
  TabVariants,
} from "./Tabs.types";
import { Tooltip } from "../../overlays";

const Tabs: TabsComponent = ({
  children,
  tabs,
  variant = TabVariants.primary,
  onTabChange,
  placement = TabPlacements.start,
  showBorder = true,
  size = "responsive",
  disabled: areTabsDisabled = false,
  beforeTabs,
  afterTabs,
}) => {
  const selectedTabIdFromProps = (
    tabs.find((tab: Tab) => tab.selected) || tabs[0]
  ).id;
  const [selectedTabId, setSelectedTabId] = React.useState<Tab["id"]>(
    selectedTabIdFromProps
  );

  React.useEffect(() => {
    setSelectedTabId(selectedTabIdFromProps);
  }, [selectedTabIdFromProps]);

  const handleTabChange = (tabId: string) => {
    if (areTabsDisabled) return;
    setSelectedTabId(tabId);
    onTabChange?.(tabId);
  };

  return (
    <div className="w-full">
      <TabsPrimitive.Root value={selectedTabId} onValueChange={handleTabChange}>
        <TabsPrimitive.List
          className={tw(
            tabListStyles[variant],
            tabPlacementStyles[placement],
            showBorder && "border-b border-basic-200"
          )}
        >
          {beforeTabs && beforeTabs}
          {tabs.map(
            ({ id, title, disabled: isTabDisabled = false, tooltip }) => (
              <TabsPrimitive.Trigger
                key={id}
                value={id}
                disabled={isTabDisabled || areTabsDisabled}
                className={tw(
                  "relative font-semibold leading-loose text-basic-900 hover:text-green-700",
                  tabStyles[variant],
                  tabSizeStyles[size],
                  selectedTabId === id && tabActiveStyles[variant],
                  (isTabDisabled || areTabsDisabled) &&
                    "text-basic-300 hover:text-basic-300"
                )}
              >
                {tooltip ? (
                  <Tooltip overlayText={tooltip}>
                    <span>{title}</span>
                  </Tooltip>
                ) : (
                  <span>{title}</span>
                )}
              </TabsPrimitive.Trigger>
            )
          )}
          {afterTabs && afterTabs}
        </TabsPrimitive.List>
        {children}
      </TabsPrimitive.Root>
    </div>
  );
};

const Content: ContentComponent = ({ children, tabId }) => (
  <div>
    <TabsPrimitive.Content value={tabId}>{children}</TabsPrimitive.Content>
  </div>
);

Tabs.Content = Content;

const tabListStyles: Record<TabVariants, string> = {
  primary: "relative flex gap-1 w-full overflow-auto sm:bg-white",
  secondary:
    "relative flex gap-1 rounded-full w-full overflow-auto bg-green-100 px-0",
  tertiary: "relative flex rounded-full w-full overflow-auto",
  quaternary: "relative flex gap-1 w-full overflow-auto px-0 font-space",
};

const tabPlacementStyles: Record<TabPlacements, string> = {
  start: "justify-start",
  center: "justify-center",
  ["justify-evenly"]: "justify-evenly",
  end: "justify-end",
};

const tabStyles: Record<TabVariants, string> = {
  primary: "m-0",
  secondary: "m-1 w-full",
  tertiary: "text-basic-800 font-space font-medium",
  quaternary:
    "font-medium text-green-1100 hover:text-green-1100 hover:bg-white rounded-full",
};

const tabActiveStyles: Record<TabVariants, string> = {
  primary:
    "after:content-[''] after:absolute after:h-1 after:bottom-0 after:left-0 after:right-0 " +
    "text-green-1000 after:bg-green-1000 after:block",
  secondary: "bg-white m-1 mb-[3px] rounded-full text-green-800",
  tertiary: "bg-white text-black rounded-full font-semibold",
  quaternary:
    "bg-green-1000 hover:bg-green-1000 rounded-full text-white border-green hover:text-white leading-[22px]",
};

const tabSizeStyles: Record<TabSizes, string> = {
  responsive:
    "p-2 sm:px-3 sm:after:rounded-t md:py-3 md:px-4 text-base lg:text-lg",
  xs: "py-2 px-2 text-base after:rounded-none",
  sm: "py-2 px-3 text-sm after:rounded-t",
  compact_md: "py-1 px-4 text-base after:rounded-t",
  md: "py-3 px-4 text-base after:rounded-t",
  lg: "py-3 px-4 text-lg after:rounded-t",
};

export default Tabs;
